import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatsComponent } from 'src/app/pages/dashboard/stats/stats.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { CountUpModule } from 'ngx-countup';
import { MailSenderComponent } from './mail-sender.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MailSenderComponent
],
  exports:[MailSenderComponent],
  imports: [   
    CountUpModule,   
    DialogModule,
    CommonModule,
    FormsModule,
    ProgressSpinnerModule,    
  ]
})
export class MailSenderModule { }
