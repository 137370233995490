import { Component, Input, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { MessageService } from 'primeng/api';
import { SortCriteria } from 'src/app/shared/pipes/sort.pipe';
import { HttpClient } from '@angular/common/http';
import { FilterService } from '../services/filter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mail-sender',
  templateUrl: './mail-sender.component.html',
  styleUrls: ['./mail-sender.component.css'],
  providers: [DialogService, MessageService]
})
export class MailSenderComponent implements OnInit {
  masterprojectstaff: any[] = [];
  masterProjectStaffType:any[]=[];
  selectedprojectstaff: any ;
  selectedprojectStaffTypeID:any;
  toEmail: string = '';
  ccEmail: string = '';
  remarks: string = '';
  filterState: any = {};
  private filterStateSubscription!: Subscription;
  constructor(private http: HttpClient,private rtmisservice:FHIRtmisService
    ,public config: DynamicDialogConfig,private filterService: FilterService) {}

  ngOnInit() {    
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
      // Update filter state with the changed value
      this.filterState = filterData.filterState; 
      // Call the method in other component to handle the filter changes
      if(this.filterService.isInitialized()){
        //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
        this.loadProjectStaffType();
      }
    });    
  }
  loadProjectStaffType() {
    this.rtmisservice.GetDashboardDataSummary('SSProjectStaffType',this.filterState).subscribe(ProjStaffTypes => {
        this.masterProjectStaffType = ProjStaffTypes;
    });
  }
  
  loadProjectStaff() {
    this.rtmisservice.GetMasterData('ProjectStaff', 'districtid', this.filterService.filterState.SelDistrictID, 'ProjectStaffType_id', this.selectedprojectStaffTypeID).subscribe(projectstaffs => {
        this.masterprojectstaff = projectstaffs;       
    });
  }
  sendEmail() {
    if(this.selectedprojectstaff=="0"){
      alert("Select Project Staff");
      return;
    }
    var formdata = new FormData();
    formdata.append("projectstaff",this.selectedprojectstaff);
    formdata.append("projectstafftype",this.selectedprojectStaffTypeID);
    formdata.append("to_email",this.toEmail);
    formdata.append("cc_email",this.ccEmail);
    formdata.append("remarks",this.remarks);
    formdata.append("indctr",this.config.data.modalfor);
    formdata.append("valuefor",this.config.data.valuefor);
    formdata.append("isupd",this.config.data.valueforid);
    formdata.append("districtid",this.filterService.filterState.SelDistrictID);
    formdata.append("fromdate",this.filterService.filterState.selectedDateRange.startDate.format("YYYY-MM-DD"));
    formdata.append("todate",this.filterService.filterState.selectedDateRange.endDate.format("YYYY-MM-DD"));
    this.http.post<any>(`https://rtmis.fhindia.org/api/send_email.php`, formdata, {
    }).subscribe(res => {  
      if(res.success){
        alert(res.message); 
        this.selectedprojectStaffTypeID="0";
        this.selectedprojectstaff="0";
        this.ccEmail="";        
      }
      else{
        alert(res.message); 
      }
    }), (err:any) => {      
      console.log("Error Occured " + err);
    }
    
  }
  ngOnDestroy() {
      
  }
}
