import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MasterListComponent } from '../masterlist/masterlist.component';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { StorageService } from '../../login/storage.service';

@Component({
  selector: 'app-ssclfeedback',
  templateUrl: './ssclfeedback.component.html',
  styleUrls: ['./ssclfeedback.component.css'],
  providers: [DialogService, MessageService]
})
export class SsclFeedbackComponent implements OnInit {
  @Input() datafor: string = '';
  @Input() valuefor: string = '';
  @Input() valueforid: string = '';
  @Input() title: string = '';
  @Input() selectedRowItem: any;
  feedbackdata:any[]=[];
  selectsForm!: FormGroup;
  userlogin:any;
  @Output() dataChanged: EventEmitter<void> = new EventEmitter<void>();
  constructor(private http: HttpClient,private rtmisservice:FHIRtmisService, private storageService: StorageService
    ,public dialogService: DialogService, public messageService: MessageService,private formBuilder: FormBuilder) { 
    this.userlogin=this.storageService.getUser();
    if(this.userlogin){      
    }    
  }
  CurDate:any;
  ngOnInit(): void {
    this.CurDate = moment();
    this.selectsForm = this.formBuilder.group({
        FeedbackDetails: this.formBuilder.array([]),
    });    
    this.LoadSSCLData();
  }
  ngOnChanges(changes: SimpleChanges) {
    // Handle changes to the selected row item
    //console.log(changes['selectedRowItem']);
    this.feedbackList?.clear();
    if (changes['selectedRowItem'] && !changes['selectedRowItem'].firstChange) {
      this.selectedRowItem=changes['selectedRowItem'].currentValue;  
      this.LoadSSCLData();
    }    
  }
  LoadSSCLData(){
    if(this.selectedRowItem!=undefined){
        if(this.selectedRowItem.feedbackdata==null)this.feedbackdata=[];
        else this.feedbackdata=this.selectedRowItem.feedbackdata;
        for(let i=0;i<3;i++){
            if(this.feedbackdata.length>i){
                this.feedbackList.push(this.formBuilder.group({Feedbackid:new FormControl(this.feedbackdata[i].Feedbackid),feedback: new FormControl(this.feedbackdata[i].feedback),deadline: new FormControl(this.feedbackdata[i].deadline),allotedtoid: new FormControl(this.feedbackdata[i].allotedtoid),allotedto: new FormControl(this.feedbackdata[i].allotedto),feedbackstatus: new FormControl(this.feedbackdata[i].feedbackstatus)}));
            }
            else{
                this.feedbackList.push(this.formBuilder.group({Feedbackid:new FormControl('0'),feedback: new FormControl(''),deadline: new FormControl(''),allotedtoid: new FormControl(this.selectedRowItem.BCCFZone_id),allotedto: new FormControl(this.selectedRowItem.BCCFZone_Name),feedbackstatus: new FormControl('Pending')}));
            }
        }
      }
  }
  get feedbackList(): FormArray {
    return this.selectsForm.get('FeedbackDetails') as FormArray;
  }
  isShift: boolean[] = [];
  onKeyDown(event: KeyboardEvent, formctrlname: any) {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 16) {
      this.isShift[formctrlname] = true;
    }
  }
  onKeyUpInner(event: KeyboardEvent, formctrlname: any,ind:any) {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 16) {
      this.isShift[formctrlname] = false;
    }    
    var dateValue = this.feedbackList.controls[ind].get(formctrlname)?.value;
    if (
      (keyCode >= 48 && keyCode <= 57) || // Numeric keys
      keyCode === 8 || // Backspace
      keyCode <= 37 || // Arrow keys
      keyCode <= 39 || // Arrow keys
      (keyCode >= 96 && keyCode <= 105) // Numpad keys
    ) {
      if ((dateValue.length === 4 || dateValue.length === 7) && keyCode !== 8) {
        dateValue += '-';
        this.feedbackList.controls[ind].get(formctrlname)?.setValue(dateValue);
      }
    } else {
      event.preventDefault();
    }
  }
  
  SaveFeedback(){
    var formdata = new FormData();
    let y=0;
    for(let i=0;i<this.feedbackList.length;i++){
        if(this.feedbackList.controls[i].get('feedback')?.value!=''){
            y++;break;
        }
    }
    if(y==0){
        alert("Kindly enter atleast one feedback");
        return;
    }
    formdata.append("FeedbackData",JSON.stringify(this.feedbackList.value));
    formdata.append("indctr","SSCLFeedback");
    formdata.append("valuefor",this.valuefor);
    formdata.append("valueforid",this.selectedRowItem.ID);
    formdata.append("EnteredBy", this.userlogin.userid);    
    formdata.append("AppVersion", "1.0");   
    this.http.post<any>(`https://rtmis.fhindia.org/api/ModalPostServices.php`, formdata, {
    }).subscribe(res => {  
      if(res.success){
        this.messageService.add({ severity: 'success', summary: res.message.replace(this.datafor,"Feedback"), detail: this.selectedRowItem.ID, sticky: true });         
        this.feedbackList?.clear();
        this.dataChanged.emit(this.selectedRowItem.ID);
      }
      else{
        this.messageService.add({ severity: 'warn', summary: res.message, detail: "", sticky: true }); 
      }
    }), (err:any) => {      
      console.log("Error Occured " + err);
    }
  }
  ref: DynamicDialogRef | undefined;

  show() {
      this.ref = this.dialogService.open(MasterListComponent, { 
         data: {
            modalfor: this.datafor,
            valuefor:this.valuefor,
            valueforid:this.valueforid,
            isMaster:true
          },
          header: this.title,
          width: '99%',
          height:'99vh',
          contentStyle: { overflow: 'auto' },
          baseZIndex: 10000,
          maximizable: true,
          draggable:true,
          resizable:false
      });

      this.ref.onClose.subscribe((product: any) => {
          if (product) {
              this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: product.name });
          }
      });

      this.ref.onMaximize.subscribe((value) => {
          this.messageService.add({ severity: 'info', summary: 'Maximized', detail: `maximized: ${value.maximized}` });
      });
  }

  ngOnDestroy() {
      if (this.ref) {
          this.ref.close();
      }
  }
}
