<p-toast></p-toast>
<main class="bg-grey px-3"> 
    <div class="row">
        <div class="col-md-12 col-sm-6">
            <div class="portlet light bordered">
                <div class="portlet-title">
                    <div class="caption caption-md">
                        <i class="icon-bar-chart font-dark hide"></i>
                        <span class="caption-subject font-green-steel uppercase bold">EMBED Project, Monthly Progress Report</span>
                        <span class="caption-helper">
                            <p-progressSpinner [style]="{ width: '30px', height: '30px' }" aria-label="Loading" *ngIf="isdataloaded"></p-progressSpinner>
                            <input type='submit' [disabled]="!AnnexureCData.finalizesave" value='Save as PDF' class='btn btn-warning btn-block' (click)="finalizeAnnexureCData()">
                            <input type='submit' value='Print' class='btn btn-warning btn-block' (click)="printDiv()">
                            <!-- <input type='submit' *ngIf="userlogin.logintype=='Admin'" value='Reset' class='btn btn-warning btn-block' (click)="ResetAnnexureCData();"> -->
                        </span>
                    </div>
                </div>
                <div class="portlet-body">
                    <table *ngIf="!blockstatus" class="table table-bordered table-striped table-condensed flip-content" style="font-size:7pt !important;">
                        <tbody>
                            <tr style="page-break-after:always !important;">
                                <td colspan="4" class="cellheading">
                                    <p>
                                        Progress Report for Elimination of Mosquito Borne Endemic Diseases (EMBED) Project
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxSizePerFile]="1048576" [selectedFiles]="AnnexureCData.titlepage"
                             (filesChange)="onFilesChange($event)"></app-file-upload>                                
                                </td>
                            </tr>
                            <tr>
                                <td class="cellheading">I. State:  </td>
                                <td colspan="3">{{AnnexureCData.SelMasterState}}</td>
                            </tr>
                            <tr>
                                <td class="cellheading" width="300px;">II.	District:</td>
                                <td>{{AnnexureCData.SelMasterDistrict}}</td>
                            </tr>
                            <tr>
                                <td class="cellheading">III.	Name of NGO Partner:  </td>
                                <td><input type="text" [disabled]="blockstatus"  [(ngModel)]="AnnexureCData.ACNameOfNGO" class="form-control" placeholder="Enter Name of NGO Partner" /></td>
                            </tr>
                            <tr>
                                <td class="cellheading">IV. Reporting period:  </td>
                                <td colspan="3">{{AnnexureCData.ReportingPeriod}}</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <b>Executive Summary:</b><br />
                                    <p><textarea class="form-control" rows="3"  [(ngModel)]="AnnexureCData.ExecutiveSummary" [disabled]="blockstatus" maxlength="800"></textarea>
                                    <p class="text-right">{{ 800 - AnnexureCData.ExecutiveSummary.length + ' / 800' }}</p>                                                         
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <b>Coverage and Demographic Profile:</b>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">V.	Coverage of the project: </td>
                            </tr>

                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;"> District </th>                                                    
                                                <th style="vertical-align: top;text-align: center;"> Local Body Type / Block </th>
                                                <th style="vertical-align: top;text-align: center;"> BCCFZone  </th>
                                                <th style="vertical-align: top;text-align: center;"> Ward / Panchayat </th>
                                                <th style="vertical-align: top;text-align: center;"> Basti Slum / Village </th>
                                                <th style="vertical-align: top;text-align: center;"> Clusters </th>
                                                <th style="vertical-align: top;text-align: center;"> Households </th>
                                                <th style="vertical-align: top;text-align: center;"> Population </th>
                                           </tr>
                                        </thead>
                                        <tbody *ngFor="let provdist of  AnnexureCData.AnnexureCSummary | unique:'District_Name'">
                                            <tr *ngFor="let provtype of  AnnexureCData.AnnexureCSummary | customFilter: { District_Name: provdist.District_Name }:true">
                                                <td> {{provtype.District_Name}} </td>
                                                <td  style="text-align: right;"> {{provtype.LocalBodyType | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{provtype.BCCFZone | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{provtype.Ward | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{provtype.BastiSlum | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{provtype.Cluster | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{provtype.HouseHold | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{provtype.Population | number:'1.0-0'}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            
                            <tr>
                                <td colspan="4" class="cellheading">
                                    <p>
                                        Objective 1: To improve knowledge on prevention and treatment of malaria at the individual, household and community level
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;" rowspan="2"> District </th>                                                    
                                                <th style="vertical-align: top;text-align: center;" *ngFor="let provtype of  AnnexureCData.ProviderTypeSummary | unique:'ProviderType_Name'  | sort: { property: 'ProviderType_Name' }"># of {{provtype.ProviderType_Name}}</th>
                                            </tr>
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;" *ngFor="let provtype of  AnnexureCData.ProviderTypeSummary | unique:'ProviderType_Name' | sort: { property: 'ProviderType_Name' }">New | Total</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let provdist of  AnnexureCData.ProviderTypeSummary | unique:'District'  | sort: { property: 'District' }">                                                
                                            <tr>
                                                <td> {{provdist.District}} </td>
                                                <td style="text-align: right;" *ngFor="let provtype of  AnnexureCData.ProviderTypeSummary | customFilter: { District: provdist.District }:true | sort: { property: 'ProviderType_Name' }"> {{provtype.CTotal | number:'1.0-0'}} | {{provtype.Total | number:'1.0-0'}}  </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">VI.	Community interventions - Chaupal: </td>
                            </tr>

                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;"> District </th>
                                                <th style="vertical-align: top;text-align: center;"> Chuapal Conducted  </th>
                                                <th style="vertical-align: top;text-align: center;"> #Cluster Reached Through Chaupal </th>
                                                <th style="vertical-align: top;text-align: center;"> #BastiSlum Reached Through Chaupal</th>
                                                <th style="vertical-align: top;text-align: center;"> #Participant Reached Through Chaupal</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let provtype of  AnnexureCData.ChaupalSummary">
                                            <tr>
                                                <td> {{provtype.District}} </td>
                                                <td  style="text-align: right;"> {{provtype.Chaupal | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{provtype.Cluster | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{provtype.BastiSlum | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{provtype.Participant | number:'1.0-0'}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">Chaupal Graphs</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxFiles]="2" [maxSizePerFile]="1048576" [captionhidestatus]="true" [extrahidestatus]="true" [extraheading]="'About the Graph'" [selectedFiles]="AnnexureCData.ChaupalGraph"
                             (filesChange)="onFilesChange($event)"></app-file-upload> 
                                </td>
                            </tr>
                            <tr style="page-break-after:always !important;">
                                <td colspan="4" class="cellheading">VII. Strengthen Community Empowerment and Mobilization: </td>
                            </tr>
                            
                            <tr>
                                <td colspan="4" class="cellheading">
                                    <p><textarea class="form-control" rows="3"  [(ngModel)]="AnnexureCData.StrengthenCommunity" [disabled]="blockstatus"maxlength="300"></textarea>
                                    <p class="text-right">{{ 300 - AnnexureCData.StrengthenCommunity.length + ' / 300' }}</p>
                                </td>
                            </tr>
                            <tr style="page-break-after:always !important;">
                                <td colspan="4" class="cellheading">VII. Involvement of school children as change agent for Malaria free BastiSlum: </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">
                                    <p><textarea class="form-control" rows="3"  [(ngModel)]="AnnexureCData.InvolvementSchoolChild" [disabled]="blockstatus"maxlength="300"></textarea>
                                    <p class="text-right">{{ 300 - AnnexureCData.InvolvementSchoolChild.length + ' / 300' }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;width:100px;"> District </th>
                                                <th style="vertical-align: top;text-align: center;width:150px;"> Type of Meeting </th>
                                                <th style="vertical-align: top;text-align: center;width:100px;"> No. of Meeting<br />Current / Total </th>
                                                <th style="vertical-align: top;text-align: center;width:100px;"> No. of Participant<br />Current / Total</th>                                                    
                                                <th style="vertical-align: top;text-align: center;width:250px;"> Outcome </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="AnnexureCData.ActivitySummary.length!=0">
                                            <tr *ngFor="let actsum of AnnexureCData.ActivitySummary | customFilter: { ActivityType_id: '2' }:true">
                                                <td> {{actsum.District}} </td>
                                                <td> {{actsum.ActivityType}} </td>
                                                <td  style="text-align: center;"> {{actsum.CurMeeting}} / {{actsum.TotalMeeting}}</td>
                                                <td  style="text-align: center;"> {{actsum.TotalParticipant}} </td>
                                                <td  style="text-align: left;"><input type="text" [disabled]="blockstatus"  [(ngModel)]="actsum.OutCome" class="form-control" placeholder="" /></td>
                                            </tr>
                                            <!-- <tr>
                                                <td> {{AnnexureCData.SelMasterDistrict}} </td>
                                                <td> 0 </td>
                                                <td> 0 </td>
                                                <td> 0 </td>
                                                <td></td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">
                                    VIII. Vector control and personal protection Larval source management (LSM)
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">
                                    <textarea class="form-control" rows="2"  [(ngModel)]="AnnexureCData.LSMDetail" [disabled]="blockstatus" maxlength="300"></textarea>
                                    <p class="text-right">{{ 300 - AnnexureCData.LSMDetail.length + ' / 300' }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;"> District </th>
                                                <th style="vertical-align: top;text-align: center;"> Total water sources</th>
                                                <th style="vertical-align: top;text-align: center;"> Oil treatment of water source </th>
                                                <th style="vertical-align: top;text-align: center;"> Gumbusia</th>                                                    
                                                <th style="vertical-align: top;text-align: center;"> Dried </th>
                                                 <th style="vertical-align: top;text-align: center;"> Filled </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let actsum of AnnexureCData.WSDataAC ">
                                                <td> {{actsum.District}} </td>
                                                <td  style="text-align: right;"> {{actsum.TotalWS | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.Oil | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.GumbusiaFish | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.Dried | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.PermanentlyFilled | number:'1.0-0'}} </td>                                                    
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">IX. Larval source management Graphs</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxFiles]="2" [maxSizePerFile]="1048576" [captionhidestatus]="true" [extrahidestatus]="true" [extraheading]="'About the Graph'" [selectedFiles]="AnnexureCData.LarvalsourceGraph"
                             (filesChange)="onFilesChange($event)"></app-file-upload>                                     
                                </td>
                            </tr>
                            <!-- <tr>
                                <td colspan="4" class="cellheading">
                                    IX. Reducing human-vector contact through personal protection
                                </td>
                            </tr> -->
                            <!-- <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;"> District </th>
                                                <th style="vertical-align: top;text-align: center;"> LLIN distributed Project Basti/Slum (no of Basti/Slum)</th>
                                                <th style="vertical-align: top;text-align: center;"> # LLIN distributed </th>
                                                <th style="vertical-align: top;text-align: center;"> # Household covered</th>                                                                                                        
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let actsum of AnnexureCData.CommunityDriveAC">
                                                <td> {{actsum.District}} </td>
                                                <td  style="text-align: right;"> {{actsum.LLINVillCount | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.LLINCount | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.LLINHHCount | number:'1.0-0'}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr> -->
                            <!-- <tr>
                                <td colspan="4" class="cellheading">
                                    X. Indoor Residual Spray (IRS)
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;"> District </th>
                                                <th style="vertical-align: top;text-align: center;"> IRS Sprayed Basti/Slum Project Basti/Slum (no of Basti/Slum)</th>
                                                <th style="vertical-align: top;text-align: center;"> # Houses done IRS completed </th>
                                                <th style="vertical-align: top;text-align: center;"> # Houses done IRS Partial </th>                                                                                                        
                                                <th style="vertical-align: top;text-align: center;"> # Household covered </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let actsum of AnnexureCData.CommunityDriveAC">
                                                <td> {{actsum.District}} </td>
                                                <td  style="text-align: right;"> {{actsum.IRSVillCount | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.CompHHCount | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.ParHHCount | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.IRSHHCount | number:'1.0-0'}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr> -->
                            <tr>
                                <td colspan="4" class="cellheading">
                                    X. Container/Larva survey report
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;"> District </th>
                                                <th style="vertical-align: top;text-align: center;"> # Houses done Surveyed </th>
                                                <th style="vertical-align: top;text-align: center;"> # Houses done Larva Surveyed </th>
                                                <th style="vertical-align: top;text-align: center;"> # Container Found </th>                                                                                                        
                                                <th style="vertical-align: top;text-align: center;"> # Container Larva Found </th>
                                                <th style="vertical-align: top;text-align: center;"> # Container Vacated </th>                                                                                                        
                                                <th style="vertical-align: top;text-align: center;"> # Container Filled Temophos Oil </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let actsum of AnnexureCData.ChaupalSummary">
                                                <td> {{actsum.District}} </td>
                                                <td  style="text-align: right;"> {{actsum.HHSurveyed | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.HHLarvaFound | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.ContainerFound | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.LarvaContainerFound | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.KhaliKaraya | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.TemophosOilDalwaya | number:'1.0-0'}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">
                                    XI. Use of mosquito repellents:
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">
                                    <p><textarea class="form-control" rows="3"  [(ngModel)]="AnnexureCData.repellentUse" [disabled]="blockstatus"maxlength="300"></textarea>
                                    <p class="text-right">{{ 300 - AnnexureCData.LSMDetail.length + ' / 300' }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;"> District </th>
                                                <th style="vertical-align: top;text-align: center;"> No Method User </th>
                                                <th style="vertical-align: top;text-align: center;"> Coil User </th>
                                                <th style="vertical-align: top;text-align: center;"> Fast Card User </th>
                                                <th style="vertical-align: top;text-align: center;"> Fire </th>      
                                                <th style="vertical-align: top;text-align: center;"> IRS User </th>                                                                                                        
                                                <th style="vertical-align: top;text-align: center;"> BedNet User </th>
                                                <th style="vertical-align: top;text-align: center;"> Spray User </th>
                                                <th style="vertical-align: top;text-align: center;"> Cream User </th>
                                                <th style="vertical-align: top;text-align: center;"> Electric replant User </th>
                                                <th style="vertical-align: top;text-align: center;"> Agarbatti </th>
                                                <th style="vertical-align: top;text-align: center;"> Window Jali </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let actsum of AnnexureCData.ChaupalSummary">
                                                <td> {{actsum.District}} </td>
                                                <td  style="text-align: right;"> {{actsum.NoMethod | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.Coil | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.FastCard | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.Fire | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.IRS | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.Net | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.Spray | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.Cream | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.Refill | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.Agarbatti | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{actsum.WindowJali | number:'1.0-0'}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                             <tr>
                                <td colspan="4" class="cellheading">Repellent Use Graphs</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxFiles]="2" [maxSizePerFile]="1048576" [captionhidestatus]="true" [extrahidestatus]="true" [extraheading]="'About the Graph'" 
                                    [selectedFiles]="AnnexureCData.RepellentUseGraph"
                             (filesChange)="onFilesChange($event)"></app-file-upload>                                     
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">
                                    XII. Other interventions:
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">
                                    <p><textarea class="form-control" rows="3"  [(ngModel)]="AnnexureCData.otherinvention" [disabled]="blockstatus"maxlength="300"></textarea>
                                    <p class="text-right">{{ 300 - AnnexureCData.otherinvention.length + ' / 300' }}</p>
                                </td>
                            </tr>
                            <tr style="page-break-after:always !important;">
                                <td colspan="4" class="cellheading">XIII. Objective 2: To support state Government in prevention and treatment efforts for malaria elimination. </td>
                            </tr>
                             <tr>
                                <td colspan="4" class="cellheading">
                                    <textarea class="form-control" rows="2"  [(ngModel)]="AnnexureCData.treatmentefforts" [disabled]="blockstatus" maxlength="300"></textarea>
                                    <p class="text-right">{{ 300 - AnnexureCData.treatmentefforts.length + ' / 300' }}</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">A. Training/Meeting/ Capacity Building events.</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th> District </th>
                                                <th> Type of Meeting </th>
                                                <th style="vertical-align: top;text-align: center;"> Event in reporting period
                                                <th style="vertical-align: top;text-align: center;"> Total Event</th>
                                                <th style="vertical-align: top;text-align: center;"> Number of Participants</th>
                                                <th style="vertical-align: top;text-align: center;"> Tools provided </th>
                                                <th style="vertical-align: top;text-align: center;"> Outcome </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let actsum of AnnexureCData.ActivitySummary">
                                                <td> {{actsum.District}} </td>
                                                <td> {{actsum.ActivityType}} </td>
                                                <td  style="text-align: right;"> {{actsum.CurMeeting | number:'1.0-0' }} </td>
                                                <td  style="text-align: right;"> {{actsum.TotalMeeting | number:'1.0-0' }} </td>
                                                <td  style="text-align: center;"> {{actsum.TotalParticipant }} </td>
                                                <td  style="text-align: left;"><input type="text" [disabled]="blockstatus"  [(ngModel)]="actsum.ProvidedTools" class="form-control" placeholder="" /></td>
                                                <td  style="text-align: left;"><input type="text" [disabled]="blockstatus"  [(ngModel)]="actsum.OutCome" class="form-control" placeholder="" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">
                                    B. Training of Health Care Providers through eModule 
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxFiles]="2" [maxSizePerFile]="1048576" [captionhidestatus]="true" [extrahidestatus]="true" [extraheading]="'About the Graph'" 
                                    [selectedFiles]="AnnexureCData.OtherGraph"
                             (filesChange)="onFilesChange($event)"></app-file-upload>                                     
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">XV.	Support in active case detection</td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">
                                    A. During Health & Nutrient Day
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;"> District </th>
                                                <th style="vertical-align: top;text-align: center;"> HND Supported</th>
                                                <th style="vertical-align: top;text-align: center;"> No of Blood test <br />C / T</th>
                                                <th style="vertical-align: top;text-align: center;"> No of Positive Case identified<br />C / T</th>
                                                <th style="vertical-align: top;text-align: center;"> No of patient follow up<br />C / T</th>
                                                <th style="vertical-align: top;text-align: center;"> No of Patient Completed treatment<br />C / T</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let actsum of AnnexureCData.HNDSummaryAC">
                                                <td> {{actsum.District}} </td>
                                                <td  style="text-align: center;"> {{actsum.CurrUHND}} | {{actsum.TotalUHND}}</td>
                                                <td  style="text-align: center;"> {{actsum.CurrNoOfSlides}} | {{actsum.TotalNoOfSlides}} </td>
                                                <td  style="text-align: center;"> {{actsum.CurrNoOfPositive}} | {{actsum.TotalNoOfPositive}} </td>
                                                <td  style="text-align: center;"> {{actsum.CurrNoofoldpatient}} | {{actsum.TotalNoofoldpatient}} </td>
                                                <td  style="text-align: center;"> {{actsum.CurrNoOfCompleteCources}} | {{actsum.TotalNoOfCompleteCources}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                           <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxFiles]="2" [maxSizePerFile]="1048576" [captionhidestatus]="true" [extrahidestatus]="true" [extraheading]="'About the Graph'" 
                                    [selectedFiles]="AnnexureCData.vhndgraph"
                             (filesChange)="onFilesChange($event)"></app-file-upload>                                     
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">B. Case Detection activites </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxSizePerFile]="1048576" [captionhidestatus]="true" [extrahidestatus]="true" [extraheading]="'About the Graph'" 
                                    [selectedFiles]="AnnexureCData.T3Graph"
                             (filesChange)="onFilesChange($event)"></app-file-upload>                                     
                                </td>
                            </tr>
                             <tr>
                                <td colspan="4" class="cellheading">C. Data Comparison</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxSizePerFile]="1048576" [captionhidestatus]="true" [extrahidestatus]="true" [extraheading]="'About the Graph'" 
                                    [selectedFiles]="AnnexureCData.tableGraph"
                             (filesChange)="onFilesChange($event)"></app-file-upload>                                     
                                </td>
                            </tr>
                             <tr>
                                <td colspan="4" class="cellheading">Availability of RDT and Antimalarial Drug at ASHA Level</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxSizePerFile]="1048576" [captionhidestatus]="true" [extrahidestatus]="true" [extraheading]="'About the Graph'" 
                                    [selectedFiles]="AnnexureCData.AshaSupplyChain"
                             (filesChange)="onFilesChange($event)"></app-file-upload>                                        
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="cellheading">XV.	Staff status under Embed project:</td>
                            </tr>
                            
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th width="4%"> S.No. </th>
                                                <th style="vertical-align: top;text-align: center;"> District </th>
                                                <th style="vertical-align: top;text-align: center;"> Staff category </th>
                                                <th style="vertical-align: top;text-align: center;"> Approved  </th>
                                                <th style="vertical-align: top;text-align: center;"> Current no. (include the new appointments) </th>
                                                <th style="vertical-align: top;text-align: center;"> Dropout in the reporting  month </th>
                                                <th style="vertical-align: top;text-align: center;"> New appointment in the reporting month </th>
                                                <th style="vertical-align: top;text-align: center;"> Remarks </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let staff of AnnexureCData.StaffSummary; let i = index">
                                                <td>{{i +1}}</td>
                                                <td> {{staff.District_Name}} </td>
                                                <td> {{staff.ProjectStaffType_Name}} </td>
                                                <td  style="text-align: right;"> {{staff.Approved | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{staff.Total | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{staff.CurrentDropout | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"> {{staff.CurrentJoining | number:'1.0-0'}} </td>
                                                <td  style="text-align: right;"><input type="text" [disabled]="blockstatus" class="form-control"  [(ngModel)]="staff.Remarks" placeholder="Enter Remark" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                             
                            <tr>
                                <td colspan="4" class="cellheading">
                                    XVI    Supportive supervision visits:
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <table class="table table-bordered table-striped table-condensed flip-content">
                                        <thead class="flip-content">
                                            <tr>
                                                <th style="vertical-align: top;text-align: center;"> Staff	 </th>
                                                <th style="vertical-align: top;text-align: center;" colspan="3"> Field Visit</th>
                                            </tr>
                                            <tr>
                                                <th>  </th>
                                                <th style="vertical-align: top;text-align: center;"> Planned </th>
                                                <th style="vertical-align: top;text-align: center;"> Conducted</th>
                                                <th style="vertical-align: top;text-align: center;"> Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let actsum of AnnexureCData.SupportiveSupervision">
                                                <td>{{actsum.ProjectStaffType_Name}}</td>
                                                <td  style="text-align: left;"><input type="text" [disabled]="blockstatus"  [(ngModel)]="actsum.Planned" class="form-control" placeholder="" /></td>
                                                <td  style="text-align: right;">{{actsum.Conducted | number:'1.0-0' }}</td>
                                                <td  style="text-align: left;"><input type="text" [disabled]="blockstatus"  [(ngModel)]="actsum.Remark" class="form-control" placeholder="" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr style="page-break-before:always !important;">
                                <td colspan="4" class="cellheading">
                                    Photo and News Clipping
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxFiles]="6" [maxSizePerFile]="1048576" [captionhidestatus]="true" [datehidestatus]="true"  
                                    [selectedFiles]="AnnexureCData.PhotoNews"
                             (filesChange)="onFilesChange($event)"></app-file-upload>                                      
                                </td>
                            </tr>
                            <tr style="page-break-before:always !important;">
                                <td colspan="4" class="cellheading">
                                    Letter
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <app-file-upload [accept]="'image/*'" [maxSizePerFile]="1048576" [captionhidestatus]="true" [datehidestatus]="true" [extraheading]="" 
                                    [selectedFiles]="AnnexureCData.Letterimage"
                             (filesChange)="onFilesChange($event)"></app-file-upload>  
                                    <!-- <div img-upload  maximage=1   [(ngModel)]="AnnexureCData."   captionallowed="Yes" dateallowed="Yes" extraallowed="No" extraheading=""></div> -->
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                    <input type='submit' [disabled]="blockstatus" value='Save' class='btn btn-warning btn-block' (click)="SaveAnnexureCData();">
                                    <p-progressSpinner [style]="{ width: '30px', height: '30px' }" aria-label="Loading" *ngIf="isdataloaded"></p-progressSpinner>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                <label><input type="checkbox"   [(ngModel)]="AnnexureCData.finalize" />  I have entered and check all data and finalize the data</label>
                                    <input type='submit' [disabled]="!AnnexureCData.finalizesave" value='Save as PDF' class='btn btn-warning btn-block' (click)="finalizeAnnexureCData()">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-md-12" style="width:210mm !important;font-family: serif !important;" #PdfDiv id="PdfDiv">
                            <table class="table table-bordered" style="font-size:7pt !important;">
                                <tbody>
                                    <tr>
                                        <td colspan="4">
                                            <table class="table table-bordered" width="100%">
                                                <tr>
                                                    <td valign="top" rowspan="6" style="height: 900px;border: none !important;padding-top:50px;width:20px;">
                                                        <div style="color: blue;width: 15px;background-color: #44546b;height: 970px;left: 20px;position: relative;"></div>
                                                    </td>
                                                    <td colspan="3" style="text-align:right;border: none !important;padding-top:50px;">
                                                        <div style="width: 99%;">
                                                            <img src="assets/images/logo1.png" style="margin-right: 10px !important; height: 75px;max-height: 75px;line-height: 75px;width: 350px;" alt="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" style="text-align:right;padding-top:80px;border: none !important;padding-right: 20px;">
                                                        <span style="font-size: 22px;font-weight: bold;">Elimination of Mosquito Borne <br/>Endemic Diseases Project (EMBED)<br/>
                                                        {{AnnexureCData.SelMasterDistrict}}, {{AnnexureCData.SelMasterState}}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" style="text-align:right;border: none !important;" valign="top"><h4>Supported by Godrej Consumer Products Limited</h4></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="top" style="height: 370px;width: 620px;" *ngIf="AnnexureCData.ChaupalGraph"><br/><img [src]="AnnexureCData.titlepage[0]?.thumbnailUrl" style="width: 600px;height: 370px;border: none !important;margin-left: 50px;" /></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="top" style="border: none !important;height: 250px;text-align: center;padding-top: 100px;">
                                                        <span style="font-size: 22px;text-align: center;">EMBED Project, Monthly Progress Report<br/>( {{AnnexureCData.ReportingPeriod}} )</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" style="text-align:right;padding-right:30px;"><b>{{curdate | date:'dd-MM-yyyy'}}</b></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-before:always !important;">
                                        <td colspan="4" style="text-align:right;border: none !important;padding-top:50px;">
                                            <b>EMBED (Urban) Monthly Progress Report-{{AnnexureCData.SelMasterDistrict}} ({{AnnexureCData.ReportingPeriod}})</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="cellheading">
                                            <p>
                                                Progress Report for Elimination of Mosquito Borne Endemic Diseases (EMBED) Project
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="cellheading">I. State:  </td>
                                        <td colspan="3">{{AnnexureCData.SelMasterState}}</td>
                                    </tr>
                                    <tr>
                                        <td class="cellheading" width="280px;">II. District:</td>
                                        <td colspan="3">{{AnnexureCData.SelMasterDistrict}}</td>
                                    </tr>
                                    <tr>
                                        <td class="cellheading">III. Name of Implementing Agency:  </td>
                                        <td colspan="3">{{AnnexureCData.ACNameOfNGO}}</td>
                                    </tr>
                                    <tr>
                                        <td class="cellheading">IV. Reporting period:  </td>
                                        <td colspan="3">{{AnnexureCData.ReportingPeriod}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <b>Executive Summary:</b><br />
                                            <p>{{AnnexureCData.ExecutiveSummary}}</p>                                        
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <b>Coverage and Demographic Profile:</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="cellheading">V.	Coverage of the project: </td>
                                    </tr>
        
                                    <tr>
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;"> District </th>                                                    
                                                        <th style="vertical-align: top;text-align: center;"> Local Body Type / Block </th>
                                                        <th style="vertical-align: top;text-align: center;"> BCCF Zone  </th>
                                                        <th style="vertical-align: top;text-align: center;"> Ward / Panchayat </th>
                                                        <th style="vertical-align: top;text-align: center;"> Basti Slum / Village </th>
                                                        <th style="vertical-align: top;text-align: center;"> Clusters </th>
                                                        <th style="vertical-align: top;text-align: center;"> Households </th>
                                                        <th style="vertical-align: top;text-align: center;"> Population </th>
                                                   </tr>
                                                </thead>
                                                <tbody *ngFor="let provdist of  AnnexureCData.AnnexureCSummary | unique:'District_Name'">
                                                    <tr *ngFor="let provtype of  AnnexureCData.AnnexureCSummary | customFilter: { District_Name: provdist.District_Name }:true">
                                                        <td> {{provtype.District_Name}} </td>
                                                        <td  style="text-align: right;"> {{provtype.LocalBodyType | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{provtype.BCCFZone | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{provtype.Ward | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{provtype.BastiSlum | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{provtype.Cluster | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{provtype.HouseHold | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{provtype.Population | number:'1.0-0'}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            
                                        </td>
                                    </tr>
                                    
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">
                                            <p>
                                                Key Achievements
                                            </p>
                                            <p>
                                                Objective 1: To improve knowledge on prevention and treatment of malaria at the individual, household and community level.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">    
                                            <table class="table table-bordered table-striped table-condensed flip-content">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;" rowspan="2"> District </th>                                                    
                                                        <th style="vertical-align: top;text-align: center;" *ngFor="let provtype of  AnnexureCData.ProviderTypeSummary | unique:'ProviderType_Name'  | sort: { property: 'ProviderType_Name' }"># of {{provtype.ProviderType_Name}}</th>
                                                    </tr>
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;" *ngFor="let provtype of  AnnexureCData.ProviderTypeSummary | unique:'ProviderType_Name' | sort: { property: 'ProviderType_Name' }">New | Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let provdist of  AnnexureCData.ProviderTypeSummary | unique:'District'  | sort: { property: 'District' }">                                                
                                                    <tr>
                                                        <td> {{provdist.District}} </td>
                                                        <td style="text-align: right;" *ngFor="let provtype of  AnnexureCData.ProviderTypeSummary | customFilter: { District: provdist.District }:true | sort: { property: 'ProviderType_Name' }"> {{provtype.CTotal | number:'1.0-0'}} | {{provtype.Total | number:'1.0-0'}}  </td>
                                                    </tr>
                                                </tbody>
                                            </table>                                                                                   
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">VI.	Community interventions - Chaupal </td>
                                    </tr>
        
                                    <tr>
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;"> Chuapal Conducted  </th>
                                                        <th style="vertical-align: top;text-align: center;"> # of Cluster Reached </th>
                                                        <th style="vertical-align: top;text-align: center;"> # of Basti / Slum Reached</th>
                                                        <th style="vertical-align: top;text-align: center;"> # of Participant Reached</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let provtype of  AnnexureCData.ChaupalSummary">
                                                    <tr>
                                                        <td> {{provtype.District}} </td>
                                                        <td  style="text-align: right;"> {{provtype.Chaupal | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{provtype.Cluster | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{provtype.BastiSlum | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{provtype.Participant | number:'1.0-0'}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.ChaupalGraph.length>0">
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.ChaupalGraph.length>0">
                                            <br/><img [src]="AnnexureCData.ChaupalGraph[0]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.ChaupalGraph[0]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.ChaupalGraph[0]?.extradata}}
                                        </td>
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.ChaupalGraph.length>1">
                                            <br/><img [src]="AnnexureCData.ChaupalGraph[1]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.ChaupalGraph[1]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.ChaupalGraph[1]?.extradata}}
                                        </td>
                                    </tr>
                                    
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">VII. Strengthen Community Empowerment and Mobilization: </td>
                                    </tr>
                                    
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <p>{{AnnexureCData.StrengthenCOmmunity}}</p>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                            <td colspan="4" class="cellheading">VII. Involvement of school children as change agent for Mosquito borne diseases including dengue and malaria free village: </td>
                                        </tr>
                                    <tr>
                                        <td colspan="4">
                                            <p>{{AnnexureCData.InvolvementSchoolChild}}</p>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Type of Meeting </th>
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> No. of Meeting<br />Current / Total </th>
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> No. of Participant<br />Current / Total</th>                                                    
                                                        <th style="vertical-align: top;text-align: center;width:250px;"> Outcome </th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="AnnexureCData.ActivitySummary.length!=0">
                                                    <tr *ngFor="let actsum of AnnexureCData.ActivitySummary | customFilter: { ActivityType_id: '2' }:true">
                                                        <td> {{actsum.District}} </td>
                                                        <td> {{actsum.ActivityType}} </td>
                                                        <td  style="text-align: center;"> {{actsum.CurMeeting}} / {{actsum.TotalMeeting}}</td>
                                                        <td  style="text-align: center;"> {{actsum.TotalParticipant}} </td>
                                                        <td  style="text-align: left;">{{actsum.OutCome}}</td>
                                                    </tr>
                                                    <!-- <tr>
                                                        <td> {{AnnexureCData.SelMasterDistrict}} </td>
                                                        <td> 0 </td>
                                                        <td> 0 </td>
                                                        <td> 0 </td>
                                                        <td></td>
                                                    </tr> -->
                                                </tbody>
                                                
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">
                                            VIII. Vector control, Larval source management (LSM) and personal protection.
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <p>{{AnnexureCData.LSMDetail}}</p>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;"> Total water sources</th>
                                                        <th style="vertical-align: top;text-align: center;"> Oil Filming </th>
                                                        <th style="vertical-align: top;text-align: center;"> Gumbusia Placement</th>                                                    
                                                        <th style="vertical-align: top;text-align: center;"> Dried </th>
                                                         <th style="vertical-align: top;text-align: center;"> Filled / Altered </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let actsum of AnnexureCData.WSDataAC ">
                                                        <td> {{actsum.District}} </td>
                                                        <td  style="text-align: right;"> {{actsum.TotalWS | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.Oil | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.GumbusiaFish | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.Dried | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.PermanentlyFilled | number:'1.0-0'}} </td>                                                    
                                                    </tr>
                                                </tbody>                                                
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.LarvalsourceGraph.length>0">
                                        <td colspan="4" class="cellheading">IX. Larval source management Graphs</td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.LarvalsourceGraph.length>0">
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.LarvalsourceGraph.length>0">
                                            <br/><img [src]="AnnexureCData.LarvalsourceGraph[0]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.LarvalsourceGraph[0]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.LarvalsourceGraph[0]?.extradata}}
                                        </td>
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.LarvalsourceGraph.length>1">
                                            <br/><img [src]="AnnexureCData.LarvalsourceGraph[1]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.LarvalsourceGraph[1]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.LarvalsourceGraph[1]?.extradata}}
                                        </td>                                         
                                    </tr>
                                    
                                    <!-- <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">
                                            IX. Reducing human-vector contact through personal protection
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;"> LLIN distributed Project village (no of Village)</th>
                                                        <th style="vertical-align: top;text-align: center;"> # LLIN distributed </th>
                                                        <th style="vertical-align: top;text-align: center;"> # Household covered</th>                                                                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr ng-repeat="actsum in AnnexureCData.CommunityDriveAC track by $index">
                                                        <td> {{actsum.District}} </td>
                                                        <td  style="text-align: right;"> {{actsum.LLINVillCount | number : fractionSize }} </td>
                                                        <td  style="text-align: right;"> {{actsum.LLINCount | number : fractionSize }} </td>
                                                        <td  style="text-align: right;"> {{actsum.LLINHHCount | number : fractionSize }} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">
                                            X. Indoor Residual Spray (IRS)
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;"> IRS Sprayed village Project village (no of Village)</th>
                                                        <th style="vertical-align: top;text-align: center;"> # Houses done IRS completed </th>
                                                        <th style="vertical-align: top;text-align: center;"> # Houses done IRS Partial </th>                                                                                                        
                                                        <th style="vertical-align: top;text-align: center;"> # Household covered </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr ng-repeat="actsum in AnnexureCData.CommunityDriveAC track by $index">
                                                        <td> {{actsum.District}} </td>
                                                        <td  style="text-align: right;"> {{actsum.IRSVillCount | number : fractionSize }} </td>
                                                        <td  style="text-align: right;"> {{actsum.CompHHCount | number : fractionSize }} </td>
                                                        <td  style="text-align: right;"> {{actsum.ParHHCount | number : fractionSize }} </td>
                                                        <td  style="text-align: right;"> {{actsum.IRSHHCount | number : fractionSize }} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr> -->
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">
                                            X. Container/Larva survey report
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;"> # Houses done Surveyed </th>
                                                        <th style="vertical-align: top;text-align: center;"> # Houses done Larva Surveyed </th>
                                                        <th style="vertical-align: top;text-align: center;"> # Container Found </th>                                                                                                        
                                                        <th style="vertical-align: top;text-align: center;"> # Container Larva Found </th>
                                                        <th style="vertical-align: top;text-align: center;"> # Container Vacated </th>                                                                                                        
                                                        <th style="vertical-align: top;text-align: center;"> # Container Filled Temophos Oil </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let actsum of AnnexureCData.ChaupalSummary">
                                                        <td> {{actsum.District}} </td>
                                                        <td  style="text-align: right;"> {{actsum.HHSurveyed | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.HHLarvaFound | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.ContainerFound | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.LarvaContainerFound | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.KhaliKaraya | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.TemophosOilDalwaya | number:'1.0-0'}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">
                                            XI. Use of mosquito repellents
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <p>{{AnnexureCData.repellentUse}}</p>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;"> No Method User </th>
                                                        <th style="vertical-align: top;text-align: center;"> Coil User </th>
                                                        <th style="vertical-align: top;text-align: center;"> Fast Card User </th>
                                                        <th style="vertical-align: top;text-align: center;"> Fire </th>      
                                                        <th style="vertical-align: top;text-align: center;"> IRS User </th>                                                                                                        
                                                        <th style="vertical-align: top;text-align: center;"> BedNet User </th>
                                                        <th style="vertical-align: top;text-align: center;"> Spray User </th>
                                                        <th style="vertical-align: top;text-align: center;"> Cream User </th>
                                                        <th style="vertical-align: top;text-align: center;"> Electric replant User </th>
                                                        <th style="vertical-align: top;text-align: center;"> Agarbatti </th>
                                                        <th style="vertical-align: top;text-align: center;"> Window Jali </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let actsum of AnnexureCData.ChaupalSummary">
                                                        <td> {{actsum.District}} </td>
                                                        <td  style="text-align: right;"> {{actsum.NoMethod | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.Coil | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.FastCard | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.Fire | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.IRS | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.Net | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.Spray | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.Cream | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.Refill | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.Agarbatti | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{actsum.WindowJali | number:'1.0-0'}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.RepellentUseGraph.length>0">
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.RepellentUseGraph.length>0">
                                            <br/><img [src]="AnnexureCData.RepellentUseGraph[0]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.RepellentUseGraph[0]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.RepellentUseGraph[0]?.extradata}}
                                        </td>
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.RepellentUseGraph.length>1">
                                            <br/><img [src]="AnnexureCData.RepellentUseGraph[1]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.RepellentUseGraph[1]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.RepellentUseGraph[1]?.extradata}}
                                        </td>                                        
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">
                                            XII. Other interventions:
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <p>{{AnnexureCData.otherinvention}}</p>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">XIII. Objective 2: To support state Government in prevention and treatment efforts for malaria elimination. </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <p>{{AnnexureCData.treatmentefforts}}</p>
                                        </td>
                                    </tr>
                                     <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">A. Training/Meeting/ Capacity Building events.</td>
                                    </tr>
                                    <tr style="page-break-inside:auto !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> Type of Events </th>
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> Events in reporting period<br/>Current / Total
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> Number of Participants<br/>Current / Total</th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Tools provided </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Outcome </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let actsum of AnnexureCData.ActivitySummary | customFilter: { TotalMeeting: '0' }:false" style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                                        <td> {{actsum.District}} </td>
                                                        <td> {{actsum.ActivityType}} </td>
                                                        <td  style="text-align: right;"> {{actsum.CurMeeting | number:'1.0-0' }} / {{actsum.TotalMeeting | number:'1.0-0' }} </td>
                                                        <td  style="text-align: center;"> {{actsum.TotalParticipant }} </td>
                                                        <td  style="text-align: left;">{{actsum.ProvidedTools}}</td>
                                                        <td  style="text-align: left;">{{actsum.OutCome}}</td>
                                                    </tr>                                                    
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr *ngIf="AnnexureCData.OtherGraph">
                                        <td colspan="4" class="cellheading">
                                            B. Training of Health Care Providers through eModule 
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.OtherGraph.length>0">
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.OtherGraph.length>0">
                                            <br/><img [src]="AnnexureCData.OtherGraph[0]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.OtherGraph[0]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.OtherGraph[0]?.extradata}}
                                        </td>
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.OtherGraph.length>1">
                                            <br/><img [src]="AnnexureCData.OtherGraph[1]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.OtherGraph[1]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.OtherGraph[1]?.extradata}}
                                        </td>                                           
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">XV.	Support in active case detection</td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">
                                            A. During Health & Nutrient Day
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;"> HND Supported </th>
                                                        <th style="vertical-align: top;text-align: center;"> No of Blood test <br />C / T</th>
                                                        <th style="vertical-align: top;text-align: center;"> No of Positive Case identified<br />C / T</th>
                                                        <th style="vertical-align: top;text-align: center;"> No of patient follow up<br />C / T</th>
                                                        <th style="vertical-align: top;text-align: center;"> No of Patient Completed treatment<br />C / T</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let actsum of AnnexureCData.HNDSummaryAC">
                                                        <td> {{actsum.District}} </td>
                                                        <td  style="text-align: center;"> {{actsum.CurrUHND}} | {{actsum.TotalUHND}}</td>
                                                        <td  style="text-align: center;"> {{actsum.CurrNoOfSlides}} | {{actsum.TotalNoOfSlides}} </td>
                                                        <td  style="text-align: center;"> {{actsum.CurrNoOfPositive}} | {{actsum.TotalNoOfPositive}} </td>
                                                        <td  style="text-align: center;"> {{actsum.CurrNoofoldpatient}} | {{actsum.TotalNoofoldpatient}} </td>
                                                        <td  style="text-align: center;"> {{actsum.CurrNoOfCompleteCources}} | {{actsum.TotalNoOfCompleteCources}} </td>
                                                    </tr>                                                    
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.vhndgraph.length>0">
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.vhndgraph.length>0">
                                            <br/><img [src]="AnnexureCData.vhndgraph[0]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.vhndgraph[0]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.vhndgraph[0]?.extradata}}
                                        </td>
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.vhndgraph.length>1">
                                            <br/><img [src]="AnnexureCData.vhndgraph[1]?.thumbnailUrl" />
                                            <br/><b style="font-size: 11px;">{{AnnexureCData.vhndgraph[1]?.caption}}</b><br/>
                                            <br/>{{AnnexureCData.vhndgraph[1]?.extradata}}
                                        </td>                                        
                                    </tr>
                                     <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.T3Graph.length>0">
                                        <td colspan="4" class="cellheading">B. Case Detection activites </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.T3Graph.length>0">
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.T3Graph"><br/>
                                            <img [src]="AnnexureCData.T3Graph[0]?.thumbnailUrl" />
                                              <br/><b style="font-size: 11px;">{{AnnexureCData.T3Graph[0]?.caption}}</b><br/>
                                             <br/>{{AnnexureCData.T3Graph[0]?.extradata}}                                            
                                        </td>
                                        <td colspan="2"></td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.tableGraph.length>0">
                                        <td colspan="4" class="cellheading">C. Data Comparison</td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.tableGraph.length>0">
                                        <td colspan="4" style="text-align: center;" *ngIf="AnnexureCData.tableGraph"><br/><br/><img style="width:600px;" [src]="AnnexureCData.tableGraph[0]?.thumbnailUrl" />
                                             <br/><b style="font-size: 11px;">{{AnnexureCData.tableGraph[0]?.caption}}</b><br/>
                                             <br/>{{AnnexureCData.tableGraph[0]?.extradata}}                                             
                                        </td>
                                    </tr>
                                     <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.AshaSupplyChain.length>0">
                                        <td colspan="4" class="cellheading">Availability of RDT and Antimalarial Drug at ASHA Level</td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;" *ngIf="AnnexureCData.AshaSupplyChain.length>0">
                                        <td colspan="4"style="text-align: center;" *ngIf="AnnexureCData.AshaSupplyChain.length>0">
                                            <br/><img [src]="AnnexureCData.AshaSupplyChain[0]?.thumbnailUrl" />
                                             <br/><b style="font-size: 11px;">{{AnnexureCData.AshaSupplyChain[0]?.caption}}</b><br/>
                                             <br/>{{AnnexureCData.AshaSupplyChain[0]?.extradata}}                                            
                                        </td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">XV.	Staff status under EMBED project</td>
                                    </tr>
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;" width="4%"> S.No. </th>
                                                        <th style="vertical-align: top;text-align: center;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;"> Staff category </th>
                                                        <th style="vertical-align: top;text-align: center;"> Approved  </th>
                                                        <th style="vertical-align: top;text-align: center;"> Current no. (include the new appointments) </th>
                                                        <th style="vertical-align: top;text-align: center;"> Dropout in the reporting  month </th>
                                                        <th style="vertical-align: top;text-align: center;"> New appointment in the reporting month </th>
                                                        <th style="vertical-align: top;text-align: center;"> Remarks </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let staff of AnnexureCData.StaffSummary | customFilter: { Total: '0' }:false let i = index" style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                                        <td>{{i +1}}</td>
                                                        <td> {{staff.District_Name}} </td>
                                                        <td> {{staff.ProjectStaffType_Name}} </td>
                                                        <td  style="text-align: right;"> {{staff.Approved | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{staff.Total | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{staff.CurrentDropout | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;"> {{staff.CurrentJoining | number:'1.0-0'}} </td>
                                                        <td  style="text-align: right;">{{staff.Remarks}}</td>
                                                    </tr>                                                    
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    
                                    <tr style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                        <td colspan="4" class="cellheading">
                                            XVI    Supportive supervision visits
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;"> Staff	 </th>
                                                        <th style="vertical-align: top;text-align: center;" colspan="3"> Field Visit</th>
                                                    </tr>
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;">  </th>
                                                        <th style="vertical-align: top;text-align: center;"> Planned </th>
                                                        <th style="vertical-align: top;text-align: center;"> Conducted</th>
                                                        <th style="vertical-align: top;text-align: center;"> Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let actsum of AnnexureCData.SupportiveSupervision | customFilter: { Total: '!0' }:true" style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                                        <td>{{actsum.ProjectStaffType_Name}}</td>
                                                        <td  style="text-align: left;">{{actsum.Planned}}</td>
                                                        <td  style="text-align: right;">{{actsum.Conducted | number:'1.0-0' }}</td>
                                                        <td  style="text-align: left;">{{actsum.Remark}}</td>
                                                    </tr>                                                    
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-before:always !important;">
                                        <td colspan="4" class="cellheading">
                                            Annexure 1 List of Intervention Area
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;" width="4%"> S.No. </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> BCCF Zone  </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Ward / Panchayat </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Basti Slum / Village </th>
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> Total Household </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let staff of AnnexureCData.SlumDetail  let i = index" style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                                        <td  style="text-align: center;">{{i +1}}</td>
                                                        <td> {{staff.District}} </td>
                                                        <td  style="text-align: left;"> {{staff.BCCFZone }} </td>
                                                         <td  style="text-align: left;"> {{staff.Ward_Name}} </td>
                                                        <td  style="text-align: left;"> {{staff.BastiSlum }} </td>
                                                        <td  style="text-align: right;"> {{staff.HHCount}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="cellheading">
                                            Annexure 2 Project Team
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;" width="4%"> S.No. </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Name </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Designation  </th>
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> Mobile Number </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Location </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let staff of AnnexureCData.StaffDetail  let i = index" style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                                        <td  style="text-align: center;">{{i +1}}</td>
                                                        <td> {{staff.District}} </td>
                                                        <td  style="text-align: left;"> {{staff.ProjectStaff_Name}} </td>
                                                        <td  style="text-align: left;"> {{staff.ProjectStaffType }} </td>
                                                        <td  style="text-align: left;"> {{staff.mobilenumber }} </td>
                                                        <td  style="text-align: right;"> {{staff.PostingLocation}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="cellheading">
                                            Planning for {{nextDateRange?.startDate?.format("DD")}}-{{nextDateRange?.endDate?.format("DD MMMM YYYY")}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <table class="table table-bordered table-striped table-condensed flip-content" style="page-break-inside:auto !important;">
                                                <thead class="flip-content">
                                                    <tr>
                                                        <th style="vertical-align: top;text-align: center;width:100px;"> Date </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> District </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> NN / Block </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> BCCF Zone </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Ward / Panchayat </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Basti / Village  </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Activity </th>
                                                        <th style="vertical-align: top;text-align: center;width:150px;"> Remark </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let staff of AnnexureCData.PlanningDetail  let i = index" style="page-break-inside:avoid !important; page-break-after:auto !important;">
                                                        <td  style="text-align: left;"> {{staff.displaydate	 }} </td>
                                                        <td> {{staff.district}} </td>
                                                        <td> {{staff.muncipalarea	}} </td>
                                                        <td> {{staff.zone	}} </td>
                                                        <td  style="text-align: left;"> {{staff.territorial	}} </td>
                                                        <td  style="text-align: left;"> {{staff.administrative	 }} </td>
                                                        <td  style="text-align: left;"> {{staff.eventtype	 }} </td>
                                                        <td  style="text-align: right;"> {{staff.title	}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="page-break-before:always !important;" *ngIf="AnnexureCData.PhotoNews.length>0">
                                        <td colspan="4" class="cellheading">
                                           Annexure 3 Photographs and Media Coverage
                                        </td>
                                    </tr>
                                    <tr *ngIf="AnnexureCData.PhotoNews.length>0">
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.PhotoNews.length>0">
                                            <br/><img [src]="AnnexureCData.PhotoNews[0]?.thumbnailUrl" /><br/><b style="font-size: 11px;">{{AnnexureCData.PhotoNews[0]?.caption}}-{{AnnexureCData.PhotoNews[0]?.imgdate}}</b><br/><br/><br/>                                            
                                        </td>
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.PhotoNews.length>1">
                                            <br/><img [src]="AnnexureCData.PhotoNews[1]?.thumbnailUrl" /><br/><b style="font-size: 11px;">{{AnnexureCData.PhotoNews[1]?.caption}}-{{AnnexureCData.PhotoNews[1]?.imgdate}}</b><br/><br/><br/>                                            
                                        </td>
                                    </tr>
                                    <tr *ngIf="AnnexureCData.PhotoNews.length>2">
                                        <td colspan="2" *ngIf="AnnexureCData.PhotoNews.length>2">
                                            <br/><img [src]="AnnexureCData.PhotoNews[2]?.thumbnailUrl" /><br/><b style="font-size: 11px;">{{AnnexureCData.PhotoNews[2]?.caption}}-{{AnnexureCData.PhotoNews[2]?.imgdate}}</b><br/><br/><br/>                                            
                                        </td>
                                        <td colspan="2" *ngIf="AnnexureCData.PhotoNews.length>3">
                                            <br/><img [src]="AnnexureCData.PhotoNews[3]?.thumbnailUrl" /><br/><b style="font-size: 11px;">{{AnnexureCData.PhotoNews[3]?.caption}}-{{AnnexureCData.PhotoNews[3]?.imgdate}}</b><br/><br/><br/>                                            
                                        </td>
                                    </tr>
                                    <tr *ngIf="AnnexureCData.PhotoNews.length>4">
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.PhotoNews.length>4">
                                            <br/><img [src]="AnnexureCData.PhotoNews[4]?.thumbnailUrl" /><br/><b style="font-size: 11px;">{{AnnexureCData.PhotoNews[4]?.caption}}-{{AnnexureCData.PhotoNews[4]?.imgdate}}</b><br/><br/><br/>                                            
                                        </td>
                                        <td colspan="2" style="text-align: center;" *ngIf="AnnexureCData.PhotoNews.length>5">
                                            <br/><img [src]="AnnexureCData.PhotoNews[5]?.thumbnailUrl" /><br/><b style="font-size: 11px;">{{AnnexureCData.PhotoNews[5]?.caption}}-{{AnnexureCData.PhotoNews[5]?.imgdate}}</b><br/><br/><br/>                                            
                                        </td>
                                    </tr>
                                    <tr style="page-break-before:always !important;" *ngIf="AnnexureCData.Letterimage.length>0">
                                        <td colspan="4" class="cellheading">
                                            Letter
                                        </td>
                                    </tr>
                                    <tr *ngIf="AnnexureCData.Letterimage.length>0">
                                        <td colspan="4" style="text-align: center;" ><br/>
                                                <img style="width:600px;height:840px;" [src]="AnnexureCData.Letterimage[0]?.thumbnailUrl" />
                                             <br/><b style="font-size: 11px;">{{AnnexureCData.Letterimage[0]?.caption}}-{{AnnexureCData.Letterimage[0]?.imgdate}}</b><br/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>