<nav class="navbar navbar-expand-md navbar-light shadow-sm">
  <div class="container-fluid page-header">
      <a class="navbar-brand" href="#">
        <img src="assets/images/logo1.png" class="custom-logo" alt="" itemprop="logo" srcset="assets/images/logo1.png 431w, assets/images/logo1-300x60.png 300w" sizes="(max-width: 431px) 100vw, 431px" width="431" height="86" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      <!-- BEGIN HEADER MENU -->
      <div class="nav-collapse navbar-collapse navbar-responsive-collapse collapse" aria-expanded="false" style="height: 0px;margin-top:15px !important;">
        <ul class="nav navbar-nav">
            <li ng-class="PageName == 'graphical_dashboard.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="graphical_dashboard.php" class="text-uppercase">
                    <i class="icon-bar-chart"></i> Dashboard-1 </a>                                    
            </li>
            <li  ng-class="PageName == 'android_dashboard.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="android_dashboard.php" class="text-uppercase">
                    <i class="icon-graph"></i> Dashboard-2 </a>                                    
            </li>
            <li  ng-class="PageName == 'AnnexureC.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="AnnexureC.php" class="text-uppercase">
                    <i class="icon-briefcase"></i> Annexure-C </a>                                    
            </li>
            <li  ng-class="PageName == 'activity_calendar.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="activity_calendar.php" class="text-uppercase">
                    <i class="icon-briefcase"></i> Calendar </a>                                    
            </li>
            <li  ng-class="PageName == 'EMBED_Quarterly_Report.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="EMBED_Quarterly_Report.php" class="text-uppercase">
                    <i class="icon-briefcase"></i> Tracker </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="https://emodule.fhindia.org/" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> Emodule </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="http://t3.fhindia.org/" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> T3 </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="https://embed.fhindia.org/" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> EMBED </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="https://fhiindia.org/" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> FH India </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="http://route.indianmicrosystems.com/dm-login" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> TMS </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="https://t3.fhindia.org/supplychain/index.php" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> SMS </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="https://hr.fhindia.co.in/dms/" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> DMS </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="https://mdata.fhindia.org/MalariaTreatmentProtocol.php" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> MTP </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="https://usha.fhindia.org/" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> E-Scaleup </a>                                    
            </li>
            <li  ng-class="dropdown dropdown-fw dropdown-fw-disabled">
                <a href="https://civicaction.fhindia.org/" class="text-uppercase" target="_blank">
                    <i class="icon-graph"></i> Youth </a>                                    
            </li>
            <li  ng-hide="true" ng-class="PageName == 'TabularReport.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="TabularReport.php" class="text-uppercase">
                    <i class="icon-briefcase"></i> Tabular Report </a>                                    
            </li>                         
            <li ng-hide="true"  ng-class="PageName == 'activity_log.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '" >
                <a href="activity_log.php" class="text-uppercase">
                    <i class="icon-briefcase"></i> Activity Log </a>                                    
            </li>                                									
        </ul>
    </div>
    <!-- END HEADER MENU -->		
  </div>
</nav>