import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MasterListComponent } from '../../Crudpages/masterlist/masterlist.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-achieve-target',
  templateUrl: './achieve-target.component.html',
  styleUrls: ['./achieve-target.component.css'],
  providers: [DialogService, MessageService]
})
export class AchieveTargetComponent  implements OnInit {
  @Input() title: string = '';
  @Input() datafor: string = '';
  @Input() valuefor: string = '';
  @Input() totalachieved: number=0;  
  @Input() achieved: any[] = [];
  @Input() target:number = 0;
  @Input() isdataloaded:boolean=false;
  @Input() loadmore:boolean=false;
  @Input() isValueSelect:boolean=false;
  achievepercent:number=0;
  constructor(public dialogService: DialogService, public messageService: MessageService) {
    
  }
  ngOnInit(): void {
    
  }
  ngOnChanges(changes: SimpleChanges) {
    // Handle changes to the selected row item
    //console.log(changes['selectedRowItem']);
    if(this.isdataloaded){
      if(this.target!=0){
        this.achievepercent = Math.floor((this.totalachieved / this.target) * 100);
      }
    } 
  }
  ref: DynamicDialogRef | undefined;

  show(valuefor:any) {
      this.ref = this.dialogService.open(MasterListComponent, { 
         data: {
            modalfor: this.datafor,
            valuefor:valuefor,
            valueforid:'',
            isMaster:false
          },
          header: this.title +(valuefor==''?'':'('+valuefor+')'),
          width: '99%',
          height:'99vh',
          contentStyle: { overflow: 'auto' },
          baseZIndex: 10000,
          maximizable: true,
          draggable:true,
          resizable:false
      });

      this.ref.onClose.subscribe((product: any) => {
          if (product) {
              this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: product.name });
          }
      });

      this.ref.onMaximize.subscribe((value) => {
          this.messageService.add({ severity: 'info', summary: 'Maximized', detail: `maximized: ${value.maximized}` });
      });
  }

  ngOnDestroy() {
      if (this.ref) {
          this.ref.close();
      }
  }
}