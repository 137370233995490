<div class="card">
    <p-toast></p-toast>
    <p-table #dt [columns]="selectedColumns" [value]="modaldata" [tableStyle]="{ 'min-width': '50rem' }" 
    styleClass="p-datatable-gridlines"
    [exportHeader]="'customExportHeader'"
    dataKey="ID"
    sortField="DistrictName" sortMode="single"
    rowGroupMode="subheader" groupRowsBy="DistrictName"
    [rowHover]="true"
    selectionMode="none"    
    (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
    [(selection)]="selectedData"    
    [loading]="loading"
    [paginator]="true"
    [rows]="50"
    [scrollable]="true"
    scrollHeight="400px" [resizableColumns]="true"
    [showCurrentPageReport]="true"    
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="[25,50,100,200]">
        <ng-template pTemplate="caption">
            <div class="flex">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{'min-width': '200px'}" placeholder="Choose Columns"></p-multiSelect>                
                &nbsp;<button type="button" pButton pRipple icon="pi pi-file" (click)="dt.exportCSV()" class="mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                &nbsp;<button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
                <!-- &nbsp;<button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button> -->
                &nbsp;<button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({ selectionOnly: true })" class="p-button-info ml-auto" pTooltip="Selection Only Export" tooltipPosition="bottom"></button>
                <!-- &nbsp;<p-fileUpload  #fileUpload mode="basic" (onSelect)="validateFileSize($event, fileUpload)" accept=".xls,.xlsx" [maxFileSize]="2097152" label="" chooseLabel=""  pTooltip="Import" tooltipPosition="bottom" chooseIcon="pi pi-upload" uploadIcon="pi pi-arrow-up" cancelIcon="pi pi-stop" class="mr-2 pfu"></p-fileUpload> -->
                <select [(ngModel)]="selectedmonth" [style]="{'min-width': '200px'}" placeholder="Choose Month">
                    <option value='0'>Select Month</option>";
                    <option value='2024-03-30'>March</option>";
                    <option value='2024-04-30'>April</option>";                    
                    <option value='2024-05-30'>May</option>";                    
                    <option value='2024-06-30'>June</option>";                    
                    <option value='2024-07-30'>July</option>";   
                    <option value='2024-08-30'>August</option>";   
                    <option value='2024-09-30'>September</option>";   
                    <option value='2024-10-30'>October</option>";   
                </select>                
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngIf="isremoveallowed" style="width: 35px;"></th>   
                <th *ngIf="iseditallowed" style="width: 35px;"></th>             
                <th [pSortableColumn]="col.field" pResizableColumn *ngFor="let col of columns | filter : false:'ishidden'"  [ngStyle]="{'max-width': col.field=='ID'?'95px':(col.maxwidth==null?'auto':col.maxwidth)}">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th *ngIf="isremoveallowed"></th>
                <th *ngIf="iseditallowed"></th>
                <th *ngFor="let col of columns | filter : false:'ishidden'">                    
                    <p-columnFilter [type]="col.fieldtype" [field]="col.field" [showClearButton]="false" *ngIf="col.fieldtype!=null"
                    [showApplyButton]="false"></p-columnFilter>
                </th>
            </tr>
            <tr>
                <th *ngIf="isremoveallowed"></th>
                <th *ngIf="iseditallowed"></th>
                <th *ngFor="let col of columns | filter : false:'ishidden'">
                    {{col.isTotal==true? getSummry(col.field) : ""}}                    
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-rowData  let-columns="columns" *ngIf="isgrouping">
            <tr pRowGroupHeader>
                <td [attr.colspan]="columns.length+2">                    
                    <span class="font-bold ml-2">{{rowData.DistrictName}}&nbsp;:&nbsp; {{calculateRowTotal(rowData.DistrictName)}}</span>
                </td>
            </tr>
        </ng-template>
        <!-- <ng-template pTemplate="groupfooter" let-rowData  let-columns="columns">
            <tr>
                <td [attr.colspan]="columns.length+2" class="text-right font-bold pr-6">Total Survey</td>
            </tr>
        </ng-template> -->
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngIf="isremoveallowed">
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" *ngIf="this.config.data.modalfor!='Chaupal'" (click)="deleteRowData(rowData)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-warning" *ngIf="this.config.data.modalfor=='Chaupal'" (click)="deleteBulkData(rowData)"></button>
                </td>
                <td *ngIf="iseditallowed">
                    <button *ngIf="config.data.modalfor!='Activity' || userlogin.logintype=='Admin' || (config.data.modalfor=='Activity' && rowData['UpdateChk']>moment().startOf('month').format('yyyy-MM-DD'))" 
                    pButton pRipple icon="pi pi-check" class="p-button-rounded p-button-success p-mr-2" [ngClass]="{'p-button-selected':selectedData===rowData}" (click)="selectRowData(rowData,null)"></button>
                    <!-- <button type="button" pButton pRipple icon="pi pi-check" styleClass="p-button-rounded"  (click)="selectRowData(rowData,null)"></button> -->
                </td>                
                <td *ngFor="let col of columns | filter : false:'ishidden'" pTooltip="{{col.maxwidth==null?'':rowData[col.field]}}"  [ngStyle]="{'max-width': col.field=='ID'?'95px':(col.maxwidth==null?'auto':col.maxwidth)}" class="p-mb-3 demo-container p-text-nowrap p-text-truncate">
                    {{ rowData[col.field] }}
                </td>
            </tr>
        </ng-template>
        
        <!-- <ng-template pTemplate="paginatorleft">
            <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button>
        </ng-template>
        <ng-template pTemplate="paginatorright">
            <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button>
        </ng-template> -->
    </p-table>
    <div style="padding: 10px;" *ngIf="(isaddallowed || selectedData) && config.data.isMaster">
        <app-crudmodal  (dataChanged)="onDataChanged($event)" [selectedRowItem]="selectedData" [modaltitle]="config.header || config.data.modalfor" [datafor]="config.data.modalfor" [valuefor]="config.data.valuefor" [valueforid]="config.data.valueforid"></app-crudmodal>
    </div>
    <div style="padding: 10px;" *ngIf="selectedData && config.data.modalfor=='ChaupalSupervision'">
        <app-ssclfeedback  [selectedRowItem]="selectedData" [datafor]="config.data.modalfor" (dataChanged)="onDataChanged($event)" [valuefor]="config.data.valuefor" [valueforid]="config.data.valueforid"></app-ssclfeedback>
    </div>
    <div style="padding: 10px;" *ngIf="isrequestallowed && selectedData">
        <button type="submit" class="btn btn-primary" (click)="onRequestDoc()">Request Document</button>
    </div>
    <div style="padding: 10px;" *ngIf="config.data.modalfor=='Activity' || config.data.modalfor=='AdministrativeArea'">
        <app-administrativearea-profile (dataChanged)="onDataChanged($event)" *ngIf="(config.data.modalfor=='AdministrativeArea')" [SlumProfileData]="selectedData" [modaltitle]="config.header || config.data.modalfor" [datafor]="config.data.modalfor" [valuefor]="config.data.valuefor" [valueforid]="config.data.valueforid"></app-administrativearea-profile>
        <app-activity-profile  *ngIf="(config.data.modalfor=='Activity')" (dataChanged)="onDataChanged($event)" [ActivityData]="selectedData" [modaltitle]="config.header || config.data.modalfor" [datafor]="config.data.modalfor" [valuefor]="config.data.valuefor" [valueforid]="config.data.valueforid"></app-activity-profile>
    </div>
</div>