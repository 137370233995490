import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform {
  transform(value: any[], columnName: string): any[] {
    if (!Array.isArray(value) || !columnName) {
      return value;
    }

    const uniqueValues :any[] = [];
    const seenValues:any = {};

    for (const item of value) {
      const columnValue = item[columnName];
      if (!seenValues[columnValue]) {
        seenValues[columnValue] = true;
        uniqueValues.push(item);
      }
    }

    return uniqueValues;
  }
}
