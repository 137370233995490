<div class="card shadow-sm achieve">
    <div class="card-body targetbody">
        <div class="row">
            <div class="col-6 title">{{title}}</div>
            <div class="col-6 achieve-value">{{totalachieved}}<span *ngIf="target!=-1">/{{target}}</span></div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p-progressSpinner [style]="{ width: '80px', height: '130px' }" aria-label="Loading" *ngIf="!isdataloaded"></p-progressSpinner>
            </div>
        </div>
        <div class="row" *ngIf="isdataloaded">            
            <div class="col-4">                
                <circle-progress  *ngIf="target!=-1"
                    [percent]="achievepercent"
                    [title]="achievepercent+ '%'"
                    [showSubtitle]="false"                                       
                    [outerStrokeColor]="'#78C000'"
                    [innerStrokeColor]="'#7893fc'"
                    [titleColor]="'#000000'"
                    [subtitleColor]="'#444444'"
                ></circle-progress>
            </div>
            <div class="col-8" >
                <div class="d-flex" *ngFor="let item of achieved; let i = index">
                    <div class="d-flex align-items-center">
                    <i class="fa fa-circle text-red fs-8px me-2"></i>
                    {{item.achievetitle}}
                    </div>
                    <div class="d-flex align-items-center ms-auto">
                        <div class="text-gray-500 small">
                            <a (click)="show(item.achievefor)" class="achieve-value" *ngIf="isValueSelect" href="javascript:;">{{ item.achievecount }}</a>
                            <span class="achieve-value" *ngIf="!isValueSelect">{{ item.achievecount }}</span>
                        </div>                    
                    </div>
                    </div>
                <div>
            </div>
        </div>
        <div class="stats-link" *ngIf="!isValueSelect">         
            <a class="btn-viewmore" (click)="show(valuefor)" href="javascript:;">View Detail&nbsp;<i class="fa fa-arrow-alt-circle-right" aria-hidden="true"></i></a>
        </div>
    </div>    
</div>