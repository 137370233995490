import { Component, Input, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { MasterListComponent } from '../../Crudpages/masterlist/masterlist.component';
import { MessageService } from 'primeng/api';
import { SortCriteria } from 'src/app/shared/pipes/sort.pipe';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.css'],
  providers: [DialogService, MessageService]
})
export class UpcomingEventsComponent implements OnInit {

  constructor(public dialogService: DialogService,private rtmisservice:FHIRtmisService, public messageService: MessageService) {
    this.criteria = {
      property: 'ActivityType_id',
      descending: false
    };
    this.listcriteria = {
      property: 'ActivityCount',
      descending: true
    };
   }

  ngOnInit(): void {
  }
  @Input() lstActivityType!:any[];
  @Input() isdataloaded:boolean=false;
  // get sortedItems(): any[] {
  //   return this.lstActivityType.slice().sort((a, b) => parseInt(b.ActivityCount) - parseInt(a.ActivityCount));
  // }
  ref: DynamicDialogRef | undefined;
  criteria!: SortCriteria;
  listcriteria!: SortCriteria;
  show(valuefor:any,title:any) {
      this.ref = this.dialogService.open(MasterListComponent, { 
         data: {
            modalfor: "Activity",
            valuefor:valuefor,
            valueforid:"",
            isMaster:true
          },
          header: title,
          width: '99%',
          height:'99vh',
          contentStyle: { overflow: 'auto' },
          baseZIndex: 10000,
          maximizable: true
      });

      this.ref.onClose.subscribe((product: any) => {
          if (product) {
              this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: product.name });
          }
      });

      this.ref.onMaximize.subscribe((value) => {
          this.messageService.add({ severity: 'info', summary: 'Maximized', detail: `maximized: ${value.maximized}` });
      });
  }
 
  MathFloor(val:any) {
    return Math.floor(val);
  }
  ngOnDestroy() {
      if (this.ref) {
          this.ref.close();
      }
  }
}
