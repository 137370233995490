import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { StorageService } from 'src/app/pages/login/storage.service';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/contactus', title: 'Contact Us', icon: 'nc-pin-3', class: '' },
  { path: '/successstories', title: 'Voice from the Field', icon: 'nc-spaceship', class: 'active-pro' },
  { path: '/gallery', title: 'Gallery', icon: 'nc-spaceship', class: 'active-pro' },
  { path: '/covid19', title: 'Covid-19', icon: 'nc-pin-3', class: '' },
  { path: '/resmat', title: 'Resource Material', icon: 'nc-pin-3', class: '' },
  { path: '/mediacoverage', title: 'Media Coverage', icon: 'nc-pin-3', class: '' },
];
@Component({
  //moduleId: module.id,
  selector: 'homenavbar-cmp',
  templateUrl: 'homenavbar.component.html',
  styleUrls: ['./homenavbar.component.css']
})



export class HomeNavbarComponent implements OnInit {
  private listTitles: any[]=[];
  location: Location;
  private nativeElement: Node;
  private toggleButton:any;
  private sidebarVisible: boolean;
  public isCollapsed = true;
  activelink: any;
  userlogin:any;
  @ViewChild("homenavbar-cmp", { static: false }) button:any;

  constructor(location: Location, private renderer: Renderer2, private element: ElementRef, private router: Router, private storageService: StorageService) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;        
    this.userlogin=this.storageService.getUser();
  }
  logout():void{
    this.storageService.clean();
    this.router.navigate(['/login']);
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
    if(window.location.hostname.includes("egarima")){
      this.router.navigate(['/egarimahome']);
    }
    //this.activelink = localStorage.getItem('activelink');
  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    this.activelink = titlee.slice(1);
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return '';
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

}
