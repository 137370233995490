import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MasterFilterComponent } from './masterfilter.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
    imports: [ RouterModule, CommonModule,FormsModule,NgMultiSelectDropDownModule,
        NgxDaterangepickerMd.forRoot({
            separator: ' - ',
            applyLabel: 'Okay',
        })
     ],
    declarations: [ MasterFilterComponent ],
    exports: [ MasterFilterComponent ]
})

export class MasterFilterModule {}
