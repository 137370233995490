
<div class="wrapper" id="myDiv1">    
    <!-- END HEADER -->
    <div class="container-fluid">
      <!-- BEGIN CONTAINER -->
      <div class="page-content" style="background: #f7f7f7;padding: 15px;">
        <main class="bg-grey px-3"> 
            <div class="row mt-3 g-1">
                <div class="col-sm-6 col-md-12">
                    <h3>Static Data</h3>
                </div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-sm-6 col-md-2">                
                    <app-stats [datafor]="'State'" [title]="'State'" [value]="StaticSummaryData.State" [isdataloaded]="isstaticdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'District'" [title]="'District'" [value]="StaticSummaryData.District"  [isdataloaded]="isstaticdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'MunicipalArea'" [title]="'NN / Block'" [value]="StaticSummaryData.MunicipalArea"  [isdataloaded]="isstaticdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'TerritorialArea'" [title]="'Ward / Panchayat'" [value]="StaticSummaryData.TerritorialArea"  [isdataloaded]="isstaticdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'AdministrativeArea'" [title]="'Slum / Village'" [value]="StaticSummaryData.Administrative_Area"  [isdataloaded]="isstaticdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>    
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'HouseHold'" [title]="'HouseHolds'" [value]="StaticSummaryData.HouseHold"  [isdataloaded]="isstaticdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>                                         
            </div> 
            <div class="row mt-3 g-1">
                <div class="col-sm-6 col-md-12">
                    <h3>Dynamic Data</h3>
                </div>
            </div>
            <div class="row mt-3 g-1">
                <div class="col-sm-6 col-md-2">                
                    <app-stats [datafor]="'Chaupal'" [title]="'Chaupal'" [value]="DynamicSummaryData.Chaupal" [isdataloaded]="isdynamicdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'SourceReduction'" [title]="'Source Reduction'" [value]="DynamicSummaryData.SourceReduction"  [isdataloaded]="isdynamicdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'CommunicationVan'" [title]="'Communication Van'" [value]="DynamicSummaryData.CommunicationVan"  [isdataloaded]="isdynamicdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'HND'" [title]="'Health and Nutrition Days'" [value]="DynamicSummaryData.HND"  [isdataloaded]="isdynamicdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'AshaTraining'" [title]="'Training of ASHA'" [value]="DynamicSummaryData.AshaTraining"  [isdataloaded]="isdynamicdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'CommunityDrive'" [title]="'Community Drive'" [value]="DynamicSummaryData.CommunityDrive"  [isdataloaded]="isdynamicdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>
                <div class="col-sm-6 col-md-2">
                    <app-stats [datafor]="'SchoolEvents'" [title]="'School Events'" [value]="DynamicSummaryData.SchoolEvents"  [isdataloaded]="isdynamicdataloaded" [change]="-1" [loadmore]="false"></app-stats>
                </div>                                             
            </div>                                   
        </main>
      </div>
    </div>
</div>