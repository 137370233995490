<div class="row mt-3 g-1">  
    <div class="col-md-12">
        <button class="btn btn-primary" (click)="updateDisplayedDate(-1)">Prev</button>
  <span>&nbsp;{{ displayedDate | date }}&nbsp;</span>
  <button class="btn btn-primary" (click)="updateDisplayedDate(1)">Next</button>
    </div>
</div>
<div class="row mt-3 g-1">         
    <div class="col-md-3 col-sm-6" *ngFor="let attendance of attendances">
        <div style="border: 1px solid;padding: 10px;margin: 4px;">
            <div style="display: flow-root;">
                <h2 class="table-avatar" style="float: left;">
                    <a disabled class="avatar">
                        <img alt="" [src]="attendance.Photo">
                    </a>
                    <a style="float: right;" disabled>{{attendance.ProjectStaff_Name}} 
                        <br/><small>{{attendance.ProjectStaffType}}</small>
                        <br/><a href="tel:{{attendance.mobilenumber}}">{{attendance.mobilenumber}}</a>
                    </a>                
                </h2>
                <span style="float: right;">{{attendance.end_TotalTime}}</span>
            </div>
            <div class="user-add-shedule-list">
                <div class="row mt-3 g-1">         
                    <div class="col-md-6 col-sm-6 col-lg-6">
                        <h2>
                            <a disabled  class="attendance" style="border:2px dashed #1eb53a">
                                <span>{{attendance.start_AttendanceType}}</span>
                                <img alt="" [src]="attendance.start_photo">
                                <span class="username-info m-b-10">{{attendance.start_time}}</span>
                                <span class="userrole-info" *ngIf="attendance.start_location!=''"><a target="_blank" href="https://maps.google.com/maps?z=17&hl=en&q={{attendance.start_location}}">Location</a></span>
                                <span>{{attendance.start_AttendanceSource}}</span><span *ngIf="attendance.start_HH!=''">{{attendance.start_HH}}</span>
                            </a>
                        </h2>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                        <h2>
                            <a disabled  class="attendance" style="border:2px dashed #1eb53a">
                                <span>{{attendance.end_AttendanceType}}</span>
                                <img alt="" [src]="attendance.end_photo">
                                <span class="username-info m-b-10">{{attendance.end_time}}</span>
                                <span class="userrole-info" *ngIf="attendance.end_location!=''"><a target="_blank" href="https://maps.google.com/maps?z=17&hl=en&q={{attendance.end_location}}">Location</a></span>
                                <span>{{attendance.end_AttendanceSource}}</span><span *ngIf="attendance.end_HH!=''">{{attendance.end_HH}}</span>
                            </a>
                        </h2>
                    </div>
                </div>                                                
            </div>  
            <div class="user-add-shedule-list" *ngIf="false">
                <a href="#" data-bs-toggle="modal" data-bs-target="#add_schedule">
                    <span><i class="fa fa-clock-o"></i></span>
                </a>
            </div>      
        </div>                                        
    </div> 
</div>        
     