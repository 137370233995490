<div class="container col-md-6 offset-md-3 mt-5">
  <div class="card  card-container">
      <h4 class="card-header" style="text-align: center;"><img
    id="profile-img"
    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
    class="profile-img-card"
  />Login</h4>
      <div class="card-body">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="mb-3">
                  <label class="form-label">Username</label>
                  <input type="text" formControlName="username" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required">Username is required</div>
                  </div>                  
              </div>
              <div class="mb-3">
                  <label class="form-label">Password</label>
                  <input type="password" formControlName="password" class="form-control" required [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
              </div>
              <div>
                  <button [disabled]="loading" class="btn btn-primary">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                      Login
                  </button>
              </div>
              <div class="form-group">
                <div *ngIf="f.submitted && isLoginFailed" class="alert alert-danger" role="alert">
                  Login failed: {{ errorMessage }}
                </div>
              </div>
          </form>
      </div>
  </div>
</div>