import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { StorageService } from '../../login/storage.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { Subscription } from 'rxjs';
import { SortCriteria } from 'src/app/shared/pipes/sort.pipe';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { AMCHARTS_GroupedBarGraph, AMCHARTS_MultiAxisGraph, AMCHARTS_SingleXYGraph } from 'src/app/shared/services/amchartgraphs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'home-cmp',
  //moduleId: module.id,
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DialogService]
})

export class HomeComponent implements OnInit {
  userlogin:any;
  filterState: any = {};
  private filterStateSubscription!: Subscription;  
  criteria!: SortCriteria;  
  ref: DynamicDialogRef | undefined;
  constructor(public dialogService: DialogService,private rtmisservice:FHIRtmisService, private storageService: StorageService
    , private router: Router,private filterService: FilterService) {    
    this.userlogin=this.storageService.getUser();
    if(this.userlogin){      
      this.criteria = {
        property: 'sortindex',
        descending: false
      };
    }
    else{
      this.router.navigate(['/login']);
    }
  }
  activeindex:any=2;
  handleChange(e:any){
    this.activeindex=e;
    if(this.activeindex==0){
      this.BindDynamicData();
    }
    else if(this.activeindex==1){
      this.BindGraphData();
    }
    else if(this.activeindex==2){
      this.BindStaticData();
    }
    else if(this.activeindex==3){
      this.BindPlanningData();
    }
    else if(this.activeindex==4){
      this.BindDipStickData();
    }
  }
  
  ngOnInit() {
    am4core.useTheme(am4themes_kelly);
    am4core.useTheme(am4themes_animated);
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {      
      // Update filter state with the changed value
      this.filterState = filterData.filterState; 
      // Call the method in other component to handle the filter changes
      if(this.filterService.isInitialized()){
        //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
        this.BindAllData();        
      }
    });    
  }
  
  lstActivityType!:any[];
  getActivityType(): void {
    this.rtmisservice.GetMasterData('ActivityType', 'main', '1', 'submain', '0').subscribe((data: []) => {
      this.lstActivityType = data;
    });
  }
  BindAllData(){
    this.handleChange(this.activeindex);
  }
  isactivitydataloaded:boolean=false;ActivitySummaryData:any[]=[];
  isdynamicdataloaded:boolean=false;DynamicSummaryData:any[]=[];
  SummaryData:any={};DynamicData:any={};
  AdministrativeChaupalSummaryData:any;ClusterChaupalSummaryData:any;HouseHoldChaupalSummaryData:any;
  //SSData:any[]=[];
  BI:any;CI:any;HI:any;
  BindDynamicData(){
      this.isdynamicdataloaded=false;
      this.DynamicSummaryData=[{"title":"SS Visits (Chaupal)","datafor":"ChaupalSupervision","valuefor":"Chaupal","achieved":[],"isValueSelect":false,"sortindex":0}
        ,{"title":"Chaupal Register","datafor":"Chaupal","valuefor":"","achieved":[],"isValueSelect":false,"sortindex":1}
        ,{"title":"Container Detail","datafor":"LarvaSurvey","valuefor":"","achieved":[],"isValueSelect":false,"sortindex":2}
        ,{"title":"Basti/Village Reached","datafor":"AdministrativeAreaChaupal","valuefor":"","achieved":[],"isValueSelect":true,"sortindex":3}
        ,{"title":"Cluster Reached","datafor":"ClusterChaupal","valuefor":"","achieved":[],"isValueSelect":true,"sortindex":4}
        ,{"title":"Household Reached","datafor":"HouseHoldChaupal","valuefor":"","achieved":[],"isValueSelect":true,"sortindex":5}      
        ,{"title":"SS Visits (HND)","datafor":"ChaupalSupervision","valuefor":"HND","achieved":[],"isValueSelect":false,"sortindex":6}
        ,{"title":"Health & Nutrition Day","datafor":"HND","valuefor":"","achieved":[],"isValueSelect":false,"sortindex":7} 
        ,{"title":"Source Reduction","datafor":"WSRegister","valuefor":"","achieved":[],"isValueSelect":false,"sortindex":8}];
      
      
      this.rtmisservice.GetDashboardDataSummary('DashboardSummarySSChaupal',this.filterState).subscribe((data: [{}]) => {
        if(data.length>0){
          this.SummaryData=data[0];
          this.DynamicSummaryData[0].target=this.SummaryData.ChaupalTarget;
          this.DynamicSummaryData[0].totalachieved=parseInt(this.SummaryData.CC)+parseInt(this.SummaryData.PA)+parseInt(this.SummaryData.RC);          
          this.DynamicSummaryData[0].achieved=[];
          this.DynamicSummaryData[0].achieved.push({"achievetitle":"CC/DC","achievecount":this.SummaryData.CC});
          this.DynamicSummaryData[0].achieved.push({"achievetitle":"PA","achievecount":this.SummaryData.PA});
          this.DynamicSummaryData[0].achieved.push({"achievetitle":"RC","achievecount":this.SummaryData.RC});
          //this.DynamicSummaryData[0].achieved.push({"achievetitle":"HCP","achievecount":this.SummaryData.HCP});
          this.DynamicSummaryData[0].achieved.push({"achievetitle":"CV","achievecount":this.SummaryData.CV});
          this.DynamicSummaryData[0].isdataloaded=true;
        }           
      });
      this.rtmisservice.GetDashboardDataSummary('DashboardSummaryChaupal',this.filterState).subscribe((data: [{}]) => {
        if(data.length>0){
          this.SummaryData=data[0];
          this.DynamicSummaryData[1].target=this.SummaryData.ChaupalTarget;
          this.DynamicSummaryData[1].totalachieved=this.SummaryData.ChaupalConducted;
          this.DynamicSummaryData[1].achieved=[];
          this.DynamicSummaryData[1].achieved.push({"achievetitle":"Meeting","achievecount":this.SummaryData.Meeting});
          this.DynamicSummaryData[1].achieved.push({"achievetitle":"Visit","achievecount":this.SummaryData.Visit});
          this.DynamicSummaryData[1].achieved.push({"achievetitle":"Lock","achievecount":this.SummaryData.Lock});
          this.DynamicSummaryData[1].achieved.push({"achievetitle":"Moved","achievecount":this.SummaryData.Moved});
          this.DynamicSummaryData[1].isdataloaded=true;

          this.DynamicSummaryData[2].target=this.SummaryData.HHSurveyed;
          this.DynamicSummaryData[2].totalachieved=this.SummaryData.HHLarvaFound;
          this.DynamicSummaryData[2].achieved=[];
          this.DynamicSummaryData[2].achieved.push({"achievetitle":"#Container","achievecount":this.SummaryData.ContainerFound});
          this.DynamicSummaryData[2].achieved.push({"achievetitle":"#Larva","achievecount":this.SummaryData.LarvaContainerFound});
          this.DynamicSummaryData[2].achieved.push({"achievetitle":"#Khali Karaya","achievecount":this.SummaryData.KhaliKaraya});
          this.DynamicSummaryData[2].achieved.push({"achievetitle":"#Temophos Oil","achievecount":this.SummaryData.TemophosOilDalwaya});
          this.DynamicSummaryData[2].isdataloaded=true;
          
          if(this.SummaryData.HHSurveyed == 0){this.HI=0;this.BI=0;}
          else{
            this.HI=this.MathFloor(( this.SummaryData.HHLarvaFound/ this.SummaryData.HHSurveyed) * 100);
            this.BI=this.MathFloor(( this.SummaryData.LarvaContainerFound/ this.SummaryData.HHSurveyed) * 100);
          }
          if(this.SummaryData.ContainerFound == 0){this.CI=0;}
          else {
            this.CI=this.MathFloor(( this.SummaryData.LarvaContainerFound/ this.SummaryData.ContainerFound) * 100);
          }
        }           
      });
      this.rtmisservice.GetDashboardDataSummary('AdministrativeAreaChaupal',this.filterState).subscribe((data: [{}]) => {
        if(data.length>0){
          this.SummaryData=data[0];
          this.DynamicSummaryData[3].target=this.SummaryData.TotCluster;
          this.DynamicSummaryData[3].totalachieved=parseInt(this.SummaryData.One)+parseInt(this.SummaryData.Two)+parseInt(this.SummaryData.Three)+parseInt(this.SummaryData.Four)+parseInt(this.SummaryData.FIveAbove);          
          this.DynamicSummaryData[3].achieved=[];
          this.DynamicSummaryData[3].achieved.push({"achievefor":"Zero","achievetitle":"Zero","achievecount":this.SummaryData.Zero});
          this.DynamicSummaryData[3].achieved.push({"achievefor":"One","achievetitle":"One","achievecount":this.SummaryData.One});
          this.DynamicSummaryData[3].achieved.push({"achievefor":"Two","achievetitle":"Two","achievecount":this.SummaryData.Two});
          this.DynamicSummaryData[3].achieved.push({"achievefor":"Three","achievetitle":"Three","achievecount":this.SummaryData.Three});
          this.DynamicSummaryData[3].achieved.push({"achievefor":"Four","achievetitle":"Four","achievecount":this.SummaryData.Four});
          this.DynamicSummaryData[3].achieved.push({"achievefor":"FiveAbove","achievetitle":"Five and Above","achievecount":this.SummaryData.FIveAbove});
          this.DynamicSummaryData[3].isdataloaded=true;
        }           
      });
      this.rtmisservice.GetDashboardDataSummary('ClusterChaupal',this.filterState).subscribe((data: [{}]) => {
        if(data.length>0){
          this.SummaryData=data[0];
          this.DynamicSummaryData[4].target=this.SummaryData.TotCluster;
          this.DynamicSummaryData[4].totalachieved=parseInt(this.SummaryData.One)+parseInt(this.SummaryData.Two)+parseInt(this.SummaryData.Three)+parseInt(this.SummaryData.Four)+parseInt(this.SummaryData.FIveAbove);
          this.DynamicSummaryData[4].achieved=[];
          this.DynamicSummaryData[4].achieved.push({"achievefor":"Zero","achievetitle":"Zero","achievecount":this.SummaryData.Zero});
          this.DynamicSummaryData[4].achieved.push({"achievefor":"One","achievetitle":"One","achievecount":this.SummaryData.One});
          this.DynamicSummaryData[4].achieved.push({"achievefor":"Two","achievetitle":"Two","achievecount":this.SummaryData.Two});
          this.DynamicSummaryData[4].achieved.push({"achievefor":"Three","achievetitle":"Three","achievecount":this.SummaryData.Three});
          this.DynamicSummaryData[4].achieved.push({"achievefor":"Four","achievetitle":"Four","achievecount":this.SummaryData.Four});
          this.DynamicSummaryData[4].achieved.push({"achievefor":"FiveAbove","achievetitle":"Five and Above","achievecount":this.SummaryData.FIveAbove});
          this.DynamicSummaryData[4].isdataloaded=true;
        }                     
      });
      this.rtmisservice.GetDashboardDataSummary('HouseHoldChaupal',this.filterState).subscribe((data: [{}]) => {
        if(data.length>0){
          this.SummaryData=data[0];
          this.DynamicSummaryData[5].target=this.SummaryData.TotCluster;
          this.DynamicSummaryData[5].totalachieved=parseInt(this.SummaryData.One)+parseInt(this.SummaryData.Two)+parseInt(this.SummaryData.Three)+parseInt(this.SummaryData.Four)+parseInt(this.SummaryData.FIveAbove);
          this.DynamicSummaryData[5].achieved=[];
          this.DynamicSummaryData[5].achieved.push({"achievefor":"Zero","achievetitle":"Zero","achievecount":this.SummaryData.Zero});
          this.DynamicSummaryData[5].achieved.push({"achievefor":"One","achievetitle":"One","achievecount":this.SummaryData.One});
          this.DynamicSummaryData[5].achieved.push({"achievefor":"Two","achievetitle":"Two","achievecount":this.SummaryData.Two});
          this.DynamicSummaryData[5].achieved.push({"achievefor":"Three","achievetitle":"Three","achievecount":this.SummaryData.Three});
          this.DynamicSummaryData[5].achieved.push({"achievefor":"Four","achievetitle":"Four","achievecount":this.SummaryData.Four});
          this.DynamicSummaryData[5].achieved.push({"achievefor":"FiveAbove","achievetitle":"Five and Above","achievecount":this.SummaryData.FIveAbove});
          this.DynamicSummaryData[5].isdataloaded=true;
        }                    
      });          
      this.rtmisservice.GetDashboardDataSummary('DashboardSummarySSHND',this.filterState).subscribe((data: [{}]) => {
        if(data.length>0){
          this.SummaryData=data[0];
          this.DynamicSummaryData[6].target=this.SummaryData.ChaupalTarget;
          this.DynamicSummaryData[6].totalachieved=parseInt(this.SummaryData.CC)+parseInt(this.SummaryData.PA)+parseInt(this.SummaryData.RC);
          this.DynamicSummaryData[6].achieved=[];
          this.DynamicSummaryData[6].achieved.push({"achievetitle":"CC/DC","achievecount":this.SummaryData.CC});
          this.DynamicSummaryData[6].achieved.push({"achievetitle":"PA","achievecount":this.SummaryData.PA});
          this.DynamicSummaryData[6].achieved.push({"achievetitle":"RC","achievecount":this.SummaryData.RC});
          //this.DynamicSummaryData[6].achieved.push({"achievetitle":"HCP","achievecount":this.SummaryData.HCP});
          this.DynamicSummaryData[6].achieved.push({"achievetitle":"CV","achievecount":this.SummaryData.CV});
          this.DynamicSummaryData[6].isdataloaded=true;
        }           
      });
      this.rtmisservice.GetDashboardDataSummary('DashboardSummaryHND',this.filterState).subscribe((data: [{}]) => {
        if(data.length>0){
          this.SummaryData=data[0];  
          this.DynamicSummaryData[7].target=this.SummaryData.HNDTarget;
          this.DynamicSummaryData[7].totalachieved=this.SummaryData.HNDConducted;
          this.DynamicSummaryData[7].achieved=[];
          this.DynamicSummaryData[7].achieved.push({"achievetitle":"#Slides","achievecount":this.SummaryData.NoOfSlides});
          this.DynamicSummaryData[7].achieved.push({"achievetitle":"#Positive","achievecount":this.SummaryData.NoOfPositive});
          this.DynamicSummaryData[7].achieved.push({"achievetitle":"#Old Patient","achievecount":this.SummaryData.Noofoldpatient});
          this.DynamicSummaryData[7].achieved.push({"achievetitle":"#Completed Courses","achievecount":this.SummaryData.NoOfCompleteCources});
          this.DynamicSummaryData[7].isdataloaded=true;
        }           
      });
      this.rtmisservice.GetDashboardDataSummary('DashboardSummaryWS',this.filterState).subscribe((data: [{}]) => {
        if(data.length>0){
          this.SummaryData=data[0];
          this.DynamicSummaryData[8].target=this.SummaryData.WSTarget;
          this.DynamicSummaryData[8].totalachieved=this.SummaryData.WSConducted;
          this.DynamicSummaryData[8].achieved=[];
          this.DynamicSummaryData[8].achieved.push({"achievetitle":"Dried","achievecount":this.SummaryData.Dried});
          this.DynamicSummaryData[8].achieved.push({"achievetitle":"Oil","achievecount":this.SummaryData.Oil});
          this.DynamicSummaryData[8].achieved.push({"achievetitle":"GumbusiaFish","achievecount":this.SummaryData.GumbusiaFish});
          this.DynamicSummaryData[8].achieved.push({"achievetitle":"PermanentlyFilled","achievecount":this.SummaryData.PermanentlyFilled});
          this.DynamicSummaryData[8].isdataloaded=true;
        }           
      });      
      
      // this.DynamicSummaryData.push({"title":"Source Reduction","totalachieved":10938,"target":12534,"achieved":[{"achievetitle":"Dried","achievecount":5856},{"achievetitle":"Oil","achievecount":4582},{"achievetitle":"Gambhusia","achievecount":2521},{"achievetitle":"Permanently Filled","achievecount":253}]});
      // this.DynamicSummaryData.push({"title":"Community Drives","totalachieved":10938,"target":12534,"achieved":[{"achievetitle":"Community Van","achievecount":5856},{"achievetitle":"IRS","achievecount":5856},{"achievetitle":"LLIN","achievecount":4582},{"achievetitle":"Family Count","achievecount":2521}]});
      // this.DynamicSummaryData.push({"title":"Health and Nutrition Day","totalachieved":10938,"target":12534,"achieved":[{"achievetitle":"#Slides","achievecount":5856},{"achievetitle":"#Positive","achievecount":4582},{"achievetitle":"#Old Patient","achievecount":2521},{"achievetitle":"#Completed Courses","achievecount":253}]});
      // this.SSData=[];
      // this.SSData.push({"title":"Supervision Visits (App)","totalachieved":528,"target":700,"achieved":[{"achievetitle":"DC /CC","achievecount":232},{"achievetitle":"Project Associate","achievecount":327}]});
      // this.SSData.push({"title":"Supervision Visits (App)","totalachieved":528,"target":700,"achieved":[{"achievetitle":"DC /CC","achievecount":232},{"achievetitle":"Project Associate","achievecount":327}]});
      // this.SSData.push({"title":"Supervision Visits (App)","totalachieved":528,"target":700,"achieved":[{"achievetitle":"DC /CC","achievecount":232},{"achievetitle":"Project Associate","achievecount":327}]});
      this.isdynamicdataloaded=true;
      this.isactivitydataloaded=false;this.ActivitySummaryData=[];
      this.rtmisservice.GetActivityDataSummary(this.filterState).subscribe((data: []) => {
        if(data.length>0){
          this.ActivitySummaryData = data;            
          this.isactivitydataloaded=true;
        }           
      });

      //console.log(this.DynamicSummaryData);
  }
  MathFloor(val:any) {
    return Math.floor(val);
  }
  graphmethodused:any[]=[];graphcontainer:any[]=[];
  isgraph1loaded:any=false;isgraph2loaded:any=false;
  BindGraphData(){
    this.isgraph1loaded=false;
    this.isgraph2loaded=false;
    this.rtmisservice.GetGraphicalDataSummary("MethodUsed",this.filterState).subscribe((data: [any]) => {
      if(data.length>0){
        this.graphmethodused = data;  
        let jsonObj:any[] = [];let jsonObjGrp:any[] = [];
        let MUModernjs=0,MUTradionaljs=0,MUNoMethodjs=0;
        let HHReachedjs=0,Coiljs=0,Netjs=0,Sprayjs=0,Creamjs=0,FastCardjs=0,Refilljs=0,Firejs=0,IRSjs=0,NoMethod=0,AnyUsed=0;
        for(var h=0;h<data.length;h++)
        {
          HHReachedjs = HHReachedjs +parseInt(data[h].HHReached==""?0:data[h].HHReached);
          Coiljs=Coiljs+parseInt(data[h].Coil==""?0:data[h].Coil);
          Netjs=Netjs+parseInt(data[h].Net==""?0:data[h].Net);
          Sprayjs=Sprayjs+parseInt(data[h].Spray==""?0:data[h].Spray);
          Creamjs=Creamjs+parseInt(data[h].Cream==""?0:data[h].Cream);
          FastCardjs=FastCardjs+parseInt(data[h].FastCard==""?0:data[h].FastCard);
          Refilljs=Refilljs+parseInt(data[h].Refill==""?0:data[h].Refill);
          Firejs=Firejs+parseInt(data[h].Fire==""?0:data[h].Fire);
          IRSjs=IRSjs+parseInt(data[h].IRS==""?0:data[h].IRS);	
          MUModernjs=MUModernjs+parseInt(data[h].Modern==""?0:data[h].Modern);
          MUTradionaljs=MUTradionaljs+parseInt(data[h].Traditional==""?0:data[h].Traditional);
          MUNoMethodjs=MUNoMethodjs+parseInt(data[h].NoMethod==""?0:data[h].NoMethod);
          AnyUsed=AnyUsed+parseInt(data[h].AnyUsed==""?0:data[h].AnyUsed);
        }											
        let MUNetjsPer=0,MUNormaljsPer=0,MUModernjsPer=0,MUTradionaljsPer=0,MUNoMethodjsPer=0,MUIRSjsPer=0,MUFirejsPer=0,AnyUsedPer=0;
        if(HHReachedjs !=0)
        {
            MUNetjsPer= Math.round(Netjs*100/HHReachedjs * 10) / 10;
            MUFirejsPer= Math.round(Firejs*100/HHReachedjs * 10) / 10;
            MUNormaljsPer = Math.round(Coiljs*100/HHReachedjs * 10) / 10;
            MUIRSjsPer = Math.round(IRSjs*100/HHReachedjs * 10) / 10;
            MUModernjsPer = Math.round(MUModernjs*100/HHReachedjs * 10) / 10;
            MUTradionaljsPer = Math.round(MUTradionaljs*100/HHReachedjs * 10) / 10;
            MUNoMethodjsPer = Math.round(MUNoMethodjs*100/HHReachedjs * 10) / 10;
            AnyUsedPer = Math.round(AnyUsed*100/HHReachedjs * 10) / 10;
        }
        jsonObjGrp.push({"category": "Mosquito Net / LLIN","Catvalue": Netjs,"value":MUNetjsPer,"valueTotal": HHReachedjs});
        jsonObjGrp.push({"category": "Modern Method (Coil/ Fastcard/ Refill/ Spray/ Cream)","Catvalue": MUModernjs,"value":MUModernjsPer,"valueTotal": HHReachedjs});
        jsonObjGrp.push({"category": "Traditional Method (Fire)","Catvalue": Firejs,"value":MUFirejsPer,"valueTotal": HHReachedjs});	
        jsonObjGrp.push({"category": "IRS","Catvalue": MUTradionaljs,"value":MUIRSjsPer,"valueTotal": HHReachedjs});				
        jsonObjGrp.push({"category": "No Method","Catvalue": MUNoMethodjs,"value":MUNoMethodjsPer,"valueTotal": HHReachedjs});		
        jsonObjGrp.push({"category": "Any Method","Catvalue": AnyUsed,"value":AnyUsedPer,"valueTotal": HHReachedjs});						
        
        jsonObj.push({"category":"AnyUsed","catvalue":AnyUsed});
        jsonObj.push({"category":"NoMethod","catvalue":MUNoMethodjs}); 
        AMCHARTS_SingleXYGraph.SingleXYGraph("chartmethodused", jsonObjGrp, "", "", "", 0, 100, false);           
      }     
      this.isgraph1loaded=true;      
    });
    this.rtmisservice.GetGraphicalDataSummary("ContainerStatus",this.filterState).subscribe((data: [{}]) => {
      if(data.length>0){
        this.graphcontainer = data; 
        let maxval=100;
        for(var h=0;h<data.length;h++)
        {
          if(this.graphcontainer[h].second>this.graphcontainer[h].first){
            if(this.graphcontainer[h].second>maxval){
              maxval=this.graphcontainer[h].second;
            }              
          }
          else if(this.graphcontainer[h].first>maxval){
            this.graphcontainer[h].first;
          }
        }    //Mosquito Breeding Sources and Larva
        let chart = am4core.create("chartcontainer", am4charts.XYChart);

        // Add percent sign to all numbers
        chart.numberFormatter.numberFormat = "#.#";
        
        // Add data
        chart.data = this.graphcontainer;
        
        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        //categoryAxis.renderer.title.text="Containers";
         let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // valueAxis.title.text = "Containers";
        //valueAxis.title.fontWeight = 800;
        
        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "second";
        series.dataFields.categoryX = "category";
        series.clustered = false;
        series.name="Number of Container larva found";
        series.tooltipText = "Number of Container larva found ({categoryX}): [bold]{valueY}[/]";
        
        let series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "first";
        series2.dataFields.categoryX = "category";
        series2.clustered = false;
        series2.name="Number of Container";
        series2.columns.template.width = am4core.percent(50);
        series2.tooltipText = "Number of Container ({categoryX}) : [bold]{valueY}[/]";
        
        let seriestotal = chart.series.push(new am4charts.LineSeries());
        seriestotal.dataFields.valueY = "valueTotal";
        seriestotal.dataFields.categoryX = "category";
        seriestotal.strokeWidth = 2;
        let circleBullet = seriestotal.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.stroke = am4core.color("#fff");
        circleBullet.circle.strokeWidth = 2;

        let labelBullet = seriestotal.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#')}";
        //labelBullet.label.text = "{value}";
        labelBullet.label.dy = -20;
        chart.legend = new am4charts.Legend();
        chart.legend.position="top";
        seriestotal.legendSettings.labelText = "[bold] Total Households : [/]";
        seriestotal.legendSettings.valueText = "{valueY.close}";
        //seriescap.legendSettings.labelText="";
        
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
        //chart.legend = new am4charts.Legend();
        //AMCHARTS_MultiAxisGraph.LoadMultiAxisGraph('chartcontainer', '', this.graphcontainer, "Number of Container found", "Number of Container where larva reported", "", "", "", maxval, "", "Number of Household Surveyed :"+this.graphcontainer[0].valueTotal,16,"Value");                    
      }
      this.isgraph2loaded=true;           
    });
  }
  isstaticdataloaded:boolean=false;isservproviderdataloaded:boolean=false;
  StaticSummaryData:any={};ServiceProviderSummaryData:any[]=[];
  BindStaticData(){
    this.isstaticdataloaded=false;this.isservproviderdataloaded=false;
    this.StaticSummaryData={};
    this.ServiceProviderSummaryData=[]; 
         
    this.rtmisservice.GetDashboardDataSummary('StaticSummary',this.filterState).subscribe((data: [{}]) => {
      if(data.length>0){
        this.StaticSummaryData = data[0];
        this.isstaticdataloaded=true;
      }           
    });
    this.rtmisservice.GetDashboardDataSummary('ServiceProviderSummary',this.filterState).subscribe((data: [{}]) => {
      if(data.length>0){
        this.ServiceProviderSummaryData = data;
        this.isservproviderdataloaded=true;
      }           
    });
  }
  
  isplanningdataloaded:boolean=false;PlanningSummaryData:any={};
  BindPlanningData(){
    this.isplanningdataloaded=false;
    this.PlanningSummaryData=[];
    this.rtmisservice.GetPlanningDataSummary(this.filterState).subscribe((data: [{}]) => {
      if(data.length>0){
        this.PlanningSummaryData = data[0];           
        this.isplanningdataloaded=true;
      }           
    });
    if(this.filterState.SelDistrictID!="0" && (this.filterState.selectedDateRange.startDate.format("MM")==this.filterState.selectedDateRange.endDate.format("MM"))){
      this.rtmisservice.GetCalendarDataSummary(this.filterState,this.filterState.selectedDateRange).subscribe((data: [{}]) => {
        if(data.length>0){
          this.PlanningDataSummaryPopup = data; 
          this.ispopupdataloaded=true;
        }           
      });
    } 
  }
  issurveydataloaded:boolean=false;SurveySummaryData:any={};
  BindDipStickData(){
    this.issurveydataloaded=false;
    this.rtmisservice.GetSurveyDataSummary(this.filterState).subscribe((data: [{}]) => {
      if(data.length>0){
        this.SurveySummaryData = data[0];            
        this.issurveydataloaded=true;
      }           
    });
    this.BindDipStickGraphData();
  }
  chartReg: any = {};
  BindDipStickGraphData(){
    this.rtmisservice.GetGraphicalDataSummary("DipStickHouseholdSurvey",this.filterState).subscribe((data: [any]) => {
      if(data.length>0){
          var Respondantdata={"Q1":{"Male":0,"Female":0,"quantity":""},"Q2":{"Male":0,"Female":0,"quantity":0},"Q3":{"Male":0,"Female":0,"quantity":0},"Q4":{"Male":0,"Female":0,"quantity":0}};
          var samplingdata={"Q1":{"Sample District":0},"Q2":{"Sample District":0},"Q3":{"Sample District":0},"Q4":{"Sample District":0}};
          var totsurveydata={"Q1":{"Survey":0},"Q2":{"Survey":0},"Q3":{"Survey":0},"Q4":{"Survey":0}};
          var everhearddata={"Q1":{"Dengue":0,"Malaria":0,"quantity":""},"Q2":{"Dengue":0,"Malaria":0,"quantity":0},"Q3":{"Dengue":0,"Malaria":0,"quantity":0},"Q4":{"Dengue":0,"Malaria":0,"quantity":0}};
          var denguesympptomdata={"Q1":{"Fewer":0,"Headache":0,"FeelingCold":0,"NauseaVomiting":0,"BodyWeakness":0,"Dizziness":0,"Diarrhea":0,"Loss Of Appetite":0,"DontKnow":0,"Others":0,"quantity":""},"Q2":{"Fewer":0,"Headache":0,"FeelingCold":0,"NauseaVomiting":0,"BodyWeakness":0,"Dizziness":0,"Diarrhea":0,"Loss Of Appetite":0,"DontKnow":0,"Others":0,"quantity":0},"Q3":{"Fewer":0,"Headache":0,"FeelingCold":0,"NauseaVomiting":0,"BodyWeakness":0,"Dizziness":0,"Diarrhea":0,"Loss Of Appetite":0,"DontKnow":0,"Others":0,"quantity":0},"Q4":{"Fewer":0,"Headache":0,"FeelingCold":0,"NauseaVomiting":0,"BodyWeakness":0,"Dizziness":0,"Diarrhea":0,"Loss Of Appetite":0,"DontKnow":0,"Others":0,"quantity":0}};
          var malariasympptomdata={"Q1":{"Fewer":0,"Headache":0,"FewerSweating":0,"NauseaVomiting":0,"BodyWeakness":0,"Loss Of Appetite":0,"Others":0,"quantity":""},"Q2":{"Fewer":0,"Headache":0,"FewerSweating":0,"NauseaVomiting":0,"BodyWeakness":0,"Loss Of Appetite":0,"Others":0,"quantity":0},"Q3":{"Fewer":0,"Headache":0,"FewerSweating":0,"NauseaVomiting":0,"BodyWeakness":0,"Loss Of Appetite":0,"Others":0,"quantity":0},"Q4":{"Fewer":0,"Headache":0,"FewerSweating":0,"NauseaVomiting":0,"BodyWeakness":0,"Loss Of Appetite":0,"Others":0,"quantity":0}};
          var denguecausedata={"Q1":{"Mosquito bites":0,"Drinking unclean water":0,"Eating unhygienic food":0,"Cold or changing weather":0,"Getting wet in the rain":0,"Witchcraft":0,"Don't Know":0,"Others":0,"quantity":""},"Q2":{"Mosquito bites":0,"Drinking unclean water":0,"Eating unhygienic food":0,"Cold or changing weather":0,"Getting wet in the rain":0,"Witchcraft":0,"Don't Know":0,"Others":0,"quantity":""},"Q3":{"Mosquito bites":0,"Drinking unclean water":0,"Eating unhygienic food":0,"Cold or changing weather":0,"Getting wet in the rain":0,"Witchcraft":0,"Don't Know":0,"Others":0,"quantity":""},"Q4":{"Mosquito bites":0,"Drinking unclean water":0,"Eating unhygienic food":0,"Cold or changing weather":0,"Getting wet in the rain":0,"Witchcraft":0,"Don't Know":0,"Others":0,"quantity":""}};
          var malariacausedata={"Q1":{"Mosquito bites":0,"From flies/insects/lice etc.":0,"eating together from the same vessel":0,"Touching eachother":0,"By the bite of a mosquito that has bitten a malaria patient.":0,"Don't Know":0,"Others":0,"quantity":""},"Q2":{"Mosquito bites":0,"From flies/insects/lice etc.":0,"eating together from the same vessel":0,"Touching eachother":0,"By the bite of a mosquito that has bitten a malaria patient.":0,"Don't Know":0,"Others":0,"quantity":""},"Q3":{"Mosquito bites":0,"From flies/insects/lice etc.":0,"eating together from the same vessel":0,"Touching eachother":0,"By the bite of a mosquito that has bitten a malaria patient.":0,"Don't Know":0,"Others":0,"quantity":""},"Q4":{"Mosquito bites":0,"From flies/insects/lice etc.":0,"eating together from the same vessel":0,"Touching eachother":0,"By the bite of a mosquito that has bitten a malaria patient.":0,"Don't Know":0,"Others":0,"quantity":""}};

          var denguepreveteddata={"Q1":{"Can dengue be prevented":0},"Q2":{"Can dengue be prevented":0},"Q3":{"Can dengue be prevented":0},"Q4":{"Can dengue be prevented":0}};
          var malariapreveteddata={"Q1":{"Dengue":0,"Malaria":0,"quantity":""},"Q2":{"Dengue":0,"Malaria":0,"quantity":0},"Q3":{"Dengue":0,"Malaria":0,"quantity":0},"Q4":{"Dengue":0,"Malaria":0,"quantity":0}};
          var familypractanymethoddata={"Q1":{"Family Practice any Method":0},"Q2":{"Family Practice any Method":0},"Q3":{"Family Practice any Method":0},"Q4":{"Family Practice any Method":0}};
          var methodofprotectdata={"Q1":{"Bednets":0,"LLIN":0,"Incense sticks":0,"Coils":0,"Electric bat":0,"Repellent creams":0,"Use of insecticidal sprays":0,"Liquid Vaporiser electric machines":0,"Oils":0,"Wearing full sleeved clothes to avoid mosquito bites":0,"Mosquito screens on windows":0,"quantity":""},"Q2":{"Bednets":0,"LLIN":0,"Incense sticks":0,"Coils":0,"Electric bat":0,"Repellent creams":0,"Use of insecticidal sprays":0,"Liquid Vaporiser electric machines":0,"Oils":0,"Wearing full sleeved clothes to avoid mosquito bites":0,"Mosquito screens on windows":0,"quantity":""},"Q3":{"Bednets":0,"LLIN":0,"Incense sticks":0,"Coils":0,"Electric bat":0,"Repellent creams":0,"Use of insecticidal sprays":0,"Liquid Vaporiser electric machines":0,"Oils":0,"Wearing full sleeved clothes to avoid mosquito bites":0,"Mosquito screens on windows":0,"quantity":""},"Q4":{"Bednets":0,"LLIN":0,"Incense sticks":0,"Coils":0,"Electric bat":0,"Repellent creams":0,"Use of insecticidal sprays":0,"Liquid Vaporiser electric machines":0,"Oils":0,"Wearing full sleeved clothes to avoid mosquito bites":0,"Mosquito screens on windows":0,"quantity":""}};
          var familyhighfewerdata={"Q1":{"Family High Fewer Last Year":0},"Q2":{"Family High Fewer Last Year":0},"Q3":{"Family High Fewer Last Year":0},"Q4":{"Family High Fewer Last Year":0}};
          var visittohcpdata={"Q1":{"Visit to Health Care Provider":0},"Q2":{"Visit to Health Care Provider":0},"Q3":{"Visit to Health Care Provider":0},"Q4":{"Visit to Health Care Provider":0}};
          var typeofhcpdata={"Q1":{"Govt. Dispensary":0,"Govt./municipalhospital":0,"Mobile clininc":0,"NGO or trust hospital /clinic":0,"Pvt. Hospital/ Pvt. doctor/ clinic":0,"Others":0,"quantity":""},"Q2":{"Govt. Dispensary":0,"Govt./municipalhospital":0,"Mobile clininc":0,"NGO or trust hospital /clinic":0,"Pvt. Hospital/ Pvt. doctor/ clinic":0,"Others":0,"quantity":""},"Q3":{"Govt. Dispensary":0,"Govt./municipalhospital":0,"Mobile clininc":0,"NGO or trust hospital /clinic":0,"Pvt. Hospital/ Pvt. doctor/ clinic":0,"Others":0,"quantity":""},"Q4":{"Govt. Dispensary":0,"Govt./municipalhospital":0,"Mobile clininc":0,"NGO or trust hospital /clinic":0,"Pvt. Hospital/ Pvt. doctor/ clinic":0,"Others":0,"quantity":""}};
          var isbloodtestconducteddata={"Q1":{"Blood Test Conducted":0},"Q2":{"Blood Test Conducted":0},"Q3":{"Blood Test Conducted":0},"Q4":{"Blood Test Conducted":0}};
          var optfortreatmentdata={"Q1":{"Treatment Opted":0},"Q2":{"Treatment Opted":0},"Q3":{"Treatment Opted":0},"Q4":{"Treatment Opted":0}};
          var heard_denguemessagedata={"Q1":{"Heard Dengue or Malaria Message":0},"Q2":{"Heard Dengue or Malaria Message":0},"Q3":{"Heard Dengue or Malaria Message":0},"Q4":{"Heard Dengue or Malaria Message":0}};
          var finyear='';
          for(var i=0;i<data.length;i++){
            if(data[i].FinancialPeriod=='Q1'){
              samplingdata.Q1={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q1={"Survey":data[i].totalRecords};
              Respondantdata.Q1={"Male":data[i].male_count*100/data[i].totalRecords,"Female":data[i].female_count*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              everhearddata.Q1={"Dengue":data[i].heardaboutdengue*100/data[i].totalRecords,"Malaria":data[i].heardaboutmalaria*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q1={"Fewer":data[i].DenSymp_Fever*100/data[i].totalRecords,"Headache":data[i].DenSymp_Headache*100/data[i].totalRecords,"FeelingCold":data[i].DenSymp_FeelingCold*100/data[i].totalRecords,"NauseaVomiting":data[i].DenSymp_NauseaVomiting*100/data[i].totalRecords,"BodyWeakness":data[i].DenSymp_BodyWeakness*100/data[i].totalRecords,"Dizziness":data[i].DenSymp_Dizziness*100/data[i].totalRecords,"Diarrhea":data[i].DenSymp_Diarrhea*100/data[i].totalRecords,"Loss Of Appetite":data[i].DenSymp_LossofAppetite*100/data[i].totalRecords,"DontKnow":data[i].DenSymp_DontKnow*100/data[i].totalRecords,"Others":data[i].DenSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasympptomdata.Q1={"Fewer":data[i].MalSymp_Fever*100/data[i].totalRecords,"Headache":data[i].MalSymp_Headache*100/data[i].totalRecords,"FewerSweating":data[i].MalSymp_FeverAndSweating*100/data[i].totalRecords,"NauseaVomiting":data[i].MalSymp_Vomiting*100/data[i].totalRecords,"BodyWeakness":data[i].MalSymp_BodyWeakness*100/data[i].totalRecords,"Loss Of Appetite":data[i].MalSymp_LossofAppetite*100/data[i].totalRecords,"Others":data[i].MalSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguecausedata.Q1={"Mosquito bites":data[i].DenCause_Mosquitobites*100/data[i].totalRecords,"Drinking unclean water":data[i].DenCause_uncleanwater*100/data[i].totalRecords,"Eating unhygienic food":data[i].DenCause_unhygienicfood*100/data[i].totalRecords,"Cold or changing weather":data[i].DenCause_changingweather*100/data[i].totalRecords,"Getting wet in the rain":data[i].DenCause_wetinrain*100/data[i].totalRecords,"Witchcraft":data[i].DenCause_Witchcraft*100/data[i].totalRecords,"Don't Know":data[i].DenCause_dontknow*100/data[i].totalRecords,"Others":data[i].DenCause_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q1={"Mosquito bites":data[i].MalCause_Mosquitobites*100/data[i].totalRecords,"From flies/insects/lice etc.":data[i].MalCause_insects*100/data[i].totalRecords,"eating together from the same vessel":data[i].MalCause_eatingtogether*100/data[i].totalRecords,"Touching eachother":data[i].MalCause_toucheachother*100/data[i].totalRecords,"By the bite of a mosquito that has bitten a malaria patient.":data[i].MalCause_SameMosquitobites*100/data[i].totalRecords,"Don't Know":data[i].MalCause_dontknow*100/data[i].totalRecords,"Others":data[i].MalCause_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguepreveteddata.Q1={"Can dengue be prevented":data[i].candengue_prevented*100/data[i].totalRecords};
              familypractanymethoddata.Q1={"Family Practice any Method":data[i].familypractice_protectionmosquito*100/data[i].totalRecords};
              methodofprotectdata.Q1={"Bednets":data[i].BedNets*100/data[i].totalRecords,"LLIN":data[i].LLIN*100/data[i].totalRecords,"Incense sticks":data[i].IncenseSticks*100/data[i].totalRecords,"Coils":data[i].Coils*100/data[i].totalRecords,"Electric bat":data[i].ElectricBat*100/data[i].totalRecords,"Repellent creams":data[i].RepellentCream*100/data[i].totalRecords,"Use of insecticidal sprays":data[i].UseOfInsecticidalSpray*100/data[i].totalRecords,"Liquid Vaporiser electric machines":data[i].LiquidVaporiserElectric*100/data[i].totalRecords,"Oils":data[i].Oil*100/data[i].totalRecords,"Wearing full sleeved clothes to avoid mosquito bites":data[i].WearingFullSleavesClothes*100/data[i].totalRecords,"Mosquito screens on windows":data[i].MosquitoScreenOnWindow*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              familyhighfewerdata.Q1={"Family High Fewer Last Year":data[i].member_highfever_lastyear*100/data[i].totalRecords};
              visittohcpdata.Q1={"Visit to Health Care Provider":data[i].visit_hcp*100/data[i].totalRecords};
              typeofhcpdata.Q1={"Govt. Dispensary":data[i].GovtDispensary*100/data[i].totalRecords,"Govt./municipalhospital":data[i].GovtMuncipalHospital*100/data[i].totalRecords,"Mobile clininc":data[i].MobileClinic*100/data[i].totalRecords,"NGO or trust hospital /clinic":data[i].NGOTrustHospitalClinic*100/data[i].totalRecords,"Pvt. Hospital/ Pvt. doctor/ clinic":data[i].PvtHospitalClinic*100/data[i].totalRecords,"Others":data[i].OtherHCPType*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              isbloodtestconducteddata.Q1={"Blood Test Conducted":data[i].isbloodtestconducted*100/data[i].totalRecords};
              optfortreatmentdata.Q1={"Treatment Opted":data[i].optfortreatment*100/data[i].totalRecords};
              heard_denguemessagedata.Q1={"Heard Dengue or Malaria Message":data[i].heard_denguemessage*100/data[i].totalRecords};
            }
            else if(data[i].FinancialPeriod=='Q2'){
              samplingdata.Q2={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q2={"Survey":data[i].totalRecords};
              Respondantdata.Q2={"Male":data[i].male_count*100/data[i].totalRecords,"Female":data[i].female_count*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              everhearddata.Q2={"Dengue":data[i].heardaboutdengue*100/data[i].totalRecords,"Malaria":data[i].heardaboutmalaria*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q2={"Fewer":data[i].DenSymp_Fever*100/data[i].totalRecords,"Headache":data[i].DenSymp_Headache*100/data[i].totalRecords,"FeelingCold":data[i].DenSymp_FeelingCold*100/data[i].totalRecords,"NauseaVomiting":data[i].DenSymp_NauseaVomiting*100/data[i].totalRecords,"BodyWeakness":data[i].DenSymp_BodyWeakness*100/data[i].totalRecords,"Dizziness":data[i].DenSymp_Dizziness*100/data[i].totalRecords,"Diarrhea":data[i].DenSymp_Diarrhea*100/data[i].totalRecords,"Loss Of Appetite":data[i].DenSymp_LossofAppetite*100/data[i].totalRecords,"DontKnow":data[i].DenSymp_DontKnow*100/data[i].totalRecords,"Others":data[i].DenSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasympptomdata.Q2={"Fewer":data[i].MalSymp_Fever*100/data[i].totalRecords,"Headache":data[i].MalSymp_Headache*100/data[i].totalRecords,"FewerSweating":data[i].MalSymp_FeverAndSweating*100/data[i].totalRecords,"NauseaVomiting":data[i].MalSymp_Vomiting*100/data[i].totalRecords,"BodyWeakness":data[i].MalSymp_BodyWeakness*100/data[i].totalRecords,"Loss Of Appetite":data[i].MalSymp_LossofAppetite*100/data[i].totalRecords,"Others":data[i].MalSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguecausedata.Q2={"Mosquito bites":data[i].DenCause_Mosquitobites*100/data[i].totalRecords,"Drinking unclean water":data[i].DenCause_uncleanwater*100/data[i].totalRecords,"Eating unhygienic food":data[i].DenCause_unhygienicfood*100/data[i].totalRecords,"Cold or changing weather":data[i].DenCause_changingweather*100/data[i].totalRecords,"Getting wet in the rain":data[i].DenCause_wetinrain*100/data[i].totalRecords,"Witchcraft":data[i].DenCause_Witchcraft*100/data[i].totalRecords,"Don't Know":data[i].DenCause_dontknow*100/data[i].totalRecords,"Others":data[i].DenCause_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q2={"Mosquito bites":data[i].MalCause_Mosquitobites*100/data[i].totalRecords,"From flies/insects/lice etc.":data[i].MalCause_insects*100/data[i].totalRecords,"eating together from the same vessel":data[i].MalCause_eatingtogether*100/data[i].totalRecords,"Touching eachother":data[i].MalCause_toucheachother*100/data[i].totalRecords,"By the bite of a mosquito that has bitten a malaria patient.":data[i].MalCause_SameMosquitobites*100/data[i].totalRecords,"Don't Know":data[i].MalCause_dontknow*100/data[i].totalRecords,"Others":data[i].MalCause_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguepreveteddata.Q2={"Can dengue be prevented":data[i].candengue_prevented*100/data[i].totalRecords};
              familypractanymethoddata.Q2={"Family Practice any Method":data[i].familypractice_protectionmosquito*100/data[i].totalRecords};
              methodofprotectdata.Q2={"Bednets":data[i].BedNets*100/data[i].totalRecords,"LLIN":data[i].LLIN*100/data[i].totalRecords,"Incense sticks":data[i].IncenseSticks*100/data[i].totalRecords,"Coils":data[i].Coils*100/data[i].totalRecords,"Electric bat":data[i].ElectricBat*100/data[i].totalRecords,"Repellent creams":data[i].RepellentCream*100/data[i].totalRecords,"Use of insecticidal sprays":data[i].UseOfInsecticidalSpray*100/data[i].totalRecords,"Liquid Vaporiser electric machines":data[i].LiquidVaporiserElectric*100/data[i].totalRecords,"Oils":data[i].Oil*100/data[i].totalRecords,"Wearing full sleeved clothes to avoid mosquito bites":data[i].WearingFullSleavesClothes*100/data[i].totalRecords,"Mosquito screens on windows":data[i].MosquitoScreenOnWindow*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              familyhighfewerdata.Q2={"Family High Fewer Last Year":data[i].member_highfever_lastyear*100/data[i].totalRecords};
              visittohcpdata.Q2={"Visit to Health Care Provider":data[i].visit_hcp*100/data[i].totalRecords};
              typeofhcpdata.Q2={"Govt. Dispensary":data[i].GovtDispensary*100/data[i].totalRecords,"Govt./municipalhospital":data[i].GovtMuncipalHospital*100/data[i].totalRecords,"Mobile clininc":data[i].MobileClinic*100/data[i].totalRecords,"NGO or trust hospital /clinic":data[i].NGOTrustHospitalClinic*100/data[i].totalRecords,"Pvt. Hospital/ Pvt. doctor/ clinic":data[i].PvtHospitalClinic*100/data[i].totalRecords,"Others":data[i].OtherHCPType*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              isbloodtestconducteddata.Q2={"Blood Test Conducted":data[i].isbloodtestconducted*100/data[i].totalRecords};
              optfortreatmentdata.Q2={"Treatment Opted":data[i].optfortreatment*100/data[i].totalRecords};
              heard_denguemessagedata.Q2={"Heard Dengue or Malaria Message":data[i].heard_denguemessage*100/data[i].totalRecords};
            }
            else if(data[i].FinancialPeriod=='Q3'){
              samplingdata.Q3={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q3={"Survey":data[i].totalRecords};
              Respondantdata.Q3={"Male":data[i].male_count*100/data[i].totalRecords,"Female":data[i].female_count*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              everhearddata.Q3={"Dengue":data[i].heardaboutdengue*100/data[i].totalRecords,"Malaria":data[i].heardaboutmalaria*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q3={"Fewer":data[i].DenSymp_Fever*100/data[i].totalRecords,"Headache":data[i].DenSymp_Headache*100/data[i].totalRecords,"FeelingCold":data[i].DenSymp_FeelingCold*100/data[i].totalRecords,"NauseaVomiting":data[i].DenSymp_NauseaVomiting*100/data[i].totalRecords,"BodyWeakness":data[i].DenSymp_BodyWeakness*100/data[i].totalRecords,"Dizziness":data[i].DenSymp_Dizziness*100/data[i].totalRecords,"Diarrhea":data[i].DenSymp_Diarrhea*100/data[i].totalRecords,"Loss Of Appetite":data[i].DenSymp_LossofAppetite*100/data[i].totalRecords,"DontKnow":data[i].DenSymp_DontKnow*100/data[i].totalRecords,"Others":data[i].DenSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasympptomdata.Q3={"Fewer":data[i].MalSymp_Fever*100/data[i].totalRecords,"Headache":data[i].MalSymp_Headache*100/data[i].totalRecords,"FewerSweating":data[i].MalSymp_FeverAndSweating*100/data[i].totalRecords,"NauseaVomiting":data[i].MalSymp_Vomiting*100/data[i].totalRecords,"BodyWeakness":data[i].MalSymp_BodyWeakness*100/data[i].totalRecords,"Loss Of Appetite":data[i].MalSymp_LossofAppetite*100/data[i].totalRecords,"Others":data[i].MalSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguecausedata.Q3={"Mosquito bites":data[i].DenCause_Mosquitobites*100/data[i].totalRecords,"Drinking unclean water":data[i].DenCause_uncleanwater*100/data[i].totalRecords,"Eating unhygienic food":data[i].DenCause_unhygienicfood*100/data[i].totalRecords,"Cold or changing weather":data[i].DenCause_changingweather*100/data[i].totalRecords,"Getting wet in the rain":data[i].DenCause_wetinrain*100/data[i].totalRecords,"Witchcraft":data[i].DenCause_Witchcraft*100/data[i].totalRecords,"Don't Know":data[i].DenCause_dontknow*100/data[i].totalRecords,"Others":data[i].DenCause_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q3={"Mosquito bites":data[i].MalCause_Mosquitobites*100/data[i].totalRecords,"From flies/insects/lice etc.":data[i].MalCause_insects*100/data[i].totalRecords,"eating together from the same vessel":data[i].MalCause_eatingtogether*100/data[i].totalRecords,"Touching eachother":data[i].MalCause_toucheachother*100/data[i].totalRecords,"By the bite of a mosquito that has bitten a malaria patient.":data[i].MalCause_SameMosquitobites*100/data[i].totalRecords,"Don't Know":data[i].MalCause_dontknow*100/data[i].totalRecords,"Others":data[i].MalCause_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguepreveteddata.Q3={"Can dengue be prevented":data[i].candengue_prevented*100/data[i].totalRecords};
              familypractanymethoddata.Q3={"Family Practice any Method":data[i].familypractice_protectionmosquito*100/data[i].totalRecords};
              methodofprotectdata.Q3={"Bednets":data[i].BedNets*100/data[i].totalRecords,"LLIN":data[i].LLIN*100/data[i].totalRecords,"Incense sticks":data[i].IncenseSticks*100/data[i].totalRecords,"Coils":data[i].Coils*100/data[i].totalRecords,"Electric bat":data[i].ElectricBat*100/data[i].totalRecords,"Repellent creams":data[i].RepellentCream*100/data[i].totalRecords,"Use of insecticidal sprays":data[i].UseOfInsecticidalSpray*100/data[i].totalRecords,"Liquid Vaporiser electric machines":data[i].LiquidVaporiserElectric*100/data[i].totalRecords,"Oils":data[i].Oil*100/data[i].totalRecords,"Wearing full sleeved clothes to avoid mosquito bites":data[i].WearingFullSleavesClothes*100/data[i].totalRecords,"Mosquito screens on windows":data[i].MosquitoScreenOnWindow*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              familyhighfewerdata.Q3={"Family High Fewer Last Year":data[i].member_highfever_lastyear*100/data[i].totalRecords};
              visittohcpdata.Q3={"Visit to Health Care Provider":data[i].visit_hcp*100/data[i].totalRecords};
              typeofhcpdata.Q3={"Govt. Dispensary":data[i].GovtDispensary*100/data[i].totalRecords,"Govt./municipalhospital":data[i].GovtMuncipalHospital*100/data[i].totalRecords,"Mobile clininc":data[i].MobileClinic*100/data[i].totalRecords,"NGO or trust hospital /clinic":data[i].NGOTrustHospitalClinic*100/data[i].totalRecords,"Pvt. Hospital/ Pvt. doctor/ clinic":data[i].PvtHospitalClinic*100/data[i].totalRecords,"Others":data[i].OtherHCPType*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              isbloodtestconducteddata.Q3={"Blood Test Conducted":data[i].isbloodtestconducted*100/data[i].totalRecords};
              optfortreatmentdata.Q3={"Treatment Opted":data[i].optfortreatment*100/data[i].totalRecords};
              heard_denguemessagedata.Q3={"Heard Dengue or Malaria Message":data[i].heard_denguemessage*100/data[i].totalRecords};
            }
            else if(data[i].FinancialPeriod=='Q4'){
              samplingdata.Q4={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q4={"Survey":data[i].totalRecords};
              Respondantdata.Q4={"Male":data[i].male_count*100/data[i].totalRecords,"Female":data[i].female_count*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              everhearddata.Q4={"Dengue":data[i].heardaboutdengue*100/data[i].totalRecords,"Malaria":data[i].heardaboutmalaria*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q4={"Fewer":data[i].DenSymp_Fever*100/data[i].totalRecords,"Headache":data[i].DenSymp_Headache*100/data[i].totalRecords,"FeelingCold":data[i].DenSymp_FeelingCold*100/data[i].totalRecords,"NauseaVomiting":data[i].DenSymp_NauseaVomiting*100/data[i].totalRecords,"BodyWeakness":data[i].DenSymp_BodyWeakness*100/data[i].totalRecords,"Dizziness":data[i].DenSymp_Dizziness*100/data[i].totalRecords,"Diarrhea":data[i].DenSymp_Diarrhea*100/data[i].totalRecords,"Loss Of Appetite":data[i].DenSymp_LossofAppetite*100/data[i].totalRecords,"DontKnow":data[i].DenSymp_DontKnow*100/data[i].totalRecords,"Others":data[i].DenSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasympptomdata.Q4={"Fewer":data[i].MalSymp_Fever*100/data[i].totalRecords,"Headache":data[i].MalSymp_Headache*100/data[i].totalRecords,"FewerSweating":data[i].MalSymp_FeverAndSweating*100/data[i].totalRecords,"NauseaVomiting":data[i].MalSymp_Vomiting*100/data[i].totalRecords,"BodyWeakness":data[i].MalSymp_BodyWeakness*100/data[i].totalRecords,"Loss Of Appetite":data[i].MalSymp_LossofAppetite*100/data[i].totalRecords,"Others":data[i].MalSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguecausedata.Q4={"Mosquito bites":data[i].DenCause_Mosquitobites*100/data[i].totalRecords,"Drinking unclean water":data[i].DenCause_uncleanwater*100/data[i].totalRecords,"Eating unhygienic food":data[i].DenCause_unhygienicfood*100/data[i].totalRecords,"Cold or changing weather":data[i].DenCause_changingweather*100/data[i].totalRecords,"Getting wet in the rain":data[i].DenCause_wetinrain*100/data[i].totalRecords,"Witchcraft":data[i].DenCause_Witchcraft*100/data[i].totalRecords,"Don't Know":data[i].DenCause_dontknow*100/data[i].totalRecords,"Others":data[i].DenCause_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q4={"Mosquito bites":data[i].MalCause_Mosquitobites*100/data[i].totalRecords,"From flies/insects/lice etc.":data[i].MalCause_insects*100/data[i].totalRecords,"eating together from the same vessel":data[i].MalCause_eatingtogether*100/data[i].totalRecords,"Touching eachother":data[i].MalCause_toucheachother*100/data[i].totalRecords,"By the bite of a mosquito that has bitten a malaria patient.":data[i].MalCause_SameMosquitobites*100/data[i].totalRecords,"Don't Know":data[i].MalCause_dontknow*100/data[i].totalRecords,"Others":data[i].MalCause_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguepreveteddata.Q4={"Can dengue be prevented":data[i].candengue_prevented*100/data[i].totalRecords};
              familypractanymethoddata.Q4={"Family Practice any Method":data[i].familypractice_protectionmosquito*100/data[i].totalRecords};
              methodofprotectdata.Q4={"Bednets":data[i].BedNets*100/data[i].totalRecords,"LLIN":data[i].LLIN*100/data[i].totalRecords,"Incense sticks":data[i].IncenseSticks*100/data[i].totalRecords,"Coils":data[i].Coils*100/data[i].totalRecords,"Electric bat":data[i].ElectricBat*100/data[i].totalRecords,"Repellent creams":data[i].RepellentCream*100/data[i].totalRecords,"Use of insecticidal sprays":data[i].UseOfInsecticidalSpray*100/data[i].totalRecords,"Liquid Vaporiser electric machines":data[i].LiquidVaporiserElectric*100/data[i].totalRecords,"Oils":data[i].Oil*100/data[i].totalRecords,"Wearing full sleeved clothes to avoid mosquito bites":data[i].WearingFullSleavesClothes*100/data[i].totalRecords,"Mosquito screens on windows":data[i].MosquitoScreenOnWindow*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              familyhighfewerdata.Q4={"Family High Fewer Last Year":data[i].member_highfever_lastyear*100/data[i].totalRecords};
              visittohcpdata.Q4={"Visit to Health Care Provider":data[i].visit_hcp*100/data[i].totalRecords};
              typeofhcpdata.Q4={"Govt. Dispensary":data[i].GovtDispensary*100/data[i].totalRecords,"Govt./municipalhospital":data[i].GovtMuncipalHospital*100/data[i].totalRecords,"Mobile clininc":data[i].MobileClinic*100/data[i].totalRecords,"NGO or trust hospital /clinic":data[i].NGOTrustHospitalClinic*100/data[i].totalRecords,"Pvt. Hospital/ Pvt. doctor/ clinic":data[i].PvtHospitalClinic*100/data[i].totalRecords,"Others":data[i].OtherHCPType*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              isbloodtestconducteddata.Q4={"Blood Test Conducted":data[i].isbloodtestconducted*100/data[i].totalRecords};
              optfortreatmentdata.Q4={"Treatment Opted":data[i].optfortreatment*100/data[i].totalRecords};
              heard_denguemessagedata.Q4={"Heard Dengue or Malaria Message":data[i].heard_denguemessage*100/data[i].totalRecords};
            }
            finyear=data[i].FinancialYear;
          }
          this.chartReg["charthhsampledistrict"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("charthhsampledistrict", samplingdata,finyear, 0, "", "valaxis","","1- Sample District","","",false,"");           
          this.chartReg["charthhtotsurvey"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("charthhtotsurvey", totsurveydata,finyear, 0, "", "valaxis","","2- Survey","","",false,"");           
          this.chartReg["chartRespondentGrowth"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartRespondentGrowth", Respondantdata,finyear, 100, "Percentage", "valaxis","","3- GENDER OF RESPONDENTS (%)","","",true,"");           
          this.chartReg["charteverheard"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("charteverheard", everhearddata,finyear, 100, "Percentage", "valaxis","","4- Ever heard of an illness called Dengue and Malaria (%)","","",true,"");           
          this.chartReg["chartdenguesympton"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartdenguesympton", denguesympptomdata,finyear, 100, "Percentage", "valaxis","","5- Main Signs and Symptons of Dengue (%)","","",true,"");           
          this.chartReg["chartmalariasympton"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartmalariasympton", malariasympptomdata,finyear, 100, "Percentage", "valaxis","","6- Main Signs and Symptons of Malaria (%)","","",true,"");           
          this.chartReg["chartdenguecause"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartdenguecause", denguecausedata,finyear, 100, "Percentage", "valaxis","","7- Cause of Dengue (%)","","",true,"");           
          this.chartReg["chartmalariacause"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartmalariacause", malariacausedata,finyear, 100, "Percentage", "valaxis","","8- Cause of Malaria (%)","","",true,"");           
          this.chartReg["chartdengueprevented"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartdengueprevented", denguepreveteddata,finyear, 100, "Percentage", "valaxis","","9- Dengue Can be prevented (%)","","",true,"");           
          this.chartReg["chartmalariaprevented"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartmalariaprevented", malariapreveteddata,finyear, 100, "Percentage", "valaxis","","10- Malaria Can be prevented (%)","","",true,"");           
          this.chartReg["chartfamilypractanymethod"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartfamilypractanymethod", familypractanymethoddata,finyear, 100, "Percentage", "valaxis","","11- Family practice any protection method against mosquito biting (%)","","",true,"");                     
          this.chartReg["chartmethodofprotect"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartmethodofprotect", methodofprotectdata,finyear, 100, "Percentage", "valaxis","","12- Method(s) of protection (%)","","",true,"");                     
          this.chartReg["chartfamilyhighfewer"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartfamilyhighfewer", familyhighfewerdata,finyear, 100, "Percentage", "valaxis","","13- Family have a high-grade fever in the last one year (%)","","",true,"");                     
          this.chartReg["chartvisittohcp"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartvisittohcp", visittohcpdata,finyear, 100, "Percentage", "valaxis","","14- Visit to healthcare provider (%)","","",true,"");                     
          this.chartReg["charttypeofhcp"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("charttypeofhcp", typeofhcpdata,finyear, 100, "Percentage", "valaxis","","15- Type of a health care provider visited (%)","","",true,"");                     
          this.chartReg["chartbloodtestconduct"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartbloodtestconduct", isbloodtestconducteddata,finyear, 100, "Percentage", "valaxis","","16- Blood test conducted to confirm diagnosis (%)","","",true,"");                     
          this.chartReg["charttreatmentopted"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("charttreatmentopted", optfortreatmentdata,finyear, 100, "Percentage", "valaxis","","17- Treatment opted (%)","","",true,"");                     
          this.chartReg["chartheardseenmessage"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartheardseenmessage", heard_denguemessagedata,finyear, 100, "Percentage", "valaxis","","18- Ever seen/heard any messages on Dengue or Malaria  (%)","","",true,"");                     
      } 
    });   
    this.rtmisservice.GetGraphicalDataSummary("DipStickHealthWorkerSurvey",this.filterState).subscribe((data: [any]) => {
      if(data.length>0){
        debugger
          var samplingdata={"Q1":{"Sample District":0},"Q2":{"Sample District":0},"Q3":{"Sample District":0},"Q4":{"Sample District":0}};
          var totsurveydata={"Q1":{"Survey":0},"Q2":{"Survey":0},"Q3":{"Survey":0},"Q4":{"Survey":0}};
          var Respondantdata={"Q1":{"ASHA":0,"ANM":0,"quantity":""},"Q2":{"ASHA":0,"ANM":0,"quantity":0},"Q3":{"ASHA":0,"ANM":0,"quantity":0},"Q4":{"ASHA":0,"ANM":0,"quantity":0}};
          var whendenguemosquitobitedata={"Q1":{"Day":0,"Night":0,"quantity":""},"Q2":{"Day":0,"Night":0,"quantity":0},"Q3":{"Day":0,"Night":0,"quantity":0},"Q4":{"Day":0,"Night":0,"quantity":0}};
          var dengueincubationperioddata={"Q1":{"3-14 days":0,"1-12 days":0,"14-28 days":0,"quantity":0},"Q2":{"3-14 days":0,"1-12 days":0,"14-28 days":0,"quantity":0},"Q3":{"3-14 days":0,"1-12 days":0,"14-28 days":0,"quantity":""},"Q4":{"3-14 days":0,"1-12 days":0,"14-28 days":0,"quantity":""}};          
          var malariacausedata={"Q1":{"By blood transfusion":0,"By touch":0,"Flies/Bugs/Lice etc":0,"From the bite of a mosquito that has bitten a person with malaria":0,"Mosquito bites":0,"Using the same utensils":0,"Don't Know":0,"Others":0,"quantity":""},"Q2":{"By blood transfusion":0,"By touch":0,"Flies/Bugs/Lice etc":0,"From the bite of a mosquito that has bitten a person with malaria":0,"Mosquito bites":0,"Using the same utensils":0,"Don't Know":0,"Others":0,"quantity":""},"Q3":{"By blood transfusion":0,"By touch":0,"Flies/Bugs/Lice etc":0,"From the bite of a mosquito that has bitten a person with malaria":0,"Mosquito bites":0,"Using the same utensils":0,"Don't Know":0,"Others":0,"quantity":""},"Q4":{"By blood transfusion":0,"By touch":0,"Flies/Bugs/Lice etc":0,"From the bite of a mosquito that has bitten a person with malaria":0,"Mosquito bites":0,"Using the same utensils":0,"Don't Know":0,"Others":0,"quantity":""}};
          var denguevirustransmitdata={"Q1":{"Yellow fever virus":0,"Malaria parasite":0,"Zika virus":0,"Rift valley fever virus":0,"Don’t know":0,"quantity":0},"Q2":{"Yellow fever virus":0,"Malaria parasite":0,"Zika virus":0,"Rift valley fever virus":0,"Don’t know":0,"quantity":0},"Q3":{"Yellow fever virus":0,"Malaria parasite":0,"Zika virus":0,"Rift valley fever virus":0,"Don’t know":0,"quantity":0},"Q4":{"Yellow fever virus":0,"Malaria parasite":0,"Zika virus":0,"Rift valley fever virus":0,"Don’t know":0,"quantity":0}};
          var seendenguefeverdata={"Q1":{"Yes":0,"No":0,"Don't Know":0,"quantity":""},"Q2":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q3":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q4":{"Yes":0,"No":0,"Don't Know":0,"quantity":0}};
          var denguesympptomdata={"Q1":{"Ascited":0,"Dyspnea":0,"Dysuria":0,"Nasal secretion":0,"Edema":0,"Icterus":0,"Persistant cough":0,"Constipation":0,"Lymphadenitis":0,"Diarrhea":0,"Chest pain":0,"Thrombocytopenia":0,"Vomit":0,"Petechial rash":0,"Positive tourniquet test":0,"Fever/subjective warmth":0,"Retro orbital pain":0,"Muscle pain":0,"Headache":0,"Any other":0,"quantity":""},"Q2":{"Ascited":0,"Dyspnea":0,"Dysuria":0,"Nasal secretion":0,"Edema":0,"Icterus":0,"Persistant cough":0,"Constipation":0,"Lymphadenitis":0,"Diarrhea":0,"Chest pain":0,"Thrombocytopenia":0,"Vomit":0,"Petechial rash":0,"Positive tourniquet test":0,"Fever/subjective warmth":0,"Retro orbital pain":0,"Muscle pain":0,"Headache":0,"Any other":0,"quantity":""},"Q3":{"Ascited":0,"Dyspnea":0,"Dysuria":0,"Nasal secretion":0,"Edema":0,"Icterus":0,"Persistant cough":0,"Constipation":0,"Lymphadenitis":0,"Diarrhea":0,"Chest pain":0,"Thrombocytopenia":0,"Vomit":0,"Petechial rash":0,"Positive tourniquet test":0,"Fever/subjective warmth":0,"Retro orbital pain":0,"Muscle pain":0,"Headache":0,"Any other":0,"quantity":""},"Q4":{"Ascited":0,"Dyspnea":0,"Dysuria":0,"Nasal secretion":0,"Edema":0,"Icterus":0,"Persistant cough":0,"Constipation":0,"Lymphadenitis":0,"Diarrhea":0,"Chest pain":0,"Thrombocytopenia":0,"Vomit":0,"Petechial rash":0,"Positive tourniquet test":0,"Fever/subjective warmth":0,"Retro orbital pain":0,"Muscle pain":0,"Headache":0,"Any other":0,"quantity":""}};
          var malariasymptonsdata={"Q1":{"Fever":0,"Headache":0,"Vomit":0,"Weekness":0,"Loss of Appitite":0,"Sweating":0,"quantity":""},"Q2":{"Fever":0,"Headache":0,"Vomit":0,"Weekness":0,"Loss of Appitite":0,"Sweating":0,"quantity":""},"Q3":{"Fever":0,"Headache":0,"Vomit":0,"Weekness":0,"Loss of Appitite":0,"Sweating":0,"quantity":""},"Q4":{"Fever":0,"Headache":0,"Vomit":0,"Weekness":0,"Loss of Appitite":0,"Sweating":0,"quantity":""}};
          var denguetreatmentdata={"Q1":{"Intravenous fluid rehydration":0,"Paracetamol":0,"Anti-bacterial antibiotics":0,"Anti-viral medicines":0,"Any of the foll: Aspirin, NSAID etc":0,"quantity":""},"Q2":{"Intravenous fluid rehydration":0,"Paracetamol":0,"Anti-bacterial antibiotics":0,"Anti-viral medicines":0,"Any of the foll: Aspirin, NSAID etc":0,"quantity":""},"Q3":{"Intravenous fluid rehydration":0,"Paracetamol":0,"Anti-bacterial antibiotics":0,"Anti-viral medicines":0,"Any of the foll: Aspirin, NSAID etc":0,"quantity":""},"Q4":{"Intravenous fluid rehydration":0,"Paracetamol":0,"Anti-bacterial antibiotics":0,"Anti-viral medicines":0,"Any of the foll: Aspirin, NSAID etc":0,"quantity":""}};
          var ismalariatrainingdata={"Q1":{"Yes":0,"No":0,"Don't Know":0,"quantity":""},"Q2":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q3":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q4":{"Yes":0,"No":0,"Don't Know":0,"quantity":0}};
          var wherereferdenguecase={"Q1":{"Govt/Municipal hospital":0,"Govt. dispensary":0,"UHC/UHP/UFWC":0,"Govt. mobile clinic":0,"Pvt hospital/Pvt doctor/clinic":0,"NGO or trust hospital/clinic":0,"Others":0,"quantity":""},"Q2":{"Govt/Municipal hospital":0,"Govt. dispensary":0,"UHC/UHP/UFWC":0,"Govt. mobile clinic":0,"Pvt hospital/Pvt doctor/clinic":0,"NGO or trust hospital/clinic":0,"Others":0,"quantity":""},"Q3":{"Govt/Municipal hospital":0,"Govt. dispensary":0,"UHC/UHP/UFWC":0,"Govt. mobile clinic":0,"Pvt hospital/Pvt doctor/clinic":0,"NGO or trust hospital/clinic":0,"Others":0,"quantity":""},"Q4":{"Govt/Municipal hospital":0,"Govt. dispensary":0,"UHC/UHP/UFWC":0,"Govt. mobile clinic":0,"Pvt hospital/Pvt doctor/clinic":0,"NGO or trust hospital/clinic":0,"Others":0,"quantity":""}};
          var finyear='';
          for(var i=0;i<data.length;i++){
            if(data[i].FinancialPeriod=='Q1'){
              samplingdata.Q1={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q1={"Survey":data[i].totalRecords};
              Respondantdata.Q1={"ASHA":data[i].providertype_asha*100/data[i].totalRecords,"ANM":data[i].providertype_anm*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              whendenguemosquitobitedata.Q1={"Day":data[i].whendenguemosquitobite_day*100/data[i].totalRecords,"Night":data[i].whendenguemosquitobite_night*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              dengueincubationperioddata.Q1={"3-14 days":data[i].dengueincubationperiod_3_14*100/data[i].totalRecords,"1-12 days":data[i].dengueincubationperiod_1_12*100/data[i].totalRecords,"14-28 days":data[i].dengueincubationperiod_14_28*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q1={"By blood transfusion":data[i].howmalariaspread_blood*100/data[i].totalRecords,"By touch":data[i].howmalariaspread_touch*100/data[i].totalRecords,"Flies/Bugs/Lice etc":data[i].howmalariaspread_fliesbuglice*100/data[i].totalRecords,"From the bite of a mosquito that has bitten a person with malaria":data[i].howmalariaspread_mosqbitefrommalariapers*100/data[i].totalRecords,"Mosquito bites":data[i].howmalariaspread_mosqbite*100/data[i].totalRecords,"Using the same utensils":data[i].howmalariaspread_usesameutensil*100/data[i].totalRecords,"Don't Know":data[i].howmalariaspread_dontknow*100/data[i].totalRecords,"Others":data[i].howmalariaspread_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguevirustransmitdata.Q1={"Yellow fever virus":data[i].virustransmitsamevectorasdengue_yellowfever*100/data[i].totalRecords,"Malaria parasite":data[i].virustransmitsamevectorasdengue_malparasite*100/data[i].totalRecords,"Zika virus":data[i].virustransmitsamevectorasdengue_zikavirus*100/data[i].totalRecords,"Rift valley fever virus":data[i].virustransmitsamevectorasdengue_riftvirus*100/data[i].totalRecords,"Don’t know":data[i].virustransmitsamevectorasdengue_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              seendenguefeverdata.Q1={"Yes":data[i].seendenguefevercase_yes*100/data[i].totalRecords,"No":data[i].seendenguefevercase_no*100/data[i].totalRecords,"Don't Know":data[i].seendenguefevercase_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q1={"Ascited":data[i].denguesign_Ascites*100/data[i].totalRecords,"Dyspnea":data[i].denguesign_Dyspnea*100/data[i].totalRecords,"Dysuria":data[i].denguesign_Dysuria*100/data[i].totalRecords,"Nasal secretion":data[i].denguesign_Nasal*100/data[i].totalRecords,"Edema":data[i].denguesign_Edema*100/data[i].totalRecords,"Icterus":data[i].denguesign_Icterus*100/data[i].totalRecords,"Persistant cough":data[i].denguesign_cough*100/data[i].totalRecords,"Constipation":data[i].denguesign_Constipation*100/data[i].totalRecords,"Lymphadenitis":data[i].denguesign_Lymphadenitis*100/data[i].totalRecords,"Diarrhea":data[i].denguesign_Diarrhea*100/data[i].totalRecords,"Chest pain":data[i].denguesign_Chestpain*100/data[i].totalRecords,"Thrombocytopenia":data[i].denguesign_Thrombocytopenia*100/data[i].totalRecords,"Vomit":data[i].denguesign_Vomit*100/data[i].totalRecords,"Petechial rash":data[i].denguesign_Petechialrash*100/data[i].totalRecords,"Positive tourniquet test":data[i].denguesign_Positivetourniquet*100/data[i].totalRecords,"Fever/subjective warmth":data[i].denguesign_Fever*100/data[i].totalRecords,"Retro orbital pain":data[i].denguesign_Retroorbitalpain*100/data[i].totalRecords,"Muscle pain":data[i].denguesign_Musclepain*100/data[i].totalRecords,"Headache":data[i].denguesign_Headache*100/data[i].totalRecords,"Any other":data[i].denguesign_anyother*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasymptonsdata.Q1={"Fever":data[i].malariasymptons_Fever*100/data[i].totalRecords,"Headache":data[i].malariasymptons_Headache*100/data[i].totalRecords,"Vomit":data[i].malariasymptons_Vomit*100/data[i].totalRecords,"Weekness":data[i].malariasymptons_weakness*100/data[i].totalRecords,"Loss of Appitite":data[i].malariasymptons_appetiteloss*100/data[i].totalRecords,"Sweating":data[i].malariasymptons_sweating*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguetreatmentdata.Q1={"Intravenous fluid rehydration":data[i].denguetreatment_IVFluidrehydration*100/data[i].totalRecords,"Paracetamol":data[i].denguetreatment_Paracetamol*100/data[i].totalRecords,"Anti-bacterial antibiotics":data[i].denguetreatment_antibiotics*100/data[i].totalRecords,"Anti-viral medicines":data[i].denguetreatment_antiviral*100/data[i].totalRecords,"Any of the foll: Aspirin, NSAID etc":data[i].denguetreatment_anyfollowing*100/data[i].totalRecords,"quantity":data[i].totalRecords};              
              ismalariatrainingdata.Q1={"Yes":data[i].isMalariaTraining_yes*100/data[i].totalRecords,"No":data[i].isMalariaTraining_no*100/data[i].totalRecords,"Don't Know":data[i].isMalariaTraining_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              wherereferdenguecase.Q1={"Govt/Municipal hospital":data[i].wherereferdenguecase_muncipalhosp*100/data[i].totalRecords,"Govt. dispensary":data[i].wherereferdenguecase_govtdispen*100/data[i].totalRecords,"UHC/UHP/UFWC":data[i].wherereferdenguecase_uhcuhp*100/data[i].totalRecords,"Govt. mobile clinic":data[i].wherereferdenguecase_govtmobclinic*100/data[i].totalRecords,"Pvt hospital/Pvt doctor/clinic":data[i].wherereferdenguecase_pvthosp*100/data[i].totalRecords,"NGO or trust hospital/clinic":data[i].wherereferdenguecase_ngohosp*100/data[i].totalRecords,"Others":data[i].wherereferdenguecase_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
            }
            else if(data[i].FinancialPeriod=='Q2'){
              samplingdata.Q2={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q2={"Survey":data[i].totalRecords};
              Respondantdata.Q2={"ASHA":data[i].providertype_asha*100/data[i].totalRecords,"ANM":data[i].providertype_anm*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              whendenguemosquitobitedata.Q2={"Day":data[i].whendenguemosquitobite_day*100/data[i].totalRecords,"Night":data[i].whendenguemosquitobite_night*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              dengueincubationperioddata.Q2={"3-14 days":data[i].dengueincubationperiod_3_14*100/data[i].totalRecords,"1-12 days":data[i].dengueincubationperiod_1_12*100/data[i].totalRecords,"14-28 days":data[i].dengueincubationperiod_14_28*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q2={"By blood transfusion":data[i].howmalariaspread_blood*100/data[i].totalRecords,"By touch":data[i].howmalariaspread_touch*100/data[i].totalRecords,"Flies/Bugs/Lice etc":data[i].howmalariaspread_fliesbuglice*100/data[i].totalRecords,"From the bite of a mosquito that has bitten a person with malaria":data[i].howmalariaspread_mosqbitefrommalariapers*100/data[i].totalRecords,"Mosquito bites":data[i].howmalariaspread_mosqbite*100/data[i].totalRecords,"Using the same utensils":data[i].howmalariaspread_usesameutensil*100/data[i].totalRecords,"Don't Know":data[i].howmalariaspread_dontknow*100/data[i].totalRecords,"Others":data[i].howmalariaspread_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguevirustransmitdata.Q2={"Yellow fever virus":data[i].virustransmitsamevectorasdengue_yellowfever*100/data[i].totalRecords,"Malaria parasite":data[i].virustransmitsamevectorasdengue_malparasite*100/data[i].totalRecords,"Zika virus":data[i].virustransmitsamevectorasdengue_zikavirus*100/data[i].totalRecords,"Rift valley fever virus":data[i].virustransmitsamevectorasdengue_riftvirus*100/data[i].totalRecords,"Don’t know":data[i].virustransmitsamevectorasdengue_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              seendenguefeverdata.Q2={"Yes":data[i].seendenguefevercase_yes*100/data[i].totalRecords,"No":data[i].seendenguefevercase_no*100/data[i].totalRecords,"Don't Know":data[i].seendenguefevercase_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q2={"Ascited":data[i].denguesign_Ascites*100/data[i].totalRecords,"Dyspnea":data[i].denguesign_Dyspnea*100/data[i].totalRecords,"Dysuria":data[i].denguesign_Dysuria*100/data[i].totalRecords,"Nasal secretion":data[i].denguesign_Nasal*100/data[i].totalRecords,"Edema":data[i].denguesign_Edema*100/data[i].totalRecords,"Icterus":data[i].denguesign_Icterus*100/data[i].totalRecords,"Persistant cough":data[i].denguesign_cough*100/data[i].totalRecords,"Constipation":data[i].denguesign_Constipation*100/data[i].totalRecords,"Lymphadenitis":data[i].denguesign_Lymphadenitis*100/data[i].totalRecords,"Diarrhea":data[i].denguesign_Diarrhea*100/data[i].totalRecords,"Chest pain":data[i].denguesign_Chestpain*100/data[i].totalRecords,"Thrombocytopenia":data[i].denguesign_Thrombocytopenia*100/data[i].totalRecords,"Vomit":data[i].denguesign_Vomit*100/data[i].totalRecords,"Petechial rash":data[i].denguesign_Petechialrash*100/data[i].totalRecords,"Positive tourniquet test":data[i].denguesign_Positivetourniquet*100/data[i].totalRecords,"Fever/subjective warmth":data[i].denguesign_Fever*100/data[i].totalRecords,"Retro orbital pain":data[i].denguesign_Retroorbitalpain*100/data[i].totalRecords,"Muscle pain":data[i].denguesign_Musclepain*100/data[i].totalRecords,"Headache":data[i].denguesign_Headache*100/data[i].totalRecords,"Any other":data[i].denguesign_anyother*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasymptonsdata.Q2={"Fever":data[i].malariasymptons_Fever*100/data[i].totalRecords,"Headache":data[i].malariasymptons_Headache*100/data[i].totalRecords,"Vomit":data[i].malariasymptons_Vomit*100/data[i].totalRecords,"Weekness":data[i].malariasymptons_weakness*100/data[i].totalRecords,"Loss of Appitite":data[i].malariasymptons_appetiteloss*100/data[i].totalRecords,"Sweating":data[i].malariasymptons_sweating*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguetreatmentdata.Q2={"Intravenous fluid rehydration":data[i].denguetreatment_IVFluidrehydration*100/data[i].totalRecords,"Paracetamol":data[i].denguetreatment_Paracetamol*100/data[i].totalRecords,"Anti-bacterial antibiotics":data[i].denguetreatment_antibiotics*100/data[i].totalRecords,"Anti-viral medicines":data[i].denguetreatment_antiviral*100/data[i].totalRecords,"Any of the foll: Aspirin, NSAID etc":data[i].denguetreatment_anyfollowing*100/data[i].totalRecords,"quantity":data[i].totalRecords};              
              ismalariatrainingdata.Q2={"Yes":data[i].isMalariaTraining_yes*100/data[i].totalRecords,"No":data[i].isMalariaTraining_no*100/data[i].totalRecords,"Don't Know":data[i].isMalariaTraining_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              wherereferdenguecase.Q2={"Govt/Municipal hospital":data[i].wherereferdenguecase_muncipalhosp*100/data[i].totalRecords,"Govt. dispensary":data[i].wherereferdenguecase_govtdispen*100/data[i].totalRecords,"UHC/UHP/UFWC":data[i].wherereferdenguecase_uhcuhp*100/data[i].totalRecords,"Govt. mobile clinic":data[i].wherereferdenguecase_govtmobclinic*100/data[i].totalRecords,"Pvt hospital/Pvt doctor/clinic":data[i].wherereferdenguecase_pvthosp*100/data[i].totalRecords,"NGO or trust hospital/clinic":data[i].wherereferdenguecase_ngohosp*100/data[i].totalRecords,"Others":data[i].wherereferdenguecase_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
            }
            else if(data[i].FinancialPeriod=='Q3'){
              samplingdata.Q3={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q3={"Survey":data[i].totalRecords};
              Respondantdata.Q3={"ASHA":data[i].providertype_asha*100/data[i].totalRecords,"ANM":data[i].providertype_anm*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              whendenguemosquitobitedata.Q3={"Day":data[i].whendenguemosquitobite_day*100/data[i].totalRecords,"Night":data[i].whendenguemosquitobite_night*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              dengueincubationperioddata.Q3={"3-14 days":data[i].dengueincubationperiod_3_14*100/data[i].totalRecords,"1-12 days":data[i].dengueincubationperiod_1_12*100/data[i].totalRecords,"14-28 days":data[i].dengueincubationperiod_14_28*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q3={"By blood transfusion":data[i].howmalariaspread_blood*100/data[i].totalRecords,"By touch":data[i].howmalariaspread_touch*100/data[i].totalRecords,"Flies/Bugs/Lice etc":data[i].howmalariaspread_fliesbuglice*100/data[i].totalRecords,"From the bite of a mosquito that has bitten a person with malaria":data[i].howmalariaspread_mosqbitefrommalariapers*100/data[i].totalRecords,"Mosquito bites":data[i].howmalariaspread_mosqbite*100/data[i].totalRecords,"Using the same utensils":data[i].howmalariaspread_usesameutensil*100/data[i].totalRecords,"Don't Know":data[i].howmalariaspread_dontknow*100/data[i].totalRecords,"Others":data[i].howmalariaspread_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguevirustransmitdata.Q3={"Yellow fever virus":data[i].virustransmitsamevectorasdengue_yellowfever*100/data[i].totalRecords,"Malaria parasite":data[i].virustransmitsamevectorasdengue_malparasite*100/data[i].totalRecords,"Zika virus":data[i].virustransmitsamevectorasdengue_zikavirus*100/data[i].totalRecords,"Rift valley fever virus":data[i].virustransmitsamevectorasdengue_riftvirus*100/data[i].totalRecords,"Don’t know":data[i].virustransmitsamevectorasdengue_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              seendenguefeverdata.Q3={"Yes":data[i].seendenguefevercase_yes*100/data[i].totalRecords,"No":data[i].seendenguefevercase_no*100/data[i].totalRecords,"Don't Know":data[i].seendenguefevercase_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q3={"Ascited":data[i].denguesign_Ascites*100/data[i].totalRecords,"Dyspnea":data[i].denguesign_Dyspnea*100/data[i].totalRecords,"Dysuria":data[i].denguesign_Dysuria*100/data[i].totalRecords,"Nasal secretion":data[i].denguesign_Nasal*100/data[i].totalRecords,"Edema":data[i].denguesign_Edema*100/data[i].totalRecords,"Icterus":data[i].denguesign_Icterus*100/data[i].totalRecords,"Persistant cough":data[i].denguesign_cough*100/data[i].totalRecords,"Constipation":data[i].denguesign_Constipation*100/data[i].totalRecords,"Lymphadenitis":data[i].denguesign_Lymphadenitis*100/data[i].totalRecords,"Diarrhea":data[i].denguesign_Diarrhea*100/data[i].totalRecords,"Chest pain":data[i].denguesign_Chestpain*100/data[i].totalRecords,"Thrombocytopenia":data[i].denguesign_Thrombocytopenia*100/data[i].totalRecords,"Vomit":data[i].denguesign_Vomit*100/data[i].totalRecords,"Petechial rash":data[i].denguesign_Petechialrash*100/data[i].totalRecords,"Positive tourniquet test":data[i].denguesign_Positivetourniquet*100/data[i].totalRecords,"Fever/subjective warmth":data[i].denguesign_Fever*100/data[i].totalRecords,"Retro orbital pain":data[i].denguesign_Retroorbitalpain*100/data[i].totalRecords,"Muscle pain":data[i].denguesign_Musclepain*100/data[i].totalRecords,"Headache":data[i].denguesign_Headache*100/data[i].totalRecords,"Any other":data[i].denguesign_anyother*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasymptonsdata.Q3={"Fever":data[i].malariasymptons_Fever*100/data[i].totalRecords,"Headache":data[i].malariasymptons_Headache*100/data[i].totalRecords,"Vomit":data[i].malariasymptons_Vomit*100/data[i].totalRecords,"Weekness":data[i].malariasymptons_weakness*100/data[i].totalRecords,"Loss of Appitite":data[i].malariasymptons_appetiteloss*100/data[i].totalRecords,"Sweating":data[i].malariasymptons_sweating*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguetreatmentdata.Q3={"Intravenous fluid rehydration":data[i].denguetreatment_IVFluidrehydration*100/data[i].totalRecords,"Paracetamol":data[i].denguetreatment_Paracetamol*100/data[i].totalRecords,"Anti-bacterial antibiotics":data[i].denguetreatment_antibiotics*100/data[i].totalRecords,"Anti-viral medicines":data[i].denguetreatment_antiviral*100/data[i].totalRecords,"Any of the foll: Aspirin, NSAID etc":data[i].denguetreatment_anyfollowing*100/data[i].totalRecords,"quantity":data[i].totalRecords};              
              ismalariatrainingdata.Q3={"Yes":data[i].isMalariaTraining_yes*100/data[i].totalRecords,"No":data[i].isMalariaTraining_no*100/data[i].totalRecords,"Don't Know":data[i].isMalariaTraining_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              wherereferdenguecase.Q3={"Govt/Municipal hospital":data[i].wherereferdenguecase_muncipalhosp*100/data[i].totalRecords,"Govt. dispensary":data[i].wherereferdenguecase_govtdispen*100/data[i].totalRecords,"UHC/UHP/UFWC":data[i].wherereferdenguecase_uhcuhp*100/data[i].totalRecords,"Govt. mobile clinic":data[i].wherereferdenguecase_govtmobclinic*100/data[i].totalRecords,"Pvt hospital/Pvt doctor/clinic":data[i].wherereferdenguecase_pvthosp*100/data[i].totalRecords,"NGO or trust hospital/clinic":data[i].wherereferdenguecase_ngohosp*100/data[i].totalRecords,"Others":data[i].wherereferdenguecase_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
            }
            else if(data[i].FinancialPeriod=='Q4'){
              samplingdata.Q4={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q4={"Survey":data[i].totalRecords};
              Respondantdata.Q4={"ASHA":data[i].providertype_asha*100/data[i].totalRecords,"ANM":data[i].providertype_anm*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              whendenguemosquitobitedata.Q4={"Day":data[i].whendenguemosquitobite_day*100/data[i].totalRecords,"Night":data[i].whendenguemosquitobite_night*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              dengueincubationperioddata.Q4={"3-14 days":data[i].dengueincubationperiod_3_14*100/data[i].totalRecords,"1-12 days":data[i].dengueincubationperiod_1_12*100/data[i].totalRecords,"14-28 days":data[i].dengueincubationperiod_14_28*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q4={"By blood transfusion":data[i].howmalariaspread_blood*100/data[i].totalRecords,"By touch":data[i].howmalariaspread_touch*100/data[i].totalRecords,"Flies/Bugs/Lice etc":data[i].howmalariaspread_fliesbuglice*100/data[i].totalRecords,"From the bite of a mosquito that has bitten a person with malaria":data[i].howmalariaspread_mosqbitefrommalariapers*100/data[i].totalRecords,"Mosquito bites":data[i].howmalariaspread_mosqbite*100/data[i].totalRecords,"Using the same utensils":data[i].howmalariaspread_usesameutensil*100/data[i].totalRecords,"Don't Know":data[i].howmalariaspread_dontknow*100/data[i].totalRecords,"Others":data[i].howmalariaspread_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguevirustransmitdata.Q4={"Yellow fever virus":data[i].virustransmitsamevectorasdengue_yellowfever*100/data[i].totalRecords,"Malaria parasite":data[i].virustransmitsamevectorasdengue_malparasite*100/data[i].totalRecords,"Zika virus":data[i].virustransmitsamevectorasdengue_zikavirus*100/data[i].totalRecords,"Rift valley fever virus":data[i].virustransmitsamevectorasdengue_riftvirus*100/data[i].totalRecords,"Don’t know":data[i].virustransmitsamevectorasdengue_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              seendenguefeverdata.Q4={"Yes":data[i].seendenguefevercase_yes*100/data[i].totalRecords,"No":data[i].seendenguefevercase_no*100/data[i].totalRecords,"Don't Know":data[i].seendenguefevercase_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q4={"Ascited":data[i].denguesign_Ascites*100/data[i].totalRecords,"Dyspnea":data[i].denguesign_Dyspnea*100/data[i].totalRecords,"Dysuria":data[i].denguesign_Dysuria*100/data[i].totalRecords,"Nasal secretion":data[i].denguesign_Nasal*100/data[i].totalRecords,"Edema":data[i].denguesign_Edema*100/data[i].totalRecords,"Icterus":data[i].denguesign_Icterus*100/data[i].totalRecords,"Persistant cough":data[i].denguesign_cough*100/data[i].totalRecords,"Constipation":data[i].denguesign_Constipation*100/data[i].totalRecords,"Lymphadenitis":data[i].denguesign_Lymphadenitis*100/data[i].totalRecords,"Diarrhea":data[i].denguesign_Diarrhea*100/data[i].totalRecords,"Chest pain":data[i].denguesign_Chestpain*100/data[i].totalRecords,"Thrombocytopenia":data[i].denguesign_Thrombocytopenia*100/data[i].totalRecords,"Vomit":data[i].denguesign_Vomit*100/data[i].totalRecords,"Petechial rash":data[i].denguesign_Petechialrash*100/data[i].totalRecords,"Positive tourniquet test":data[i].denguesign_Positivetourniquet*100/data[i].totalRecords,"Fever/subjective warmth":data[i].denguesign_Fever*100/data[i].totalRecords,"Retro orbital pain":data[i].denguesign_Retroorbitalpain*100/data[i].totalRecords,"Muscle pain":data[i].denguesign_Musclepain*100/data[i].totalRecords,"Headache":data[i].denguesign_Headache*100/data[i].totalRecords,"Any other":data[i].denguesign_anyother*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasymptonsdata.Q4={"Fever":data[i].malariasymptons_Fever*100/data[i].totalRecords,"Headache":data[i].malariasymptons_Headache*100/data[i].totalRecords,"Vomit":data[i].malariasymptons_Vomit*100/data[i].totalRecords,"Weekness":data[i].malariasymptons_weakness*100/data[i].totalRecords,"Loss of Appitite":data[i].malariasymptons_appetiteloss*100/data[i].totalRecords,"Sweating":data[i].malariasymptons_sweating*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguetreatmentdata.Q4={"Intravenous fluid rehydration":data[i].denguetreatment_IVFluidrehydration*100/data[i].totalRecords,"Paracetamol":data[i].denguetreatment_Paracetamol*100/data[i].totalRecords,"Anti-bacterial antibiotics":data[i].denguetreatment_antibiotics*100/data[i].totalRecords,"Anti-viral medicines":data[i].denguetreatment_antiviral*100/data[i].totalRecords,"Any of the foll: Aspirin, NSAID etc":data[i].denguetreatment_anyfollowing*100/data[i].totalRecords,"quantity":data[i].totalRecords};              
              ismalariatrainingdata.Q4={"Yes":data[i].isMalariaTraining_yes*100/data[i].totalRecords,"No":data[i].isMalariaTraining_no*100/data[i].totalRecords,"Don't Know":data[i].isMalariaTraining_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              wherereferdenguecase.Q4={"Govt/Municipal hospital":data[i].wherereferdenguecase_muncipalhosp*100/data[i].totalRecords,"Govt. dispensary":data[i].wherereferdenguecase_govtdispen*100/data[i].totalRecords,"UHC/UHP/UFWC":data[i].wherereferdenguecase_uhcuhp*100/data[i].totalRecords,"Govt. mobile clinic":data[i].wherereferdenguecase_govtmobclinic*100/data[i].totalRecords,"Pvt hospital/Pvt doctor/clinic":data[i].wherereferdenguecase_pvthosp*100/data[i].totalRecords,"NGO or trust hospital/clinic":data[i].wherereferdenguecase_ngohosp*100/data[i].totalRecords,"Others":data[i].wherereferdenguecase_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
            }
            finyear=data[i].FinancialYear;
        } 
        this.chartReg["charthcsampledistrict"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("charthcsampledistrict", samplingdata,finyear, 0, "", "valaxis","","19- Sample District","","",false,"");           
        this.chartReg["charthctotsurvey"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("charthctotsurvey", totsurveydata,finyear, 0, "", "valaxis","","20- Survey","","",false,"");           
        this.chartReg["chart1"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart1", Respondantdata,finyear, 100, "Percentage", "valaxis","","21- Type of providers (%)","","",true,"");           
        this.chartReg["chart2"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart2", whendenguemosquitobitedata,finyear, 100, "Percentage", "valaxis","","22- Dengue mosquito bite %","","",true,"");           
        this.chartReg["chart3"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart3", dengueincubationperioddata,finyear, 100, "Percentage", "valaxis","","23- Incubation period dengue %","","",true,"");           
        this.chartReg["chart4"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart4", malariacausedata,finyear, 100, "Percentage", "valaxis","","24- Malaria Spread %","","",true,"");           
        this.chartReg["chart5"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart5", denguevirustransmitdata,finyear, 100, "Percentage", "valaxis","","25- Parasite/virus is transmitted by the same vector as dengue?","","",true,"");           
        this.chartReg["chart6"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart6", seendenguefeverdata,finyear, 100, "Percentage", "valaxis","","26- Ever seen dengue fever case","","",true,"");           
        this.chartReg["chart7"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart7", denguesympptomdata,finyear, 100, "Percentage", "valaxis","","27- Signs and symptoms of dengue without warning signs","","",true,"");           
        this.chartReg["chart8"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart8", malariasymptonsdata,finyear, 100, "Percentage", "valaxis","","28- Signs and symptoms of Malaria","","",true,"");           
        this.chartReg["chart9"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart9", denguetreatmentdata,finyear, 100, "Percentage", "valaxis","","29- Treatment for suspected dengue patients","","",true,"");                     
        this.chartReg["chart10"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart10", ismalariatrainingdata,finyear, 100, "Percentage", "valaxis","","30- Training received on malaria","","",true,"");                     
        this.chartReg["chart11"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart11", wherereferdenguecase,finyear, 100, "Percentage", "valaxis","","31- Patients refer for the patient for the treatment","","",true,"");                     
      }
    });
    this.rtmisservice.GetGraphicalDataSummary("DipStickProviderSurvey",this.filterState).subscribe((data: [any]) => {
      if(data.length>0){
        debugger
        var samplingdata={"Q1":{"Sample District":0},"Q2":{"Sample District":0},"Q3":{"Sample District":0},"Q4":{"Sample District":0}};
        var totsurveydata={"Q1":{"Survey":0},"Q2":{"Survey":0},"Q3":{"Survey":0},"Q4":{"Survey":0}};
        var Respondantdata={"Q1":{"Doctor (Ayurveda/Unani/Homeopath/Others)":0,"Doctor (MBBS)":0,"quantity":""},"Q2":{"Doctor (Ayurveda/Unani/Homeopath/Others)":0,"Doctor (MBBS)":0,"quantity":0},"Q3":{"Doctor (Ayurveda/Unani/Homeopath/Others)":0,"Doctor (MBBS)":0,"quantity":0},"Q4":{"Doctor (Ayurveda/Unani/Homeopath/Others)":0,"Doctor (MBBS)":0,"quantity":0}};
        var whendenguemosquitobitedata={"Q1":{"Day":0,"Night":0,"quantity":""},"Q2":{"Day":0,"Night":0,"quantity":0},"Q3":{"Day":0,"Night":0,"quantity":0},"Q4":{"Day":0,"Night":0,"quantity":0}};
        var dengueincubationperioddata={"Q1":{"3-14 days":0,"1-12 days":0,"14-28 days":0,"quantity":0},"Q2":{"3-14 days":0,"1-12 days":0,"14-28 days":0,"quantity":0},"Q3":{"3-14 days":0,"1-12 days":0,"14-28 days":0,"quantity":""},"Q4":{"3-14 days":0,"1-12 days":0,"14-28 days":0,"quantity":""}};
        var seendenguefeverdata={"Q1":{"Yes":0,"No":0,"Don't Know":0,"quantity":""},"Q2":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q3":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q4":{"Yes":0,"No":0,"Don't Know":0,"quantity":0}};
        var familiarwithwhoguidelinesdata={"Q1":{"Yes":0,"No":0,"Don't Know":0,"quantity":""},"Q2":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q3":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q4":{"Yes":0,"No":0,"Don't Know":0,"quantity":0}};
        var iswhoguidelinehelpdata={"Q1":{"Yes":0,"No":0,"Don't Know":0,"quantity":""},"Q2":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q3":{"Yes":0,"No":0,"Don't Know":0,"quantity":0},"Q4":{"Yes":0,"No":0,"Don't Know":0,"quantity":0}};
        var denguevirustransmitdata={"Q1":{"Yellow fever virus":0,"Malaria parasite":0,"Zika virus":0,"Rift valley fever virus":0,"Don’t know":0,"quantity":0},"Q2":{"Yellow fever virus":0,"Malaria parasite":0,"Zika virus":0,"Rift valley fever virus":0,"Don’t know":0,"quantity":0},"Q3":{"Yellow fever virus":0,"Malaria parasite":0,"Zika virus":0,"Rift valley fever virus":0,"Don’t know":0,"quantity":0},"Q4":{"Yellow fever virus":0,"Malaria parasite":0,"Zika virus":0,"Rift valley fever virus":0,"Don’t know":0,"quantity":0}};
        var handle_highfewerpatientdata={"Q1":{"Prescribe paracetamol":0,"Cold sponges":0,"Advise them to visit a health facility for a blood test":0,"Other":0,"quantity":0},"Q2":{"Prescribe paracetamol":0,"Cold sponges":0,"Advise them to visit a health facility for a blood test":0,"Other":0,"quantity":0},"Q3":{"Prescribe paracetamol":0,"Cold sponges":0,"Advise them to visit a health facility for a blood test":0,"Other":0,"quantity":0},"Q4":{"Prescribe paracetamol":0,"Cold sponges":0,"Advise them to visit a health facility for a blood test":0,"Other":0,"quantity":0}};
        var denguesympptomdata={"Q1":{"Ascited":0,"Dyspnea":0,"Dysuria":0,"Nasal secretion":0,"Edema":0,"Icterus":0,"Persistant cough":0,"Constipation":0,"Lymphadenitis":0,"Diarrhea":0,"Chest pain":0,"Thrombocytopenia":0,"Vomit":0,"Petechial rash":0,"Positive tourniquet test":0,"Fever/subjective warmth":0,"Retro orbital pain":0,"Muscle pain":0,"Headache":0,"Any other":0,"quantity":""},"Q2":{"Ascited":0,"Dyspnea":0,"Dysuria":0,"Nasal secretion":0,"Edema":0,"Icterus":0,"Persistant cough":0,"Constipation":0,"Lymphadenitis":0,"Diarrhea":0,"Chest pain":0,"Thrombocytopenia":0,"Vomit":0,"Petechial rash":0,"Positive tourniquet test":0,"Fever/subjective warmth":0,"Retro orbital pain":0,"Muscle pain":0,"Headache":0,"Any other":0,"quantity":""},"Q3":{"Ascited":0,"Dyspnea":0,"Dysuria":0,"Nasal secretion":0,"Edema":0,"Icterus":0,"Persistant cough":0,"Constipation":0,"Lymphadenitis":0,"Diarrhea":0,"Chest pain":0,"Thrombocytopenia":0,"Vomit":0,"Petechial rash":0,"Positive tourniquet test":0,"Fever/subjective warmth":0,"Retro orbital pain":0,"Muscle pain":0,"Headache":0,"Any other":0,"quantity":""},"Q4":{"Ascited":0,"Dyspnea":0,"Dysuria":0,"Nasal secretion":0,"Edema":0,"Icterus":0,"Persistant cough":0,"Constipation":0,"Lymphadenitis":0,"Diarrhea":0,"Chest pain":0,"Thrombocytopenia":0,"Vomit":0,"Petechial rash":0,"Positive tourniquet test":0,"Fever/subjective warmth":0,"Retro orbital pain":0,"Muscle pain":0,"Headache":0,"Any other":0,"quantity":""}};
        var malariasymptonsdata={"Q1":{"Fever":0,"Headache":0,"Vomit":0,"Weekness":0,"Loss of Appitite":0,"Sweating":0,"quantity":""},"Q2":{"Fever":0,"Headache":0,"Vomit":0,"Weekness":0,"Loss of Appitite":0,"Sweating":0,"quantity":""},"Q3":{"Fever":0,"Headache":0,"Vomit":0,"Weekness":0,"Loss of Appitite":0,"Sweating":0,"quantity":""},"Q4":{"Fever":0,"Headache":0,"Vomit":0,"Weekness":0,"Loss of Appitite":0,"Sweating":0,"quantity":""}};
        var stepdo_feverpatientdata={"Q1":{"Cure with Exorcism":0,"Give Medicine/Herb/Decoction":0,"Send to Hospital/Doctor":0,"Other":0,"quantity":""},"Q2":{"Cure with Exorcism":0,"Give Medicine/Herb/Decoction":0,"Send to Hospital/Doctor":0,"Other":0,"quantity":""},"Q3":{"Cure with Exorcism":0,"Give Medicine/Herb/Decoction":0,"Send to Hospital/Doctor":0,"Other":0,"quantity":0},"Q4":{"Cure with Exorcism":0,"Give Medicine/Herb/Decoction":0,"Send to Hospital/Doctor":0,"Other":0,"quantity":0}};
        var referwhich_hcpdata={"Q1":{"ASHA/ANM":0,"Government Hospital/PHC/CHC/Health Center":0,"Private Hospital/Doctor":0,"quantity":""},"Q2":{"ASHA/ANM":0,"Government Hospital/PHC/CHC/Health Center":0,"Private Hospital/Doctor":0,"quantity":""},"Q3":{"ASHA/ANM":0,"Government Hospital/PHC/CHC/Health Center":0,"Private Hospital/Doctor":0,"quantity":""},"Q4":{"ASHA/ANM":0,"Government Hospital/PHC/CHC/Health Center":0,"Private Hospital/Doctor":0,"quantity":""}};
        var lackingresourcetotreatpatientdata={"Q1":{"Sufficient training":0,"Medication needed to treat":0,"Instruments needed to treat":0,"Access to laboratory tools":0,"quantity":""},"Q2":{"Sufficient training":0,"Medication needed to treat":0,"Instruments needed to treat":0,"Access to laboratory tools":0,"quantity":""},"Q3":{"Sufficient training":0,"Medication needed to treat":0,"Instruments needed to treat":0,"Access to laboratory tools":0,"quantity":""},"Q4":{"Sufficient training":0,"Medication needed to treat":0,"Instruments needed to treat":0,"Access to laboratory tools":0,"quantity":0},};
        var malariacausedata={"Q1":{"By blood transfusion":0,"By touch":0,"Flies/Bugs/Lice etc":0,"From the bite of a mosquito that has bitten a person with malaria":0,"Mosquito bites":0,"Using the same utensils":0,"Don't Know":0,"Others":0,"quantity":""},"Q2":{"By blood transfusion":0,"By touch":0,"Flies/Bugs/Lice etc":0,"From the bite of a mosquito that has bitten a person with malaria":0,"Mosquito bites":0,"Using the same utensils":0,"Don't Know":0,"Others":0,"quantity":""},"Q3":{"By blood transfusion":0,"By touch":0,"Flies/Bugs/Lice etc":0,"From the bite of a mosquito that has bitten a person with malaria":0,"Mosquito bites":0,"Using the same utensils":0,"Don't Know":0,"Others":0,"quantity":""},"Q4":{"By blood transfusion":0,"By touch":0,"Flies/Bugs/Lice etc":0,"From the bite of a mosquito that has bitten a person with malaria":0,"Mosquito bites":0,"Using the same utensils":0,"Don't Know":0,"Others":0,"quantity":""}};
        var denguetreatmentdata={"Q1":{"Intravenous fluid rehydration":0,"Paracetamol":0,"Anti-bacterial antibiotics":0,"Anti-viral medicines":0,"Any of the foll: Aspirin, NSAID etc":0,"quantity":""},"Q2":{"Intravenous fluid rehydration":0,"Paracetamol":0,"Anti-bacterial antibiotics":0,"Anti-viral medicines":0,"Any of the foll: Aspirin, NSAID etc":0,"quantity":""},"Q3":{"Intravenous fluid rehydration":0,"Paracetamol":0,"Anti-bacterial antibiotics":0,"Anti-viral medicines":0,"Any of the foll: Aspirin, NSAID etc":0,"quantity":""},"Q4":{"Intravenous fluid rehydration":0,"Paracetamol":0,"Anti-bacterial antibiotics":0,"Anti-viral medicines":0,"Any of the foll: Aspirin, NSAID etc":0,"quantity":""}};
          var finyear='';
          for(var i=0;i<data.length;i++){
            if(data[i].FinancialPeriod=='Q1'){
              samplingdata.Q1={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q1={"Survey":data[i].totalRecords};
              Respondantdata.Q1={"Doctor (Ayurveda/Unani/Homeopath/Others)":data[i].providertype_doctor*100/data[i].totalRecords,"Doctor (MBBS)":data[i].providertype_mbbs*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              whendenguemosquitobitedata.Q1={"Day":data[i].whendenguemosquitobite_day*100/data[i].totalRecords,"Night":data[i].whendenguemosquitobite_night*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              dengueincubationperioddata.Q1={"3-14 days":data[i].dengueincubationperiod_3_14*100/data[i].totalRecords,"1-12 days":data[i].dengueincubationperiod_1_12*100/data[i].totalRecords,"14-28 days":data[i].dengueincubationperiod_14_28*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              seendenguefeverdata.Q1={"Yes":data[i].seendenguefevercase_yes*100/data[i].totalRecords,"No":data[i].seendenguefevercase_no*100/data[i].totalRecords,"Don't Know":data[i].seendenguefevercase_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              familiarwithwhoguidelinesdata.Q1={"Yes":data[i].familiarwithwhoguidelines_yes*100/data[i].totalRecords,"No":data[i].familiarwithwhoguidelines_no*100/data[i].totalRecords,"Don't Know":data[i].familiarwithwhoguidelines_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              iswhoguidelinehelpdata.Q1={"Yes":data[i].iswhoguidelinehelp_yes*100/data[i].totalRecords,"No":data[i].iswhoguidelinehelp_no*100/data[i].totalRecords,"Don't Know":data[i].iswhoguidelinehelp_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguevirustransmitdata.Q1={"Yellow fever virus":data[i].virustransmitsamevectorasdengue_yellowfever*100/data[i].totalRecords,"Malaria parasite":data[i].virustransmitsamevectorasdengue_malparasite*100/data[i].totalRecords,"Zika virus":data[i].virustransmitsamevectorasdengue_zikavirus*100/data[i].totalRecords,"Rift valley fever virus":data[i].virustransmitsamevectorasdengue_riftvirus*100/data[i].totalRecords,"Don’t know":data[i].virustransmitsamevectorasdengue_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              handle_highfewerpatientdata.Q1={"Prescribe paracetamol":data[i].howyouhandle_highfewerpatient_paracetamol*100/data[i].totalRecords,"Cold sponges":data[i].howyouhandle_highfewerpatient_coldsponge*100/data[i].totalRecords,"Advise them to visit a health facility for a blood test":data[i].howyouhandle_highfewerpatient_advisehealthfac*100/data[i].totalRecords,"Other":data[i].howyouhandle_highfewerpatient_Other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q1={"Ascited":data[i].denguesign_Ascites*100/data[i].totalRecords,"Dyspnea":data[i].denguesign_Dyspnea*100/data[i].totalRecords,"Dysuria":data[i].denguesign_Dysuria*100/data[i].totalRecords,"Nasal secretion":data[i].denguesign_Nasal*100/data[i].totalRecords,"Edema":data[i].denguesign_Edema*100/data[i].totalRecords,"Icterus":data[i].denguesign_Icterus*100/data[i].totalRecords,"Persistant cough":data[i].denguesign_cough*100/data[i].totalRecords,"Constipation":data[i].denguesign_Constipation*100/data[i].totalRecords,"Lymphadenitis":data[i].denguesign_Lymphadenitis*100/data[i].totalRecords,"Diarrhea":data[i].denguesign_Diarrhea*100/data[i].totalRecords,"Chest pain":data[i].denguesign_Chestpain*100/data[i].totalRecords,"Thrombocytopenia":data[i].denguesign_Thrombocytopenia*100/data[i].totalRecords,"Vomit":data[i].denguesign_Vomit*100/data[i].totalRecords,"Petechial rash":data[i].denguesign_Petechialrash*100/data[i].totalRecords,"Positive tourniquet test":data[i].denguesign_Positivetourniquet*100/data[i].totalRecords,"Fever/subjective warmth":data[i].denguesign_Fever*100/data[i].totalRecords,"Retro orbital pain":data[i].denguesign_Retroorbitalpain*100/data[i].totalRecords,"Muscle pain":data[i].denguesign_Musclepain*100/data[i].totalRecords,"Headache":data[i].denguesign_Headache*100/data[i].totalRecords,"Any other":data[i].denguesign_anyother*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasymptonsdata.Q1={"Fever":data[i].malariasymptons_Fever*100/data[i].totalRecords,"Headache":data[i].malariasymptons_Headache*100/data[i].totalRecords,"Vomit":data[i].malariasymptons_Vomit*100/data[i].totalRecords,"Weekness":data[i].malariasymptons_weakness*100/data[i].totalRecords,"Loss of Appitite":data[i].malariasymptons_appetiteloss*100/data[i].totalRecords,"Sweating":data[i].malariasymptons_sweating*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              stepdo_feverpatientdata.Q1={"Cure with Exorcism":data[i].stepdo_feverpatient_exorcism*100/data[i].totalRecords,"Give Medicine/Herb/Decoction":data[i].stepdo_feverpatient_givemedicine*100/data[i].totalRecords,"Send to Hospital/Doctor":data[i].stepdo_feverpatient_senttohosp*100/data[i].totalRecords,"Other":data[i].stepdo_feverpatient_other*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              referwhich_hcpdata.Q1={"ASHA/ANM":data[i].referwhich_hcp_ashaanm*100/data[i].totalRecords,"Government Hospital/PHC/CHC/Health Center":data[i].referwhich_hcp_govhosp*100/data[i].totalRecords,"Private Hospital/Doctor":data[i].referwhich_hcp_pvthosp*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              lackingresourcetotreatpatientdata.Q1={"Sufficient training":data[i].lackingresourcetotreatpatient_sufftraining*100/data[i].totalRecords,"Medication needed to treat":data[i].lackingresourcetotreatpatient_medication*100/data[i].totalRecords,"Instruments needed to treat":data[i].lackingresourcetotreatpatient_instrument*100/data[i].totalRecords,"Access to laboratory tools":data[i].lackingresourcetotreatpatient_laboratory*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q1={"By blood transfusion":data[i].howmalariaspread_blood*100/data[i].totalRecords,"By touch":data[i].howmalariaspread_touch*100/data[i].totalRecords,"Flies/Bugs/Lice etc":data[i].howmalariaspread_fliesbuglice*100/data[i].totalRecords,"From the bite of a mosquito that has bitten a person with malaria":data[i].howmalariaspread_mosqbitefrommalariapers*100/data[i].totalRecords,"Mosquito bites":data[i].howmalariaspread_mosqbite*100/data[i].totalRecords,"Using the same utensils":data[i].howmalariaspread_usesameutensil*100/data[i].totalRecords,"Don't Know":data[i].howmalariaspread_dontknow*100/data[i].totalRecords,"Others":data[i].howmalariaspread_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguetreatmentdata.Q1={"Intravenous fluid rehydration":data[i].denguetreatment_IVFluidrehydration*100/data[i].totalRecords,"Paracetamol":data[i].denguetreatment_Paracetamol*100/data[i].totalRecords,"Anti-bacterial antibiotics":data[i].denguetreatment_antibiotics*100/data[i].totalRecords,"Anti-viral medicines":data[i].denguetreatment_antiviral*100/data[i].totalRecords,"Any of the foll: Aspirin, NSAID etc":data[i].denguetreatment_anyfollowing*100/data[i].totalRecords,"quantity":data[i].totalRecords};              
            }
            else if(data[i].FinancialPeriod=='Q2'){
              samplingdata.Q2={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q2={"Survey":data[i].totalRecords};
              Respondantdata.Q2={"Doctor (Ayurveda/Unani/Homeopath/Others)":data[i].providertype_doctor*100/data[i].totalRecords,"Doctor (MBBS)":data[i].providertype_mbbs*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              whendenguemosquitobitedata.Q2={"Day":data[i].whendenguemosquitobite_day*100/data[i].totalRecords,"Night":data[i].whendenguemosquitobite_night*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              dengueincubationperioddata.Q2={"3-14 days":data[i].dengueincubationperiod_3_14*100/data[i].totalRecords,"1-12 days":data[i].dengueincubationperiod_1_12*100/data[i].totalRecords,"14-28 days":data[i].dengueincubationperiod_14_28*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              seendenguefeverdata.Q2={"Yes":data[i].seendenguefevercase_yes*100/data[i].totalRecords,"No":data[i].seendenguefevercase_no*100/data[i].totalRecords,"Don't Know":data[i].seendenguefevercase_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              familiarwithwhoguidelinesdata.Q2={"Yes":data[i].familiarwithwhoguidelines_yes*100/data[i].totalRecords,"No":data[i].familiarwithwhoguidelines_no*100/data[i].totalRecords,"Don't Know":data[i].familiarwithwhoguidelines_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              iswhoguidelinehelpdata.Q2={"Yes":data[i].iswhoguidelinehelp_yes*100/data[i].totalRecords,"No":data[i].iswhoguidelinehelp_no*100/data[i].totalRecords,"Don't Know":data[i].iswhoguidelinehelp_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguevirustransmitdata.Q2={"Yellow fever virus":data[i].virustransmitsamevectorasdengue_yellowfever*100/data[i].totalRecords,"Malaria parasite":data[i].virustransmitsamevectorasdengue_malparasite*100/data[i].totalRecords,"Zika virus":data[i].virustransmitsamevectorasdengue_zikavirus*100/data[i].totalRecords,"Rift valley fever virus":data[i].virustransmitsamevectorasdengue_riftvirus*100/data[i].totalRecords,"Don’t know":data[i].virustransmitsamevectorasdengue_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              handle_highfewerpatientdata.Q2={"Prescribe paracetamol":data[i].howyouhandle_highfewerpatient_paracetamol*100/data[i].totalRecords,"Cold sponges":data[i].howyouhandle_highfewerpatient_coldsponge*100/data[i].totalRecords,"Advise them to visit a health facility for a blood test":data[i].howyouhandle_highfewerpatient_advisehealthfac*100/data[i].totalRecords,"Other":data[i].howyouhandle_highfewerpatient_Other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q2={"Ascited":data[i].denguesign_Ascites*100/data[i].totalRecords,"Dyspnea":data[i].denguesign_Dyspnea*100/data[i].totalRecords,"Dysuria":data[i].denguesign_Dysuria*100/data[i].totalRecords,"Nasal secretion":data[i].denguesign_Nasal*100/data[i].totalRecords,"Edema":data[i].denguesign_Edema*100/data[i].totalRecords,"Icterus":data[i].denguesign_Icterus*100/data[i].totalRecords,"Persistant cough":data[i].denguesign_cough*100/data[i].totalRecords,"Constipation":data[i].denguesign_Constipation*100/data[i].totalRecords,"Lymphadenitis":data[i].denguesign_Lymphadenitis*100/data[i].totalRecords,"Diarrhea":data[i].denguesign_Diarrhea*100/data[i].totalRecords,"Chest pain":data[i].denguesign_Chestpain*100/data[i].totalRecords,"Thrombocytopenia":data[i].denguesign_Thrombocytopenia*100/data[i].totalRecords,"Vomit":data[i].denguesign_Vomit*100/data[i].totalRecords,"Petechial rash":data[i].denguesign_Petechialrash*100/data[i].totalRecords,"Positive tourniquet test":data[i].denguesign_Positivetourniquet*100/data[i].totalRecords,"Fever/subjective warmth":data[i].denguesign_Fever*100/data[i].totalRecords,"Retro orbital pain":data[i].denguesign_Retroorbitalpain*100/data[i].totalRecords,"Muscle pain":data[i].denguesign_Musclepain*100/data[i].totalRecords,"Headache":data[i].denguesign_Headache*100/data[i].totalRecords,"Any other":data[i].denguesign_anyother*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasymptonsdata.Q2={"Fever":data[i].malariasymptons_Fever*100/data[i].totalRecords,"Headache":data[i].malariasymptons_Headache*100/data[i].totalRecords,"Vomit":data[i].malariasymptons_Vomit*100/data[i].totalRecords,"Weekness":data[i].malariasymptons_weakness*100/data[i].totalRecords,"Loss of Appitite":data[i].malariasymptons_appetiteloss*100/data[i].totalRecords,"Sweating":data[i].malariasymptons_sweating*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              stepdo_feverpatientdata.Q2={"Cure with Exorcism":data[i].stepdo_feverpatient_exorcism*100/data[i].totalRecords,"Give Medicine/Herb/Decoction":data[i].stepdo_feverpatient_givemedicine*100/data[i].totalRecords,"Send to Hospital/Doctor":data[i].stepdo_feverpatient_senttohosp*100/data[i].totalRecords,"Other":data[i].stepdo_feverpatient_other*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              referwhich_hcpdata.Q2={"ASHA/ANM":data[i].referwhich_hcp_ashaanm*100/data[i].totalRecords,"Government Hospital/PHC/CHC/Health Center":data[i].referwhich_hcp_govhosp*100/data[i].totalRecords,"Private Hospital/Doctor":data[i].referwhich_hcp_pvthosp*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              lackingresourcetotreatpatientdata.Q2={"Sufficient training":data[i].lackingresourcetotreatpatient_sufftraining*100/data[i].totalRecords,"Medication needed to treat":data[i].lackingresourcetotreatpatient_medication*100/data[i].totalRecords,"Instruments needed to treat":data[i].lackingresourcetotreatpatient_instrument*100/data[i].totalRecords,"Access to laboratory tools":data[i].lackingresourcetotreatpatient_laboratory*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q2={"By blood transfusion":data[i].howmalariaspread_blood*100/data[i].totalRecords,"By touch":data[i].howmalariaspread_touch*100/data[i].totalRecords,"Flies/Bugs/Lice etc":data[i].howmalariaspread_fliesbuglice*100/data[i].totalRecords,"From the bite of a mosquito that has bitten a person with malaria":data[i].howmalariaspread_mosqbitefrommalariapers*100/data[i].totalRecords,"Mosquito bites":data[i].howmalariaspread_mosqbite*100/data[i].totalRecords,"Using the same utensils":data[i].howmalariaspread_usesameutensil*100/data[i].totalRecords,"Don't Know":data[i].howmalariaspread_dontknow*100/data[i].totalRecords,"Others":data[i].howmalariaspread_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguetreatmentdata.Q2={"Intravenous fluid rehydration":data[i].denguetreatment_IVFluidrehydration*100/data[i].totalRecords,"Paracetamol":data[i].denguetreatment_Paracetamol*100/data[i].totalRecords,"Anti-bacterial antibiotics":data[i].denguetreatment_antibiotics*100/data[i].totalRecords,"Anti-viral medicines":data[i].denguetreatment_antiviral*100/data[i].totalRecords,"Any of the foll: Aspirin, NSAID etc":data[i].denguetreatment_anyfollowing*100/data[i].totalRecords,"quantity":data[i].totalRecords};              
            }
            else if(data[i].FinancialPeriod=='Q3'){
              samplingdata.Q3={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q3={"Survey":data[i].totalRecords};
              Respondantdata.Q3={"Doctor (Ayurveda/Unani/Homeopath/Others)":data[i].providertype_doctor*100/data[i].totalRecords,"Doctor (MBBS)":data[i].providertype_mbbs*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              whendenguemosquitobitedata.Q3={"Day":data[i].whendenguemosquitobite_day*100/data[i].totalRecords,"Night":data[i].whendenguemosquitobite_night*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              dengueincubationperioddata.Q3={"3-14 days":data[i].dengueincubationperiod_3_14*100/data[i].totalRecords,"1-12 days":data[i].dengueincubationperiod_1_12*100/data[i].totalRecords,"14-28 days":data[i].dengueincubationperiod_14_28*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              seendenguefeverdata.Q3={"Yes":data[i].seendenguefevercase_yes*100/data[i].totalRecords,"No":data[i].seendenguefevercase_no*100/data[i].totalRecords,"Don't Know":data[i].seendenguefevercase_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              familiarwithwhoguidelinesdata.Q3={"Yes":data[i].familiarwithwhoguidelines_yes*100/data[i].totalRecords,"No":data[i].familiarwithwhoguidelines_no*100/data[i].totalRecords,"Don't Know":data[i].familiarwithwhoguidelines_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              iswhoguidelinehelpdata.Q3={"Yes":data[i].iswhoguidelinehelp_yes*100/data[i].totalRecords,"No":data[i].iswhoguidelinehelp_no*100/data[i].totalRecords,"Don't Know":data[i].iswhoguidelinehelp_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguevirustransmitdata.Q3={"Yellow fever virus":data[i].virustransmitsamevectorasdengue_yellowfever*100/data[i].totalRecords,"Malaria parasite":data[i].virustransmitsamevectorasdengue_malparasite*100/data[i].totalRecords,"Zika virus":data[i].virustransmitsamevectorasdengue_zikavirus*100/data[i].totalRecords,"Rift valley fever virus":data[i].virustransmitsamevectorasdengue_riftvirus*100/data[i].totalRecords,"Don’t know":data[i].virustransmitsamevectorasdengue_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              handle_highfewerpatientdata.Q3={"Prescribe paracetamol":data[i].howyouhandle_highfewerpatient_paracetamol*100/data[i].totalRecords,"Cold sponges":data[i].howyouhandle_highfewerpatient_coldsponge*100/data[i].totalRecords,"Advise them to visit a health facility for a blood test":data[i].howyouhandle_highfewerpatient_advisehealthfac*100/data[i].totalRecords,"Other":data[i].howyouhandle_highfewerpatient_Other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q3={"Ascited":data[i].denguesign_Ascites*100/data[i].totalRecords,"Dyspnea":data[i].denguesign_Dyspnea*100/data[i].totalRecords,"Dysuria":data[i].denguesign_Dysuria*100/data[i].totalRecords,"Nasal secretion":data[i].denguesign_Nasal*100/data[i].totalRecords,"Edema":data[i].denguesign_Edema*100/data[i].totalRecords,"Icterus":data[i].denguesign_Icterus*100/data[i].totalRecords,"Persistant cough":data[i].denguesign_cough*100/data[i].totalRecords,"Constipation":data[i].denguesign_Constipation*100/data[i].totalRecords,"Lymphadenitis":data[i].denguesign_Lymphadenitis*100/data[i].totalRecords,"Diarrhea":data[i].denguesign_Diarrhea*100/data[i].totalRecords,"Chest pain":data[i].denguesign_Chestpain*100/data[i].totalRecords,"Thrombocytopenia":data[i].denguesign_Thrombocytopenia*100/data[i].totalRecords,"Vomit":data[i].denguesign_Vomit*100/data[i].totalRecords,"Petechial rash":data[i].denguesign_Petechialrash*100/data[i].totalRecords,"Positive tourniquet test":data[i].denguesign_Positivetourniquet*100/data[i].totalRecords,"Fever/subjective warmth":data[i].denguesign_Fever*100/data[i].totalRecords,"Retro orbital pain":data[i].denguesign_Retroorbitalpain*100/data[i].totalRecords,"Muscle pain":data[i].denguesign_Musclepain*100/data[i].totalRecords,"Headache":data[i].denguesign_Headache*100/data[i].totalRecords,"Any other":data[i].denguesign_anyother*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasymptonsdata.Q3={"Fever":data[i].malariasymptons_Fever*100/data[i].totalRecords,"Headache":data[i].malariasymptons_Headache*100/data[i].totalRecords,"Vomit":data[i].malariasymptons_Vomit*100/data[i].totalRecords,"Weekness":data[i].malariasymptons_weakness*100/data[i].totalRecords,"Loss of Appitite":data[i].malariasymptons_appetiteloss*100/data[i].totalRecords,"Sweating":data[i].malariasymptons_sweating*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              stepdo_feverpatientdata.Q3={"Cure with Exorcism":data[i].stepdo_feverpatient_exorcism*100/data[i].totalRecords,"Give Medicine/Herb/Decoction":data[i].stepdo_feverpatient_givemedicine*100/data[i].totalRecords,"Send to Hospital/Doctor":data[i].stepdo_feverpatient_senttohosp*100/data[i].totalRecords,"Other":data[i].stepdo_feverpatient_other*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              referwhich_hcpdata.Q3={"ASHA/ANM":data[i].referwhich_hcp_ashaanm*100/data[i].totalRecords,"Government Hospital/PHC/CHC/Health Center":data[i].referwhich_hcp_govhosp*100/data[i].totalRecords,"Private Hospital/Doctor":data[i].referwhich_hcp_pvthosp*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              lackingresourcetotreatpatientdata.Q3={"Sufficient training":data[i].lackingresourcetotreatpatient_sufftraining*100/data[i].totalRecords,"Medication needed to treat":data[i].lackingresourcetotreatpatient_medication*100/data[i].totalRecords,"Instruments needed to treat":data[i].lackingresourcetotreatpatient_instrument*100/data[i].totalRecords,"Access to laboratory tools":data[i].lackingresourcetotreatpatient_laboratory*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q3={"By blood transfusion":data[i].howmalariaspread_blood*100/data[i].totalRecords,"By touch":data[i].howmalariaspread_touch*100/data[i].totalRecords,"Flies/Bugs/Lice etc":data[i].howmalariaspread_fliesbuglice*100/data[i].totalRecords,"From the bite of a mosquito that has bitten a person with malaria":data[i].howmalariaspread_mosqbitefrommalariapers*100/data[i].totalRecords,"Mosquito bites":data[i].howmalariaspread_mosqbite*100/data[i].totalRecords,"Using the same utensils":data[i].howmalariaspread_usesameutensil*100/data[i].totalRecords,"Don't Know":data[i].howmalariaspread_dontknow*100/data[i].totalRecords,"Others":data[i].howmalariaspread_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguetreatmentdata.Q3={"Intravenous fluid rehydration":data[i].denguetreatment_IVFluidrehydration*100/data[i].totalRecords,"Paracetamol":data[i].denguetreatment_Paracetamol*100/data[i].totalRecords,"Anti-bacterial antibiotics":data[i].denguetreatment_antibiotics*100/data[i].totalRecords,"Anti-viral medicines":data[i].denguetreatment_antiviral*100/data[i].totalRecords,"Any of the foll: Aspirin, NSAID etc":data[i].denguetreatment_anyfollowing*100/data[i].totalRecords,"quantity":data[i].totalRecords};              
            }
            else if(data[i].FinancialPeriod=='Q4'){
              samplingdata.Q4={"Sample District":data[i].SampleDistrict};
              totsurveydata.Q4={"Survey":data[i].totalRecords};
              Respondantdata.Q4={"Doctor (Ayurveda/Unani/Homeopath/Others)":data[i].providertype_doctor*100/data[i].totalRecords,"Doctor (MBBS)":data[i].providertype_mbbs*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              whendenguemosquitobitedata.Q4={"Day":data[i].whendenguemosquitobite_day*100/data[i].totalRecords,"Night":data[i].whendenguemosquitobite_night*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              dengueincubationperioddata.Q4={"3-14 days":data[i].dengueincubationperiod_3_14*100/data[i].totalRecords,"1-12 days":data[i].dengueincubationperiod_1_12*100/data[i].totalRecords,"14-28 days":data[i].dengueincubationperiod_14_28*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              seendenguefeverdata.Q4={"Yes":data[i].seendenguefevercase_yes*100/data[i].totalRecords,"No":data[i].seendenguefevercase_no*100/data[i].totalRecords,"Don't Know":data[i].seendenguefevercase_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              familiarwithwhoguidelinesdata.Q4={"Yes":data[i].familiarwithwhoguidelines_yes*100/data[i].totalRecords,"No":data[i].familiarwithwhoguidelines_no*100/data[i].totalRecords,"Don't Know":data[i].familiarwithwhoguidelines_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              iswhoguidelinehelpdata.Q4={"Yes":data[i].iswhoguidelinehelp_yes*100/data[i].totalRecords,"No":data[i].iswhoguidelinehelp_no*100/data[i].totalRecords,"Don't Know":data[i].iswhoguidelinehelp_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguevirustransmitdata.Q4={"Yellow fever virus":data[i].virustransmitsamevectorasdengue_yellowfever*100/data[i].totalRecords,"Malaria parasite":data[i].virustransmitsamevectorasdengue_malparasite*100/data[i].totalRecords,"Zika virus":data[i].virustransmitsamevectorasdengue_zikavirus*100/data[i].totalRecords,"Rift valley fever virus":data[i].virustransmitsamevectorasdengue_riftvirus*100/data[i].totalRecords,"Don’t know":data[i].virustransmitsamevectorasdengue_dontknow*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              handle_highfewerpatientdata.Q4={"Prescribe paracetamol":data[i].howyouhandle_highfewerpatient_paracetamol*100/data[i].totalRecords,"Cold sponges":data[i].howyouhandle_highfewerpatient_coldsponge*100/data[i].totalRecords,"Advise them to visit a health facility for a blood test":data[i].howyouhandle_highfewerpatient_advisehealthfac*100/data[i].totalRecords,"Other":data[i].howyouhandle_highfewerpatient_Other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q4={"Ascited":data[i].denguesign_Ascites*100/data[i].totalRecords,"Dyspnea":data[i].denguesign_Dyspnea*100/data[i].totalRecords,"Dysuria":data[i].denguesign_Dysuria*100/data[i].totalRecords,"Nasal secretion":data[i].denguesign_Nasal*100/data[i].totalRecords,"Edema":data[i].denguesign_Edema*100/data[i].totalRecords,"Icterus":data[i].denguesign_Icterus*100/data[i].totalRecords,"Persistant cough":data[i].denguesign_cough*100/data[i].totalRecords,"Constipation":data[i].denguesign_Constipation*100/data[i].totalRecords,"Lymphadenitis":data[i].denguesign_Lymphadenitis*100/data[i].totalRecords,"Diarrhea":data[i].denguesign_Diarrhea*100/data[i].totalRecords,"Chest pain":data[i].denguesign_Chestpain*100/data[i].totalRecords,"Thrombocytopenia":data[i].denguesign_Thrombocytopenia*100/data[i].totalRecords,"Vomit":data[i].denguesign_Vomit*100/data[i].totalRecords,"Petechial rash":data[i].denguesign_Petechialrash*100/data[i].totalRecords,"Positive tourniquet test":data[i].denguesign_Positivetourniquet*100/data[i].totalRecords,"Fever/subjective warmth":data[i].denguesign_Fever*100/data[i].totalRecords,"Retro orbital pain":data[i].denguesign_Retroorbitalpain*100/data[i].totalRecords,"Muscle pain":data[i].denguesign_Musclepain*100/data[i].totalRecords,"Headache":data[i].denguesign_Headache*100/data[i].totalRecords,"Any other":data[i].denguesign_anyother*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasymptonsdata.Q4={"Fever":data[i].malariasymptons_Fever*100/data[i].totalRecords,"Headache":data[i].malariasymptons_Headache*100/data[i].totalRecords,"Vomit":data[i].malariasymptons_Vomit*100/data[i].totalRecords,"Weekness":data[i].malariasymptons_weakness*100/data[i].totalRecords,"Loss of Appitite":data[i].malariasymptons_appetiteloss*100/data[i].totalRecords,"Sweating":data[i].malariasymptons_sweating*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              stepdo_feverpatientdata.Q4={"Cure with Exorcism":data[i].stepdo_feverpatient_exorcism*100/data[i].totalRecords,"Give Medicine/Herb/Decoction":data[i].stepdo_feverpatient_givemedicine*100/data[i].totalRecords,"Send to Hospital/Doctor":data[i].stepdo_feverpatient_senttohosp*100/data[i].totalRecords,"Other":data[i].stepdo_feverpatient_other*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              referwhich_hcpdata.Q4={"ASHA/ANM":data[i].referwhich_hcp_ashaanm*100/data[i].totalRecords,"Government Hospital/PHC/CHC/Health Center":data[i].referwhich_hcp_govhosp*100/data[i].totalRecords,"Private Hospital/Doctor":data[i].referwhich_hcp_pvthosp*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              lackingresourcetotreatpatientdata.Q4={"Sufficient training":data[i].lackingresourcetotreatpatient_sufftraining*100/data[i].totalRecords,"Medication needed to treat":data[i].lackingresourcetotreatpatient_medication*100/data[i].totalRecords,"Instruments needed to treat":data[i].lackingresourcetotreatpatient_instrument*100/data[i].totalRecords,"Access to laboratory tools":data[i].lackingresourcetotreatpatient_laboratory*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q4={"By blood transfusion":data[i].howmalariaspread_blood*100/data[i].totalRecords,"By touch":data[i].howmalariaspread_touch*100/data[i].totalRecords,"Flies/Bugs/Lice etc":data[i].howmalariaspread_fliesbuglice*100/data[i].totalRecords,"From the bite of a mosquito that has bitten a person with malaria":data[i].howmalariaspread_mosqbitefrommalariapers*100/data[i].totalRecords,"Mosquito bites":data[i].howmalariaspread_mosqbite*100/data[i].totalRecords,"Using the same utensils":data[i].howmalariaspread_usesameutensil*100/data[i].totalRecords,"Don't Know":data[i].howmalariaspread_dontknow*100/data[i].totalRecords,"Others":data[i].howmalariaspread_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguetreatmentdata.Q4={"Intravenous fluid rehydration":data[i].denguetreatment_IVFluidrehydration*100/data[i].totalRecords,"Paracetamol":data[i].denguetreatment_Paracetamol*100/data[i].totalRecords,"Anti-bacterial antibiotics":data[i].denguetreatment_antibiotics*100/data[i].totalRecords,"Anti-viral medicines":data[i].denguetreatment_antiviral*100/data[i].totalRecords,"Any of the foll: Aspirin, NSAID etc":data[i].denguetreatment_anyfollowing*100/data[i].totalRecords,"quantity":data[i].totalRecords};              
            }
            finyear=data[i].FinancialYear;
          }
          this.chartReg["chartprovtotsurvey"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartprovtotsurvey", totsurveydata,finyear, 0, "", "valaxis","","33- Survey","","",false,"");           
          this.chartReg["chartprovsampledistrict"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartprovsampledistrict", samplingdata,finyear, 0, "", "valaxis","","32- Sample District","","",false,"");           
          this.chartReg["chart12"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart12", Respondantdata,finyear, 100, "Percentage", "valaxis","","34- Type of providers (%)","","",true,"");           
          this.chartReg["chart13"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart13", whendenguemosquitobitedata,finyear, 100, "Percentage", "valaxis","","35- Dengue mosquito bite %","","",true,"");           
          this.chartReg["chart14"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart14", dengueincubationperioddata,finyear, 100, "Percentage", "valaxis","","36- Incubation period dengue %","","",true,"");                     
          this.chartReg["chart15"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart15", seendenguefeverdata,finyear, 100, "Percentage", "valaxis","","37- Ever seen dengue fever case","","",true,"");           
          this.chartReg["chart16"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart16", familiarwithwhoguidelinesdata,finyear, 100, "Percentage", "valaxis","","38- Providers are aware of WHO’s guidelines","","",true,"");                     
          this.chartReg["chart17"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart17", iswhoguidelinehelpdata,finyear, 100, "Percentage", "valaxis","","39- Providers are aware of WHO’s guidelines help in managing dengue","","",true,"");                     
          this.chartReg["chart18"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart18", denguevirustransmitdata,finyear, 100, "Percentage", "valaxis","","40- Parasite/virus is transmitted by the same vector as dengue?","","",true,"");           
          this.chartReg["chart19"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart19", handle_highfewerpatientdata,finyear, 100, "Percentage", "valaxis","","41- Patient comes to you with high grade fever, how do you handle the patient","","",true,"");                     
          this.chartReg["chart20"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart20", denguesympptomdata,finyear, 100, "Percentage", "valaxis","","42- Signs and symptoms of dengue without warning signs","","",true,"");           
          this.chartReg["chart21"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart21", malariasymptonsdata,finyear, 100, "Percentage", "valaxis","","43- Signs and symptoms of Malaria","","",true,"");           
          this.chartReg["chart22"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart22", stepdo_feverpatientdata,finyear, 100, "Percentage", "valaxis","","44- How do you treat fever patient","","",true,"");           
          this.chartReg["chart23"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart23", referwhich_hcpdata,finyear, 100, "Percentage", "valaxis","","45- Where do you refer fever patient?","","",true,"");           
          this.chartReg["chart24"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart24", lackingresourcetotreatpatientdata,finyear, 100, "Percentage", "valaxis","","46- Resources are lacking to treat patients with dengue","","",true,"");           
          this.chartReg["chart25"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart25", malariacausedata,finyear, 100, "Percentage", "valaxis","","47- Malaria Spread %","","",true,"");                    
          this.chartReg["chart26"] =AMCHARTS_GroupedBarGraph.GroupedBarGraph("chart26", denguetreatmentdata,finyear, 100, "Percentage", "valaxis","","48- Treatment for suspected dengue patients","","",true,"");                            
      } 
    });
  }

  getFirstMatchingObject(myArray:any[],columnName: string, value: any,columnStatus:any):any {    
    if(columnStatus==""){
      return myArray.filter(item => item[columnName] === value).reduce((sum, current) => sum + current['ProviderCount'], 0);
    }
    else{
      return myArray.filter(item => item[columnName] === value && item['ProviderStatus'] === columnStatus).reduce((sum, current) => sum + current['ProviderCount'], 0);
    }
  }
  popupvisible: boolean = false;
  ispopupdataloaded:boolean=true;
  PlanningDataSummaryPopup:any[]=[];
  ViewPlanningSummary(){
    this.ispopupdataloaded=false;
    debugger
    if(this.filterState.SelDistrictID!="0" && (this.filterState.selectedDateRange.startDate.format("MM")==this.filterState.selectedDateRange.endDate.format("MM"))){
      this.popupvisible=true;
      this.ispopupdataloaded=true;
    }    
    else{
      if(this.filterState.SelDistrictID=="0")
        alert("Select District.");
      else
        alert("Date range should be one month.");
      this.ispopupdataloaded=true;
    }
  }
  @ViewChild('PdfDiv') PdfDiv!: ElementRef;
  printDiv() {
    let filename ='Planning Summary-'+this.filterState.SelDistrictID+'_'+this.filterState.selectedDateRange.startDate?.format("YYYYMM")+'.pdf';
    const content = this.PdfDiv.nativeElement.innerHTML;
    const printWindow = window.open('', '', 'width=600,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/components.min.css" rel="stylesheet" />');
    printWindow?.document.write(`<style>img{
      width: 280px;
      height: 280px;
    }            
    .cellheading{
      font-weight:bold;
      font-size:12pt;
    }                   
    .table td, .table th {
      font-size: 12px !important;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    // setTimeout(() => {
    //   printWindow?.close();
    // }, 100);    
  }
  ngOnDestroy(): void {
    if (this.filterStateSubscription) {
      this.filterStateSubscription.unsubscribe();
    }
    am4core.disposeAllCharts();
  }
}