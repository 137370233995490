import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

interface ValidationRule {
  type: string;
  value?: any;
  errorMessage?: string;
  extraCondition?: boolean;
  minDate?: any;
  maxDate?: any;
}

@Directive({
  selector: '[appCustomValidation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CustomValidationDirective,
      multi: true
    }
  ]
})
export class CustomValidationDirective implements Validator {
  @Input('appCustomValidation') validationRules!: ValidationRule[];

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.validationRules || this.validationRules.length === 0) {
      return null;
    }

    const errors: ValidationErrors = {};

    for (const rule of this.validationRules) {
      if (rule.extraCondition === false) {
        continue; // Skip validation if the extra condition is not met
      }

      const validationResult = this.validateRule(control, rule);
      if (validationResult) {
        errors[rule.type] = validationResult;
      }
    }

    return Object.keys(errors).length > 0 ? errors : null;
  }

  private validateRule(control: AbstractControl, rule: ValidationRule): any {
    switch (rule.type) {
      case 'required':
        return this.validateRequired(control, rule);
      case 'email':
        return this.validateEmail(control);
      case 'minlength':
        return this.validateMinLength(control, rule);
      case 'maxlength':
        return this.validateMaxLength(control, rule);
      case 'pattern':
        return this.validatePattern(control, rule);
      case 'custom':
        return this.validateCustom(control, rule);
      case 'date':
        return this.validateDate(control, rule);
      // Add more validation cases as needed
      default:
        return null;
    }
  }

  private validateRequired(control: AbstractControl, rule: ValidationRule): ValidationErrors | null {
    if (rule.value === 0 && (control.value === null || control.value === undefined || control.value === '' || control.value === 0)) {
      return { required: true };
    }
    return Validators.required(control);
  }

  private validateEmail(control: AbstractControl): ValidationErrors | null {
    return Validators.email(control);
  }

  private validateMinLength(control: AbstractControl, rule: ValidationRule): ValidationErrors | null {
    return Validators.minLength(rule.value)(control);
  }

  private validateMaxLength(control: AbstractControl, rule: ValidationRule): ValidationErrors | null {
    return Validators.maxLength(rule.value)(control);
  }

  private validatePattern(control: AbstractControl, rule: ValidationRule): ValidationErrors | null {
    return Validators.pattern(rule.value)(control);
  }

  private validateCustom(control: AbstractControl, rule: ValidationRule): ValidationErrors | null {
    const validationFn = rule.value as ValidatorFn;
    return validationFn(control) ? null : { custom: true };
  }

  private validateDate(control: AbstractControl, rule: ValidationRule): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return null;
    }

    const dateValue = moment(value);
    const minValue = rule.minDate ? moment(rule.minDate) : null;
    const maxValue = rule.maxDate ? moment(rule.maxDate) : null;

    if (!dateValue.isValid()) {
      return { date: true };
    }

    if (minValue && !minValue.isValid()) {
      return { minDateInvalid: true };
    }

    if (maxValue && !maxValue.isValid()) {
      return { maxDateInvalid: true };
    }

    if (minValue && dateValue.isBefore(minValue)) {
      return { minDate: true };
    }

    if (maxValue && dateValue.isAfter(maxValue)) {
      return { maxDate: true };
    }

    return null;
  }
}
