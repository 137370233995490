import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app-routing.module';
import { DashboardLayoutModule } from './layouts/dashboard-layout/dashboard-layout.module';
import { HomeNavbarModule } from './shared/homenavbar/homenavbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { AdminNavbarModule } from './shared/adminnavbar/adminnavbar.module';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Page404Component } from './pages/page404/page404.component';
import { MasterFilterModule } from './shared/masterfilter/masterfilter.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './pages/login/jwt.interceptor';
import { FilterService } from './shared/services/filter.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataHomeComponent } from './pages/data-home/data-home.component';
import { StatsModule } from './pages/dashboard/stats/stats.module';
import { MailSenderModule } from './shared/mailsender/mailsender.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminLayoutComponent,
    HomeLayoutComponent,
    DashboardLayoutComponent,
    Page404Component,    
    DataHomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    AdminNavbarModule,    
    StatsModule,
    DashboardLayoutModule,
    HomeNavbarModule,   
    MasterFilterModule,
    FooterModule,
    MailSenderModule,
    CommonModule,
  ],
  entryComponents: [  ],
  providers: [FilterService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
