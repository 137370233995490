import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { CountUpModule } from 'ngx-countup';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ActivityCalendarComponent } from './activity-calendar.component';

@NgModule({
  declarations: [
    ActivityCalendarComponent
],
  exports:[ActivityCalendarComponent],
  imports: [   
    CountUpModule,   
    DialogModule,
    CommonModule,
    ProgressSpinnerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),       
  ]
})
export class ActivityCalendarModule { }
