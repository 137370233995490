import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FHIRtmisService } from '../../../shared/services/fhirtmis.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Table } from 'jspdf-autotable';
import { StorageService } from '../../login/storage.service';
import { HttpClient } from '@angular/common/http';
//import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';

interface Column {
    field: string;
    header: string;
	ishidden?:boolean;
	showfilter?:boolean;
    fieldtype?:string;
    filterMatchMode?: string;
    customExportHeader?: string;
	isTotal?:boolean;
	maxwidth?:string;
}
interface ExportColumn {
    title: string;
    dataKey: string;
}
@Component({
  selector: 'app-masterlist',
  templateUrl: './masterlist.component.html',
  styleUrls: ['./masterlist.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation:ViewEncapsulation.None,
  providers: [MessageService]
})
export class MasterListComponent implements OnInit {
    @ViewChild('dt') table!: Table;
	moment: any = moment;
	isremoveallowed:boolean=false;
	iseditallowed:boolean=false;
	isreportallowed:boolean=false;
	isaddallowed:boolean=false;
	isrequestallowed:boolean=false;
	isgrouping:boolean=false;
    modaldata!: any[];    
    cols!: Column[];
    selectedData!: any;
    _selectedColumns!: Column[];
    exportColumns!: ExportColumn[];
    loading: boolean = true;
    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }
	userlogin:any;
    set selectedColumns(val: any[]) {
        //restore original order		
        this._selectedColumns = this.cols.filter((col) => val.includes(col));
    } 
	//Whenever you want to manually trigger change detection in the component, call the markForCheck method of the ChangeDetectorRef:
	//this.cdr.markForCheck();
    constructor(private http: HttpClient,private primengConfig: PrimeNGConfig,private rtmisservice:FHIRtmisService, public ref: DynamicDialogRef
        ,public config: DynamicDialogConfig,private filterService: FilterService, private messageService: MessageService
		, private storageService: StorageService
		//,private cdr: ChangeDetectorRef
		) {
			this.userlogin=this.storageService.getUser(); 			
		}

    ngOnInit() { 
		this.InitCrudOperation(this.config.data);
        this.rtmisservice.GetModalPopUpData(this.config.data.modalfor
			,this.config.data.valuefor
			,this.config.data.valueforid
			,""
			,this.filterService.filterState).subscribe((data) => {
			if(data.length>0){
			  this.modaldata = data;
			  this.loading = false;			  
			  this.cols=[];
			  this.GetColsDefinition(this.config.data.modalfor);
			  if(this.cols.length==0){
				var keysArray :string[]= [];
				keysArray = Object.keys(data[0]);
				for (var i = 0; i < keysArray.length; i++) {
					this.cols.push({field: keysArray[i], header: keysArray[i],filterMatchMode:'contains',fieldtype:'text'})
				}
			  }
			  this._selectedColumns = this.cols;
			  this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
			}  
			else{
				this.modaldata = data;
			  	this.loading = false;			  
			  	this.cols=[];
			  	this.GetColsDefinition(this.config.data.modalfor);
				this._selectedColumns = this.cols;
				this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
			}         
		  });
		  if(this.config.data.modalfor=='Activity' || this.config.data.modalfor=='AdministrativeArea'){
			this.isreportallowed=true;
		  }
		  this.primengConfig.ripple = true;               
    }   
	calculateRowTotal(colname: string) {
        let total = 0;

        if (this.modaldata) {
            for (let rowdata of this.modaldata) {
                if (rowdata.DistrictName === colname) {
                    total++;
                }
            }
        }

        return total;
    }
	getSummry(col:any): any {
		var total: number = 0;
		for (var i = 0; i < this.modaldata.length; i++) {
			if (typeof +this.modaldata[i][col] === "number" && !isNaN(+this.modaldata[i][col])){
				total += Number(this.modaldata[i][col]);
			}
			else{
				if(this.modaldata[i][col]=="Yes"){
					total +=1;
				}
			}					  
		}
		return total;
	}   
	//IsViewAllowed`, `
	InitCrudOperation(datafor:any){			
		let lstPermission:any[]=[];
		lstPermission = JSON.parse(this.storageService.getPermission());
		var idxuser = lstPermission.findIndex((m:any)=>(m.DataFor.toString() === datafor.modalfor && m.UserID.toString() === this.userlogin.id.toString()));
		var idx = lstPermission.findIndex((m:any)=>(m.DataFor.toString() === datafor.modalfor));
		var idxall = lstPermission.findIndex((m:any)=>(m.DataFor.toString() === 'All'));		
		if(idxuser > -1 || idx>-1 || idxall>-1){
			if(idxuser>-1){
				if(lstPermission[idxuser].IsAddAllowed=="1")
				{this.isaddallowed=true;}
				if(lstPermission[idxuser].IsUpdateAllowed=="1")
				{
					this.iseditallowed=true;
				}
				if(lstPermission[idxuser].IsDeleteAllowed=="1")
				{this.isremoveallowed=true;}
			}
			else if(idx>-1){
				if(lstPermission[idx].IsAddAllowed=="1")
				{this.isaddallowed=true;}
				if(lstPermission[idx].IsUpdateAllowed=="1")
				{
					this.iseditallowed=true;
				}
				if(lstPermission[idx].IsDeleteAllowed=="1")
				{this.isremoveallowed=true;}
			}
			else
			{
				 if(lstPermission[idxall].IsAddAllowed=="1")
				{this.isaddallowed=true;}
				else
				{ this.isaddallowed=false;}
				if(lstPermission[idxall].IsUpdateAllowed=="1")
				{
					this.iseditallowed=true;
				}
				else
				{ this.iseditallowed=false;}
				if(lstPermission[idxall].IsDeleteAllowed=="1")
				{this.isremoveallowed=true;}
				else
				{ this.isremoveallowed=false;}
			}			
		}
		else{
			this.isaddallowed=false;this.iseditallowed=false; this.isremoveallowed=false;
		}		
		if(datafor.isMaster==false){
			if((datafor.modalfor=='Chaupal' || datafor.modalfor=='HND' || datafor.modalfor=='ChaupalSupervision') && this.userlogin.logintype=='Admin'){this.isremoveallowed=true;}
			else{this.isremoveallowed=false;}
			this.isaddallowed=false;
			if(datafor.modalfor=='Chaupal' || datafor.modalfor=='HND' || datafor.modalfor=='ChaupalSupervision' || datafor.modalfor=='WSRegister'){
				this.isrequestallowed=true;
			}
		}
		if(datafor.modalfor=="HouseholdSurvey" || datafor.modalfor=="ProjectProviderSurvey" || datafor.modalfor=="FLWSurvey"){
			this.isgrouping=true;
		}
	}
	onRequestDoc(){
		if(confirm("Are you sure want to request document?")) {
			var formdata = new FormData();			
			formdata.append("indctr","RequestDocument");			
			formdata.append("modaladministrativeAreaid",this.selectedData.Administrative_Area_id);
			formdata.append("modalclusterid",this.selectedData.Cluster_id);
			formdata.append("DocumentFor",this.config.data.modalfor);
			formdata.append("DocumentSubFor",this.config.data.valuefor);
			formdata.append("DocumentForid",this.selectedData.ID);
			formdata.append("DateFor",this.selectedData.ReportOnDate);
			formdata.append("EnteredBy", this.userlogin.userid);    
			formdata.append("AppVersion", "1.0");   			
			this.http.post<any>(`https://rtmis.fhindia.org/api/ModalPostServices.php`, formdata, {
			}).subscribe(res => {  
				if(res.success){
					this.messageService.add({ severity: 'success', summary: "Request Sent Successfully", detail: "", sticky: true }); 
				}
				else{
					this.messageService.add({ severity: 'warn', summary: res.message, detail: "", sticky: true }); 
				}
			}), (err:any) => {      
				console.log("Error Occured " + err);
			}			
		}
	}
	// Event handler for data change
	onDataChanged(dataid :any) {
		this.selrow = false;
		this.selectedData=null;
		this.selrowid=0;		
		this.rtmisservice.GetModalPopUpData(this.config.data.modalfor
			,this.config.data.valuefor
			,this.config.data.valueforid
			,dataid
			,this.filterService.filterState).subscribe((data) => {
			if(data.length>0){
			  //let modaldata2 = this.modaldata.map(x => Object.assign({}, x));			  
			  var idx = this.modaldata.findIndex(m=>m.ID.toString() === dataid);
			  if(idx>-1){
			  	this.modaldata[idx] = data[0];  
			  }
			  else{
				this.modaldata.splice(0,0,data[0]);
			  }
			  this.modaldata = [...this.modaldata];
			}           
		  });
		//this.config.data.modalfor
		// Refresh or fetch the updated data for the table
		// For example, if the data is fetched from a service, you can call the service method here
	
		// Update the data array used by the PrimeNG table
		// this.data = ... (updated data)
	
		// You can also use PrimeNG's table API to refresh the data if needed
		// For example, if you are using the `p-table` component, you can call the `refresh()` method
		// this.table.refresh();
	  }
	selrowid:any=0; 
    selrow: boolean = false;
    onRowSelect(event: any) {
        this.selectRowData(event.data,event);
        //this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
    }

    onRowUnselect(event: any) {
        this.selectRowData(event.data,event);
        //this.messageService.add({ severity: 'info', summary: 'Product Unselected', detail: event.data.name });
    }
	deleteBulkData(rowData: any){				
		if(confirm("Are you sure want to delete?")) {
			this.rtmisservice.getDeleteModalBulkData(this.config.data.modalfor,rowData.Cluster_id,rowData.ReportOnDate).subscribe((res) => {				
				if(res.success){
					var idx = this.modaldata.findIndex(m=>m.Cluster_id === rowData.Cluster_id && m.ReportOnDate === rowData.ReportOnDate);
					if(idx>-1){
						this.modaldata.splice(idx,1);
						this.modaldata = [...this.modaldata];
					}
					//this.messageService.add({ severity: 'info', summary: res.message, detail: res.dataid }); 							
				}
				alert(res.message);
			});
		}
	}
	selectedmonth:any='0';
	deleteRowData(rowData: any){				
		if(confirm("Are you sure want to delete?")) {
			this.rtmisservice.getDeleteModalData(this.config.data.modalfor,rowData.ID,this.selectedmonth).subscribe((res) => {				
				if(res.success){
					var idx = this.modaldata.findIndex(m=>m.ID === rowData.ID);
					if(idx>-1){
						this.modaldata.splice(idx,1);
						this.modaldata = [...this.modaldata];
					}
					//this.messageService.add({ severity: 'info', summary: res.message, detail: res.dataid }); 							
				}
				alert(res.message);
			});
		}
	}
    selectRowData(rowData: any,event: any) {
		//if(this.selectedData==null && event!=null){this.selrow = false;return;}
        if(!this.selrow || this.selrowid != rowData.ID){
            this.selrow = true;
            this.selectedData=rowData;
			this.selrowid=rowData.ID;
            this.messageService.add({ severity: 'info', summary: 'Row Selected', detail: rowData.ID });        
        }
        else{
            this.selrow = false;
            this.selectedData=null;
			this.selrowid=0;
            this.messageService.add({ severity: 'info', summary: 'Row Unselected', detail: rowData.ID }); 
        }
        //this.ref.close(rowData);
    }
	//Bulk Upload Code Start
	validateFileSize($event: any, fileUpload: any): void {	
		if ($event.files[0].size > fileUpload.maxFileSize) {
			fileUpload.clear();
		}
		else{
			const file = $event.files[0];
			const reader: FileReader = new FileReader();
			reader.onload = (e: any) => {
				const binaryString = e.target.result;
				const workbook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' });
				const worksheet: XLSX.WorkSheet = workbook.Sheets[workbook.SheetNames[0]];
				const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
				// Validate and process the data
				this.validateAndProcessData(jsonData,fileUpload);
			};
			reader.readAsBinaryString(file);
		}
	  }
	  validateAndProcessData(jsonData: any[],fileUpload:any) {
		// Validate if all columns exist
		const numberFields = [
			'State_id',
			'District_id',
			'MunicipalArea_id',
			'BCCFZone_id',
			'TerritorialArea_id',
			'Administrative_Area_id',
			'Cluster_id',
		  ];
		  const dateFields = [
			'FirstPlanningDate',
			'SecondPlanningDate',
			'ThirdPlanningDate',
			'FourthPlanningDate',
			'FifthPlanningDate',
			'SixthPlanningDate',
			'SeventhPlanningDate',
			'EighthPlanningDate',
			'NinthPlanningDate',
			'TenthPlanningDate',
			'EleventhPlanningDate',
			'TwelvthPlanningDate',
		  ];
		const requiredFields = ['FirstPlanningDate','FinancialYear'];	
		const expectedColumns : any[]=[];
		for (const field of numberFields) {expectedColumns.push(field);}
		for (const field of dateFields) {expectedColumns.push(field);}		
		expectedColumns.push("FinancialYear");		 
		const missingColumns = expectedColumns.filter(col => !jsonData[0].includes(col));
	  
		if (missingColumns.length > 0) {
		  fileUpload.me('Column Missing in excel :'+ missingColumns);
		  fileUpload.clear();
		  return;
		}
	  
		// Validate each row and extract the data
		const extractedData :any[]= [];
		for (let i = 1; i < jsonData.length; i++) {
		  const row = jsonData[i];
		  // Validate Required fields
		  if (!this.validateRequiredFields(row,requiredFields)) {
			console.log('Mandatory fields validation at row', i + 1);
			continue;
		   }
		  // Validate ID fields as numbers
		  if (!this.validateNumberFields(row,numberFields)) {
			console.log('Number fields validation at row', i + 1);
			continue;
		  }	  
		  // Validate date fields
		  if (!this.validateDateFields(row,dateFields)) {
			console.log('Invalid date fields at row', i + 1);
			continue;
		  }	  
		  // Extract the data and add to the extracted data array
		  const extractedRow :any = {
			modalstateid: row[0],
			modaldistrictid: row[1],
			modalmunicipalAreaid: row[2],
			modalbccfZoneid: row[3],
			modalterritorialAreaid: row[4],
			modaladministrativeAreaid: row[5],
			modalclusterid: row[6],
			FirstPlanningDate: row[7],
			SecondPlanningDate: row[8],
			ThirdPlanningDate: row[9],
			FourthPlanningDate: row[10],
			FifthPlanningDate: row[11],
			SixthPlanningDate: row[12],
			SeventhPlanningDate: row[13],
			EighthPlanningDate: row[14],
			NinthPlanningDate: row[15],
			TenthPlanningDate: row[16],
			EleventhPlanningDate: row[17],
			TwelvthPlanningDate: row[18],
		  };
	  
		  extractedData.push(extractedRow);
		}	  
		// Perform further processing with the extracted data (e.g., update the table)
		console.log('Extracted data:', extractedData);
	  }
	  validateRequiredFields(row: any[],requiredFields:any[]): boolean {			  
		for (const field of requiredFields) {
			if (!row[field]) {
			  return false;
			}
		  }	  
		  return true;
	  }
	  validateNumberFields(row: any[],numberFields:any[]): boolean {			  
		for (const field of numberFields) {
		  if (isNaN(row[field])) {
			return false;
		  }
		}	  
		return true;
	  }
	  
	  validateDateFields(row: any[],dateFields:any[]): boolean {			  		  
		for (const field of dateFields) {
		  if (!this.isValidDate(row[field])) {
			return false;
		  }
		}	  		 
		return true;
	  }
	  
	  isValidDate(dateString: string): boolean {
		// Check if the date string is in the correct format (YYYY-MM-DD)
		const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
		if (!dateRegex.test(dateString)) {
		  return false;
		}	  
		// Check if the date is valid
		const date = new Date(dateString);
		return !isNaN(date.getTime());
	  }
	  //Bulk Upload Code End
    exportPdf() {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then((x) => {
                const doc = new jsPDF.default('p', 'px', 'a4');
                (doc as any).autoTable(this.exportColumns, this.modaldata);
                doc.save('products.pdf');
            });
        });
    }

    exportExcel() {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.modaldata);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'products');
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    GetColsDefinition(datafor:any){
        if (datafor === 'ProjectStaffType') {		    
			this.cols = [ 
                { fieldtype:"text", field: 'ProjectStaffType_Name',header: 'Staff Type' },
				{ fieldtype:"text", field: 'AppDisplay',header: 'Display in App' }            
			];
		}
		else if (datafor === 'ActivityType') {		    
			this.cols = [ 
                { fieldtype:"text", field: 'ActivityType_Name',header: 'Activity Type' },
				{ fieldtype:"text", field: 'AppDisplay',header: 'Display in App' }                 
			];
		}		
		else  if (datafor === 'State') {		    
			this.cols = [ 
                { field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' }              
			];
		}
		else if (datafor === 'District') {		    
			this.cols = [ 
                { field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' }              
			];
		}
		else if(datafor === 'MunicipalArea'){
			this.cols = [  
    			{ field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
				{ fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,		
			];
		}
		else if(datafor === 'BCCFZone'){
			this.cols = [  
    			{ field: 'ID',header: 'ID'},
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,		
			];
		}
		else if(datafor === 'TerritorialArea'){
			this.cols = [      
    			{ field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' }, 
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
                { fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
                { fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat'  }			
			];
		}
		else if(datafor === 'AdministrativeArea'){
			this.cols = [     
                {  field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone'  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,				
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Type',header: 'Slum / Village Type'  },
				{ fieldtype:"text", field: 'CensusCode',header: 'Census Code'  },
				{ fieldtype:"text", field: 'Latitude',header: 'Latitude'  },
				{ fieldtype:"text", field: 'Longitude',header: 'Longitude'  },
				{ fieldtype:"text", field: 'Pincode',header: 'Pincode'  },				
				{ fieldtype:"text", field: 'TotCluster',header: '#Cluster'  },
				{ fieldtype:"text", field: 'TotHouseHold',header: '#HouseHold'  },
				{ fieldtype:"text", field: 'TotPopulation',header: 'Population'  },
				{ fieldtype:"text", field: 'InceptionDate',header: 'InceptionDate'  },
				{ fieldtype:"text", field: 'PhaseOutDate',header: 'PhaseOutDate'  },
				{ fieldtype:"text", field: 'LayoutDate',header: 'LayoutDate'  },
				{ fieldtype:"text", field: 'LayoutHelper',header: 'LayoutHelper'  },
				{ fieldtype:"text", field: 'SHCName',header: 'SHCName'  },
				{ fieldtype:"text", field: 'SHCDistance',header: 'SHCDistance'  },
				{ fieldtype:"text", field: 'PHCName',header: 'PHCName'  },
				{ fieldtype:"text", field: 'PHCDistance',header: 'PHCDistance'  },
				{ fieldtype:"text", field: 'DistrictHospName',header: 'DistrictHospName'  },
				{ fieldtype:"text", field: 'DistrictHospDistance',header: 'DistrictHospDistance'  },
				{ fieldtype:"text", field: 'PrivateHospName',header: 'PrivateHospName'  },
				{ fieldtype:"text", field: 'PrivateHospDistance',header: 'PrivateHospDistance'  },
				{ fieldtype:"text", field: 'PrivateHospName',header: 'PrivateHospName'  }				
				//Administrative_Area_Photo 	 	 	 	 	 	 	 	 	 					
			];
		}
		else if(datafor === 'Cluster'){
			this.cols = [    
    			{ field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
                { fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone'  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'Cluster_Name',header: 'Cluster'  },
				{ fieldtype:"text", field: 'TotHouseHold',header: 'HouseHold'  },
				{ fieldtype:"text", field: 'TotPopulation',header: 'Population'  }
			];
		}
		else if(datafor === 'HNDSite'){
			this.cols = [  
                { fieldtype:"text", field: 'ID',header: 'ID' },
				{ fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'HND_Location',header: 'HND Site' },
				{ fieldtype:"text", field: 'FixHND_Day',header: 'FixHND_Day'  }
			];
		}
		else if(datafor === 'User'){
			this.cols = [ 
			    { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone',header: 'BCCF Zone'  },
				{ fieldtype:"text", field: 'User_id',header: 'User_id'  },
				{ fieldtype:"text", field: 'user_name',header: 'user_name'  },
				{ fieldtype:"text", field: 'logintype',header: 'logintype'  },
				{ fieldtype:"text", field: 'password',header: 'password'  }
			];
		}		
		else if(datafor === 'HouseHold'){
			this.cols = [    
    			{  field: 'ID',header: 'ID',fieldtype: "numeric" },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone'  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'Cluster_Name',header: 'Cluster'  },
				{ fieldtype:"text", field: 'Household_No',header: 'Household No.'  },
				{ fieldtype:"text", field: 'Structure_No',header: 'Structure No.'  },
				{ fieldtype:"text", field: 'NoOfMember',header: 'No.Of Member'  },
				{ fieldtype:"text", field: 'HouseHeadName',header: 'Household Head Name'  },
				{ fieldtype:"text", field: 'mobilenumber',header: 'Mobile Number'  },
				{ fieldtype:"text", field: 'Rented',header: 'Rented'  },
				{ fieldtype:"text", field: 'Rented_Date',header: 'Rented Date'  },
				{ fieldtype:"text", field: 'MovedOut_Date',header: 'Moved Date'  },
				{ fieldtype:"text", field: 'PregnantCount',header: 'Pregnant'  },
				{ fieldtype:"text", field: 'ChildCount',header: 'Child'  }
			];
		}
		else if(datafor === 'WaterStagnation'){
			this.cols = [  
    			{  field: 'ID',header: 'ID',fieldtype: "numeric" },	
				{ fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'WaterSatgnation_Type',header: 'WaterSatgnation_Type'  },
				{ fieldtype:"text", field: 'WaterSatgnation_No',header: 'WaterSatgnation_No' },				
				{ fieldtype:"text", field: 'WaterSatgnation_Name',header: 'WaterSatgnation_Name'  },
				{ fieldtype:"text", field: 'Larva_Status',header: 'LarvaStatus'  }
			];
		}
		else if(datafor === 'SchoolData'){
			this.cols = [    
                { fieldtype:"text", field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'School_code_DISE_code',header: 'School_code_DISE_code'  },
				{ fieldtype:"text", field: 'School_Name',header: 'School_Name'  },
				{ fieldtype:"text", field: 'School_Category',header: 'School_Category'  },
				{ fieldtype:"text", field: 'School_Management',header: 'School_Management'  },
				{ fieldtype:"text", field: 'PrincipalName',header: 'PrincipalName'  },
				{ fieldtype:"text", field: 'PrincipalMobileNo',header: 'PrincipalMobileNo'  },
				{ fieldtype:"text", field: 'TotalTeacher',header: 'TotalTeacher'  },
				{ fieldtype:"text", field: 'TotalClass',header: 'TotalClass'  }
			];
		}
		else if(datafor === 'OutletData'){
			this.cols = [    
                { fieldtype:"text", field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'Outlet_Type',header: 'Outlet Type'  },
				{ fieldtype:"text", field: 'Outlet_Name',header: 'Outlet Name'  },
				{ fieldtype:"text", field: 'Outlet_Address',header: 'Outlet Address'  }
			];
		}
		
		else if(datafor === 'ServiceProvider'){
			this.cols = [    
                { fieldtype:"text", field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'Cluster_Name',header: 'Cluster',ishidden:(this.config.data.valuefor=='9'?false:true) } ,				
				{ fieldtype:"text", field: 'ProviderType',header: 'ProviderType'  },
				{ fieldtype:"text", field: 'ServiceProvider_Name',header: 'ServiceProvider_Name'  },
				{ fieldtype:"text", field: 'Address',header: 'Address'  },
				{ fieldtype:"text", field: 'DateofEngagement',header: 'Date of Engagement'},
				{ fieldtype:"text", field: 'mobilenumber',header: 'mobilenumber'  },
				{ fieldtype:"text", field: 'ProviderID',header: 'ID'  },
				{ fieldtype:"text", field: 'ProviderStatus',header: 'Status'  },
				{ fieldtype:"text", field: 'StatusReason',header: 'Reason'  }
			];
		}
		else if(datafor === 'ProjectStaff'){
			this.cols = [   
                { fieldtype:"text", field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,  
				{ fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,              
                { fieldtype:"text", field: 'ProjectStaffType',header: 'ProjectStaffType'  },
				{ fieldtype:"text", field: 'ProjectStaff_Name',header: 'ProjectStaff_Name'  },
				{ fieldtype:"text", field: 'mobilenumber',header: 'mobilenumber'  },
				{ fieldtype:"text", field: 'emailid',header: 'emailid'  },
				{ fieldtype:"text", field: 'OfficialEmailID',header: 'Official Email'  },
				{ fieldtype:"text", field: 'Education_Qualification',header: 'Education_Qualification'  },
				{ fieldtype:"text", field: 'Experience_Year_PH',header: 'Experience_Year_PH'  },
				{ fieldtype:"text", field: 'ProjectJoining_Date',header: 'ProjectJoining_Date'},
				{ fieldtype:"text", field: 'ProjectLeaving_Date',header: 'ProjectLeaving_Date'},
				{ fieldtype:"text", field: 'PostingLocation',header: 'PostingLocation'  },
				{ fieldtype:"text", field: 'Photo',header: 'Photo'  },
				{ fieldtype:"text", field: 'Document',header: 'Document'  }
			];
		}
        else if(datafor === 'ChaupalPlanning'){
			this.cols = [    				
                { fieldtype:"numeric",field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text",field: 'Cluster_Name',header: 'Cluster'  },
				{ field: 'PlanningFor',header: 'Activity Type',ishidden:(this.config.data.valuefor=='Chaupal'?true:false)},
				{ field: 'FirstChaupalDate',header: 'FirstChaupal Date'  },
				{ field: 'SecondChaupalDate',header: 'SecondChaupal Date' },
				{ field: 'ThirdChaupalDate',header: 'ThirdChaupal Date' },
				{ field: 'FourthChaupalDate',header: 'FourthChaupal Date' },
			];
		}		
		else if(datafor === 'HNDPlanning'){
			this.cols = [    				
                { field: 'ID',header: 'ID' },
				{ fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ field: 'HND_Location',header: 'HND Site'  },
				{ field: 'FixHND_Day',header: 'Fix HND Day'  },
				{ field: 'FirstHNDDate',header: 'First Date'  },
				{ field: 'SecondHNDDate',header: 'Second_Date'  },
				{ field: 'ThirdHNDDate',header: 'Third Date'  },
				{ field: 'FourthHNDDate',header: 'Fourth Date'  },
				{ field: 'FifthHNDDate',header: 'Fifth Date'  },
				{ field: 'SixthHNDDate',header: 'Sixth Date'  },
				{ field: 'SeventhHNDDate',header: 'Seventh Date'  },
				{ field: 'EighthHNDDate',header: 'Eighth Date'  },
				{ field: 'NinthHNDDate',header: 'Ninth Date'  },
				{ field: 'TenthHNDDate',header: 'Tenth Date'  },
				{ field: 'EleventhHNDDate',header: 'Eleventh Date'  },
				{ field: 'TwelvthHNDDate',header: 'Twelvth Date'  }
			];
		}
		else if(datafor=='ActivityPlanning'){
			this.cols = [    				
                { field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ field: 'ActivityType',header: 'Activity'  },
				{ field: 'FirstDate',header: 'First Date'  },
				{ field: 'SecondDate',header: 'Second_Date'  },
				{ field: 'ThirdDate',header: 'Third Date'  },
				{ field: 'FourthDate',header: 'Fourth Date'  },
				{ field: 'FifthDate',header: 'Fifth Date'  },
				{ field: 'SixthDate',header: 'Sixth Date'  },
				{ field: 'SeventhDate',header: 'Seventh Date'  },
				{ field: 'EighthDate',header: 'Eighth Date'  },
				{ field: 'NinthDate',header: 'Ninth Date'  },
				{ field: 'TenthDate',header: 'Tenth Date'  },
				{ field: 'EleventhDate',header: 'Eleventh Date'  },
				{ field: 'TwelvthDate',header: 'Twelvth Date'  },
				{ field: 'Remark',header: 'Remark'  }
			];
		}
		else if(datafor === 'MeetingPlanning'){
			this.cols = [    			
                { field: 'ID',header: 'ID' },
                { field: 'State',header: 'State' },
                { field: 'District',header: 'District' },
				{ field: 'FirstDate',header: 'First Date' },
				{ field: 'SecondDate',header: 'Second Date' },
				{ field: 'ThirdDate',header: 'Third Date' },
				{ field: 'FourthDate',header: 'Fourth Date' },
				{ field: 'FifthDate',header: 'Fifth Date' },
				{ field: 'SixthDate',header: 'Sixth Date' },
				{ field: 'SeventhDate',header: 'Seventh Date' },
				{ field: 'EighthDate',header: 'Eighth Date' },
				{ field: 'NinthDate',header: 'Ninth Date' },
				{ field: 'TenthDate',header: 'Tenth Date' },
				{ field: 'EleventhDate',header: 'Eleventh Date' },
				{ field: 'TwelvthDate',header: 'Twelvth Date' }
			];
		}
		else if(datafor === 'EModulePlanning'){
			this.cols = [    				
                { field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ field: 'Training_Type',header: 'Training Type'  },
				{ field: 'ServiceProviderName',header: (this.config.data.valuefor=='NIV'?'Asha Name':(this.config.data.valueforid=='9'?'CV Name':(this.config.data.valueforid=='8'?'HCP Name':'Asha Name')))  },
				{ field: 'FirstTrainingDate',header: 'First Training Date' },
				{ field: 'SecondTrainingDate',header: 'Second Training Date' },
				{ field: 'ThirdTrainingDate',header: 'Third Training Date' },
				{ field: 'FourthTrainingDate',header: 'Fourth Training Date' },
			];
		}
		else if(datafor === 'SchoolEventPlanning'){
			this.cols = [    				
                { field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ field: 'School_Name',header: 'School Name'  },
				{ field: 'FirstTrainingDate',header: 'First Training Date' },
				{ field: 'SecondTrainingDate',header: 'Second Training Date' },
				{ field: 'ThirdTrainingDate',header: 'Third Training Date' },
				{ field: 'FourthTrainingDate',header: 'Fourth Training Date' },
			];
		}
		else if(datafor === 'SSVisitPlanning'){
			this.cols = [    				
                { field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ field: 'Cluster_Name',header: 'Cluster'  },
				{ field: 'DistanceO_L1',header: 'Distance O_L1'  },
				{ field: 'DistanceL1_L2',header: 'Distance L1_L2'  },
				{ field: 'DistanceL2_O',header: 'Distance L2_O'  },
				{ field: 'Time_From',header: 'Time From'  },
				{ field: 'Time_To',header: 'Time To' },
				{ field: 'Staff_Type',header: 'Staff Type'  },
				{ field: 'Vehicle',header: 'Vehicle'  },
				{ field: 'ActivityName',header: 'Activity'  },
				{ field: 'Activity_Date',header: 'Visit Date'  },
				{ field: 'Remark',header: 'Remark'  },
				{ field: 'ExecutionStatus',header: 'Execution Status'  },
				{ field: 'DeviationReason',header: 'Deviation Reason'  },
			];
		}
		else if(datafor === 'Activity'){
			if(this.config.data.valuefor==84){

			}
			else{
				this.cols = [   
					{ field: 'ID',header: 'ID',fieldtype: "numeric" },
					{ fieldtype:"text", field: 'State_Name',header: 'State' },
					{ fieldtype:"text", field: 'District_Name',header: 'District' } ,
					{ fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
					{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone' } ,			
					{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
					{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village',maxwidth:'120px' } ,
					{ fieldtype:"text", field: 'ActivityType',header: 'ActivityType'  },
					{ fieldtype:"text", field: 'Activity_Name',header: 'Activity',maxwidth:'120px'  },
					{ fieldtype:"text", field: 'Activity_Date',header: 'Activity_Date' },
					{ fieldtype:"text", field: 'No_of_Participants',header: 'No_of_Participants' },
					{ fieldtype:"text", field: 'Name_of_Participants',header: 'Name_of_Participants',maxwidth:'120px'  },
					{ fieldtype:"text", field: 'ServiceProviderNames',header: 'Service Provider Names',maxwidth:'120px',ishidden:((this.config.data.valuefor=='9' || this.config.data.valuefor=='10' || this.config.data.valuefor=='11' || this.config.data.valuefor=='12')?false:true)   },
					{ fieldtype:"text", field: 'SchoolNames',header: 'School Names',maxwidth:'120px',ishidden:(this.config.data.valuefor=='2'?false:true)  },
					{ fieldtype:"text", field: 'ProjectStaffNames',header: 'Project Staffs',maxwidth:'120px',ishidden:(this.config.data.valuefor=='13'?false:true)  },
					{ fieldtype:"text", field: 'ClusterNames',header: 'Clusters',maxwidth:'120px',ishidden:(this.config.data.valuefor=='24'?false:true)  },
					{ fieldtype:"text", field: 'ConductedBy',header: 'Conducted By'  },
					{ fieldtype:"text", field: 'Outcome',header: 'Outcome'  },
					{ fieldtype:"text", field: 'PhotoPath_1',header: 'Photo 1'  },
					{ fieldtype:"text", field: 'AttendancePath_1',header: 'Attendance 1'  },
					//  { fieldtype:"text", field: 'File',header: 'File', enableSorting: false, enableFiltering: false,enableHiding:false,
					//   cellTemplate: ' <div><a ng-hide="{{row.entity.ReportPath==\'\'?true:false}}" ng-href="{{row.entity.ReportPath}}" target="_blank"><i class="fa fa-download fa-lg" ></i></a></div>'
					// }
				];
			}
		}        								
        else if (datafor === 'Chaupal' || datafor==='HouseholdContactedList') {
            this.cols = [ 			
                { fieldtype:"text", field: 'State_Name',header: 'State',isTotal:false },
                { fieldtype:"text", field: 'District_Name',header: 'District',isTotal:false } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block',isTotal:false } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone',isTotal:false  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat',isTotal:false } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village',isTotal:false } ,
				{ fieldtype:"text", field: 'Cluster_Name',header: 'Cluster',isTotal:false  },
				{ fieldtype:"text", field: 'ReportOn',header: 'Reported On',isTotal:false },
				{ fieldtype:"text", field: 'HHReported',header: 'HH Reported',isTotal:true },
				{ fieldtype:"text", field: 'Meeting',header: 'Meeting',isTotal:true},
				{ fieldtype:"text", field: 'Visit',header: 'Visit',isTotal:true},
				{ fieldtype:"text", field: 'Lock',header: 'Lock' ,isTotal:true},
				{ fieldtype:"text", field: 'Moved',header: 'Moved' ,isTotal:true},
				{ fieldtype:"text", field: 'FewerStatus',header: 'Fewer',isTotal:true },
				{ fieldtype:"text", field: 'Coil',header: 'Coil',isTotal:true },
				{ fieldtype:"text", field: 'Net',header: 'Net',isTotal:true },
				{ fieldtype:"text", field: 'Spray',header: 'Spray',isTotal:true , 
				   },
				{ fieldtype:"text", field: 'Cream',header: 'Cream' ,isTotal:true ,
				   },
				{ fieldtype:"text", field: 'FastCard',header: 'FastCard',isTotal:true  ,
				   },
				{ fieldtype:"text", field: 'IRS',header: 'IRS',isTotal:true  ,
				   },
				{ fieldtype:"text", field: 'Fire',header: 'Fire' ,isTotal:true ,
				   },
				{ fieldtype:"text", field: 'Refill',header: 'Refill',isTotal:true  ,
				   },
			    { fieldtype:"text", field: 'WindowJali',header: 'Window Net',isTotal:true  ,
				   },
				  { fieldtype:"text", field: 'Agarbatti',header: 'Agarbatti',isTotal:true  ,
				   },
				{ fieldtype:"text", field: 'Pregnant',header: 'Pregnant',isTotal:true  ,
				   },
				{ fieldtype:"text", field: 'Child',header: 'Child',isTotal:true  ,
				   },
				{ fieldtype:"text", field: 'PLW',header: 'Pipeline',isTotal:true },
				{ fieldtype:"text", field: 'PLWLarwa',header: 'Pipeline Larwa',isTotal:true },
				{ fieldtype:"text", field: 'Tire',header: 'Tire',isTotal:true },
				{ fieldtype:"text", field: 'TIRELarwa',header: 'Tire Larwa',isTotal:true },
				{ fieldtype:"text", field: 'Tin',header: 'Tin',isTotal:true },
				{ fieldtype:"text", field: 'TINLarwa',header: 'Tin Larwa',isTotal:true },
				{ fieldtype:"text", field: 'CementTank',header: 'Cement Tank',isTotal:true },
				{ fieldtype:"text", field: 'CEMENTTANKLarwa',header: 'Cement Tank Larwa',isTotal:true },
				{ fieldtype:"text", field: 'ConstructSite',header: 'Construct Site',isTotal:true },
				{ fieldtype:"text", field: 'CONSTRUCTSITELarwa',header: 'Construct Site Larwa',isTotal:true },
				{ fieldtype:"text", field: 'Cooler',header: 'Cooler',isTotal:true },
				{ fieldtype:"text", field: 'COOLERLarwa',header: 'Cooler Larwa',isTotal:true },
				{ fieldtype:"text", field: 'Gamle',header: 'Gamle',isTotal:true},
				{ fieldtype:"text", field: 'GAMLELarwa',header: 'Gamle Larwa',isTotal:true },
				{ fieldtype:"text", field: 'PlasticPot',header: 'PlasticPot',isTotal:true },
				{ fieldtype:"text", field: 'PLASTICPOTLarwa',header: 'PlasticPot Larwa',isTotal:true },
				{ fieldtype:"text", field: 'Fridge',header: 'Fridge',isTotal:true },
				{ fieldtype:"text", field: 'FridgeLarwa',header: 'Fridge Larwa',isTotal:true },
				{ fieldtype:"text", field: 'OthCont',header: 'Other Container',isTotal:true },
				{ fieldtype:"text", field: 'OthContLarwa',header: 'Other Container Larwa',isTotal:true },
				// { fieldtype:"text", field: 'File',header: 'File', enableSorting: false, enableFiltering: false,enableHiding:false,
                //   cellTemplate: ' <div><a ng-hide="{{row.entity.ReportPath==\'\'?true:false}}" ng-href="{{row.entity.ReportPath}}" target="_blank"><i class="fa fa-download fa-lg" ></i></a></div>'
                // }
            ];                      
        }
		else if (datafor === 'ChaupalSupervision') {
            this.cols = [ 
				{ field: 'ID',header: 'ID',fieldtype: "numeric" },
                { fieldtype:"text", field: 'State_Name',header: 'State',isTotal:false },
                { fieldtype:"text", field: 'District_Name',header: 'District',isTotal:false } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block',isTotal:false } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone',isTotal:false  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat',isTotal:false } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village',isTotal:false } ,
				{ fieldtype:"text", field: 'Cluster_Name',header: 'Cluster',isTotal:false,ishidden:(this.config.data.valuefor=='Chaupal'?false:true)  },
				{ fieldtype:"text", field: 'ReportOn',header: 'Reported On',isTotal:false },
				{ fieldtype:"text", field: 'ModulePresent',header: 'Module',ishidden:(this.config.data.valuefor=='Chaupal'?false:true) },
				{ fieldtype:"text", field: 'CC',header: 'CC',isTotal:true },
				{ fieldtype:"text", field: 'PA',header: 'PA',isTotal:true },
				{ fieldtype:"text", field: 'RC',header: 'RC',isTotal:true },
				{ fieldtype:"text", field: 'HCP',header: 'HCP',isTotal:true },
				{ fieldtype:"text", field: 'CV',header: 'CV',isTotal:true },
				// { fieldtype:"text", field: 'File',header: 'File', enableSorting: false, enableFiltering: false,enableHiding:false,
                //   cellTemplate: ' <div><a ng-hide="{{row.entity.ReportPath==\'\'?true:false}}" ng-href="{{row.entity.ReportPath}}" target="_blank"><i class="fa fa-download fa-lg" ></i></a></div>'
                // }
				
            ];                      
        }
        else if (datafor === 'LarvaSurvey') {
            this.cols = [ 
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone'  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'Cluster_Name',header: 'Cluster'  },
				{ fieldtype:"text", field: 'ReportOn',header: 'Reported On' },
				{ fieldtype: "numeric", field: 'HHSurveyed',header: 'कितने घरों में लार्वा सर्वे किया गया',isTotal:true },
				{ fieldtype:"numeric", field: 'HHLarvaFound',header: 'कितने घरों में लार्वा पाया गया',isTotal:true },
				{ fieldtype:"numeric", field: 'CTankFound', header: 'कुल पानी की टंकी चेक  की गयी',isTotal:true},
				{ fieldtype:"numeric", field: 'CTankLarvaFound', header: 'कुल पानी की टंकी में लार्वा पाया गया',isTotal:true},
				{ fieldtype:"numeric", field: 'CoolerFound', header: 'कुल कूलर चेक किये गए',isTotal:true},
				{ fieldtype:"numeric", field: 'CoolerLarvaFound', header: 'कुल कूलर में लार्वा पाया गया',isTotal:true},
				{ fieldtype:"numeric", field: 'TireFound', header: 'कुल टायर चेक किये गए',isTotal:true},
				{ fieldtype:"numeric", field: 'TireLarvaFound', header: 'कुल टायर में लार्वा पाया गया',isTotal:true},
				{ fieldtype:"numeric", field: 'OtherContainerFound', header: 'अन्य कंटेनर चेक किये गए',isTotal:true},
				{ fieldtype:"numeric", field: 'OtherLarvaContainerFound', header: 'अन्य कंटेनर में लार्वा पाया गया',isTotal:true},
				
				
				{ fieldtype:"numeric", field: 'ContainerFound',header: 'कितने बर्तनों में लार्वा सर्वे कराया',isTotal:true },
				{ fieldtype:"numeric", field: 'LarvaContainerFound',header: 'कितने बर्तनों में लार्वा पाया',isTotal:true },
				{ fieldtype:"numeric", field: 'KhaliKaraya',header: 'कितने सकारात्मक कंटेनर खली कराए',isTotal:true },
				{ fieldtype:"numeric", field: 'TemophosOilDalwaya',header: 'कितने सकारात्मक कंटेनरों में टेमोफास / आयल डलवाया',isTotal:true }
			];
        }
        else if (datafor === 'LockedHouseholdList') {
            this.cols = [ 
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'Cluster',header: 'Cluster'  },
				{ fieldtype:"text", field: 'HouseHold',header: 'HouseHold'  },
				{ fieldtype:"text", field: 'LastReportDate',header: 'Lock Reported On' },
				{ fieldtype:"text", field: 'VisitDate',header: 'Visit On' }
            ];                      
        }
        else if (datafor === 'SupportSupervisionDC') {
            this.cols = [ 
                { fieldtype:"text", field: 'District',header: 'District', //enablePinning:true, hidePinLeft: false, hidePinRight: true, pinnedLeft:true ,
				   },
               { fieldtype:"text", field: 'LocalBodyType_Name',header: 'LocalBody Type'  },
                { fieldtype:"text", field: 'BCCFZone',header: 'BCCF Zone'  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'ReportOn',header: 'Reported On' },
				{ fieldtype:"text", field: 'ReportType',header: 'Visit Type' },
				{ fieldtype:"text", field: 'VisitBy',header: 'Visit By' },
				// { fieldtype:"text", field: 'File',header: 'File', enableSorting: false, enableFiltering: false,enableHiding:false,
                //   cellTemplate: ' <div><a ng-hide="{{row.entity.ReportPath==\'\'?true:false}}" ng-href="{{row.entity.ReportPath}}" target="_blank"><i class="fa fa-download fa-lg" ></i></a></div>'
                // }
            ];                      
        }
		else if(datafor === 'WSRegister'){
			this.cols = [ 
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone'  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'ReportOn',header: 'Report On'  },
				{ fieldtype:"text", field: 'WSReported',header: 'WSReported',isTotal:true  },
				{ fieldtype:"text", field: 'Dried',header: 'Dried',isTotal:true  ,
				   },
				{ fieldtype:"text", field: 'Oil',header: 'Oil',isTotal:true  ,
				   },
				{ fieldtype:"text", field: 'GumbusiaFish',header: 'Gumbusia Fish',isTotal:true  ,
				   },
				{ fieldtype:"text", field: 'PermanentlyFilled',header: 'Permanently Filled',isTotal:true  ,
				   },
				//   { fieldtype:"text", field: 'File',header: 'File', enableSorting: false, enableFiltering: false,enableHiding:false,
                //   cellTemplate: ' <div><a ng-hide="{{row.entity.ReportPath==\'\'?true:false}}" ng-href="{{row.entity.ReportPath}}" target="_blank"><i class="fa fa-download fa-lg" ></i></a></div>'
                // }
			];
		}
		else if(datafor === 'CommunityDrive'){
			this.cols = [     
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone'  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'Cluster_Name',header: 'Cluster'  },
				{ fieldtype:"text", field: 'ReportOn',header: 'Report On'  },
				{ fieldtype:"text", field: 'HHReported',header: 'HH Reported'  },
				{ fieldtype:"text", field: 'BedNetStatus',header: 'BedNet Status'  ,
				   },
				{ fieldtype:"text", field: 'Complete',header: 'Complete'  ,
				   },
				{ fieldtype:"text", field: 'Partial',header: 'Partial'  ,
				   },
				{ fieldtype:"text", field: 'NotDone',header: 'Not Done'  ,
				   }
			];
		}		
		else if(datafor === 'HND'){
			this.cols = [   
				{ fieldtype:"text", field: 'ID',header: 'ID' },
                { fieldtype:"text", field: 'State_Name',header: 'State' },
                { fieldtype:"text", field: 'District_Name',header: 'District' } ,
                { fieldtype:"text", field: 'MunicipalArea_Name',header: 'NN/Block' } ,
				{ fieldtype:"text", field: 'BCCFZone_Name',header: 'BCCF Zone'  },
				{ fieldtype:"text", field: 'TerritorialArea_Name',header: 'Ward/Panchayat' } ,
				{ fieldtype:"text", field: 'Administrative_Area_Name',header: 'Slum/Village' } ,
				{ fieldtype:"text", field: 'ReportOn',header: 'Reported On'  },								
				{ fieldtype:"text", field: 'NoOfSlides',header: 'No Of Slides',isTotal:true},
				{ fieldtype:"text", field: 'NoOfPositive',header: 'No Of Positive' ,isTotal:true},
				{ fieldtype:"text", field: 'Noofoldpatient',header: 'No of oldpatient' ,isTotal:true},
				{ fieldtype:"text", field: 'NoOfCompleteCources',header: 'No Of Complete Cources',isTotal:true},
				{ fieldtype:"text", field: 'AttendsHH',header: 'HH Attended'},
				{ fieldtype:"text", field: 'Noofpregnantwomen',header: 'No Of Pregnant',isTotal:true},
				//   { fieldtype:"text", field: 'File',header: 'File', enableSorting: false, enableFiltering: false,enableHiding:false,
                //   cellTemplate: ' <div><a ng-hide="{{row.entity.ReportPath==\'\'?true:false}}" ng-href="{{row.entity.ReportPath}}" target="_blank"><i class="fa fa-download fa-lg" ></i></a></div>'
                // }
				
			];
		}
		
		
    }
	
}

