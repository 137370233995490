<style>


  .navbar {
    width: 100%;
    position: relative;
    margin-top: 2px;
    bottom: 0;
    padding: 0 1em;
    background: #000;
    background: #333;
  }

  .navbar-nav li {
    border-right: 1px solid #fff;
  }

    .navbar-nav li a {
      display: block;
      color: #fff;
      text-decoration: none;
      padding: 0.8em 1.3em;
      text-transform: uppercase;
      font-size: 12px;
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
      letter-spacing: 2px;
      -webkit-transition: color .2s ease;
      -moz-transition: color .2s ease;
      -ms-transition: color .2s ease;
      -o-transition: color .2s ease;
      transition: color .2s ease;
      font-weight: bold;
      font-family: 'Open Sans';
    }

      .navbar-nav li a.active {
        color: #e8592b;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: bold;
      }

  .dropdown-menu-v2 {
    padding: 0px 0;
    margin: 16px 0 0;
    background: #000000;
  }

  .dropdown-menu {
    background-color: rgba(0,0,0,.9);
    right: 0;
    left: auto;
  }

    .dropdown-menu a:hover {
      background-color: blue;
    }
    /*.navbar {
    padding: 1rem 1rem;
  }
  .navbar-nav li {
    border-right: 1px solid #fff;
  }
    .navbar-nav li a {
      color: white;
      padding-left: 5px;
      padding-right: 5px;
      font: 15px Arial, sans-serif;
      -webkit-transition: color .2s ease;
      -moz-transition: color .2s ease;
      -ms-transition: color .2s ease;
      -o-transition: color .2s ease;
      transition: color .2s ease;
      font-weight: bold;
      font-family: 'Open Sans';
    }

    .navbar-nav li a.active {
      color: #e8592b;
      padding-left: 10px;
      padding-right: 10px;
      font-weight:bold;
    }
    .dropdown-menu-v2 {
    padding: 0px 0;
    margin: 16px 0 0;
    background: #000000;
  }
  .dropdown-menu {
    background-color: rgba(0,0,0,.9);
    right: 0;
    left: auto;
  }*/
    .dropdown-menu a:hover {
      background-color: blue;
    }
</style>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="d-flex flex-grow-1">
    <span class="w-100 d-lg-none d-block"><!-- hidden spacer to center brand on mobile --></span>
    <a class="navbar-brand" href="#">
      
    </a>
    <div class="w-100 text-right">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#myNavbar7">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </div>
  <div class="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar7">
    <ul class="navbar-nav ml-auto flex-nowrap">
      <li class="nav-item">
        <a href="/#/home" class="nav-link text-uppercase " [class.active]="activelink === 'home'">
          <i class="icon-bar-chart"></i> Home
        </a>
      </li>
      <li class="nav-item">
        <a href="/#/aboutproject" class="nav-link text-uppercase " [class.active]="activelink === 'aboutproject'">
          <i class="icon-bar-chart"></i> About Project
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="text-uppercase nav-link dropdown-toggle" id="navbarDropdownMenuLink1" role="button" data-hover="dropdown" data-close-others="true" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false" [ngClass]="{'active': activelink === 'mentoringchklst' || activelink === 'vochklst'}"><i class="icon-briefcase"></i>Implementation Progress</a>
        <div class="dropdown-menu dropdown-primary" role="menu" aria-labelledby="navbarDropdownMenuLink1">
          <a href="/#/mentoringchklst" class="dropdown-item" [ngClass]="{'active': activelink === 'mentoringchklst'}">
            CONCURRENT MONITORING SHG
          </a>
          <a href="/#/vochklst" class="dropdown-item" [ngClass]="{'active': activelink === 'vochklst'}">
            CONCURRENT MONITORING VO
          </a>
          <a href="/#/pwchklst" class="dropdown-item" [ngClass]="{'active': activelink === 'vochklst'}">
            CONCURRENT MONITORING PW
          </a>
          <a href="/#/lmchklst" class="dropdown-item" [ngClass]="{'active': activelink === 'vochklst'}">
            CONCURRENT MONITORING CHILDREN ≤ 2YEARS
          </a>
          <a href="/#/wadachklst" class="dropdown-item" [ngClass]="{'active': activelink === 'vochklst'}">WADA SAKHIS- OUR CHANGE AGENT</a>
        </div>
      </li>
      <li class="nav-item">

      </li>
      <!-- Dropdown -->
      <li class="nav-item dropdown">
        <a class="text-uppercase nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-hover="dropdown" data-close-others="true" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false"><i class="icon-graph"></i>Survey Results</a>
        <div class="dropdown-menu dropdown-primary" role="menu" aria-labelledby="navbarDropdownMenuLink">
          <a href="/#/surveyresult" class="dropdown-item text-uppercase" [ngClass]="{'active': activelink === 'surveyresult'}">
            <img src="../../../assets/gallery/survey.png" width="25" height="25" />Knowledge Assessment of SHG Members
          </a>
          <a href="/#/c19telsurvey" class="dropdown-item text-uppercase" [ngClass]="{'active': activelink === 'c19telsurvey'}">
            <img src="../../../assets/gallery/stopcorona.png" width="25" height="25" />Survey on COVID-19
          </a>
          <a href="/#/shgpregsurvey" class="dropdown-item text-uppercase" [ngClass]="{'active': activelink === 'shgpregsurvey'}">
            <img src="../../../assets/gallery/pregwomen.png" width="25" height="25" />SURVEY ON MATERNAL NUTRITION PRACTICES
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<!--Navbar-->

