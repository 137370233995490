<div class="card">
    <div class="card-body">
        <div class="card-title heading-2">Recent Orders</div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Status</th>
                        <th scope="col" class="text-end">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of orders">
                        <td>{{ order.date | date: 'dd MMM yyyy HH:mm aaa' }}</td>
                        <td>{{ order.customer }}</td>
                        <td [class]="getStatusClass(order.status)">&#9679; {{ order.status }}</td>
                        <td>{{ order.total | currency }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>