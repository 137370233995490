import { Component, HostBinding } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
    //moduleId: module.id,
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html',
	providers: [DatePipe]
})

export class FooterComponent{
    curdate: any;
	myDate = new Date();
	constructor(private datePipe: DatePipe) {    
		this.curdate = this.datePipe.transform(this.myDate, 'dd MMM yyyy');	
  }
}
