<div class="row" *ngIf="SlumProfileData != undefined" #PdfDiv id="PdfDiv">
    <div class="col-md-12">
        <table  class="table table-bordered table-striped table-condensed flip-content" style="width:850px" id="dvprintSlum">
          <tbody>
            <tr>
              <td colspan="8" style="text-align:center;"><h4>मच्छर जनित रोगों के उन्मूलन हेतु परियोजना के लिये स्लम का विवरण </h4></td>
            </tr>
            <tr style="background-color:#7f0000;color:white;">
              <td width="180px"><b>गाँव / बस्ती का नाम :</b></td>
              <td colspan="3">{{SlumProfileData.Administrative_Area_Name}}</td>
              <td width="180px"><b>जनगणना कोड :</b></td>
              <td colspan="3">{{SlumProfileData.Administrative_Area_Code}}</td>
            </tr>
            <tr style="background-color:#7f0000;color:white;">
              <td><b>अक्षांश :</b></td>
              <td colspan="3">{{SlumProfileData.Latitude}}</td>
              <td><b>देशान्तर :</b></td>
              <td colspan="3"> {{SlumProfileData.Longitude}}</td>
            </tr>
            <tr style="background-color:#7f0000;color:white;">
              <td><b>पंचायत का नाम / वॉर्ड स० एवं नाम :</b></td>
              <td colspan="3">{{SlumProfileData.TerritorialArea_Name}}</td>
              <td><b>	जोन :</b></td>
              <td colspan="3">{{SlumProfileData.BCCFZone_Name}}</td>
        
            </tr>
            <tr style="background-color:#7f0000;color:white;">
        
              <td><b>म्युनिसिपल :</b></td>
              <td colspan="3">{{SlumProfileData.MunicipalArea_Name}}</td>
              <td><b>जिला :</b></td>
              <td colspan="3">{{SlumProfileData.District_Name}}</td>
            </tr>
            <tr style="background-color:#7f0000;color:white;">
              <td><b>राज्य :</b></td>
              <td colspan="3">{{SlumProfileData.State_Name}}</td>
              <td><b>क्लस्टर :</b></td>
              <td colspan="3"><span  *ngFor="let cl of SlumProfileData.ClusterList; let i = index"><span *ngIf="i!=0">,</span>{{cl.Cluster_Name}}</span></td>
            </tr>
            <tr style="background-color:#7f0000;color:white;">
              <td><b>कुल परिवार :</b></td>
              <td colspan="3">{{SlumProfileData.TotHouseHold}}</td>
              <td><b>कुल जनसंख्या :</b></td>
              <td colspan="3">{{SlumProfileData.TotPopulation}}</td>
            </tr>
            <tr style="background-color:#7f0000;color:white;">
              <td><b>शुरुआत की तारीख :</b></td>
              <td colspan="3">{{SlumProfileData.InceptionDate | date:'dd-MM-yyyy'}}</td>
              <td><b>फेज आउट की तारीख :</b></td>
              <td colspan="3"><span *ngIf="SlumProfileData.PhaseOutDate!='0000-00-00'">{{SlumProfileData.PhaseOutDate | date:'dd-MM-yyyy'}}</span></td>
            </tr>
            <tr style="background-color:#7f0000;color:white;">
              <td><b>बी.सी.सी.एफ.का नाम :</b></td>
              <td colspan="3">{{SlumProfileData.BCCFName}}</td>
              <td><b>सामुदायिक सहयोगी :</b></td>
              <td colspan="3">{{GetCellValue('9','ServiceProvider_Name')}}</td>
            </tr>
            <tr style="background-color:#fbe4d5;">
              <td colspan="4"><b>गांव का सामाजिक मानचित्र </b></td>
              <td colspan="4"><b>घरों की सूची </b></td>
            </tr>
            <tr>
              <td colspan="4"><img  
                [style.backgroundImage]="'url(' + backgroundImageUrl(SlumProfileData.Administrative_Area_Photo) + ')'"
    style="background-size: contain; background-repeat: no-repeat; max-width: 300px; max-height: 400px;"
    width="300px"
    height="400px"
    border="0"
    id="layoutimg" /></td>
              <td colspan="4">
                <table  class="table-bordered">
                  <thead class="flip-content">
                    <tr>
                      <th style="vertical-align: top;text-align: center;"> S.No. </th>
                      <th style="vertical-align: top;text-align: center;"> Cluster </th>
                      <th style="vertical-align: top;text-align: center;"> H.No. </th>
                      <th style="vertical-align: top;text-align: center;"> S.No.</th>
                      <th style="vertical-align: top;text-align: center;"> HouseHead Name </th>
                      <th style="vertical-align: top;text-align: center;">No.of Member </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  *ngFor="let hh of SlumProfileData.hhlist | customFilter: { NoOfMember: 0 }:false | limitTo:25; let i = index">
                      <td style="text-align: center;font-size:6pt;"> {{i+1}} </td>
                      <td style="text-align: left;font-size:6pt;"> {{hh.Cluster}} </td>
                      <td style="text-align: center;font-size:6pt;"> {{hh.HouseHold_No}} </td>
                      <td style="text-align: center;font-size:6pt;"> {{hh.Structure_No}} </td>
                      <td style="text-align: left;font-size:6pt;"> {{hh.HouseHeadName}} </td>
                      <td style="text-align: center;font-size:6pt;"> {{hh.NoOfMember}} </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td><b>सामाजिक मानचित्र बनाने का दिनांक	</b></td>
              <td colspan="3"><span *ngIf="SlumProfileData.LayoutDate!='0000-00-00'">{{SlumProfileData.LayoutDate | date:'dd-MM-yyyy'}}</span></td>
              <td><b>सामाजिक मानचित्र बनाने में सहयोगी</b></td>
              <td colspan="3">{{SlumProfileData.LayoutHelper}}</td>
            </tr>
            <tr style="background-color:#7f0000;color:white;">
                <td colspan="8">सेवा प्रदाता का नाम </td>
            </tr>
            <tr style="background-color:#fbe4d5;">
              <td><b>अर्बन आशा का नाम</b></td>
              <td colspan="3">{{GetCellValue('1','ServiceProvider_Name')}}</td>
              <td><b>आंगन बाड़ी कार्यकर्ता का नाम</b></td>
              <td colspan="3">{{GetCellValue('2','ServiceProvider_Name')}}</td>
            </tr>
            <tr>
              <td><b>आरोग्य केन्द्र</b></td>
              <td colspan="3">{{SlumProfileData.HNDSite}}</td>
              <td><b>अर्बन स्वास्थ्य एवं पोषण दिवस</b></td>
              <td colspan="3">{{SlumProfileData.HNDDay}}</td>
            </tr>
            <tr style="background-color:#fbe4d5;">
              <td><b>आंगन बाड़ी सहायिका का नाम</b></td>
              <td colspan="3">{{GetCellValue('6','ServiceProvider_Name')}}</td>
              <td><b>ए.एन.एम. का नाम</b></td>
              <td colspan="3">{{GetCellValue('4','ServiceProvider_Name')}}</td>
            </tr>
            <tr>
              <td><b>पार्षद का नाम</b></td>
              <td colspan="3">{{GetCellValue('3','ServiceProvider_Name')}}</td>
              <td><b>सरपंच/वार्ड मेंबर का नाम	</b></td>
              <td colspan="3"></td>
            </tr>
            <tr style="background-color:#fbe4d5;">
              <td><b>निजी स्वास्थ्य सेवा प्रदाताओ का नाम</b></td>
              <td colspan="3">{{GetCellValue('8','ServiceProvider_Name')}}</td>
              <td><b>ओझा/गुनिया/पण्डा का नाम</b></td>
              <td colspan="3">{{GetCellValue('7','ServiceProvider_Name')}}</td>
            </tr>
            <tr>
              <td><b>स्कूल की जानकारी </b></td>
              <td colspan="7"><span  *ngFor="let ol of SlumProfileData.SchoolList; let i = index" ><span *ngIf="i!=0">,</span>{{ol.School_Name}}</span></td>
            </tr>
            <tr style="background-color:#fbe4d5;">
              <td><b>दुकानों की/आउटलेट का नाम</b></td>
              <td colspan="7"><span  *ngFor="let ol of SlumProfileData.OutletList; let i = index"><span *ngIf="i!=0">,</span>{{ol.Outlet_Name}}</span></td>
            </tr>
            <tr>
              <td><b>पानी जमा होने वाले स्थानो का नाम</b></td>
              <td colspan="7"><span  *ngFor="let wl of SlumProfileData.WSList; let i = index"><span *ngIf="i!=0">,</span>{{wl.WaterSatgnation_Name}}</span></td>
            </tr>
            <tr style="background-color:#7f0000;color:white;">
              <td colspan="8"><b>स्लम के नजदीक स्वास्थ्य सेवा इकाइयॉ एवं दूरी</b></td>
            </tr>
            <tr>
              <td><b>उपस्वास्थ्य केन्द्र का नाम एवं दूरी</b></td>
              <td colspan="3">{{SlumProfileData.SHCName}}&nbsp;({{SlumProfileData.SHCDistance}})</td>
              <td><b>प्राथमिक/सामुदायिक स्वास्थ्य केन्द्र का नाम एवं दूरी</b></td>
              <td colspan="3">{{SlumProfileData.SHCName}}&nbsp;({{SlumProfileData.PHCDistance}})</td>
            </tr>
            <tr>
              <td><b>जिला स्तरीय चिकित्सालय का नाम एवं दूरी</b></td>
              <td colspan="3">{{SlumProfileData.DistrictHospName}}&nbsp;({{SlumProfileData.DistrictHospDistance}})</td>
              <td><b>निजी चिकित्सालय का नाम एवं दूरी</b></td>
              <td colspan="3">{{SlumProfileData.PrivateHospName}}&nbsp;({{SlumProfileData.PrivateHospDistance}})</td>
            </tr>
            <tr style="background-color:#fbe4d5;">
              <td colspan="2"></td>
              <td colspan="2"></td>
              <td colspan="2"></td>
              <td colspan="2"></td>
            </tr>
          </tbody>
        </table>
    </div>
</div>
<div class='row'>    
    <div class='col-md-6'>
        <div class='form-group'>
            <a class="btn btn-warning btn-block" (click)="generatePDF();">Download PDF</a>
        </div>
    </div>
</div>