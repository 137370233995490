import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import moment from 'moment';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  attendances: any[] = [];
  userlogin:any;
  filterState: any = {};
  currentDate: Date = new Date();
  displayedDate: Date = new Date();
  
  updateDisplayedDate(dayOffset: number): void {
    const newDate = new Date(this.displayedDate);
    newDate.setDate(this.displayedDate.getDate() + dayOffset);
    this.displayedDate = newDate;
    this.BindAllData();
  }
  private filterStateSubscription!: Subscription;
  constructor(private rtmisservice:FHIRtmisService,private filterService: FilterService) {    
    
  }
  
  ngOnInit() {
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
      // Update filter state with the changed value
      this.filterState = filterData.filterState; 
      // Call the method in other component to handle the filter changes
      if(this.filterService.isInitialized()){
        this.BindAllData();
      }
    });  
  }
  ngOnDestroy(): void {
    if (this.filterStateSubscription) {
      this.filterStateSubscription.unsubscribe();
    }
    this.filterStateSubscription.unsubscribe();
  }  

   BindAllData(): void {
    this.rtmisservice.GetAttendanceDataSummary(this.filterState,moment(this.displayedDate)).subscribe((data: [{}]) => {
      if(data.length>0){
        this.attendances = data;
      }           
    });    
  }

}
