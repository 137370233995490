<nav class="navbar navbar-expand-md navbar-light shadow-sm">
  <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="assets/images/logo1.png" class="custom-logo" alt="" itemprop="logo" srcset="assets/images/logo1.png 431w, assets/images/logo1-300x60.png 300w" sizes="(max-width: 431px) 100vw, 431px" width="431" height="86" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li ng-class="PageName == 'graphical_dashboard.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="#/dash/home" class="text-uppercase">
                    <i class="icon-bar-chart"></i> Dashboard-1 </a>                                    
              </li>
              <li  ng-class="PageName == 'android_dashboard.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                  <a href="#/dash/home" class="text-uppercase">
                      <i class="icon-graph"></i> Dashboard-2 </a>                                    
              </li>
              <li  ng-class="PageName == 'AnnexureC.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="#/dash/annexurec" class="text-uppercase">
                    <i class="icon-briefcase"></i> Annexure-C </a>                                    
            </li>
            <li  ng-class="PageName == 'activity_calendar.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="#/dash/calendar" class="text-uppercase">
                    <i class="icon-briefcase"></i> Calendar </a>                                    
            </li>
            <li  ng-class="PageName == 'EMBED_Quarterly_Report.php'?'dropdown dropdown-fw dropdown-fw-disabled  active open selected':'dropdown dropdown-fw dropdown-fw-disabled '">
                <a href="#/dash/home" class="text-uppercase">
                    <i class="icon-briefcase"></i> Tracker </a>                                    
            </li>
            
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                   Important Links
              </a>
              <ul class="dropdown-menu ms-auto mb-2 mb-lg-0" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" href="https://fhiindia.org/" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> FH India </a>                                    
              </li>
                <li>
                  <a class="dropdown-item" href="https://emodule.fhindia.org/" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> Emodule </a>                                    
              </li>
                <li>
                  <a class="dropdown-item" href="http://t3.fhindia.org/" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> T3 </a>                                    
              </li>
              <li>
                  <a class="dropdown-item" href="https://embed.fhindia.org/" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> EMBED </a>                                    
              </li>
                <li>
                  <a class="dropdown-item" href="http://route.indianmicrosystems.com/dm-login" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> TMS </a>                                    
              </li>
              <li>
                  <a class="dropdown-item" href="https://t3.fhindia.org/supplychain/index.php" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> SMS </a>                                    
              </li>
              <li>
                  <a class="dropdown-item" href="https://hr.fhindia.co.in/dms/" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> DMS </a>                                    
              </li>
              <li>
                  <a class="dropdown-item" href="https://mdata.fhindia.org/MalariaTreatmentProtocol.php" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> MTP </a>                                    
              </li>
              <li>
                  <a class="dropdown-item" href="https://usha.fhindia.org/" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> E-Scaleup </a>                                    
              </li>
              <li>
                  <a class="dropdown-item" href="https://civicaction.fhindia.org/" class="text-uppercase" target="_blank">
                      <i class="icon-graph"></i> Youth </a>                                    
              </li>                       
              </ul>
          </li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                      data-bs-toggle="dropdown" aria-expanded="false" *ngIf="userlogin">
                      <i class="fa-solid fa-user" aria-hidden="true"></i> {{userlogin.username}}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
                  </ul>
              </li>
          </ul>
      </div>
  </div>
</nav>