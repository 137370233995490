import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { StorageService } from '../../login/storage.service';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';

@Component({
    selector: 'app-activity-profile',
    templateUrl: './activity-profile.component.html',
    styleUrls: ['./activity-profile.component.css'],
    //changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation:ViewEncapsulation.None
  })
  export class ActivityProfileComponent implements OnInit {
    @Input() datafor: string = '';    
    @Input() modaltitle: string = '';  
    @Input() valuefor: string = '';
    @Input() valueforid: string = '';
    @Input() ActivityData: any;
    @Output() dataChanged: EventEmitter<void> = new EventEmitter<void>();
    userlogin:any;
    constructor(private rtmisservice:FHIRtmisService,private storageService: StorageService,private el: ElementRef
      ) { 
      this.userlogin=this.storageService.getUser();
      if(this.userlogin){      
  
      }    
    }
  ngOnChanges(changes: SimpleChanges) {
    // Handle changes to the selected row item
    //console.log(changes['selectedRowItem']);
    
    if (changes['ActivityData'] && !changes['ActivityData'].firstChange) {
      this.ActivityData=changes['ActivityData'].currentValue;
    }
  }
  ngOnInit(): void {
  }
  backgroundImageUrl(ProfileDataPhoto:any): string {
    // Modify this logic based on your actual requirements
    return ProfileDataPhoto.includes('SlumPhoto') ? 'https://dengue.fhindia.org/'+ProfileDataPhoto : ProfileDataPhoto;
  }
  @ViewChild('PdfDiv') PdfDiv!: ElementRef;
  generatePDF() {
    let filename ='Activity Report-'+this.ActivityData.ActivityType+'.pdf';
    const content = this.PdfDiv.nativeElement.innerHTML;
    const printWindow = window.open('', '', 'width=600,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/components.min.css" rel="stylesheet" />');
    printWindow?.document.write(`<style>img{
      width: 280px;
      height: 280px;
    }            
    .cellheading{
      font-weight:bold;
      font-size:12pt;
    }                   
    .table td, .table th {
      font-size: 12px !important;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    setTimeout(() => {
      printWindow?.close();
    }, 100);    
  }
}