<main class="bg-grey px-3"> 
    <div class="row">
        <div class="col-md-12" style="text-align: center;">
            <h4></h4>
        </div>
        <div class="col-md-12">
            <span style="background-color: rgb(0, 100, 0);color: white;padding: 5px;">Planning</span>&nbsp;<span style="background-color: rgb(227, 188, 8);color: white;padding: 5px;">Chaupal</span>&nbsp;
            <span style="background-color: rgb(30, 144, 255);color: white;padding: 5px;">VHND</span>&nbsp;<span style="background-color: rgb(173, 33, 33);color: white;padding: 5px;">Activity</span>
        </div>
        <div class="col-md-12">
            <ng-template #loading>
                <div class="text-center">
                  <i class="fas fa-spin fa-spinner fa-5x"></i> <br />
                  Loading events...
                </div>
              </ng-template>
            <div *ngIf="events$ | async; else loading; let events">
            <mwl-calendar-month-view
            [viewDate]="viewDate"
            [events]="events"
          >
       
          </mwl-calendar-month-view>   </div>        
            <ng-template #calendarMonthCell let-day="day" let-locale="locale">
                <div class="cal-month-day {{day.cssClass}}">        
                    <span
                      class="pull-right"
                      data-cal-date
                      ng-click="eventClicked(calendarEvent)"
                      ng-bind="day.label">
                    </span>
                    <ul class="unstyled list-unstyled">
                    <li *ngFor="let event of day.events" class="ng-scope"> 
                    <span class="pull-left event" ng-style="{backgroundColor: event.color.primary}" style="background-color: rgb(0, 100, 0);"></span> &nbsp; 
                    <span style="font-size: 9pt;font-weight: bold;">{{event.title}}</span>
                    </li> 
                    </ul><br/><br/>
                  </div>
                <!-- <div class="cal-cell-top">
                  <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
                  <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                </div>
                <small style="margin: 5px">There are {{ day.events.length }} events on this day</small> -->
            </ng-template>              
    </div>
    </div>
</main>