import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { Page404Component } from './pages/page404/page404.component';
import { DataHomeComponent } from './pages/data-home/data-home.component';

export const AppRoutes: Routes = [
  //{ path: '**', component: Page404Component },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },{ path: 'login', component: LoginComponent }
  ,{ path: 'onlinedata', component: DataHomeComponent }
  ,{
    path: 'admin',
    loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
  },
  {
    path: 'hm',    
    loadChildren: () => import('./layouts/home-layout/home-layout.module').then(m => m.HomeLayoutModule)    
  },
  {
    path: 'dash',   
    loadChildren: () => import('./layouts/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule)      
  },
   // otherwise redirect to home
   { path: '**', component: Page404Component }
]

