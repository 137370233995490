import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {  DashboardRoutingModule } from './dashboard-layout.routing';
import { AchieveTargetComponent } from 'src/app/pages/dashboard/achieve-target/achieve-target.component';
import { RecentOrdersComponent } from 'src/app/pages/dashboard/recent-orders/recent-orders.component';
import { AttendanceComponent } from 'src/app/pages/dashboard/attendance/attendance.component';
import { UpcomingEventsComponent } from 'src/app/pages/dashboard/upcoming-events/upcoming-events.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { HomeComponent } from 'src/app/pages/dashboard/home/home.component';
import { DashboardNavbarModule } from 'src/app/shared/dashboardnavbar/dashboardnavbar.module';
import { CountUpModule } from 'ngx-countup';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { KnobModule } from 'primeng/knob';
import { ButtonModule } from 'primeng/button';
import { MasterListComponent } from 'src/app/pages/Crudpages/masterlist/masterlist.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import { CrudModalComponent } from 'src/app/pages/Crudpages/crudmodal/crudmodal.component';
import {FileUploadModule} from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { CustomValidationDirective } from 'src/app/shared/directives/custom-validator.directive';
import { FilterPipe } from 'src/app/shared/pipes/filterpipe';
import { IncludeIfDirective } from 'src/app/shared/directives/include-if.directive';
import { StatsModule } from 'src/app/pages/dashboard/stats/stats.module';
import { FileUploadComponent } from 'src/app/shared/file-upload/file-upload.component';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { ActivityCalendarModule } from 'src/app/pages/activity-calendar/activity-calendar.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CustomFilterPipe } from 'src/app/shared/pipes/custom-filter.pipe';
import { UniquePipe } from 'src/app/shared/pipes/unique.pipe';
import { AnnexureCComponent } from 'src/app/pages/annexure-c/annexure-c.component';
import { AdministrativeAreaProfileComponent } from 'src/app/pages/Crudpages/administrativearea-profile/administrativearea-profile.component';
import { ActivityProfileComponent } from 'src/app/pages/Crudpages/activity-profile/activity-profile.component';
import { LimitToPipe } from 'src/app/shared/pipes/limitto.pipe';
import { SsclFeedbackComponent } from 'src/app/pages/Crudpages/sscl-feedback/ssclfeedback.component';
import { DipstickGraphsComponent } from 'src/app/pages/dashboard/dipstick-graphs/dipstick-graphs.component';

@NgModule({
  declarations: [
    FilterPipe,
    SortPipe,
    CustomFilterPipe,
    UniquePipe,
    LimitToPipe,
    HomeComponent,
    DipstickGraphsComponent,
    MasterListComponent,
    AchieveTargetComponent,
    RecentOrdersComponent,
    AttendanceComponent,
    FileUploadComponent,
    CrudModalComponent, 
    SsclFeedbackComponent,
    AdministrativeAreaProfileComponent,
    ActivityProfileComponent,
    UpcomingEventsComponent,  
    AnnexureCComponent, 
    CustomValidationDirective,
    IncludeIfDirective
  ],
  exports:[CustomValidationDirective],
  imports: [   
    StatsModule,    
    ActivityCalendarModule,
    DashboardRoutingModule,
    FormsModule,
    DashboardNavbarModule,
    CountUpModule,       
    //NgbModule,
    NgCircleProgressModule.forRoot({
      "radius": 80,
      "space": 4,
      "showInnerStroke":true,
      "outerStrokeWidth": 15,
      "innerStrokeWidth": 5,
      "titleFontSize":"28",
      "subtitleFontSize":"18",
      "animateTitle": false,
      "showSubtitle": true,
      "animationDuration": 1000,
      "showUnits": false,
      "showBackground": false,
      "startFromZero": true,
      "lazy": true,
      "responsive": true
    }),        
    //Prime NG
    ProgressSpinnerModule,    
    DialogModule,
    KnobModule ,
    TableModule,
    ButtonModule,
    ToastModule,
    TabViewModule,
    MultiSelectModule,
    FileUploadModule,
    TooltipModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(), 
  ],
  schemas:[NO_ERRORS_SCHEMA]
})
export class DashboardLayoutModule { }
