<div class="card shadow-sm stats">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="selectsForm">
                <table width="100%" class="table table-bordered table-striped table-condensed flip-content" formArrayName="FeedbackDetails">
                <tr>
                    <th>S.No.</th>
                    <th>Feedback</th>
                    <th>Deadline Date</th>
                    <th>Alloted To</th>
                    <th>Status</th>
                </tr>
                <tbody *ngFor="let feedbackDetail of feedbackList.controls; let i = index">
                    <tr [formGroupName]="i">
                    <td>{{i+1}}</td>
                    <td>
                        <input class='form-control' type='text' formControlName="feedback">
                    </td>
                    <td>
                        <input class='form-control' type='text' formControlName="deadline" maxlength="10"
                        (keydown)="onKeyDown($event,'deadline')" (keyup)="onKeyUpInner($event,'deadline',i)"
                        [appCustomValidation]="[
               { type: 'date', minDate: CurDate, errorMessage: 'Please enter a valid date after ' + CurDate }
             ]">
                    </td>
                    <td>
                        <input class='form-control' type='text' formControlName="allotedto">
                    </td>
                    <td>
                        <select class='form-control' formControlName="feedbackstatus">
                        <option value="">Select Status</option>
                        <option value='Pending'>Pending</option>
                        <option value='Completed'>Completed</option>
                        </select>
                    </td>
                    </tr>
                </tbody>
                </table>
            </form>
            </div>
        </div>
        <div class='col-md-12'>
            <div class='form-group'>
              <input type='submit' style="width:125px;" value='Save Feedback' class='btn btn-warning btn-block' (click)="SaveFeedback();">
              <div class="font-red">
              </div>
            </div>
        </div>
    </div>
</div>