import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeNavbarComponent } from './homenavbar.component';

@NgModule({
  imports: [RouterModule, CommonModule    
  ],
  declarations: [HomeNavbarComponent],
  exports: [HomeNavbarComponent]
})

export class HomeNavbarModule { }
