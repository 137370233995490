import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { StorageService } from '../login/storage.service';
import { combineLatest, Subscription } from 'rxjs';
import { FilterService } from 'src/app/shared/services/filter.service';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import jsPDF from 'jspdf';
declare let html2pdf: any;

@Component({
  selector: 'app-annexure-c',
  templateUrl: './annexure-c.component.html',
  styleUrls: ['./annexure-c.component.css','../../../assets/css/components.min.css'],
  providers: [DialogService, MessageService],
  encapsulation:ViewEncapsulation.None
})
export class AnnexureCComponent implements OnInit { 
  userlogin:any;
  filterState: any = {};
  blockstatus:boolean=true;
  AnnexureCData:any ={"UpdateID":"","finalizesave":false,"SelMasterState":"","SelMasterDistrict":"","ReportingPeriod":""
  ,"ACNameOfNGO":"","ExecutiveSummary":"","StrengthenCommunity":"","InvolvementSchoolChild":"","LSMDetail":"","repellentUse":""
  ,"otherinvention":"","treatmentefforts":"","TotalASHAWorkers":0,"TotalRHCPs":0,"TotalOjhas":0
  ,"ProviderTypeSummary":[],"CommunityDrives":[],"PrioritiesNextMonth":[],"AccomplishmentData":[],"ActivitySummary":[]
  ,"SupportiveSupervision":[],"AttendanceSummary":[],"StaffSummary":[],"StaffDetail":[],"SlumDetail":[],"ChaupalSummary":[]
  ,"ChaupalDataAC":[],"WSDataAC":[],"CommunityDriveAC":[],"HNDSummaryAC":[],"titlepage": [],"ChaupalGraph": []
  ,"LarvalsourceGraph": [],"RepellentUseGraph": [],"OtherGraph": [],"vhndgraph": [],"T3Graph": [],"tableGraph": []
  ,"AshaSupplyChain": [],"PhotoNews": [],"Letterimage": []};
  curdate:Date=new Date();
  private filterStateSubscription!: Subscription;
    viewDate: Date = new Date();

  events: CalendarEvent[] =  []; 
  selectedDateRange :any;
  constructor(private http: HttpClient,private el: ElementRef,private rtmisservice:FHIRtmisService, private storageService: StorageService
    , private router: Router,private filterService: FilterService,private dialogService: DialogService, private messageService: MessageService) {    
    this.userlogin=this.storageService.getUser();
    if(this.userlogin){    
      this.selectedDateRange= { startDate: moment().startOf('month'), endDate: moment().endOf('month') };  
        filterService.resetFilter();
      this.filterState = filterService.filterState;    
      this.filterService.setFilterValue("selectedDateRange", this.selectedDateRange,false);this.BindAllData();
    }
    else{
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
        // Update filter state with the changed value
        this.filterState = filterData.filterState; 
        // Call the method in other component to handle the filter changes
        if(this.filterService.isInitialized()){
          //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
          if(this.filterService.getFilterValue("SelDistrictID") != "0"){
            this.BindAllData();
          }
        }
      });   
  }

  ngOnDestroy(): void {
    if (this.filterStateSubscription) {
      this.filterStateSubscription.unsubscribe();
    }
    this.filterStateSubscription.unsubscribe();
    if (this.ref) {
          this.ref.close();
      }
  }

  ref: DynamicDialogRef | undefined;  
  ResetAnnexureCData():void{
    this.AnnexureCData ={"UpdateID":"","finalizesave":false,"SelMasterState":"","SelMasterDistrict":"","ReportingPeriod":""
    ,"ACNameOfNGO":"","ExecutiveSummary":"","StrengthenCommunity":"","InvolvementSchoolChild":"","LSMDetail":"","repellentUse":""
    ,"otherinvention":"","treatmentefforts":"","TotalASHAWorkers":0,"TotalRHCPs":0,"TotalOjhas":0,"AnnexureCSummary":[]
    ,"ProviderTypeSummary":[],"CommunityDrives":[],"PrioritiesNextMonth":[],"AccomplishmentData":[],"ActivitySummary":[]
    ,"SupportiveSupervision":[],"AttendanceSummary":[],"StaffSummary":[],"StaffDetail":[],"SlumDetail":[],"ChaupalSummary":[]
    ,"ChaupalDataAC":[],"WSDataAC":[],"CommunityDriveAC":[],"HNDSummaryAC":[],"titlepage": [],"ChaupalGraph": []
    ,"LarvalsourceGraph": [],"RepellentUseGraph": [],"OtherGraph": [],"vhndgraph": [],"T3Graph": [],"tableGraph": []
    ,"AshaSupplyChain": [],"PhotoNews": [],"Letterimage": []};
  }  
  isdataloaded:boolean=false;
  BindAllData(){  
    this.isdataloaded=true;   
    this.ResetAnnexureCData();   
    this.blockstatus= (new Date(this.filterState.selectedDateRange.startDate).getMonth()+1==(new Date().getMonth()==0?12:new Date().getMonth()) && new Date(this.filterState.selectedDateRange.startDate).getDate()<=15?false:true); 
    this.rtmisservice.GetAnnexureCDataSummary("AnnexureJsonData",this.filterState).subscribe((data: any) => {
      if(data!="" && data!=null){
        //if(data.finalizedata && this.blockstatus==false){}        
        //const fileWithUrl = new File([''], 'your-file.jpg', { type: 'image/jpeg' }); // Create a File object
        this.AnnexureCData = data; 
        this.isdataloaded=false;                 
      }
      else{
        if(this.filterState.SelDistrictID!="0"){
            this.LoadAnnexureCData();
        }
        else{
          this.isdataloaded=false;   
        }
      }   
    });			
  }
  nextDateRange:any;
  LoadAnnexureCData():void{
    this.AnnexureCData.SelMasterState=this.filterState.selStateName;
    this.AnnexureCData.SelMasterDistrict=this.filterState.selDistrictName;      
    debugger;
    var date1 = {
      startDate: this.filterState.selectedDateRange.startDate.clone(),
      endDate: this.filterState.selectedDateRange.endDate.clone()
    };
    this.nextDateRange= { startDate: date1.startDate.add(1, 'month'), endDate: date1.endDate.add(1, 'month') };
    this.AnnexureCData.ReportingPeriod = this.filterState.selectedDateRange.startDate?.format("DD MMMM YYYY")+"-"+this.filterState.selectedDateRange.endDate?.format("DD MMMM YYYY")
    combineLatest([
      this.rtmisservice.GetAnnexureCDataSummary("AnnexureCSummary",this.filterState),   
      this.rtmisservice.GetAnnexureCDataSummary("ProviderTypeSummary",this.filterState),
      this.rtmisservice.GetAnnexureCDataSummary("ChaupalSummary",this.filterState),
      this.rtmisservice.GetAnnexureCDataSummary("WSDataAC",this.filterState),
      this.rtmisservice.GetAnnexureCDataSummary("HNDSummary",this.filterState),
      this.rtmisservice.GetAnnexureCDataSummary("BastiSlum",this.filterState),
      this.rtmisservice.GetAnnexureCDataSummary("AttendanceSummary",this.filterState),
      this.rtmisservice.GetAnnexureCDataSummary("ActivitySummary",this.filterState),
      this.rtmisservice.GetAnnexureCDataSummary("SupportiveSupervision",this.filterState),
      this.rtmisservice.GetAnnexureCDataSummary("StaffSummary",this.filterState),
      this.rtmisservice.GetAnnexureCDataSummary("ProjectStaff",this.filterState),
      this.rtmisservice.GetCalendarDataSummary(this.filterState,this.nextDateRange)
    ]).subscribe({
      next: ([AnnexureCSummary, ProviderTypeSummary, ChaupalSummary,WSDataAC,HNDSummaryAC,SlumDetail,AttendanceSummary,ActivitySummary,SupportiveSupervision,StaffSummary,StaffDetail,PlanningDetail]) => {
        // Update component state with each service's data
        if (AnnexureCSummary.length>0){ this.AnnexureCData.AnnexureCSummary = AnnexureCSummary;}
        if (ProviderTypeSummary.length>0) this.AnnexureCData.ProviderTypeSummary = ProviderTypeSummary;
        if (ChaupalSummary.length>0) this.AnnexureCData.ChaupalSummary = ChaupalSummary;
        if (WSDataAC.length>0) this.AnnexureCData.WSDataAC = WSDataAC;
        if (HNDSummaryAC.length>0) this.AnnexureCData.HNDSummaryAC = HNDSummaryAC;
        if (SlumDetail.length>0) this.AnnexureCData.SlumDetail = SlumDetail;
        if (AttendanceSummary.length>0) this.AnnexureCData.AttendanceSummary = AttendanceSummary;
        if (ActivitySummary.length>0) this.AnnexureCData.ActivitySummary = ActivitySummary;
        if (SupportiveSupervision.length>0) this.AnnexureCData.SupportiveSupervision = SupportiveSupervision;
        if (StaffSummary.length>0) this.AnnexureCData.StaffSummary = StaffSummary;
        if (StaffDetail.length>0) this.AnnexureCData.StaffDetail = StaffDetail;
        if (PlanningDetail.length>0) {debugger;this.AnnexureCData.PlanningDetail = PlanningDetail;}

        // Hide loader when all have at least emitted once
        this.isdataloaded=false;
      }
    });
  }
  onFilesChange(files: File[]): void {
    //console.log('Selected files:', files);
  }
  SaveAnnexureCData():void{
    this.isdataloaded=true;
    var formdata = new FormData();
    formdata.append("UpdateID",this.AnnexureCData.UpdateID);
    formdata.append("indctr","AnnexureJsonData");
    formdata.append("NGOName",this.AnnexureCData.ACNameOfNGO);
    formdata.append("fromdate",this.filterState.selectedDateRange.startDate?.format("YYYY-MM-DD"));
    formdata.append("todate",this.filterState.selectedDateRange.endDate?.format("YYYY-MM-DD"));
    formdata.append("districtid",this.filterState.SelDistrictID);
    formdata.append("AnnexureCData",JSON.stringify(this.AnnexureCData));
    formdata.append("EnteredBy", this.userlogin.userid);    
    formdata.append("AppVersion", "1.0"); 
    this.http.post<any>(`https://rtmis.fhindia.org/api/ModalPostServices.php`, formdata, {
    }).subscribe(res => {  
      this.isdataloaded=false;
      if(res.success){
        this.AnnexureCData.finalizesave = true;
        this.messageService.add({ severity: 'success', summary: res.message, detail: res.dataid, sticky: true });           
      }
      else{
        this.messageService.add({ severity: 'warn', summary: res.message, detail: res.dataid, sticky: true }); 
      }
    }), (err:any) => {  
      this.isdataloaded=false;    
      console.log("Error Occured " + err);
    }
  }   
  @ViewChild('PdfDiv') PdfDiv!: ElementRef;
  finalizeAnnexureCData():void{
    if(this.AnnexureCData.finalize){
      var formdata = new FormData();
      formdata.append("UpdateID",this.AnnexureCData.UpdateID);
      formdata.append("indctr","AnnexureCFinalize");
      formdata.append("finalize",this.AnnexureCData.finalize);       
      formdata.append("EnteredBy", this.userlogin.userid);    
      formdata.append("AppVersion", "1.0"); 
      this.http.post<any>(`https://rtmis.fhindia.org/api/ModalPostServices.php`, formdata, {
      }).subscribe(res => {  
        if(res.success){            
          // const content = this.PdfDiv?.nativeElement.innerHTML;
          // const printWindow = window.open('', '', 'width=600,height=600');
          // printWindow?.document.open();
          // printWindow?.document.write('<html><head><title>Print</title></head><body>');
          // printWindow?.document.write(content);
          // printWindow?.document.write('</body></html>');
          // printWindow?.document.close();
          // printWindow?.print();
          // printWindow?.close();
          this.AnnexureCData.finalizesave = true;
          this.messageService.add({ severity: 'success', summary: res.message, detail: res.dataid, sticky: true });                       
          this.printDiv();
        }
        else{
          this.messageService.add({ severity: 'warn', summary: res.message, detail: res.dataid, sticky: true }); 
        }
      }), (err:any) => {      
        console.log("Error Occured " + err);
      }
    }
  }   
  printDiv() {
    let filename ='EMBED-Progress Report-'+this.filterState.SelDistrictID+'_'+this.filterState.selectedDateRange.startDate?.format("YYYYMM")+'.pdf';
    const content = this.PdfDiv.nativeElement.innerHTML;
    const printWindow = window.open('', '', 'width=600,height=600');

    printWindow?.document.open();
    printWindow?.document.write('<html><head><title>'+ filename +'</title>');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/bootstrap.min.css" rel="stylesheet" />');
    printWindow?.document.write('<link href="https://rtmis.fhindia.org/assets/css/components.min.css" rel="stylesheet" />');
    printWindow?.document.write(`<style>img{
      width: 280px;
      height: 280px;
    }            
    .cellheading{
      font-weight:bold;
      font-size:12pt;
    }                   
    .table td, .table th {
      font-size: 12px !important;
    }</style>`);
    printWindow?.document.write('</head><body onload="window.print();window.close()">');
    printWindow?.document.write('<div>'+content+'</div>');
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();

    printWindow?.print();
    // Wait for the user to finish printing, then close the print window   
    // setTimeout(() => {
    //   printWindow?.close();
    // }, 100);    
  }

  generatePDF(filename:any) {
    let jsPdf = new jsPDF('p', 'pt', 'a4');
    const element = this.el.nativeElement.querySelector('#PdfDiv');
    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt = {
        callback: function (jsPdf:any) {
            jsPdf.save(filename);
            // to open the generated PDF in browser window
            // window.open(jsPdf.output('bloburl'));
        },
        margin: [72, 72, 72, 72],
        autoPaging: 'text',
        html2canvas: {
            allowTaint: true,
            //dpi: 300,
            letterRendering: true,
            logging: false,
            scale: .8
        }
    };
    const customCss = `<link href="https://rtmis.fhindia.org/assets/css/components.min.css" rel="stylesheet" />`;
    const combinedHtml = customCss + element.innerHTML;
    jsPdf.html(combinedHtml, {
      callback: function (jsPdf:any) {
          jsPdf.save(filename);
          // to open the generated PDF in browser window
          // window.open(jsPdf.output('bloburl'));
      },
      margin: [72, 72, 72, 72],
      autoPaging: 'text',
      html2canvas: {
          allowTaint: true,
          //dpi: 300,
          letterRendering: true,
          logging: false,
          scale: .8
      }
    });
  }
}

