import { Directive, Input, OnInit } from '@angular/core';
import { FormControl, NgControl, ValidatorFn, Validators } from '@angular/forms';

@Directive({
  selector: '[appIncludeIf]',
})
export class IncludeIfDirective implements OnInit {
  @Input('appIncludeIf') condition!: boolean;

  private formControl!: FormControl;
  private isIncludeValidationCalled = false;
  
  constructor(private ngControl: NgControl) {}

  ngOnInit() {
    this.formControl = this.ngControl.control as FormControl;
    this.updateValidation();

    this.formControl.valueChanges.subscribe(() => {
      this.updateValidation();
    });
  }
  getngControl(): NgControl {
    return this.ngControl;
  }
  getFormControl(): FormControl {
    return this.formControl;
  }
  shouldInclude(): boolean {
    return this.condition;
  }

  private updateValidation() {
    if (!this.isIncludeValidationCalled) {
      this.isIncludeValidationCalled = true;
      if (this.shouldInclude()) {
        this.addValidators(this.formControl);
      } else {
        this.removeValidators(this.formControl);
      }

      this.formControl.updateValueAndValidity();
    }    
  }

  private addValidators(control: FormControl) {
    const existingValidators = control.validator ? [control.validator] : [];
    const validators: ValidatorFn[] = this.getValidators(existingValidators);

    control.setValidators(validators);
  }

  private removeValidators(control: FormControl) {
    control.clearValidators();
  }

  private getValidators(existingValidators: ValidatorFn[]): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if(this.ngControl?.control?.errors){
        if (this.ngControl?.control?.errors['required']) {
            validators.push(Validators.required);
        }
    }
    // Add any other validators here based on your conditions

    return [...existingValidators, ...validators];
  }
}
