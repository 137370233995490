import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export const AMCHARTS_SingleXYGraph = {
  SingleXYGraph(graphdivid:any, graphdata:any, graphtitle:any, graphCatgoryaxistitle:any, graphvalueaxistitle:any, axismin:any, axismax:any, imageshow:any) {
    let chartcap = am4core.create(graphdivid, am4charts.XYChart);
    chartcap.data = graphdata;
    //chartcap.padding(40, 40, 40, 40);
    let title = chartcap.titles.push(new am4core.Label());
    title.text = graphtitle;
    title.fontSize = 25;
    title.marginBottom = 25;
    title.align = "center";
    title.wrap = true;


    let xAxis = chartcap.xAxes.push(new am4charts.CategoryAxis());
    xAxis.renderer.grid.template.location = 0;
    xAxis.dataFields.category = "category";
    xAxis.renderer.minGridDistance = 40;
    if(xAxis.renderer.tooltip){
      xAxis.renderer.tooltip.label.maxWidth = 40;
      xAxis.renderer.tooltip.label.align = "center";
      xAxis.renderer.tooltip.label.wrap = false;
    }
    xAxis.renderer.inversed = false;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.title.text = graphCatgoryaxistitle;
    //xAxis.title.fontWeight = "bold";
    xAxis.title.fontSize = 18;
    xAxis.title.wrap = true;
    xAxis.title.truncate = false;
    xAxis.title.paddingBottom = 35;
    xAxis.title.align = "center";

    let valueAxisCap = chartcap.yAxes.push(new am4charts.ValueAxis());
    valueAxisCap.min = axismin;
    valueAxisCap.max = axismax;
    valueAxisCap.title.text = graphvalueaxistitle;
    valueAxisCap.title.fontSize = 16;
    valueAxisCap.title.wrap = true;
    valueAxisCap.title.truncate = false;

    let seriescap = chartcap.series.push(new am4charts.ColumnSeries());
    seriescap.dataFields.categoryX = "category";
    seriescap.dataFields.valueY = "value";
    //seriescap.tooltipText = "{valueY.value}"
    seriescap.columns.template.strokeOpacity = 0;
    seriescap.columns.template.column.cornerRadiusTopRight = 10;
    seriescap.columns.template.column.cornerRadiusTopLeft = 10;    
    seriescap.tooltipText = "Method ({categoryX}): [bold]{valueY}[/]";
    chartcap.maskBullets = false;
    chartcap.paddingTop = 25;
    var labelBullet = seriescap.bullets.push(new am4charts.LabelBullet());
    if (graphdivid == 'chartdivthrrec' || graphdivid == 'chartdivthrrec1' || graphdivid == 'chartdivaveragetime') {
      labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.0')}";
    }
    else if (graphdivid == 'chartdivc19week' || graphdivid == 'chartdivmodule') {
      labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#')}";
    }
    else { labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.0')}%"; }
    //labelBullet.fontWeight = "bold";
    labelBullet.dy = -7;
    //labelBullet.adapter.add("y", function (y) {
    //  return -15;
    //});
    if(graphdivid == 'chartmethodused'){
      let series = chartcap.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "valueTotal";
      series.dataFields.categoryX = "category";
      series.strokeWidth = 2;
      let circleBullet = series.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.stroke = am4core.color("#fff");
      circleBullet.circle.strokeWidth = 2;

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#')}";
      //labelBullet.label.text = "{value}";
      labelBullet.label.dy = -20;
      chartcap.legend = new am4charts.Legend();
      chartcap.legend.position="top";
      series.legendSettings.labelText = "[bold] Total Households : [/]";
      series.legendSettings.valueText = "{valueY.close}";
      //seriescap.legendSettings.labelText="";
      seriescap.hiddenInLegend=true;
      //seriescap.legendSettings.labelText = "[bold {categoryX}[/]";
      //seriescap.legendSettings.valueText = "{valueY.close}";
      //series.legendSettings.valueText = "Total Students : [bold]{valueY}[/]";
    }
    if (imageshow) {
      let image = new am4core.Image();
      image.horizontalCenter = "middle";
      image.width = 60;
      image.height = 140;
      image.verticalCenter = "bottom";
      image.dy = 20;
      image.y = am4core.percent(100);
      image.adapter.add("href", (href, target) => {
        if(target.dataItem){
          let category = target.dataItem.index + 1;
          if (category) {
            //if (category > 7) {
            //image.fillOpacity = 1;
            //image.opacity = 1;
            //image.strokeOpacity = 1;
            //image.disabled = true;
            //}
            return "../../../assets/gallery/M" + category + ".jpg";
          }
        }
        return href;
      })
      xAxis.dataItems.template.bullet = image;
    }
    var label = xAxis.renderer.labels.template;
    label.paddingTop = 20;
    label.truncate = false;
    label.wrap = true;
    label.fontSize = 14;
    //label.textAlign = "middle";
    //label.fontWeight = "bold";
    //label.tooltipText = "{category}";

    xAxis.events.on("sizechanged", function (ev) {
      let axis = ev.target;
      let cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      axis.renderer.labels.template.maxWidth = cellWidth;
    });
    chartcap.zoomOutButton.disabled = true;
    chartcap.exporting.menu = new am4core.ExportMenu();
    chartcap.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "PNG" },
        //{ "type": "json", "label": "JSON" },
        //{ "label": "Print", "type": "print" }
      ]
    }];
    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    seriescap.columns.template.adapter.add("fill", function (fill, target) {
      let cindex = 0;
      if(target.dataItem){
        cindex=target.dataItem.index;        
      }
      return chartcap.colors.getIndex(cindex);
      
    });
    chartcap.responsive.enabled = true;

  }
};

export const AMCHARTS_MultiAxisGraph = {

  LoadMultiAxisGraph(divhtml:any, charttitle:any, chartdata:any, stackbar1:any, stackbar2:any, stackbar3:any, stackbar4:any, stackbar5:any, maxyaxis:any, isdecimal:any, graphCatgoryaxistitle:any, graphcategoryaxistitlefont:any, yaxistitle:any) {
    let chart = am4core.create(divhtml, am4charts.XYChart);
    chart.colors.step = 2;

    // Title
    let title = chart.titles.push(new am4core.Label());
    
    title.text = charttitle;
    title.fontSize = 25;
    title.marginBottom = 15;
    title.align = "center";
    title.wrap = true;
    title.minWidth = 400;

    chart.legend = new am4charts.Legend()
    chart.legend.position = 'top';
    chart.legend.paddingBottom = 30;
    chart.legend.labels.template.maxWidth = 300;
    chart.legend.labels.template.wrap = true;

    var legendlabel = chart.legend.labels.template;
    legendlabel.truncate = false;
    legendlabel.wrap = true;
    //label.textAlign = "middle";
    //label.fontWeight = "bold";
    //label.tooltipText = "{category}";

    chart.events.on("sizechanged", function (ev) {
      let axis = ev.target;
      let cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      chart.legend.labels.template.maxWidth = cellWidth;
    });

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = 'category'
    //xAxis.renderer.cellStartLocation = 0.1
    //xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.minGridDistance = 30;
    xAxis.renderer.grid.template.location = 0;
    if(xAxis.renderer.tooltip){
      xAxis.renderer.tooltip.label.maxWidth = 50;
      xAxis.renderer.tooltip.label.align = "center";
      xAxis.renderer.tooltip.label.wrap = true;
    }
    xAxis.renderer.inversed = false;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.title.text = graphCatgoryaxistitle;
    //xAxis.title.fontWeight = "bold";
    xAxis.title.fontSize = 12;
    xAxis.title.wrap = true;
    xAxis.title.truncate = false;
    xAxis.title.paddingBottom = 35;
    xAxis.title.align = "right";

    var label = xAxis.renderer.labels.template;
    label.paddingTop = 20;
    label.truncate = false;
    label.wrap = true;
    label.fontSize = 14;

    xAxis.events.on("sizechanged", function (ev) {
      let axis = ev.target;
      let cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      axis.renderer.labels.template.maxWidth = cellWidth;
    });
    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.max = maxyaxis;
    yAxis.title.text = yaxistitle;
    yAxis.title.fontSize = 12;
    yAxis.title.wrap = true;
    yAxis.title.truncate = false;
    //if (divhtml == 'chartdivshgpartimodule' || divhtml == 'chartdivEWparti' || divhtml == 'chartdivdietryintake'
    //  || divhtml == 'chartdivifacalcons' || divhtml == 'chartdivthrrec' || divhtml == 'chartdivthrcons' || divhtml == 'chartdivcompfeed') {
    //  if (divhtml == 'chartdivEWparti') { yAxis.title.text = "Number of Eligible Women"; }
    //  else if (divhtml == 'chartdivdietryintake' || divhtml == 'chartdivthrcons' || divhtml == 'chartdivcompfeed') { yAxis.title.text = "Percentage"; }
    //  else if (divhtml == 'chartdivifacalcons' || divhtml == 'chartdivthrrec') { yAxis.title.text = "Average Number"; }
    //  else { yAxis.title.text = "Number of SHG Members"; }
    //  //xAxis.title.fontWeight = "bold";
    //  yAxis.title.text = yaxistitle;
    //  yAxis.title.fontSize = 16;
    //  yAxis.title.wrap = true;
    //  yAxis.title.truncate = false;
    //}
    function createSeries(value:any, name:any, displaypercent:any) {
      let series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = value
      series.dataFields.categoryX = 'category'
      series.name = name

      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);
      //chart.maskBullets = false;
      //chart.paddingTop = 25;
      //var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      //labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#')}%";
      //labelBullet.adapter.add("y", function (y) {
      //  return -15;
      //});

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      //if (divhtml == 'chartdivdietryintake' || divhtml == 'chartdivdietryintake1') {
        labelBullet.fontSize = 12;
      //}
      if (isdecimal)
        labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.0')}";
      else
        labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#')}";
      //labelBullet.fontWeight = "bold";
      labelBullet.dy = -7;
      return series;
    }

    chart.data = chartdata;

    if (stackbar1 != "")
      createSeries('first', stackbar1, '');
    if (stackbar2 != "")
      createSeries('second', stackbar2, 'numb');
    if (stackbar3 != "")
      createSeries('third', stackbar3, '');
    if (stackbar4 != "")
      createSeries('fourth', stackbar4, '');
    if (stackbar5 != "")
      createSeries('fifth', stackbar5, '');

    function arrangeColumns() {

      let series = chart.series.getIndex(0);

      let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if(series){
        if (series.dataItems.length > 1) {
          let xyitem =series.dataItems.getIndex(0);
          let xyitem1 =series.dataItems.getIndex(1);
          if(xyitem && xyitem1){
            let x0 = xAxis.getX(xyitem, "categoryX");
            let x1 = xAxis.getX(xyitem1, "categoryX");
            let delta = ((x1 - x0) / chart.series.length) * w;
            if (am4core.isNumber(delta)) {
              let middle = chart.series.length / 2;

              let newIndex = 0;
              chart.series.each(function (series) {
                if (!series.isHidden && !series.isHiding) {
                  series.dummyData = newIndex;
                  newIndex++;
                }
                else {
                  series.dummyData = chart.series.indexOf(series);
                }
              })
              let visibleCount = newIndex;
              let newMiddle = visibleCount / 2;

              chart.series.each(function (series) {
                let trueIndex = chart.series.indexOf(series);
                let newIndex = series.dummyData;

                let dx = (newIndex - trueIndex + middle - newMiddle) * delta

                series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
              })
            }
          }
        }
      }
    }	
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "PNG" },
        //{ "type": "json", "label": "JSON" },
        //{ "label": "Print", "type": "print" }
      ]
    }];
    chart.responsive.enabled = true;
	chart.validateData();
	return chart;
  }
};


export const AMCHARTS_LineGraph = {

  Linegraph(divid:any, data:any, titile:any, xtitle:any, maxyaxis:any, ispercent:any, valaxistitle:any) {
    // Create chart instance
    let chart = am4core.create(divid, am4charts.XYChart);
    chart.paddingRight = 20;
    let title = chart.titles.push(new am4core.Label());
    title.text = titile;
    title.fontSize = 25;
    title.marginBottom = 25;
    title.align = "center";
    title.wrap = true;

    // Add data
    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.minGridDistance = 50;
    //categoryAxis.renderer.grid.template.location = 0.5;
    //categoryAxis.startLocation = 0.5;
    //categoryAxis.endLocation = 0.5;
    categoryAxis.title.text = xtitle;
    //categoryAxis.title.fontWeight = "bold";
    categoryAxis.title.fontSize = 16;
    categoryAxis.title.wrap = true;
    categoryAxis.title.truncate = false;
    categoryAxis.title.paddingBottom = 35;
    categoryAxis.title.align = "right";
    //categoryAxis.title.textAlign = "middle";

    var label1 = categoryAxis.renderer.labels.template;
    label1.paddingTop = 20;
    label1.textAlign = "middle";
    label1.truncate = false;
    label1.wrap = true;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.baseValue = 0;
    valueAxis.min = 0;
    valueAxis.max = maxyaxis;
    valueAxis.title.text = valaxistitle;
    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "year";
    series.strokeWidth = 2;
    //series.tensionX = 0.77;
    let circleBullet = series.bullets.push(new am4charts.CircleBullet());
    circleBullet.circle.stroke = am4core.color("#fff");
    circleBullet.circle.strokeWidth = 2;

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.0')}" + ispercent;
    //labelBullet.label.text = "{value}";
    labelBullet.label.dy = -20;
    chart.cursor = new am4charts.XYCursor();
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "PNG" },
        //{ "type": "json", "label": "JSON" },
        //{ "label": "Print", "type": "print" }
      ]
    }];
    chart.responsive.enabled = true;
  }
};



export const AMCHARTS_MultiLineGraph = {

  MultiLinegraph(divid:any, data:any, stackbar1:any, stackbar2:any, stackbar3:any, stackbar4:any, stackbar5:any, title:any, xtitle:any, maxyaxis:any, ispercent:any, valaxistitle:any) {
    // Create chart instance
    // Create chart instance
    let chart1 = am4core.create(divid, am4charts.XYChart);
    chart1.legend = new am4charts.Legend();
    chart1.paddingRight = 20;
    // Add data
    chart1.data = data;

    // Create axes
    let categoryAxis1 = chart1.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis1.dataFields.category = "year";
    categoryAxis1.renderer.minGridDistance = 50;
    //categoryAxis1.renderer.grid.template.location = 0.5;
    //categoryAxis1.startLocation = 0.5;
    //categoryAxis1.endLocation = 0.5;
    categoryAxis1.title.text = xtitle;
    //categoryAxis1.title.fontWeight = "bold";
    categoryAxis1.title.fontSize = 16;
    categoryAxis1.title.align = "right";
    categoryAxis1.title.textAlign = "middle";
    categoryAxis1.title.wrap = true;
    categoryAxis1.title.truncate = false;
    categoryAxis1.title.paddingBottom = 35;

    var label = categoryAxis1.renderer.labels.template;
    label.paddingTop = 20;
    label.textAlign = "middle";
    label.truncate = false;
    label.wrap = true;

    // Create value axis
    let valueAxis1 = chart1.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.baseValue = 0;
    valueAxis1.title.text = "Percentage";
    valueAxis1.min = 0;
    valueAxis1.max = maxyaxis;
    // Create series           
    //series1.tensionX = 0.77;
    if (stackbar1 != "") {
      let series1 = chart1.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = "first";
      series1.dataFields.categoryX = "year";
      series1.strokeWidth = 2;
      series1.name = stackbar1;
      let circleBullet1 = series1.bullets.push(new am4charts.CircleBullet()); circleBullet1.circle.stroke = am4core.color("#fff"); circleBullet1.circle.strokeWidth = 2;
      let labelBullet1 = series1.bullets.push(new am4charts.LabelBullet());
    //labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.0')}" + ispercent;
      labelBullet1.label.text = "{first.formatNumber('#.0')}";
      labelBullet1.label.dy = -10;
    }
    if (stackbar2 != "") {
      let series2 = chart1.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = "second";
      series2.dataFields.categoryX = "year";
      series2.strokeWidth = 2;
      series2.name = stackbar2;
      let circleBullet2 = series2.bullets.push(new am4charts.CircleBullet()); circleBullet2.circle.stroke = am4core.color("#fff"); circleBullet2.circle.strokeWidth = 2;
      let labelBullet2 = series2.bullets.push(new am4charts.LabelBullet());
      labelBullet2.label.text = "{second.formatNumber('#.0')}";
      labelBullet2.label.dy = 10;
    }
    if (stackbar3 != "") {
      let series3 = chart1.series.push(new am4charts.LineSeries());
      series3.dataFields.valueY = "third";
      series3.dataFields.categoryX = "year";
      series3.strokeWidth = 2;
      series3.name = stackbar3;
      let circleBullet3 = series3.bullets.push(new am4charts.CircleBullet()); circleBullet3.circle.stroke = am4core.color("#fff"); circleBullet3.circle.strokeWidth = 2;
      let labelBullet3 = series3.bullets.push(new am4charts.LabelBullet());
      labelBullet3.label.text = "{third.formatNumber('#.0')}";
      labelBullet3.label.dy = -10;
    }
	if (stackbar4 != "") {
      let series4 = chart1.series.push(new am4charts.LineSeries());
      series4.dataFields.valueY = "fourth";
      series4.dataFields.categoryX = "year";
      series4.strokeWidth = 2;
      series4.name = stackbar4;
      let circleBullet4 = series4.bullets.push(new am4charts.CircleBullet()); 
	  circleBullet4.circle.stroke = am4core.color("#fff"); circleBullet4.circle.strokeWidth = 2;
      let labelBullet4 = series4.bullets.push(new am4charts.LabelBullet());
      labelBullet4.label.text = "{fourth.formatNumber('#.0')}";
      labelBullet4.label.dy = -10;
    }
	if (stackbar5 != "") {
      let series5 = chart1.series.push(new am4charts.LineSeries());
      series5.dataFields.valueY = "fifth";
      series5.dataFields.categoryX = "year";
      series5.strokeWidth = 2;
      series5.name = stackbar5;
      let circleBullet5 = series5.bullets.push(new am4charts.CircleBullet()); 
	  circleBullet5.circle.stroke = am4core.color("#fff"); circleBullet5.circle.strokeWidth = 2;
      let labelBullet5 = series5.bullets.push(new am4charts.LabelBullet());
      labelBullet5.label.text = "{fifth.formatNumber('#.0')}";
      labelBullet5.label.dy = -10;
    }
    chart1.exporting.menu = new am4core.ExportMenu();
    chart1.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "PNG" },
        //{ "type": "json", "label": "JSON" },
        //{ "label": "Print", "type": "print" }
      ]
    }];
    chart1.cursor = new am4charts.XYCursor();
    chart1.responsive.enabled = true;
  }
};

export const AMCHARTS_GroupedBarGraph = {
  GroupedBarGraph(divid:any, data:any, finyear:any, axismax:any, graphvalueaxistitle:any, valaxis:any, stackbar5:any, charttitle:any, xtitle:any, maxyaxis:any, ispercent:any, valaxistitle:any):any{
    // Create chart instance
    am4core.options.animationsEnabled = false;
    let chart = am4core.create(divid, am4charts.XYChart);
    let title = chart.titles.push(new am4core.Label());
    title.text = charttitle;
    title.fontSize = 25;
    title.marginBottom = 25;
    title.align = "center";
    title.wrap = true;
    // some extra padding for range labels
    chart.paddingBottom = 50;

    chart.cursor = new am4charts.XYCursor();
    chart.scrollbarX = new am4core.Scrollbar();
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "PNG" },
        //{ "type": "json", "label": "JSON" },
        //{ "label": "Print", "type": "print" }
      ]
    }];
    chart.responsive.enabled = true;
    let label = chart.plotContainer.createChild(am4core.Label);
    label.text = "Financial Year : "+finyear;
    label.fontWeight='500';
    label.x = 5;
    label.y = 5;
    // will use this to store colors of the same items
    let colors = {};

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 15;
    //categoryAxis.renderer.inside=true;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.grid.template.strokeDasharray = "1,3";
    categoryAxis.renderer.labels.template.rotation = -90;
    categoryAxis.renderer.labels.template.horizontalCenter = "left";
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.maxWidth=150;
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.labels.template.tooltipText="{category}";

    categoryAxis.renderer.labels.template.adapter.add("dx", function(dx, target) {
        return -target.maxRight / 2;
    })
    categoryAxis.dataItems.template.text = "{realName}";
    categoryAxis.adapter.add("tooltipText", function(tooltipText, target){
      return categoryAxis.tooltipDataItem.dataContext['realName'];
    })

    // categoryAxis.dataFields.category = "category";
    // categoryAxis.renderer.minGridDistance = 60;
    // categoryAxis.renderer.grid.template.location = 0;
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //valueAxis.tooltip.disabled = true;
    valueAxis.min = 0;
    if(axismax!=0){
      valueAxis.max = axismax;
    }
    valueAxis.title.text = graphvalueaxistitle;
    // single column series for all data
    let columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.columns.template.width = am4core.percent(20);
    if(ispercent){
    columnSeries.tooltipText = "{provider}: {realName}, {valueY.formatNumber('#.0')}%";
    }
    else{
    columnSeries.tooltipText = "{provider}: {realName}, {valueY.formatNumber('#')}";
    }
    columnSeries.dataFields.categoryX = "category";
    columnSeries.dataFields.valueY = "value";

    columnSeries.sequencedInterpolation = true;
    //columnSeries.fillOpacity = 0;
    columnSeries.strokeOpacity = 1;
    //columnSeries.strokeDasharray = "1,3";
    columnSeries.columns.template.width = 10;
    //columnSeries.tooltip.pointerOrientation = "horizontal";
    //let bullet = columnSeries.bullets.create(am4charts.CircleBullet);

    // second value axis for quantity
    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.opposite = true;
    valueAxis2.syncWithAxis = valueAxis;
    //valueAxis2.tooltip.disabled = true;

    // quantity line series
    let lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.tooltipText = "Total Survey : {valueY}";
    lineSeries.dataFields.categoryX = "category";
    lineSeries.dataFields.valueY = "quantity";
    lineSeries.yAxis = valueAxis2;
    lineSeries.bullets.push(new am4charts.CircleBullet());
    lineSeries.stroke = chart.colors.getIndex(13);
    lineSeries.fill = lineSeries.stroke;
    lineSeries.strokeWidth = 2;
    lineSeries.snapTooltip = true;

    // when data validated, adjust location of data item based on count
    lineSeries.events.on("datavalidated", function(){
    lineSeries.dataItems.each(function(dataItem:any){
      // if count divides by two, location is 0 (on the grid)
      if(dataItem.dataContext.count / 2 == Math.round(dataItem.dataContext.count / 2)){
      dataItem.setLocation("categoryX", 0);
      }
      // otherwise location is 0.5 (middle)
      else{
        dataItem.setLocation("categoryX", 0.5);
      }
    })
    })

    // fill adapter, here we save color value to colors object so that each time the item has the same name, the same color is used
    columnSeries.columns.template.adapter.add("fill", function(fill, target) {
    let name = target.dataItem?.dataContext['realName'];
    if (!colors[name]) {
      colors[name] = chart.colors.next();
    }
    target.stroke = colors[name];
    return colors[name];
    })


    let rangeTemplate = categoryAxis.axisRanges.template;
    rangeTemplate.tick.disabled = false;
    rangeTemplate.tick.location = 0;
    rangeTemplate.tick.strokeOpacity = 0.6;
    rangeTemplate.tick.length = 60;
    rangeTemplate.grid.strokeOpacity = 0.5;
    rangeTemplate.label.tooltip = new am4core.Tooltip();
    rangeTemplate.label.tooltip.dy = -10;
    rangeTemplate.label.cloneTooltip = false;

    ///// DATA
    let chartData :any[]= [];
    let lineSeriesData = [];
    // process data ant prepare it for the chart
    for (var providerName in data) {
    let providerData = data[providerName];

    // add data of one provider to temp array
    let tempArray :any[]= [];
    let count = 0;
    // add items
    for (var itemName in providerData) {
      if(itemName != "quantity"){
      count++;
      // we generate unique category for each column (providerName + "_" + itemName) and store realName
      tempArray.push({ category: providerName + "_" + itemName, realName: itemName, value: providerData[itemName], provider: providerName})
      }
    }
    // sort temp array
    // tempArray.sort(function(a, b) {
    //   if (a.value > b.value) {
    //   return 1;
    //   }
    //   else if (a.value < b.value) {
    //   return -1
    //   }
    //   else {
    //   return 0;
    //   }
    // })

    // add quantity and count to middle data item (line series uses it)
    let lineSeriesDataIndex = Math.floor(count / 2);
    tempArray[lineSeriesDataIndex].quantity = providerData.quantity;
    tempArray[lineSeriesDataIndex].count = count;
    // push to the final data
    am4core.array.each(tempArray, function(item) {
      chartData.push(item);
    })

    // create range (the additional label at the bottom)
    let range = categoryAxis.axisRanges.create();
    range.category = tempArray[0].category;
    range.endCategory = tempArray[tempArray.length - 1].category;
    range.label.text = tempArray[0].provider;
    range.label.dy = 30;
    range.label.truncate = true;
    range.label.fontWeight = "bold";
    range.label.tooltipText = tempArray[0].provider;

    range.label.adapter.add("maxWidth", function(maxWidth, target){
      let range = target.dataItem;
      let startPosition = categoryAxis.categoryToPosition(range['category'], 0);
      let endPosition = categoryAxis.categoryToPosition(range['endCategory'], 1);
      let startX = categoryAxis.positionToCoordinate(startPosition);
      let endX = categoryAxis.positionToCoordinate(endPosition);
      return endX - startX;
    })
    }

    chart.data = chartData;


    // last tick
    let range = categoryAxis.axisRanges.create();
    range.category = chart.data[chart.data.length - 1].category;
    range.label.disabled = true;
    range.tick.location = 1;
    range.grid.location = 1;
    return chart;
  }
};

