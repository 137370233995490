import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filter',
})
export class FilterPipe implements PipeTransform {
    transform(objects: any[], filterString: any, property: string): any[] {       
        if(objects) {
            if (objects.length === 0) {
                return objects;
            }
            let filtereddata: any[] = [];
            for (let data of objects) {
                if (data[property] == filterString || data[property] == undefined) {
                    filtereddata.push(data);
                }
            }
            
            return filtereddata;
        }
        return objects;
    }

}