import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {
  transform(items: any[], limit: number): any[] {
    if (!items || !items.length) {
      return [];
    }

    return items.slice(0, limit);
  }
}
