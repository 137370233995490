import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://rtmis.fhindia.org/api/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
	  //return this.http.post(loginUrl, { username, password });
    return this.http.post(
      AUTH_API+"authservice.php",
      {
        username,
        password,
      },
      httpOptions
    );
  }
  GetPermission(logintype:any): Observable<any> {  	
    return this.http.get(AUTH_API+'DashboardSummary.php?AppVersion=2&indctr=PermissionSummary&methodtype=GETTING&logintype='+logintype,      
     httpOptions
     );  
  }
 
  logout(): Observable<any> {
    return this.http.post(AUTH_API + 'signout', { }, httpOptions);
  }
}
