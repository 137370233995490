import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  form1!: FormGroup;
  loading = false;
  submitted = false;

  constructor(private authService: AuthService, private storageService: StorageService, private router: Router,
    private formBuilder: FormBuilder,
        private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });
    if (this.storageService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.roles = this.storageService.getUser().logintype;
    }
  }
// convenience getter for easy access to form fields
get f() { return this.form.controls; }

  onSubmit(): void {
    this.submitted = true;

    // reset alert on submit
    this.errorMessage = '';

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }
    this.loading = true;
    this.authService.login(this.f.username.value, this.f.password.value).subscribe({
      next: (data: any) => {
        this.storageService.saveUser(data.token);
        this.authService.GetPermission(this.storageService.getUser().logintype).subscribe((data1: [{}]) => {
          if(data1.length>0){
            this.storageService.savePermission(data1);
          }           
        });
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.router.navigate(['/dash/home']);
        //this.reloadPage();
      },
      error: (err: any) => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        this.loading = false;
      }
    });
  }

  reloadPage(): void {
    window.location.reload();
  }
}
