<div class="panel">
  <div class="panel-heading">
        <div class="row">
            <div class='col-md-6'>
                <div class='form-group'>
                  <label for="stafftype">Project Staff Type</label>
                  <select class='form-control' id="stafftype" [(ngModel)]="selectedprojectStaffTypeID" (change)="loadProjectStaff()">
                    <option value="0">Select Project Staff Type</option>
                    <option *ngFor="let stafftype of masterProjectStaffType" [value]="stafftype.ProjectStaffType_id">
                      {{ stafftype.ProjectStaffType_Name }}
                    </option>
                  </select>
                  <div class="font-red"
                       *ngIf="selectedprojectStaffTypeID=='0'">
                    Please Select Project Staff Type
                  </div>
                </div>
              </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="label">Project Staff:</label>
                    <select class='form-control' [(ngModel)]="selectedprojectstaff" name="lblprojectstaff" id="lblprojectstaff">
                        <option value="0">Select Project Staff Type</option>
                        <option *ngFor="let projectstaff of masterprojectstaff" [value]="projectstaff.ID">
                            {{projectstaff.DataName}}
                        </option>
                    </select>
                    <div class="font-red" *ngIf="selectedprojectstaff=='0'">
                        Please Select Project Staff.
                    </div>
                </div>
            </div>
            <div class="col-md-6" style="display: none;">
                <div class="form-group">
                    <label for="to_email">To Email ID:</label>
                    <input class='form-control' type="email" [(ngModel)]="toEmail" name="to_email" id="to_email"/>                        
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="cc_email">CC Email ID:</label>
                    <input class='form-control' type="email" [(ngModel)]="ccEmail" name="cc_email" id="cc_email"/>                                                   
                </div>
            </div>
            <div class="col-md-6" style="display: none;">
                <div class="form-group">
                    <label for="remarks">Remarks:</label>
                    <textarea class='form-control' id="remarks"  [(ngModel)]="remarks" rows="5"></textarea>                                                 
                </div>
            </div>    
            <div class="col-md-12">
                <button type="submit" class="btn btn-primary" (click)="sendEmail()">Send Email</button>
            </div> 
        </div>                                       
    </div>
</div>
