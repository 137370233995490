<div class="row"  *ngIf="ActivityData != undefined" #PdfDiv id="PdfDiv">
    <div class="col-md-12">
        <table  class="table" style="width:700px;color:black;" id="dvprintActivity">
          <tbody>
            <tr>
              <td colspan="6" style="text-align:center;font-weight:bold;"><h4 style="font-weight:bold;">EMBED - {{ActivityData.District_Name}}</h4></td>
            </tr>
            <tr>
              <td colspan="6" style="text-align:center;font-weight:bold;"><h4 style="font-weight:bold;">{{ActivityData.ActivityType}}</h4></td>
            </tr>
            <tr>
              <td colspan="6" style="text-align:left;"><b>Event detail:</b></td>
            </tr>
            <tr>
              <td colspan="2" style="text-align:left;"><b>Subject:</b></td>
              <td colspan="4">{{ActivityData.Activity_Name}}</td>
            </tr>
            <tr>
              <td colspan="2" style="text-align:left;"><b>Date:</b></td>
              <td colspan="4">{{ActivityData.Activity_Date | date:'dd-MM-yyyy'}}</td>
            </tr>
            <tr>
              <td colspan="2" style="text-align:left;"><b>Venue:</b></td>
              <td colspan="4">{{ActivityData.Venue}}</td>
            </tr>
            <tr>
              <td colspan="2" style="text-align:left;"><b>Time:</b></td>
              <td colspan="4">{{ActivityData.ActivityTime}}</td>
            </tr>
            <tr>
              <td colspan="2" style="text-align:left;"><b>Facilitator:</b></td>
              <td colspan="4">{{ActivityData.ConductedBy}}</td>
            </tr>
            <tr>
              <td colspan="2" style="text-align:left;"><b>No.of Participants:</b></td>
              <td colspan="4">{{ActivityData.No_of_Participants}}</td>
            </tr>
            <tr>
              <td colspan="2" style="text-align:left;"><b>Participants:</b></td>
              <td colspan="4">{{ActivityData.Name_of_Participants}}</td>
            </tr>
            <tr>
              <td colspan="6" style="text-align:left;"><b>Background:</b></td>
            </tr>
            <tr>
              <td colspan="6">
                  {{ActivityData.Background}}
              </td>
            </tr>
            <tr>
              <td colspan="6" style="text-align:left;"><b>Objectives of training program:</b></td>
            </tr>
            <tr>
              <td colspan="6">
                  {{ActivityData.Objectives}}
              </td>
            </tr>
            <tr>
              <td colspan="6" style="text-align:left;"><b>Process:</b></td>
            </tr>
            <tr>
              <td colspan="6">
                  {{ActivityData.Process}}
              </td>
            </tr>
            <tr>
              <td colspan="6" style="text-align:left;"><b>Outcome:</b></td>
            </tr>
            <tr>
              <td colspan="6">
                  {{ActivityData.Outcome}}
              </td>
            </tr>
            <tr>
              <td colspan="6" style="text-align:left;"><b>Photographs:</b></td>
            </tr>
            <tr>
              <td colspan="3">
                <div [style.backgroundImage]="'url(' + backgroundImageUrl(ActivityData.PhotoPath_1) + ')'"
                style="background-size: contain; background-repeat: no-repeat;width:250px;height:200px;max-width: 250px; max-height: 200px;"
                width="220px"
                height="250px"
                border="0"
                id="layoutimg1"></div>
              </td>
              <td colspan="3">
                <div [style.backgroundImage]="'url(' + backgroundImageUrl(ActivityData.PhotoPath_2) + ')'"
                style="background-size: contain; background-repeat: no-repeat;width:250px;height:200px;max-width: 250px; max-height: 200px;"
                width="220px"
                height="250px"
                border="0"
                id="layoutimg2"></div>
              </td>
            </tr>
            <!-- <tr>
              <td colspan="3"><img src="{{ActivityData.PhotoPath_1}}" width="320px" height="250px" border="0" /></td>
              <td colspan="3"><img src="{{ActivityData.PhotoPath_2}}" width="320px" height="250px" border="0" id="layoutimg2" /></td>
            </tr> -->
            <tr>
              <td colspan="6" style="page-break-before:always !important;text-align:left !important;"><b>Participants:</b></td>
            </tr>
            <tr>
              <td colspan="2">
                <div [style.backgroundImage]="'url(' + backgroundImageUrl(ActivityData.AttendancePath_1) + ')'"
                style="background-size: contain; background-repeat: no-repeat; width:250px;height:200px;max-width: 250px; max-height: 200px;"
                width="220px"
                height="250px"
                border="0"
                id="layoutimg4"></div>
              </td>
              <td colspan="2">
                <div [style.backgroundImage]="'url(' + backgroundImageUrl(ActivityData.AttendancePath_2) + ')'"
                style="background-size: contain; background-repeat: no-repeat; width:250px;height:200px;max-width: 250px; max-height: 200px;"
                width="220px"
                height="250px"
                border="0"
                id="layoutimg5"></div>
              </td>
              <td colspan="2">
                <div [style.backgroundImage]="'url(' + backgroundImageUrl(ActivityData.AttendancePath_3) + ')'"
                style="background-size: contain; background-repeat: no-repeat;width:250px;height:200px;max-width: 250px; max-height: 200px;"
                width="220px"
                height="250px"
                border="0"
                id="layoutimg6"></div>
              </td>
            </tr>
            <!-- <tr>
              <td colspan="2"><img src="{{ActivityData.AttendancePath_1}}" width="220px" height="250px" border="0" id="layoutimg4" /></td>
              <td colspan="2"><img src="{{ActivityData.AttendancePath_2}}" width="220px" height="250px" border="0" id="layoutimg5" /></td>
              <td colspan="2"><img src="{{ActivityData.AttendancePath_3}}" width="220px" height="250px" border="0" id="layoutimg6" /></td>
            </tr> -->
          </tbody>
        </table>
    </div>
</div>
<div class='row'>    
  <div class='col-md-6'>
      <div class='form-group'>
          <a class="btn btn-warning btn-block" (click)="generatePDF();">Download PDF</a>
      </div>
  </div>
</div>
<!-- <div class='row'>
    <div class='col-md-6'>
        <div class='form-group'>
            <a class="btn btn-warning btn-block" ng-click="saveImage();" ng-show="modalheading=='Slum' || modalheading=='Activity'">Download Image</a>
        </div>
    </div>
    <div class='col-md-6'>
        <div class='form-group'>
            <a class="btn btn-warning btn-block" ng-click="downloadpdf();" ng-show="modalheading=='Slum' || modalheading=='Activity'">Download PDF</a>
        </div>
    </div>
</div> -->