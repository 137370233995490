<div class="row mt-3 g-1">
    <h5>Profile of the Respondents</h5>    
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartRespondentGrowth" style="width: 100%; height: 500px"></div></div>
</div> 
<div class="row mt-3 g-1">
    <h5>Knowledge and Awareness</h5>    
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="charteverheard" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">    
    <div class="col-md-12"><div id="chartdenguesympton" style="width: 100%; height: 500px"></div></div>
</div> 
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartmalariasympton" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">    
    <div class="col-md-12"><div id="chartdenguecause" style="width: 100%; height: 500px"></div></div>
</div> 
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartmalariacause" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartdengueprevented" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartmalariaprevented" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <h5>Practices</h5>    
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartfamilypractanymethod" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartmethodofprotect" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <h5>Health seeking behaviour</h5>    
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartfamilyhighfewer" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartvisittohcp" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="charttypeofhcp" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartbloodtestconduct" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="charttreatmentopted" style="width: 100%; height: 500px"></div></div>
</div>
<div class="row mt-3 g-1">
    <div class="col-md-12"><div id="chartheardseenmessage" style="width: 100%; height: 500px"></div></div>
</div>
