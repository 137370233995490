<div class="file-upload">
    <div class="row"> 
        <div class="col-md-11" style="border-radius: 7px;border: 2px dashed #DDD;margin-left: 10px;min-height: 120px;">
            <div class="file-input-frame" *ngFor="let file of selectedFiles; let i = index" >
                <div class="file-input-x" (click)="removeFile(file)" role="button" tabindex="0">×</div>
                <ng-container *ngIf="isImage(file)">
                    <img [src]="file.thumbnailUrl" alt="Thumbnail">
                </ng-container>
                <ng-container *ngIf="isDocument(file)">
                    <img [src]="file.thumbnailUrl" alt="Thumbnail">
                </ng-container>
                <ng-container *ngIf="isVideo(file)">
                <video controls>
                    <source [src]="file.thumbnailUrl" [type]="file.type">
                    Your browser does not support the video tag.
                </video>
                </ng-container>
                <ng-container *ngIf="isAudio(file)">
                <audio controls>
                    <source [src]="file.thumbnailUrl" [type]="file.type">
                    Your browser does not support the audio tag.
                </audio>
                </ng-container>
                <div class="file-input-frame-footer">
                    <div class="file-input-frame-caption" *ngIf="file.name">{{ file.name }} ({{ formatFileSize(file.size) }})</div>
                    <div class="file-input-frame-caption" *ngIf="captionhidestatus"><input type="text" style="width:400px;" [(ngModel)]="file.caption" class="form-control" placeholder="Caption" maxlength="50" /></div>
                    <div class="file-input-frame-caption" *ngIf="datehidestatus"><input type="text" style="width:400px;" [(ngModel)]="file.imgdate" class="form-control" placeholder="Date"/></div>
                    <div class="file-input-frame-caption" *ngIf="extrahidestatus"><input type="text" style="width:400px;" [(ngModel)]="file.extradata" class="form-control" [placeholder]="extraheading" maxlength="100" /></div>
                </div>
            </div>        
        </div>
        <div class="" class="drop-area col-md-11" (dragover)="onDragOver($event)" (drop)="onDrop($event)" style="border-radius: 5px;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);margin-left: 10px;display: flex;flex-direction: row-reverse;">
            <div class="file-input-caption layout-align-start-center layout-row flex" layout="row" layout-align="start center">                       
                <div class="input-caption-text flex">
                    <span *ngIf="selectedFiles.length>0">{{selectedFiles.length}} files selected</span>     
                    <button class="btn btn-primary" (click)="triggerFileInput()">Select Files</button>   
                </div>
                <input #fileInput type="file" [accept]="accept" (change)="onFileChange($event)" style="display: none;">
            </div>            
        </div>        
    </div>
</div>