import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { FHIRtmisService } from '../services/fhirtmis.service';
import { StorageService } from 'src/app/pages/login/storage.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FilterService } from '../services/filter.service';
import { ThisReceiver } from '@angular/compiler';
import tr from 'date-fns/esm/locale/tr/index.js';

@Component({
    //moduleId: module.id,
    selector: 'masterfilter-cmp',
    templateUrl: 'masterfilter.component.html',    
  styleUrls: ['./masterfilter.component.css'],
	providers: [DatePipe],
  encapsulation:ViewEncapsulation.None
})

export class MasterFilterComponent{
  customrangeshow:boolean=true;
  shocalendaralways:boolean=true;
  curdate: any;
  curpageurl:any='';
	myDate = new Date();
  lstState:any=[];lstDistrict:any=[];lstMunicipalArea:any=[];lstBCCFZone:any=[];lstTerritorialArea:any=[];lstAdministratorArea:any=[];
  SelStateID:any="0";SelDistrictID:any="0";SelMunicipalAreaID:any="0";SelBCCFZoneID:any="0";SelTerritorialAreaID:any="0";SelAdministratorAreaID:any="0";
  ranges:any;
  userlogin:any;
  lstAdministrativeType:any[]=[];
	constructor(private datePipe: DatePipe,private rtmisservice:FHIRtmisService, private storageService: StorageService
    , private router: Router,private filterService: FilterService,private actroute: ActivatedRoute,) {        
    this.userlogin=this.storageService.getUser();
    if(this.userlogin){
      this.curdate = this.datePipe.transform(this.myDate, 'dd MMM yyyy');	      
      this.lstAdministrativeType.push({"AdministrativeID":"INTENSIVE","AdministrativeName":"INTENSIVE"});
      this.lstAdministrativeType.push({"AdministrativeID":"SCALEUP","AdministrativeName":"SCALEUP"});
      this.lstAdministrativeType.push({"AdministrativeID":"CIVIC","AdministrativeName":"CIVIC"});
      this.lstAdministrativeType.push({"AdministrativeID":"PHASE OUT","AdministrativeName":"PHASE OUT"});
      this.getStates();
      router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {   
          this.curpageurl=val.urlAfterRedirects;
          this.LoadDateRange(val.urlAfterRedirects);
          this.updateFilterValue("selectedDateRange",this.selectedDateRange,false);   
        }
       
     });
      
    }
    else{
      this.router.navigate(['/login']);
    }
  }
  ddlMultiSlumTypeSettings={};
  selectedAdministrativeType:any;
  ngOnInit() {
    this.ddlMultiSlumTypeSettings = {
      singleSelection: false,
      idField: 'AdministrativeID',
      textField: 'AdministrativeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }; 
    this.selectedAdministrativeType = this.lstAdministrativeType;   
  }  
  onLoad(){
    this.updateFilterValue('SelAdministrativeAreaType', this.selectedAdministrativeType,true);
  }
  updateFilterValue(controlName: string, value: string,serviceinitalize:boolean): void {
    if(controlName=="SelStateID"){
      this.filterService.setFilterValue("SelDistrictID", "0",false);
    }
    if(controlName=="SelStateID" || controlName=="SelDistrictID"){
      this.filterService.setFilterValue("SelMunicipalAreaID", "0",false);
    }
    if(controlName=="SelStateID" || controlName=="SelDistrictID" || controlName=="SelMunicipalAreaID"){
      this.filterService.setFilterValue("SelBCCFZoneID", "0",false);
    }
    if(controlName=="SelStateID" || controlName=="SelDistrictID" || controlName=="SelMunicipalAreaID" || controlName=="SelBCCFZoneID"){
      this.filterService.setFilterValue("SelTerritorialAreaID", "0",false);
    }
    if(controlName=="SelStateID" || controlName=="SelDistrictID" || controlName=="SelMunicipalAreaID" || controlName=="SelBCCFZoneID" || controlName=="SelTerritorialAreaID"){
      this.filterService.setFilterValue("SelAdministratorAreaID", "0",false);
    }
    this.filterService.setFilterValue(controlName, value,serviceinitalize);
  }
  getStates(): void {
    this.rtmisservice.GetMasterData('State', 'main', '1', 'stateid', (this.CheckLoginType('Project') || this.CheckLoginType('State'))?this.userlogin.State_id:'0').subscribe((data) => {      
      this.lstState = data;
      if(this.CheckLoginType("State")){
        this.SelStateID=this.lstState[0].State_id;
        if(this.userlogin.logintype=='State'){
          this.updateFilterValue("SelStateID",this.SelStateID,true);
        }
        else{
          this.updateFilterValue("SelStateID",this.SelStateID,false);
        }
        this.getDistricts();
      }
      else{
        if(this.userlogin.logintype=='Admin' || this.userlogin.logintype=='Project'){
          this.updateFilterValue("isButtonClick",'',true);
        }
      }      
    });
  }
  
  getDistricts(): void {  
    this.lstDistrict =[];
    if(this.SelStateID!="0"){
      var idx = this.lstState.findIndex((m:any)=>m.ID === this.SelStateID);
      if(idx>-1){
        this.filterService.setSelStateName(this.lstState[idx].State_Name);
      }
      this.rtmisservice.GetMasterData('District', 'stateid', this.SelStateID, 'districtid', (this.CheckLoginType('Project') || this.CheckLoginType('District'))?this.userlogin.District_id:'0').subscribe((data: {}) => {
        this.lstDistrict = data;
        if(this.CheckLoginType("District")){
          this.SelDistrictID=this.lstDistrict[0].District_id;                    
          if(this.userlogin.logintype=='District'){
            this.updateFilterValue("SelDistrictID", this.SelDistrictID,true);
          }
          else{
            this.updateFilterValue("SelDistrictID", this.SelDistrictID,false);
          }
          this.getMunicipalArea();
        }
        else{
          this.SelDistrictID="0";          
          this.getMunicipalArea();
        } 
      });
    }
    else{
      this.SelDistrictID="0"; 
      this.filterService.setSelStateName("");     
      this.getMunicipalArea();
    }    
  }

  getMunicipalArea(): void {
    this.lstMunicipalArea =[];
    if(this.SelDistrictID != "0"){
      var idx = this.lstDistrict.findIndex((m:any)=>m.ID === this.SelDistrictID);
      if(idx>-1){
        this.filterService.setSelDistrictName(this.lstDistrict[idx].District_Name);
      }
      this.rtmisservice.GetMasterData('MunicipalArea', 'districtid', this.SelDistrictID, 'municipalareaid', this.CheckLoginType('MuncipalArea')?this.userlogin.MuncipalArea_id:'0').subscribe((data: {}) => {
        this.lstMunicipalArea = data;
        if(this.CheckLoginType("MunicipalArea")){
          this.SelMunicipalAreaID=this.lstMunicipalArea[0].MunicipalArea_id;
          if(this.userlogin.logintype=='District'){
            this.updateFilterValue("SelMunicipalAreaID", this.SelMunicipalAreaID,true);
          }
          else{
            this.updateFilterValue("SelMunicipalAreaID", this.SelMunicipalAreaID,false);
          }          
          this.getBCCFZone();
        }
        else{
          this.SelMunicipalAreaID="0";
          this.getBCCFZone();
        }
      });
    }
    else{
      this.filterService.setSelDistrictName("");
      this.SelMunicipalAreaID="0";
      this.getBCCFZone();
    }
  }

  getBCCFZone(): void {
    this.lstBCCFZone =[];
    if(this.SelMunicipalAreaID !="0"){
      this.rtmisservice.GetMasterData('BCCFZone', 'municipalareaid', this.SelMunicipalAreaID, 'zoneid', this.CheckLoginType('BCCFZone')?this.userlogin.ZoneID:'0').subscribe((data: {}) => {
        this.lstBCCFZone = data;
        if(this.CheckLoginType("BCCFZone")){
          this.SelBCCFZoneID=this.lstBCCFZone[0].BCCFZone_id;
          this.updateFilterValue("SelBCCFZoneID", this.SelBCCFZoneID,true);
          this.getTerritorialArea();
        }
        else{
          this.SelBCCFZoneID="0";
          this.getTerritorialArea();
        }
      });
    }
    else{
      this.SelBCCFZoneID="0";
      this.getTerritorialArea();
    }
  }

  getTerritorialArea(): void {
    this.lstTerritorialArea =[];
    if(this.SelBCCFZoneID !="0"){
      this.rtmisservice.GetMasterData('TerritorialArea', 'zoneid', this.SelBCCFZoneID, 'territorialareaid', '0').subscribe((data: {}) => {
        this.lstTerritorialArea = data;
        this.SelTerritorialAreaID="0";
        this.getAdministratorArea();
      });
    }
    else{
      this.SelTerritorialAreaID="0";
      this.getAdministratorArea();
    }
  }

  getAdministratorArea(): void {
    this.lstAdministratorArea = [];    
    this.SelAdministratorAreaID="0";
    if(this.SelTerritorialAreaID !="0"){
      this.rtmisservice.GetMasterData('AdministrativeArea', 'territorialareaid', this.SelTerritorialAreaID, 'administrativeareaid', '0').subscribe((data: {}) => {
        this.lstAdministratorArea = data;        
      });
    }
  }

  CheckLoginType(checkfor:any):boolean{
    var resp :boolean=false;
    if(checkfor==='BCCFZone'){
      resp = this.userlogin.logintype=='BCCFZone';
    }
    if(checkfor==='MunicipalArea'){
      resp = this.userlogin.logintype=='MunicipalArea' || this.userlogin.logintype=='BCCFZone';
    }
    if(checkfor==='District'){
      resp = this.userlogin.logintype=='District' || this.userlogin.logintype=='MunicipalArea' || this.userlogin.logintype=='BCCFZone';
    }
    if(checkfor==='State'){
      resp = this.userlogin.logintype=='State' || this.userlogin.logintype=='District' || this.userlogin.logintype=='MunicipalArea' || this.userlogin.logintype=='BCCFZone';
    }
    if(checkfor==='Project'){
      resp = this.userlogin.logintype=='Project';
    }
    return resp;
  }
  fs_day !:any;fe_day !:any;lfs_day !:any;lfe_day !:any;
  LoadDateRange(pageurl:any):void{
    var s_finyear:any = new Date().getFullYear();
    var e_finyear:any = new Date().getFullYear();
    var curmonth:any = new Date().getMonth()+1;
    if (curmonth <= 3) {
      s_finyear = s_finyear - 1;
      e_finyear = e_finyear;
    } else {
        s_finyear = s_finyear;
        e_finyear = e_finyear + 1;
    }
    this.fs_day = new Date(s_finyear, 3, 1);
    this.fe_day = new Date(e_finyear, 2, 31);
    this.lfs_day = new Date(s_finyear-1, 3, 1);
    this.lfe_day = new Date(e_finyear-1, 2, 31);
    var cs_day = new Date(new Date().getFullYear(), 0, 1);
    this.ranges = {
        'Today': [moment(), moment()],
        'Last Financial Year': [moment(this.lfs_day), moment(this.lfe_day)],
        'Current Financial Year': [moment(this.fs_day), moment(this.fe_day)],
        'Current Calendar Year': [moment(cs_day), moment()],
        'Quarter-1': [moment(new Date(s_finyear,3,1)), moment(new Date(s_finyear,5,30))],
        'Quarter-2': [moment(new Date(s_finyear,6,1)), moment(new Date(s_finyear,8,30))],
        'Quarter-3': [moment(new Date(s_finyear,9,1)), moment(new Date(s_finyear,11,31))],
        'Quarter-4': [moment(new Date(e_finyear,0,1)), moment(new Date(e_finyear,2,31))],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Three Month': [moment().subtract(3, 'month'), moment()]
    };
    if(pageurl=='/dash/calendar'){
      this.ranges = {[moment().add(3, 'month').startOf('month').format("MMMM YYYY")]: [moment().add(3, 'month').startOf('month'), moment().add(3, 'month').endOf('month')],
      [moment().add(2, 'month').startOf('month').format("MMMM YYYY")]: [moment().add(2, 'month').startOf('month'), moment().add(2, 'month').endOf('month')],
      [moment().add(1, 'month').startOf('month').format("MMMM YYYY")]: [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
      [moment().startOf('month').format("MMMM YYYY")]: [moment().startOf('month'), moment().endOf('month')],
      [moment().subtract(1, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      [moment().subtract(2, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')],
      [moment().subtract(3, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(3, 'month').startOf('month'), moment().subtract(3, 'month').endOf('month')],
      [moment().subtract(4, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(4, 'month').startOf('month'), moment().subtract(4, 'month').endOf('month')],
      [moment().subtract(5, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(5, 'month').startOf('month'), moment().subtract(5, 'month').endOf('month')],
      [moment().subtract(6, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(6, 'month').startOf('month'), moment().subtract(6, 'month').endOf('month')],
      [moment().subtract(7, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(7, 'month').startOf('month'), moment().subtract(7, 'month').endOf('month')],
      [moment().subtract(8, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(8, 'month').startOf('month'), moment().subtract(8, 'month').endOf('month')],
      [moment().subtract(9, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(9, 'month').startOf('month'), moment().subtract(9, 'month').endOf('month')],
      [moment().subtract(10, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(10, 'month').startOf('month'), moment().subtract(10, 'month').endOf('month')],
      [moment().subtract(11, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(11, 'month').startOf('month'), moment().subtract(11, 'month').endOf('month')]};
    }
    if(pageurl=='/dash/annexurec'){
      this.ranges = {[moment().subtract(1, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      [moment().subtract(2, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')],
      [moment().subtract(3, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(3, 'month').startOf('month'), moment().subtract(3, 'month').endOf('month')],
      [moment().subtract(4, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(4, 'month').startOf('month'), moment().subtract(4, 'month').endOf('month')],
      [moment().subtract(5, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(5, 'month').startOf('month'), moment().subtract(5, 'month').endOf('month')],
      [moment().subtract(6, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(6, 'month').startOf('month'), moment().subtract(6, 'month').endOf('month')],
      [moment().subtract(7, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(7, 'month').startOf('month'), moment().subtract(7, 'month').endOf('month')],
      [moment().subtract(8, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(8, 'month').startOf('month'), moment().subtract(8, 'month').endOf('month')],
      [moment().subtract(9, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(9, 'month').startOf('month'), moment().subtract(9, 'month').endOf('month')],
      [moment().subtract(10, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(10, 'month').startOf('month'), moment().subtract(10, 'month').endOf('month')],
      [moment().subtract(11, 'month').startOf('month').format("MMMM YYYY")]: [moment().subtract(11, 'month').startOf('month'), moment().subtract(11, 'month').endOf('month')]};
    }
    if(pageurl=="/dash/calendar"){
      //this.shocalendaralways=false;
      this.selectedDateRange= { startDate: moment().startOf('month'), endDate: moment().endOf('month') };
    }
    else if(pageurl=="/dash/annexurec"){
      //this.shocalendaralways=false;
      this.selectedDateRange= { startDate: moment().subtract(1, 'month').startOf('month'), endDate: moment().subtract(1, 'month').endOf('month') };
    }
    else
    {
      //this.shocalendaralways=true;
      this.selectedDateRange= { startDate: moment(new Date(s_finyear,6,1)), endDate: moment(new Date(s_finyear,8,31))};
    }
  }
  selectedDateRange: any;

  // ranges: any = {
  //   'Today': [moment(), moment()],
  //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  //   'This Month': [moment().startOf('month'), moment().endOf('month')],
  //   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  // }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  
  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  isTooltipDate = (m: moment.Moment) => {
    const tooltip = m.weekday() === 0 || m.weekday() === 6;
    if (tooltip) {
      return 'Weekends not allowed!';
    } else {
      return false;
    }
  }
  
}

