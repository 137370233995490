import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/pages/dashboard/home/home.component';
import { AuthGuard } from 'src/app/pages/login/auth.guard';
import { DashboardLayoutComponent } from './dashboard-layout.component';
import { ActivityCalendarComponent } from 'src/app/pages/activity-calendar/activity-calendar.component';
import { AnnexureCComponent } from 'src/app/pages/annexure-c/annexure-c.component';

export const routes: Routes = [
  { path: '',
  component: DashboardLayoutComponent,
  children: [
    { path: 'home', component: HomeComponent, 
    canActivate: [AuthGuard] },
    { path: 'calendar', component: ActivityCalendarComponent, 
    canActivate: [AuthGuard] },
    { path: 'annexurec', component: AnnexureCComponent, 
    canActivate: [AuthGuard] }
    // other routes for the default layout
  ]}
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
