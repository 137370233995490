import { Injectable } from '@angular/core';  
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';  
import { Observable, retry,catchError, throwError  } from 'rxjs';  
import { StorageService } from 'src/app/pages/login/storage.service';

const Service_API = 'https://rtmis.fhindia.org/api/';
const httpOptions = {
   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
 };
@Injectable({
   providedIn: 'root',
 })  
export class FHIRtmisService {  
   userlogin:any;	
   constructor(private http: HttpClient, private storageService: StorageService) { 
      this.userlogin=this.storageService.getUser();
      if(this.userlogin){ }
      else{this.userlogin={"State_id":"0","District_id":"0"};}
   }      
   GetMasterData(indctr:any, MasterFor:any, MasterForId:any, DataFor:any, DataId:any): Observable<any> {  	       
	  return this.http.get(Service_API+'ModalDashboard.php?AppVersion=2&indctr=' + indctr + '&methodtype=GETTING&' + MasterFor + '=' + MasterForId +'&' + DataFor + '=' + DataId,      
      httpOptions)
      .pipe(retry(1), catchError(this.handleError));  
   } 
   
   GetModalPopUpData(indctr:any,valuefor:any,valueforid:any,indctrid:any, filterState:any): Observable<any> {  	       
      var seladministype='';
      for (var u = 0; u < filterState.SelAdministrativeAreaType.length; u++) {
         seladministype = seladministype+(seladministype==""?"": "','") + filterState.SelAdministrativeAreaType[u].AdministrativeID;
       }
      return this.http.get(Service_API+'ModalDashboard.php?AppVersion=2&indctr='+ indctr+'&methodtype=GETTING&stateid=' 
      + (filterState.SelStateID=="0"?this.userlogin.State_id:filterState.SelStateID) 
      + '&districtid=' + (filterState.SelDistrictID=="0"?this.userlogin.District_id:filterState.SelDistrictID)  + '&municipalareaid=' + filterState.SelMunicipalAreaID
      + '&zoneid=' + filterState.SelBCCFZoneID+ '&territorialareaid=' + filterState.SelTerritorialAreaID
      + '&administrativeareaid=' + filterState.SelAdministratorAreaID
      + '&administrativeareatype=' + seladministype
      + '&valuefor=' + valuefor + '&valueforid=' + valueforid + '&indctrid=' + indctrid
      + '&fromdate=' + filterState.selectedDateRange.startDate.format("YYYY-MM-DD")+ '&todate=' + filterState.selectedDateRange.endDate.format("YYYY-MM-DD"),      
       httpOptions)
       .pipe(retry(1), catchError(this.handleError));  
    } 
    GetAnnexureCDataSummary(indctr:any,filterState:any): Observable<any> { 
      return this.http.get(Service_API+'AnnexureC_Data.php?AppVersion=2&indctr='+ indctr + '&methodtype=GETTING&stateid=' 
      + (filterState.SelStateID=="0"?this.userlogin.State_id:filterState.SelStateID) 
      + '&districtid=' + (filterState.SelDistrictID=="0"?this.userlogin.District_id:filterState.SelDistrictID)
      + '&fromdate=' + filterState.selectedDateRange.startDate?.format("YYYY-MM-DD")+ '&todate=' + filterState.selectedDateRange.endDate?.format("YYYY-MM-DD"),      
       httpOptions)
       .pipe(retry(1), catchError(this.handleError));  
    }
    GetAttendanceDataSummary(filterState:any,curdate:any): Observable<any> { 
      var seladministype='';
      for (var u = 0; u < filterState.SelAdministrativeAreaType.length; u++) {
         seladministype = seladministype+(seladministype==""?"": "','") + filterState.SelAdministrativeAreaType[u].AdministrativeID;
       }
      return this.http.get(Service_API+'DashboardSummary.php?AppVersion=2&indctr=AttendanceSummary&methodtype=GETTING&stateid=' 
      + (filterState.SelStateID=="0"?this.userlogin.State_id:filterState.SelStateID) 
      + '&districtid=' + (filterState.SelDistrictID=="0"?this.userlogin.District_id:filterState.SelDistrictID) + '&municipalareaid=' + filterState.SelMunicipalAreaID
      + '&zoneid=' + filterState.SelBCCFZoneID+ '&territorialareaid=' + filterState.SelTerritorialAreaID
      + '&administrativeareaid=' + filterState.SelAdministratorAreaID
      + '&administrativeareatype=' + seladministype
      + '&fromdate=' + curdate?.format("YYYY-MM-DD")+ '&todate=' + curdate?.format("YYYY-MM-DD"),      
       httpOptions)
       .pipe(retry(1), catchError(this.handleError));  
    }
    GetCalendarDataSummary(filterState:any,selectedDateRange:any): Observable<any> { 
      return this.http.get(Service_API+'planning_calendar.php?AppVersion=2&stateid=' 
      + (filterState.SelStateID=="0"?this.userlogin.State_id:filterState.SelStateID) 
      + '&districtid=' + (filterState.SelDistrictID=="0"?this.userlogin.District_id:filterState.SelDistrictID) + '&municipalareaid=' + filterState.SelMunicipalAreaID
      + '&zoneid=' + filterState.SelBCCFZoneID+ '&territorialareaid=' + filterState.SelTerritorialAreaID
      + '&administrativeareaid=' + filterState.SelAdministratorAreaID
      + '&fromdate=' + selectedDateRange.startDate.format("YYYY-MM-DD")+ '&todate=' + selectedDateRange.endDate.format("YYYY-MM-DD"),      
       httpOptions)
       .pipe(retry(1), catchError(this.handleError));  
    }
   GetDashboardDataSummary(SummaryFor:any,filterState:any): Observable<any> { 
      var seladministype='';
      for (var u = 0; u < filterState.SelAdministrativeAreaType.length; u++) {
         seladministype = seladministype+(seladministype==""?"": "','") + filterState.SelAdministrativeAreaType[u].AdministrativeID;
       }
      return this.http.get(Service_API+'DashboardSummary.php?AppVersion=2&indctr='+SummaryFor+'&methodtype=GETTING&stateid=' 
      + (filterState.SelStateID=="0"?this.userlogin.State_id:filterState.SelStateID) 
      + '&districtid=' + (filterState.SelDistrictID=="0"?this.userlogin.District_id:filterState.SelDistrictID) + '&municipalareaid=' + filterState.SelMunicipalAreaID
      + '&zoneid=' + filterState.SelBCCFZoneID+ '&territorialareaid=' + filterState.SelTerritorialAreaID
      + '&administrativeareaid=' + filterState.SelAdministratorAreaID
      + '&administrativeareatype=' + seladministype
      + '&fromdate=' + filterState.selectedDateRange.startDate?.format("YYYY-MM-DD")+ '&todate=' + filterState.selectedDateRange.endDate?.format("YYYY-MM-DD"),      
       httpOptions)
       .pipe(retry(1), catchError(this.handleError));  
    }
    GetSurveyDataSummary(filterState:any): Observable<any> {  	
      var seladministype='';
      for (var u = 0; u < filterState.SelAdministrativeAreaType.length; u++) {
         seladministype = seladministype+(seladministype==""?"": "','") + filterState.SelAdministrativeAreaType[u].AdministrativeID;
       }
      return this.http.get(Service_API+'DashboardSummary.php?AppVersion=2&indctr=DashboardSummarySurvey&methodtype=GETTING&stateid=' 
      + (filterState.SelStateID=="0"?this.userlogin.State_id:filterState.SelStateID)
      + '&districtid=' + (filterState.SelDistrictID=="0"?this.userlogin.District_id:filterState.SelDistrictID)  + '&municipalareaid=' + filterState.SelMunicipalAreaID
      + '&zoneid=' + filterState.SelBCCFZoneID+ '&territorialareaid=' + filterState.SelTerritorialAreaID
      + '&administrativeareaid=' + filterState.SelAdministratorAreaID    
      + '&administrativeareatype=' + seladministype  
      + '&fromdate=' + filterState.selectedDateRange.startDate.format("YYYY-MM-DD")+ '&todate=' + filterState.selectedDateRange.endDate.format("YYYY-MM-DD"),      
       httpOptions)
       .pipe(retry(1), catchError(this.handleError));  
    }
    GetGraphicalDataSummary(SummaryFor:any,filterState:any): Observable<any> {
      var seladministype='';
      for (var u = 0; u < filterState.SelAdministrativeAreaType.length; u++) {
         seladministype = seladministype+(seladministype==""?"": "','") + filterState.SelAdministrativeAreaType[u].AdministrativeID;
       }  	
      return this.http.get(Service_API+'graphical_data.php?AppVersion=2&indctr='+SummaryFor+'&methodtype=GETTING&stateid=' 
      + (filterState.SelStateID=="0"?this.userlogin.State_id:filterState.SelStateID)
      + '&districtid=' + (filterState.SelDistrictID=="0"?this.userlogin.District_id:filterState.SelDistrictID)  + '&municipalareaid=' + filterState.SelMunicipalAreaID
      + '&zoneid=' + filterState.SelBCCFZoneID+ '&territorialareaid=' + filterState.SelTerritorialAreaID
      + '&administrativeareaid=' + filterState.SelAdministratorAreaID
      + '&administrativeareatype=' + seladministype
      + '&fromdate=' + filterState.selectedDateRange.startDate.format("YYYY-MM-DD")+ '&todate=' + filterState.selectedDateRange.endDate.format("YYYY-MM-DD"),      
       httpOptions)
       .pipe(retry(1), catchError(this.handleError));  
    }
    GetActivityDataSummary(filterState:any): Observable<any> { 
      var seladministype='';
      for (var u = 0; u < filterState.SelAdministrativeAreaType.length; u++) {
         seladministype = seladministype+(seladministype==""?"": "','") + filterState.SelAdministrativeAreaType[u].AdministrativeID;
       } 	
      return this.http.get(Service_API+'DashboardSummary.php?AppVersion=2&indctr=ActivitySummary&methodtype=GETTING&stateid=' 
      + (filterState.SelStateID=="0"?this.userlogin.State_id:filterState.SelStateID)
      + '&districtid=' + (filterState.SelDistrictID=="0"?this.userlogin.District_id:filterState.SelDistrictID)  + '&municipalareaid=' + filterState.SelMunicipalAreaID
      + '&zoneid=' + filterState.SelBCCFZoneID+ '&territorialareaid=' + filterState.SelTerritorialAreaID
      + '&administrativeareaid=' + filterState.SelAdministratorAreaID
      + '&administrativeareatype=' + seladministype
      + '&fromdate=' + filterState.selectedDateRange.startDate.format("YYYY-MM-DD")+ '&todate=' + filterState.selectedDateRange.endDate.format("YYYY-MM-DD"),      
       httpOptions)
       .pipe(retry(1), catchError(this.handleError));  
    }
    GetPlanningDataSummary(filterState:any): Observable<any> { 
      var seladministype='';
      for (var u = 0; u < filterState.SelAdministrativeAreaType.length; u++) {
         seladministype = seladministype+(seladministype==""?"": "','") + filterState.SelAdministrativeAreaType[u].AdministrativeID;
       } 	
      return this.http.get(Service_API+'DashboardSummary.php?AppVersion=2&indctr=PlanningSummary&methodtype=GETTING&stateid=' 
      + (filterState.SelStateID=="0"?this.userlogin.State_id:filterState.SelStateID)
      + '&districtid=' + (filterState.SelDistrictID=="0"?this.userlogin.District_id:filterState.SelDistrictID)  + '&municipalareaid=' + filterState.SelMunicipalAreaID
      + '&zoneid=' + filterState.SelBCCFZoneID+ '&territorialareaid=' + filterState.SelTerritorialAreaID
      + '&administrativeareaid=' + filterState.SelAdministratorAreaID
      + '&administrativeareatype=' + seladministype
      + '&fromdate=' + filterState.selectedDateRange.startDate.format("YYYY-MM-DD")+ '&todate=' + filterState.selectedDateRange.endDate.format("YYYY-MM-DD"),      
       httpOptions)
       .pipe(retry(1), catchError(this.handleError));  
    }
    getDeleteModalBulkData(indcrtr:any,clusterid:any,reportdate:any): Observable<any> {  	  
      return this.http.get(Service_API+'DataManagement.php?AppVersion=2&indctr=' + indcrtr + '&clusterid=' + clusterid+ '&reportdate=' + reportdate,      
      httpOptions)
      .pipe(catchError(this.handleError));  
      
    }    
   getDeleteModalData(indcrtr:any,indcrtrid:any,deldate:any): Observable<any> {  	  
	  return this.http.get(Service_API+'DataManagement.php?AppVersion=2&indctr=' + indcrtr + '&indctrid=' + indcrtrid+'&reportdate=' + deldate,      
     httpOptions)
     .pipe(catchError(this.handleError));  
	  
   }
   // Error handling
  handleError(error: any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
      } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      //window.alert(errorMessage);
      console.log(errorMessage);
      return throwError(() => {
      return errorMessage;
      });
   }

}  
