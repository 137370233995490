import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFilter'
})
export class CustomFilterPipe implements PipeTransform {
  transform(items: any[], filterObject: any, checkTrue: boolean): any[] {
    if (!items || !filterObject) {
      return items;
    }

    return items.filter(item => {
      // Check if all conditions are met
      const conditionsMet = Object.keys(filterObject).every(key => {
        if (checkTrue) {
          // Check if the condition should be true
          return item[key] === filterObject[key];
        } else {
          // Check if the condition should be false
          return item[key] !== filterObject[key];
        }
      });

      return conditionsMet;
    });
  }
}
