import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class FileUploadComponent {
  @Input() multiple: boolean = true;
  @Input() captionhidestatus:boolean=false;
  @Input() datehidestatus:boolean=false;
  @Input() extrahidestatus:boolean=false;
  @Input() extraheading:any='';
  @Input() accept: string = '*';
  @Input() maxFiles: number = 1;
  @Input() maxSizePerFile: number = Infinity;//1048576; Default max file size: 1 MB
  @Input() maxTotalSize: number = Infinity;
  @Input() allowedFileTypes: string[] = [];
  @Input() selectedFiles: File[] = [];
  @Output() filesChange: EventEmitter<File[]> = new EventEmitter<File[]>();
  @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
  private fileInput!: HTMLInputElement;
  constructor(private el: ElementRef) {
    this.fileInput = el.nativeElement; 
  }
  triggerFileInput(): void {
    const fileInput = this.fileInputRef.nativeElement;
    fileInput.click();
  }
  ngOnInit(): void {
    this.selectedFiles.forEach(file => {
        this.selectedFiles.push(file);
        this.generateThumbnail(file);
    });
  }  
  onFileChange(event: any): void {
    if(event!=undefined){
      const files: FileList = event.target.files;
      this.handleFileSelect(files);
    }
  }
  onDragOver(event: any): void {
    event.preventDefault();
  }

  onDrop(event: any): void {
    event.preventDefault();
    const files: FileList = event.dataTransfer.files;
    this.handleFileSelect(files);
  }
  handleFileSelect(files: FileList): void {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const isValid = this.validateFile(file);
      if (isValid) {
        this.selectedFiles.push(file);
        this.generateThumbnail(file);
      }
    }
    //this.fileList = [...this.fileList, ...selectedFiles];
    this.fileInput.value = ""; // Reset input value  
    this.filesChange.emit(this.selectedFiles);
  }

  validateFile(file: File): boolean {
    if (this.selectedFiles.length + 1 > this.maxFiles) {
      console.log('Maximum number of files reached.');
      return false;
    }

    if (file.size > this.maxSizePerFile) {
      console.log('File size exceeds maximum size per file.');
      return false;
    }

    const totalSize = this.selectedFiles.reduce((sum, currFile) => sum + currFile.size, file.size);
    if (totalSize > this.maxTotalSize) {
      console.log('Total size of selected files exceeds maximum total size.');
      return false;
    }
    // Check if the file type is allowed based on acceptedTypes
    if (this.accept && this.accept.length > 0) {
        const acceptedTypesArray = this.accept.split(',').map(type => type.trim());
        const fileType = file.type;
      
        if (!acceptedTypesArray.includes(fileType) && !acceptedTypesArray.includes(`${fileType.split('/')[0]}/*`)) {
          console.log(`File type (${fileType}) is not allowed.`);
          return false;
        }
    }
    return true;
  }

  // Method to get the thumbnail URL for an image file
  private generateThumbnail(file: File): void {
    
    if(file.type==undefined){
      file['thumbnailUrl'] = file.thumbnailUrl;
    }
    else{
      const reader = new FileReader();
      reader.onload = (event: any) => {
        
        const fileType = file.type;
        let thumbnailUrl = '';
        if (this.isImage(file)) {
          thumbnailUrl = event.target.result;
        } else {
          // For non-image file types, use icons or placeholders
          // if (this.isDocument(fileType)) {
          //   thumbnailUrl = 'path-to-document-icon.png';
          // } else if (this.isVideo(fileType)) {
          //   thumbnailUrl = 'path-to-video-icon.png';
          // } else if (this.isAudio(fileType)) {
          //   thumbnailUrl = 'path-to-audio-icon.png';
          // } else {
          //   thumbnailUrl = 'path-to-generic-icon.png';
          // }
        }
        file['thumbnailUrl'] = thumbnailUrl;
      };
      reader.readAsDataURL(file);
    }
  }

  // Method to check if a file is an image
  isImage(file: File): boolean {
    
    if(file==undefined){return false;}
    if(file.type==undefined && file.thumbnailUrl?.startsWith('data:image/')){ return true;}
    else if(file.type==undefined){return false;}
    return file.type.startsWith('image/');   
  }

  private isPDF(fileType: string): boolean {
    return fileType === 'application/pdf';
  }

  isDocument(file: File): boolean {
    if(file==undefined){return false;}
    let fileType:string=file.type;
    if(fileType==undefined) return false;
    return fileType.startsWith('application/msword') || // DOC
           fileType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document'); // DOCX
    // Add more document types as needed
  }

  isVideo(file: File): boolean {
    if(file==undefined){return false;}
    let fileType:string=file.type;
    if(fileType==undefined) return false;
    return fileType.startsWith('video/');
  }

  isAudio(file: File): boolean {
    if(file==undefined){return false;}
    let fileType:string=file.type;
    if(fileType==undefined) return false;
    return fileType.startsWith('audio/');
  }
  // Method to view a file
  viewFile(file: File): void {
    // Logic to view the file
    // You can implement this based on your requirements
  }

  // Method to remove a file
  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index > -1) {
      this.selectedFiles.splice(index, 1);
      this.filesChange.emit(this.selectedFiles);
    }
  }
  // Helper function to format file size to human-readable format
  formatFileSize(size: number): string {
    if(size==undefined){return "";}
    const units = ['B', 'KB', 'MB', 'GB'];
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`;
  }
}
declare global {
    interface File {
      thumbnailUrl?: string;
      caption?: string;
      imgdate?: string;
      extraheading?: string;
      extradata?: string;
    }
  }