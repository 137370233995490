import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { StorageService } from '../../login/storage.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { Subscription } from 'rxjs';

import * as am4core from "@amcharts/amcharts4/core";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {  AMCHARTS_GroupedBarGraph } from 'src/app/shared/services/amchartgraphs';

@Component({
  selector: 'app-dipstick-graphs',
  templateUrl: './dipstick-graphs.component.html',
  styleUrls: ['./dipstick-graphs.component.css']
})
export class DipstickGraphsComponent  implements AfterViewInit {
  userlogin:any;
  filterState: any = {};
  private filterStateSubscription!: Subscription;
  graphmethodused:any[]=[];graphcontainer:any[]=[];
  constructor(private rtmisservice:FHIRtmisService, private storageService: StorageService
    , private router: Router,private filterService: FilterService) {    
    this.userlogin=this.storageService.getUser();
    if(this.userlogin){      
    }
  }
  ngAfterViewInit() {
    am4core.useTheme(am4themes_kelly);
    am4core.useTheme(am4themes_animated);
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
      // Update filter state with the changed value
      this.filterState = filterData.filterState; 
      // Call the method in other component to handle the filter changes
      if(this.filterService.isInitialized()){
        //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
        this.BindDipStickGraphData();        
      }
    });    
  }
  ngOnDestroy(): void {
    if (this.filterStateSubscription) {
      this.filterStateSubscription.unsubscribe();
    }
  }
  BindDipStickGraphData(){
    this.rtmisservice.GetGraphicalDataSummary("DipStickHouseholdSurvey",this.filterState).subscribe((data: [any]) => {
      if(data.length>0){
          var Respondantdata={"Q1":{"Male":0,"Female":0,"quantity":""},"Q2":{"Male":0,"Female":0,"quantity":0},"Q3":{"Male":0,"Female":0,"quantity":0},"Q4":{"Male":0,"Female":0,"quantity":0}};
          var everhearddata={"Q1":{"Dengue":0,"Malaria":0,"quantity":""},"Q2":{"Dengue":0,"Malaria":0,"quantity":0},"Q3":{"Dengue":0,"Malaria":0,"quantity":0},"Q4":{"Dengue":0,"Malaria":0,"quantity":0}};
          var denguesympptomdata={"Q1":{"Fewer":0,"Headache":0,"FeelingCold":0,"NauseaVomiting":0,"BodyWeakness":0,"Dizziness":0,"Diarrhea":0,"Loss Of Appetite":0,"DontKnow":0,"Others":0,"quantity":""},"Q2":{"Fewer":0,"Headache":0,"FeelingCold":0,"NauseaVomiting":0,"BodyWeakness":0,"Dizziness":0,"Diarrhea":0,"Loss Of Appetite":0,"DontKnow":0,"Others":0,"quantity":0},"Q3":{"Fewer":0,"Headache":0,"FeelingCold":0,"NauseaVomiting":0,"BodyWeakness":0,"Dizziness":0,"Diarrhea":0,"Loss Of Appetite":0,"DontKnow":0,"Others":0,"quantity":0},"Q4":{"Fewer":0,"Headache":0,"FeelingCold":0,"NauseaVomiting":0,"BodyWeakness":0,"Dizziness":0,"Diarrhea":0,"Loss Of Appetite":0,"DontKnow":0,"Others":0,"quantity":0}};
          var malariasympptomdata={"Q1":{"Fewer":0,"Headache":0,"FewerSweating":0,"NauseaVomiting":0,"BodyWeakness":0,"Loss Of Appetite":0,"Others":0,"quantity":""},"Q2":{"Fewer":0,"Headache":0,"FewerSweating":0,"NauseaVomiting":0,"BodyWeakness":0,"Loss Of Appetite":0,"Others":0,"quantity":0},"Q3":{"Fewer":0,"Headache":0,"FewerSweating":0,"NauseaVomiting":0,"BodyWeakness":0,"Loss Of Appetite":0,"Others":0,"quantity":0},"Q4":{"Fewer":0,"Headache":0,"FewerSweating":0,"NauseaVomiting":0,"BodyWeakness":0,"Loss Of Appetite":0,"Others":0,"quantity":0}};
          var denguecausedata={"Q1":{"Mosquito bites":0,"Drinking unclean water":0,"Eating unhygienic food":0,"Cold or changing weather":0,"Getting wet in the rain":0,"Witchcraft":0,"Don't Know":0,"Others":0,"quantity":""},"Q2":{"Mosquito bites":0,"Drinking unclean water":0,"Eating unhygienic food":0,"Cold or changing weather":0,"Getting wet in the rain":0,"Witchcraft":0,"Don't Know":0,"Others":0,"quantity":""},"Q3":{"Mosquito bites":0,"Drinking unclean water":0,"Eating unhygienic food":0,"Cold or changing weather":0,"Getting wet in the rain":0,"Witchcraft":0,"Don't Know":0,"Others":0,"quantity":""},"Q4":{"Mosquito bites":0,"Drinking unclean water":0,"Eating unhygienic food":0,"Cold or changing weather":0,"Getting wet in the rain":0,"Witchcraft":0,"Don't Know":0,"Others":0,"quantity":""}};
          var malariacausedata={"Q1":{"Mosquito bites":0,"From flies/insects/lice etc.":0,"eating together from the same vessel":0,"Touching eachother":0,"By the bite of a mosquito that has bitten a malaria patient.":0,"Don't Know":0,"Others":0,"quantity":""},"Q2":{"Mosquito bites":0,"From flies/insects/lice etc.":0,"eating together from the same vessel":0,"Touching eachother":0,"By the bite of a mosquito that has bitten a malaria patient.":0,"Don't Know":0,"Others":0,"quantity":""},"Q3":{"Mosquito bites":0,"From flies/insects/lice etc.":0,"eating together from the same vessel":0,"Touching eachother":0,"By the bite of a mosquito that has bitten a malaria patient.":0,"Don't Know":0,"Others":0,"quantity":""},"Q4":{"Mosquito bites":0,"From flies/insects/lice etc.":0,"eating together from the same vessel":0,"Touching eachother":0,"By the bite of a mosquito that has bitten a malaria patient.":0,"Don't Know":0,"Others":0,"quantity":""}};
          
          var denguepreveteddata={"Q1":{"Can dengue be prevented":0},"Q2":{"Can dengue be prevented":0},"Q3":{"Can dengue be prevented":0},"Q4":{"Can dengue be prevented":0}};
          var malariapreveteddata={"Q1":{"Dengue":0,"Malaria":0,"quantity":""},"Q2":{"Dengue":0,"Malaria":0,"quantity":0},"Q3":{"Dengue":0,"Malaria":0,"quantity":0},"Q4":{"Dengue":0,"Malaria":0,"quantity":0}};
          var familypractanymethoddata={"Q1":{"Family Practice any Method":0},"Q2":{"Family Practice any Method":0},"Q3":{"Family Practice any Method":0},"Q4":{"Family Practice any Method":0}};
          var methodofprotectdata={"Q1":{"Bednets":0,"LLIN":0,"Incense sticks":0,"Coils":0,"Electric bat":0,"Repellent creams":0,"Use of insecticidal sprays":0,"Liquid Vaporiser electric machines":0,"Oils":0,"Wearing full sleeved clothes to avoid mosquito bites":0,"Mosquito screens on windows":0,"quantity":""},"Q2":{"Bednets":0,"LLIN":0,"Incense sticks":0,"Coils":0,"Electric bat":0,"Repellent creams":0,"Use of insecticidal sprays":0,"Liquid Vaporiser electric machines":0,"Oils":0,"Wearing full sleeved clothes to avoid mosquito bites":0,"Mosquito screens on windows":0,"quantity":""},"Q3":{"Bednets":0,"LLIN":0,"Incense sticks":0,"Coils":0,"Electric bat":0,"Repellent creams":0,"Use of insecticidal sprays":0,"Liquid Vaporiser electric machines":0,"Oils":0,"Wearing full sleeved clothes to avoid mosquito bites":0,"Mosquito screens on windows":0,"quantity":""},"Q4":{"Bednets":0,"LLIN":0,"Incense sticks":0,"Coils":0,"Electric bat":0,"Repellent creams":0,"Use of insecticidal sprays":0,"Liquid Vaporiser electric machines":0,"Oils":0,"Wearing full sleeved clothes to avoid mosquito bites":0,"Mosquito screens on windows":0,"quantity":""}};
          var familyhighfewerdata={"Q1":{"Family High Fewer Last Year":0},"Q2":{"Family High Fewer Last Year":0},"Q3":{"Family High Fewer Last Year":0},"Q4":{"Family High Fewer Last Year":0}};
          var visittohcpdata={"Q1":{"Visit to Health Care Provider":0},"Q2":{"Visit to Health Care Provider":0},"Q3":{"Visit to Health Care Provider":0},"Q4":{"Visit to Health Care Provider":0}};
          var typeofhcpdata={"Q1":{"Govt. Dispensary":0,"Govt./municipalhospital":0,"Mobile clininc":0,"NGO or trust hospital /clinic":0,"Pvt. Hospital/ Pvt. doctor/ clinic":0,"Others":0,"quantity":""},"Q2":{"Govt. Dispensary":0,"Govt./municipalhospital":0,"Mobile clininc":0,"NGO or trust hospital /clinic":0,"Pvt. Hospital/ Pvt. doctor/ clinic":0,"Others":0,"quantity":""},"Q3":{"Govt. Dispensary":0,"Govt./municipalhospital":0,"Mobile clininc":0,"NGO or trust hospital /clinic":0,"Pvt. Hospital/ Pvt. doctor/ clinic":0,"Others":0,"quantity":""},"Q4":{"Govt. Dispensary":0,"Govt./municipalhospital":0,"Mobile clininc":0,"NGO or trust hospital /clinic":0,"Pvt. Hospital/ Pvt. doctor/ clinic":0,"Others":0,"quantity":""}};
          var isbloodtestconducteddata={"Q1":{"Blood Test Conducted":0},"Q2":{"Blood Test Conducted":0},"Q3":{"Blood Test Conducted":0},"Q4":{"Blood Test Conducted":0}};
          var optfortreatmentdata={"Q1":{"Treatment Opted":0},"Q2":{"Treatment Opted":0},"Q3":{"Treatment Opted":0},"Q4":{"Treatment Opted":0}};
          var heard_denguemessagedata={"Q1":{"Heard Dengue or Malaria Message":0},"Q2":{"Heard Dengue or Malaria Message":0},"Q3":{"Heard Dengue or Malaria Message":0},"Q4":{"Heard Dengue or Malaria Message":0}};
          var finyear='';
          for(var i=0;i<data.length;i++){
            if(data[i].FinancialPeriod=='Q1'){
              Respondantdata.Q1={"Male":data[i].male_count*100/data[i].totalRecords,"Female":data[i].female_count*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              everhearddata.Q1={"Dengue":data[i].heardaboutdengue*100/data[i].totalRecords,"Malaria":data[i].heardaboutmalaria*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q1={"Fewer":data[i].DenSymp_Fever*100/data[i].totalRecords,"Headache":data[i].DenSymp_Headache*100/data[i].totalRecords,"FeelingCold":data[i].DenSymp_FeelingCold*100/data[i].totalRecords,"NauseaVomiting":data[i].DenSymp_NauseaVomiting*100/data[i].totalRecords,"BodyWeakness":data[i].DenSymp_BodyWeakness*100/data[i].totalRecords,"Dizziness":data[i].DenSymp_Dizziness*100/data[i].totalRecords,"Diarrhea":data[i].DenSymp_Diarrhea*100/data[i].totalRecords,"Loss Of Appetite":data[i].DenSymp_LossofAppetite*100/data[i].totalRecords,"DontKnow":data[i].DenSymp_DontKnow*100/data[i].totalRecords,"Others":data[i].DenSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasympptomdata.Q1={"Fewer":data[i].MalSymp_Fever*100/data[i].totalRecords,"Headache":data[i].MalSymp_Headache*100/data[i].totalRecords,"FewerSweating":data[i].MalSymp_FeverAndSweating*100/data[i].totalRecords,"NauseaVomiting":data[i].MalSymp_Vomiting*100/data[i].totalRecords,"BodyWeakness":data[i].MalSymp_BodyWeakness*100/data[i].totalRecords,"Loss Of Appetite":data[i].MalSymp_LossofAppetite*100/data[i].totalRecords,"Others":data[i].MalSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguecausedata.Q1={"Mosquito bites":data[i].DenCause_Mosquitobites*100/data[i].totalRecords,"Drinking unclean water":data[i].DenCause_uncleanwater*100/data[i].totalRecords,"Eating unhygienic food":data[i].DenCause_unhygienicfood*100/data[i].totalRecords,"Cold or changing weather":data[i].DenCause_changingweather*100/data[i].totalRecords,"Getting wet in the rain":data[i].DenCause_wetinrain*100/data[i].totalRecords,"Witchcraft":data[i].DenCause_Witchcraft*100/data[i].totalRecords,"Don't Know":data[i].DenCause_dontknow*100/data[i].totalRecords,"Others":data[i].DenCause_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q1={"Mosquito bites":data[i].MalCause_Mosquitobites*100/data[i].totalRecords,"From flies/insects/lice etc.":data[i].MalCause_insects*100/data[i].totalRecords,"eating together from the same vessel":data[i].MalCause_eatingtogether*100/data[i].totalRecords,"Touching eachother":data[i].MalCause_toucheachother*100/data[i].totalRecords,"By the bite of a mosquito that has bitten a malaria patient.":data[i].MalCause_SameMosquitobites*100/data[i].totalRecords,"Don't Know":data[i].MalCause_dontknow*100/data[i].totalRecords,"Others":data[i].MalCause_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguepreveteddata.Q1={"Can dengue be prevented":data[i].candengue_prevented*100/data[i].totalRecords};
              familypractanymethoddata.Q1={"Family Practice any Method":data[i].familypractice_protectionmosquito*100/data[i].totalRecords};
              methodofprotectdata.Q1={"Bednets":data[i].BedNets*100/data[i].totalRecords,"LLIN":data[i].LLIN*100/data[i].totalRecords,"Incense sticks":data[i].IncenseSticks*100/data[i].totalRecords,"Coils":data[i].Coils*100/data[i].totalRecords,"Electric bat":data[i].ElectricBat*100/data[i].totalRecords,"Repellent creams":data[i].RepellentCream*100/data[i].totalRecords,"Use of insecticidal sprays":data[i].UseOfInsecticidalSpray*100/data[i].totalRecords,"Liquid Vaporiser electric machines":data[i].LiquidVaporiserElectric*100/data[i].totalRecords,"Oils":data[i].Oil*100/data[i].totalRecords,"Wearing full sleeved clothes to avoid mosquito bites":data[i].WearingFullSleavesClothes*100/data[i].totalRecords,"Mosquito screens on windows":data[i].MosquitoScreenOnWindow*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              familyhighfewerdata.Q1={"Family High Fewer Last Year":data[i].member_highfever_lastyear*100/data[i].totalRecords};
              visittohcpdata.Q1={"Visit to Health Care Provider":data[i].visit_hcp*100/data[i].totalRecords};
              typeofhcpdata.Q1={"Govt. Dispensary":data[i].GovtDispensary*100/data[i].totalRecords,"Govt./municipalhospital":data[i].GovtMuncipalHospital*100/data[i].totalRecords,"Mobile clininc":data[i].MobileClinic*100/data[i].totalRecords,"NGO or trust hospital /clinic":data[i].NGOTrustHospitalClinic*100/data[i].totalRecords,"Pvt. Hospital/ Pvt. doctor/ clinic":data[i].PvtHospitalClinic*100/data[i].totalRecords,"Others":data[i].OtherHCPType*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              isbloodtestconducteddata.Q1={"Blood Test Conducted":data[i].isbloodtestconducted*100/data[i].totalRecords};
              optfortreatmentdata.Q1={"Treatment Opted":data[i].optfortreatment*100/data[i].totalRecords};
              heard_denguemessagedata.Q1={"Heard Dengue or Malaria Message":data[i].heard_denguemessage*100/data[i].totalRecords};
            }
            else if(data[i].FinancialPeriod=='Q2'){
              Respondantdata.Q2={"Male":data[i].male_count*100/data[i].totalRecords,"Female":data[i].female_count*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              everhearddata.Q2={"Dengue":data[i].heardaboutdengue*100/data[i].totalRecords,"Malaria":data[i].heardaboutmalaria*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q2={"Fewer":data[i].DenSymp_Fever*100/data[i].totalRecords,"Headache":data[i].DenSymp_Headache*100/data[i].totalRecords,"FeelingCold":data[i].DenSymp_FeelingCold*100/data[i].totalRecords,"NauseaVomiting":data[i].DenSymp_NauseaVomiting*100/data[i].totalRecords,"BodyWeakness":data[i].DenSymp_BodyWeakness*100/data[i].totalRecords,"Dizziness":data[i].DenSymp_Dizziness*100/data[i].totalRecords,"Diarrhea":data[i].DenSymp_Diarrhea*100/data[i].totalRecords,"Loss Of Appetite":data[i].DenSymp_LossofAppetite*100/data[i].totalRecords,"DontKnow":data[i].DenSymp_DontKnow*100/data[i].totalRecords,"Others":data[i].DenSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasympptomdata.Q2={"Fewer":data[i].MalSymp_Fever*100/data[i].totalRecords,"Headache":data[i].MalSymp_Headache*100/data[i].totalRecords,"FewerSweating":data[i].MalSymp_FeverAndSweating*100/data[i].totalRecords,"NauseaVomiting":data[i].MalSymp_Vomiting*100/data[i].totalRecords,"BodyWeakness":data[i].MalSymp_BodyWeakness*100/data[i].totalRecords,"Loss Of Appetite":data[i].MalSymp_LossofAppetite*100/data[i].totalRecords,"Others":data[i].MalSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguecausedata.Q2={"Mosquito bites":data[i].DenCause_Mosquitobites*100/data[i].totalRecords,"Drinking unclean water":data[i].DenCause_uncleanwater*100/data[i].totalRecords,"Eating unhygienic food":data[i].DenCause_unhygienicfood*100/data[i].totalRecords,"Cold or changing weather":data[i].DenCause_changingweather*100/data[i].totalRecords,"Getting wet in the rain":data[i].DenCause_wetinrain*100/data[i].totalRecords,"Witchcraft":data[i].DenCause_Witchcraft*100/data[i].totalRecords,"Don't Know":data[i].DenCause_dontknow*100/data[i].totalRecords,"Others":data[i].DenCause_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q2={"Mosquito bites":data[i].MalCause_Mosquitobites*100/data[i].totalRecords,"From flies/insects/lice etc.":data[i].MalCause_insects*100/data[i].totalRecords,"eating together from the same vessel":data[i].MalCause_eatingtogether*100/data[i].totalRecords,"Touching eachother":data[i].MalCause_toucheachother*100/data[i].totalRecords,"By the bite of a mosquito that has bitten a malaria patient.":data[i].MalCause_SameMosquitobites*100/data[i].totalRecords,"Don't Know":data[i].MalCause_dontknow*100/data[i].totalRecords,"Others":data[i].MalCause_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguepreveteddata.Q2={"Can dengue be prevented":data[i].candengue_prevented*100/data[i].totalRecords};
              familypractanymethoddata.Q2={"Family Practice any Method":data[i].familypractice_protectionmosquito*100/data[i].totalRecords};
              methodofprotectdata.Q2={"Bednets":data[i].BedNets*100/data[i].totalRecords,"LLIN":data[i].LLIN*100/data[i].totalRecords,"Incense sticks":data[i].IncenseSticks*100/data[i].totalRecords,"Coils":data[i].Coils*100/data[i].totalRecords,"Electric bat":data[i].ElectricBat*100/data[i].totalRecords,"Repellent creams":data[i].RepellentCream*100/data[i].totalRecords,"Use of insecticidal sprays":data[i].UseOfInsecticidalSpray*100/data[i].totalRecords,"Liquid Vaporiser electric machines":data[i].LiquidVaporiserElectric*100/data[i].totalRecords,"Oils":data[i].Oil*100/data[i].totalRecords,"Wearing full sleeved clothes to avoid mosquito bites":data[i].WearingFullSleavesClothes*100/data[i].totalRecords,"Mosquito screens on windows":data[i].MosquitoScreenOnWindow*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              familyhighfewerdata.Q2={"Family High Fewer Last Year":data[i].member_highfever_lastyear*100/data[i].totalRecords};
              visittohcpdata.Q2={"Visit to Health Care Provider":data[i].visit_hcp*100/data[i].totalRecords};
              typeofhcpdata.Q2={"Govt. Dispensary":data[i].GovtDispensary*100/data[i].totalRecords,"Govt./municipalhospital":data[i].GovtMuncipalHospital*100/data[i].totalRecords,"Mobile clininc":data[i].MobileClinic*100/data[i].totalRecords,"NGO or trust hospital /clinic":data[i].NGOTrustHospitalClinic*100/data[i].totalRecords,"Pvt. Hospital/ Pvt. doctor/ clinic":data[i].PvtHospitalClinic*100/data[i].totalRecords,"Others":data[i].OtherHCPType*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              isbloodtestconducteddata.Q2={"Blood Test Conducted":data[i].isbloodtestconducted*100/data[i].totalRecords};
              optfortreatmentdata.Q2={"Treatment Opted":data[i].optfortreatment*100/data[i].totalRecords};
              heard_denguemessagedata.Q2={"Heard Dengue or Malaria Message":data[i].heard_denguemessage*100/data[i].totalRecords};
            }
            else if(data[i].FinancialPeriod=='Q3'){
              Respondantdata.Q3={"Male":data[i].male_count*100/data[i].totalRecords,"Female":data[i].female_count*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              everhearddata.Q3={"Dengue":data[i].heardaboutdengue*100/data[i].totalRecords,"Malaria":data[i].heardaboutmalaria*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q3={"Fewer":data[i].DenSymp_Fever*100/data[i].totalRecords,"Headache":data[i].DenSymp_Headache*100/data[i].totalRecords,"FeelingCold":data[i].DenSymp_FeelingCold*100/data[i].totalRecords,"NauseaVomiting":data[i].DenSymp_NauseaVomiting*100/data[i].totalRecords,"BodyWeakness":data[i].DenSymp_BodyWeakness*100/data[i].totalRecords,"Dizziness":data[i].DenSymp_Dizziness*100/data[i].totalRecords,"Diarrhea":data[i].DenSymp_Diarrhea*100/data[i].totalRecords,"Loss Of Appetite":data[i].DenSymp_LossofAppetite*100/data[i].totalRecords,"DontKnow":data[i].DenSymp_DontKnow*100/data[i].totalRecords,"Others":data[i].DenSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasympptomdata.Q3={"Fewer":data[i].MalSymp_Fever*100/data[i].totalRecords,"Headache":data[i].MalSymp_Headache*100/data[i].totalRecords,"FewerSweating":data[i].MalSymp_FeverAndSweating*100/data[i].totalRecords,"NauseaVomiting":data[i].MalSymp_Vomiting*100/data[i].totalRecords,"BodyWeakness":data[i].MalSymp_BodyWeakness*100/data[i].totalRecords,"Loss Of Appetite":data[i].MalSymp_LossofAppetite*100/data[i].totalRecords,"Others":data[i].MalSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguecausedata.Q3={"Mosquito bites":data[i].DenCause_Mosquitobites*100/data[i].totalRecords,"Drinking unclean water":data[i].DenCause_uncleanwater*100/data[i].totalRecords,"Eating unhygienic food":data[i].DenCause_unhygienicfood*100/data[i].totalRecords,"Cold or changing weather":data[i].DenCause_changingweather*100/data[i].totalRecords,"Getting wet in the rain":data[i].DenCause_wetinrain*100/data[i].totalRecords,"Witchcraft":data[i].DenCause_Witchcraft*100/data[i].totalRecords,"Don't Know":data[i].DenCause_dontknow*100/data[i].totalRecords,"Others":data[i].DenCause_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q3={"Mosquito bites":data[i].MalCause_Mosquitobites*100/data[i].totalRecords,"From flies/insects/lice etc.":data[i].MalCause_insects*100/data[i].totalRecords,"eating together from the same vessel":data[i].MalCause_eatingtogether*100/data[i].totalRecords,"Touching eachother":data[i].MalCause_toucheachother*100/data[i].totalRecords,"By the bite of a mosquito that has bitten a malaria patient.":data[i].MalCause_SameMosquitobites*100/data[i].totalRecords,"Don't Know":data[i].MalCause_dontknow*100/data[i].totalRecords,"Others":data[i].MalCause_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguepreveteddata.Q3={"Can dengue be prevented":data[i].candengue_prevented*100/data[i].totalRecords};
              familypractanymethoddata.Q3={"Family Practice any Method":data[i].familypractice_protectionmosquito*100/data[i].totalRecords};
              methodofprotectdata.Q3={"Bednets":data[i].BedNets*100/data[i].totalRecords,"LLIN":data[i].LLIN*100/data[i].totalRecords,"Incense sticks":data[i].IncenseSticks*100/data[i].totalRecords,"Coils":data[i].Coils*100/data[i].totalRecords,"Electric bat":data[i].ElectricBat*100/data[i].totalRecords,"Repellent creams":data[i].RepellentCream*100/data[i].totalRecords,"Use of insecticidal sprays":data[i].UseOfInsecticidalSpray*100/data[i].totalRecords,"Liquid Vaporiser electric machines":data[i].LiquidVaporiserElectric*100/data[i].totalRecords,"Oils":data[i].Oil*100/data[i].totalRecords,"Wearing full sleeved clothes to avoid mosquito bites":data[i].WearingFullSleavesClothes*100/data[i].totalRecords,"Mosquito screens on windows":data[i].MosquitoScreenOnWindow*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              familyhighfewerdata.Q3={"Family High Fewer Last Year":data[i].member_highfever_lastyear*100/data[i].totalRecords};
              visittohcpdata.Q3={"Visit to Health Care Provider":data[i].visit_hcp*100/data[i].totalRecords};
              typeofhcpdata.Q3={"Govt. Dispensary":data[i].GovtDispensary*100/data[i].totalRecords,"Govt./municipalhospital":data[i].GovtMuncipalHospital*100/data[i].totalRecords,"Mobile clininc":data[i].MobileClinic*100/data[i].totalRecords,"NGO or trust hospital /clinic":data[i].NGOTrustHospitalClinic*100/data[i].totalRecords,"Pvt. Hospital/ Pvt. doctor/ clinic":data[i].PvtHospitalClinic*100/data[i].totalRecords,"Others":data[i].OtherHCPType*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              isbloodtestconducteddata.Q3={"Blood Test Conducted":data[i].isbloodtestconducted*100/data[i].totalRecords};
              optfortreatmentdata.Q3={"Treatment Opted":data[i].optfortreatment*100/data[i].totalRecords};
              heard_denguemessagedata.Q3={"Heard Dengue or Malaria Message":data[i].heard_denguemessage*100/data[i].totalRecords};
            }
            else if(data[i].FinancialPeriod=='Q4'){
              Respondantdata.Q4={"Male":data[i].male_count*100/data[i].totalRecords,"Female":data[i].female_count*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              everhearddata.Q4={"Dengue":data[i].heardaboutdengue*100/data[i].totalRecords,"Malaria":data[i].heardaboutmalaria*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguesympptomdata.Q4={"Fewer":data[i].DenSymp_Fever*100/data[i].totalRecords,"Headache":data[i].DenSymp_Headache*100/data[i].totalRecords,"FeelingCold":data[i].DenSymp_FeelingCold*100/data[i].totalRecords,"NauseaVomiting":data[i].DenSymp_NauseaVomiting*100/data[i].totalRecords,"BodyWeakness":data[i].DenSymp_BodyWeakness*100/data[i].totalRecords,"Dizziness":data[i].DenSymp_Dizziness*100/data[i].totalRecords,"Diarrhea":data[i].DenSymp_Diarrhea*100/data[i].totalRecords,"Loss Of Appetite":data[i].DenSymp_LossofAppetite*100/data[i].totalRecords,"DontKnow":data[i].DenSymp_DontKnow*100/data[i].totalRecords,"Others":data[i].DenSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariasympptomdata.Q4={"Fewer":data[i].MalSymp_Fever*100/data[i].totalRecords,"Headache":data[i].MalSymp_Headache*100/data[i].totalRecords,"FewerSweating":data[i].MalSymp_FeverAndSweating*100/data[i].totalRecords,"NauseaVomiting":data[i].MalSymp_Vomiting*100/data[i].totalRecords,"BodyWeakness":data[i].MalSymp_BodyWeakness*100/data[i].totalRecords,"Loss Of Appetite":data[i].MalSymp_LossofAppetite*100/data[i].totalRecords,"Others":data[i].MalSymp_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguecausedata.Q4={"Mosquito bites":data[i].DenCause_Mosquitobites*100/data[i].totalRecords,"Drinking unclean water":data[i].DenCause_uncleanwater*100/data[i].totalRecords,"Eating unhygienic food":data[i].DenCause_unhygienicfood*100/data[i].totalRecords,"Cold or changing weather":data[i].DenCause_changingweather*100/data[i].totalRecords,"Getting wet in the rain":data[i].DenCause_wetinrain*100/data[i].totalRecords,"Witchcraft":data[i].DenCause_Witchcraft*100/data[i].totalRecords,"Don't Know":data[i].DenCause_dontknow*100/data[i].totalRecords,"Others":data[i].DenCause_Others*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              malariacausedata.Q4={"Mosquito bites":data[i].MalCause_Mosquitobites*100/data[i].totalRecords,"From flies/insects/lice etc.":data[i].MalCause_insects*100/data[i].totalRecords,"eating together from the same vessel":data[i].MalCause_eatingtogether*100/data[i].totalRecords,"Touching eachother":data[i].MalCause_toucheachother*100/data[i].totalRecords,"By the bite of a mosquito that has bitten a malaria patient.":data[i].MalCause_SameMosquitobites*100/data[i].totalRecords,"Don't Know":data[i].MalCause_dontknow*100/data[i].totalRecords,"Others":data[i].MalCause_other*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              denguepreveteddata.Q4={"Can dengue be prevented":data[i].candengue_prevented*100/data[i].totalRecords};
              familypractanymethoddata.Q4={"Family Practice any Method":data[i].familypractice_protectionmosquito*100/data[i].totalRecords};
              methodofprotectdata.Q4={"Bednets":data[i].BedNets*100/data[i].totalRecords,"LLIN":data[i].LLIN*100/data[i].totalRecords,"Incense sticks":data[i].IncenseSticks*100/data[i].totalRecords,"Coils":data[i].Coils*100/data[i].totalRecords,"Electric bat":data[i].ElectricBat*100/data[i].totalRecords,"Repellent creams":data[i].RepellentCream*100/data[i].totalRecords,"Use of insecticidal sprays":data[i].UseOfInsecticidalSpray*100/data[i].totalRecords,"Liquid Vaporiser electric machines":data[i].LiquidVaporiserElectric*100/data[i].totalRecords,"Oils":data[i].Oil*100/data[i].totalRecords,"Wearing full sleeved clothes to avoid mosquito bites":data[i].WearingFullSleavesClothes*100/data[i].totalRecords,"Mosquito screens on windows":data[i].MosquitoScreenOnWindow*100/data[i].totalRecords,"quantity":data[i].totalRecords}
              familyhighfewerdata.Q4={"Family High Fewer Last Year":data[i].member_highfever_lastyear*100/data[i].totalRecords};
              visittohcpdata.Q4={"Visit to Health Care Provider":data[i].visit_hcp*100/data[i].totalRecords};
              typeofhcpdata.Q4={"Govt. Dispensary":data[i].GovtDispensary*100/data[i].totalRecords,"Govt./municipalhospital":data[i].GovtMuncipalHospital*100/data[i].totalRecords,"Mobile clininc":data[i].MobileClinic*100/data[i].totalRecords,"NGO or trust hospital /clinic":data[i].NGOTrustHospitalClinic*100/data[i].totalRecords,"Pvt. Hospital/ Pvt. doctor/ clinic":data[i].PvtHospitalClinic*100/data[i].totalRecords,"Others":data[i].OtherHCPType*100/data[i].totalRecords,"quantity":data[i].totalRecords};
              isbloodtestconducteddata.Q4={"Blood Test Conducted":data[i].isbloodtestconducted*100/data[i].totalRecords};
              optfortreatmentdata.Q4={"Treatment Opted":data[i].optfortreatment*100/data[i].totalRecords};
              heard_denguemessagedata.Q4={"Heard Dengue or Malaria Message":data[i].heard_denguemessage*100/data[i].totalRecords};
            }
            finyear=data[i].FinancialYear;
          }
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartRespondentGrowth", Respondantdata,finyear, 100, "Percentage", "valaxis","","GENDER OF RESPONDENTS (%)","","",true,"");           
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("charteverheard", everhearddata,finyear, 100, "Percentage", "valaxis","","Ever heard of an illness called Dengue and Malaria (%)","","",true,"");           
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartdenguesympton", denguesympptomdata,finyear, 100, "Percentage", "valaxis","","Main Signs and Symptons of Dengue (%)","","",true,"");           
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartmalariasympton", malariasympptomdata,finyear, 100, "Percentage", "valaxis","","Main Signs and Symptons of Malaria (%)","","",true,"");           
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartdenguecause", denguecausedata,finyear, 100, "Percentage", "valaxis","","Cause of Dengue (%)","","",true,"");           
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartmalariacause", malariacausedata,finyear, 100, "Percentage", "valaxis","","Cause of Malaria (%)","","",true,"");           

          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartdengueprevented", denguepreveteddata,finyear, 100, "Percentage", "valaxis","","Dengue Can be prevented (%)","","",true,"");           
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartmalariaprevented", malariapreveteddata,finyear, 100, "Percentage", "valaxis","","Malaria Can be prevented (%)","","",true,"");           
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartfamilypractanymethod", familypractanymethoddata,finyear, 100, "Percentage", "valaxis","","Family practice any protection method against mosquito biting (%)","","",true,"");                     
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartmethodofprotect", methodofprotectdata,finyear, 100, "Percentage", "valaxis","","Method(s) of protection (%)","","",true,"");                     
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartfamilyhighfewer", familyhighfewerdata,finyear, 100, "Percentage", "valaxis","","Family have a high-grade fever in the last one year (%)","","",true,"");                     
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartvisittohcp", visittohcpdata,finyear, 100, "Percentage", "valaxis","","Visit to healthcare provider (%)","","",true,"");                     
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("charttypeofhcp", typeofhcpdata,finyear, 100, "Percentage", "valaxis","","Type of a health care provider visited (%)","","",true,"");                     

          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartbloodtestconduct", isbloodtestconducteddata,finyear, 100, "Percentage", "valaxis","","Blood test conducted to confirm diagnosis (%)","","",true,"");                     
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("charttreatmentopted", optfortreatmentdata,finyear, 100, "Percentage", "valaxis","","Treatment opted (%)","","",true,"");                     
          AMCHARTS_GroupedBarGraph.GroupedBarGraph("chartheardseenmessage", heard_denguemessagedata,finyear, 100, "Percentage", "valaxis","","Ever seen/heard any messages on Dengue or Malaria  (%)","","",true,"");                     
      } 
    });   
  }
}

