import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardnavbarComponent } from './dashboardnavbar.component';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
    imports: [ RouterModule, 
        //NgbModule         
     
    ],
    declarations: [ DashboardnavbarComponent ],
    exports: [ DashboardnavbarComponent ]
})

export class DashboardNavbarModule {}
