<div class="card shadow-sm">
    <div class="card-body" style="min-height: 418px;"> 
        <div class="row" *ngIf="isdataloaded">
            <div class="col-6" *ngFor="let event of lstActivityType | customFilter: { targetcount: '0' }:false | sort:criteria">
                <div class="row">
                    <div class="col-12">
                        <span class="event-title">{{event.ActivityType}}</span>
                    </div>
                    <div class="col-12">                
                        <circle-progress 
                        [percent]="MathFloor(((event.ActivityType_id=='2'?event.ActivityCount:event.Participants) / event.targetcount) * 100)"
                        [title]="MathFloor(((event.ActivityType_id=='2'?event.ActivityCount:event.Participants) / event.targetcount) * 100)+ '%'"
                        [showSubtitle]="true"  
                        [titleFontSize]="'12'"
                        [subtitleFontSize]="'10'"
                        [radius]="40"
                        [backgroundPadding]="5"
                        [outerStrokeWidth]="10"
                        [space]="-10"
                        [innerStrokeWidth]="10"
                        [backgroundStrokeWidth]="0"
                        [animation] = "true"                
                        [titleColor]="'#000000'"
                        [subtitleColor]="'#444444'"
                        [subtitle]="(event.ActivityType_id=='2'?event.ActivityCount.toString():event.Participants.toString())+'/'+event.targetcount.toString()"
                        ></circle-progress>
                    </div>
                </div>
            </div>       
        </div>
        <div class="row">
            <div class="col-12">                
                <p-progressSpinner [style]="{ width: '150px', height: '120px' }" aria-label="Loading" *ngIf="!isdataloaded"></p-progressSpinner>
            </div>
        </div>        
    </div>
</div>
<div class="card shadow-sm">
    <div class="card-body" style="overflow-y: scroll;height: 460px;">
        <div class="card-title heading-2">Activities</div>
        <p-progressSpinner [style]="{ width: '150px', height: '100px' }" aria-label="Loading" *ngIf="!isdataloaded"></p-progressSpinner>           
        <ul class="list-group list-group-flush" *ngIf="isdataloaded">            
            <li class="list-group-item px-0" *ngFor="let event of lstActivityType | sort : listcriteria">
                <div class="row">
                    <div class="col-3 text-secondary event-time">
                         {{ event.ActivityCount}}/{{event.Participants}}
                    </div>
                    <div class="col-9 event-title"><a (click)="show(event.ActivityType_id,event.ActivityType)" href="javascript:;">{{ event.ActivityType }}</a></div>
                </div>
            </li>
        </ul>
        <!-- <button type="button" class="btn btn-secondary btn-sm">See All</button> -->
    </div>
</div>