import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { takeLast } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { StorageService } from '../../login/storage.service';
import { MessageService } from 'primeng/api';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { IncludeIfDirective } from 'src/app/shared/directives/include-if.directive';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MailSenderComponent } from 'src/app/shared/mailsender/mail-sender.component';

@Component({
  selector: 'app-crudmodal',
  templateUrl: './crudmodal.component.html',
  styleUrls: ['./crudmodal.component.css'],
  providers: [MessageService,DatePipe]
})
export class CrudModalComponent implements OnInit {
    @Input() datafor: string = '';    
    @Input() modaltitle: string = '';  
    @Input() valuefor: string = '';
    @Input() valueforid: string = '';
    @Input() selectedRowItem: any;
    dateMask = {
        mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
        guide: false
    };
  ModalPhotos:any[]=[];
  ModalSlumPhotos:any[]=[];
  ModalActivityPhoto:any[]=[];
  ModalActivityAttendance:any[]=[];
  masterOutletType:any[] = [{"OutletType":"Kirana"},{"OutletType":"Pan Bidi"},{"OutletType":"Medical"},{"OutletType":"Others"}];
  masterProjectStaffType:any[] = [];
  masterProviderType:any[]=[];
  masterWaterStagnationType:any[]=[];
  selectsForm!: FormGroup;
  states: any[] = [];
  districts: any[] = [];
  municipalAreas: any[] = [];
  bccfZones: any[] = [];
  territorialAreas: any[] = [];
  administrativeAreas: any[] = [];
  clusters: any[]=[];
  hndsites:any[]=[]; 
  ModalServiceProviders:any[]=[];
  schooldata:any[]=[];
  activitytypes:any[]=[];
  userlogin:any;
  @Output() dataChanged: EventEmitter<void> = new EventEmitter<void>();
  
  constructor(public dialogService: DialogService,private http: HttpClient,private rtmisservice:FHIRtmisService, private storageService: StorageService
    , private messageService: MessageService,private formBuilder: FormBuilder, private datePipe: DatePipe) { 
    this.userlogin=this.storageService.getUser();
    if(this.userlogin){      
        this.loadStates();
      //   this.selectedUrls.forEach(url => {
      //     const file = this.createFileFromUrl(url);
      //     this.selectedFiles.push(file);
      //     this.generateThumbnail(file);
      // });
    }    
  }
  createFileFromUrl(url: string): File {
    // Fetch the file and create a Blob
    // You can use a suitable method to fetch the file content using the URL
    const blob = new Blob([/* fetched file content */]);
    const file = new File([blob], 'filename', { type: 'mime-type' });
    return file;
  }
  onFilesChange(files: File[]): void {
    //console.log('Selected files:', files);
  }
  onItemSelect(item: any) {
    //console.log(item);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }
  ngOnInit() {
    this.ddlMultiClusterSelectSettings = {
      singleSelection: false,
      idField: 'ID',
      textField: 'ClusterSlumName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.ddlMultiSelectSettings = {
      singleSelection: false,
      idField: 'ID',
      textField: 'DataName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    if(this.datafor=='ProjectStaff' || this.datafor=='SSVisitPlanning'){
      this.loadProjectStaffType();
    }    
    if(this.datafor=='ServiceProvider'){
      this.loadProviderType();
    }  
    if(this.datafor=='ActivityPlanning' || this.datafor=='Activity' || this.datafor=='SSVisitPlanning'){
      this.loadActivityType();
    }
    if(this.datafor=='WaterStagnation'){
      this.loadWaterStagnationType();
    }
    if(this.datafor=='SchoolData'){
      this.loadSchoolCategory();
      this.loadSchoolManagement();
    }
    this.LoadQuarterDated()
    this.selectsForm = this.formBuilder.group({
      ProjectInformation : new FormControl(''),
      ProjectName : new FormControl(''),
      NameofTeamMembers : new FormControl(''),
      CaseIdentificationNumber : new FormControl(''),
      participantname : new FormControl(''),
      father_husband_name : new FormControl(''),
      household_head : new FormControl(''),
      household_number : new FormControl(''),
      address_landmark : new FormControl(''),
      noofmember : new FormControl(''),
      noofperson_testdone : new FormControl(''),
      noofperson_testpositive : new FormControl(''),
      noof_pfpvmixedcase : new FormControl(''),
      noofperson_treatmentstarted : new FormControl(''),
      noofhousehold : new FormControl(''),
      noofhousehold_feversurvey : new FormControl(''),
      noofhousehold_feveridentified : new FormControl(''),
      noofperson_malariadenguescreen : new FormControl(''),
      noofperson_testedmalden : new FormControl(''),
      noofperson_pfpvmixedcase : new FormControl(''),
      noofhousehold_larvasurved : new FormControl(''),
      noofhousehold_larvafound : new FormControl(''),
      sourcereductionactivities : new FormControl(''),
      noofwaterstagnation : new FormControl(''),
      noofwaterstagnation_inspected : new FormControl(''),
      noofwaterstagnation_larvafound : new FormControl(''),
      typeof_larvadestruction : new FormControl(''),


      UpdateID: new FormControl('0'),
      DataName: new FormControl(''),
        modalstateid: new FormControl('0'),
        modaldistrictid: new FormControl('0',[Validators.required]),
        modalmunicipalAreaid: new FormControl('0',[Validators.required]),
        modalbccfZoneid: new FormControl('0',[Validators.required]),
        modalterritorialAreaid: new FormControl('0',[Validators.required]),
        modaladministrativeAreaid: new FormControl('0',[Validators.required]),
        ModalWaterStagnationNo : new FormControl('0'),
        ModalWaterStagnationTypeID: new FormControl('0'),
        ModalLarvaStatus : new FormControl(''),

        AdministrativeAreaType:new FormControl('0'),
        ModalHouseholdNo: new FormControl(''),
        ModalPopulation: new FormControl(''),
        ModalLatitude: new FormControl(''),
        ModalLongitude: new FormControl(''),
        ModalPINCode: new FormControl(''),
        ModalSlumCode: new FormControl(''),
        ModalInceptionDate: new FormControl(''),
        ModalPhaseOutDate: new FormControl(''),
        ModalSlumPhoto: new FormControl(''),
        ModalLayoutDate: new FormControl(''),
        ModalLayoutHelper: new FormControl(''),
        Modalshcname: new FormControl(''),
        Modalshcdist: new FormControl(''),
        Modalphcname: new FormControl(''),
        Modalphcdist: new FormControl(''),
        Modaldistrhospname: new FormControl(''),
        Modaldistrhospdist: new FormControl(''),
        Modalprivhospname: new FormControl(''),
        Modalprivhospdist: new FormControl(''),

        HouseholdDetails: this.formBuilder.array([]),
        ModalHouseHoldNo: new FormControl(''),
        ModalStructureNo: new FormControl(''),
        ModalNoofmember: new FormControl(''),
        ModalHouseHoldname: new FormControl(''),
        ModalHHMobile: new FormControl(''),
        ModalRented: new FormControl(''),
        ModalRenteddate: new FormControl(''),

        ModalDistanceO_L1: new FormControl(''),
        ModalDistanceL1_L2: new FormControl(''),
        ModalDistanceL2_O: new FormControl(''),
        ModalSSTimeFrom: new FormControl(''),
        ModalSSTimeTo: new FormControl(''),
        ModalSSStaffType: new FormControl(''),
        ModalSSVehicle: new FormControl(''),
        ModalSSActivity: new FormControl(''),
        ModalSSVisitDate: new FormControl(''),
        ModalExecutionStatus: new FormControl(''),
        ModalDeviationReason: new FormControl(''),

        ModalOutletType: new FormControl('0'),
        ModalAddress: new FormControl(''),

        ModalHNDSite: new FormControl(''),
        ModalHNDFixDay: new FormControl(''),

        ModalProjectStaffTypeID: new FormControl('0'),
        ModalMobile: new FormControl(''),
        ModalPSemail: new FormControl(''),
        ModalPSOfficialemail: new FormControl(''),
        ModalEducation: new FormControl(''),
        ModalExperience: new FormControl(''),
        ModalJoiningDate: new FormControl(''),
        ModalLeavingDate: new FormControl(''),
        ModalPostingLocation: new FormControl(''),
        modalmultipleadministrativeAreaid: new FormControl(''),
        modalmultipleclusterid: new FormControl(''),

        ModalProviderTypeID: new FormControl(''),
        ModalProviderID: new FormControl(''),
        ModalProviderStatus: new FormControl(''),
        ModalStatusReason: new FormControl(''),
        ModalMeetingType: new FormControl(''),
        ModalAppStatus: new FormControl(''),
        modalclusterid: new FormControl('0'),
        FirstPlanningDate: new FormControl(''),
        SecondPlanningDate: new FormControl(''),
        ThirdPlanningDate: new FormControl(''),
        FourthPlanningDate: new FormControl(''),
        FifthPlanningDate: new FormControl(''),
        SixthPlanningDate: new FormControl(''),
        SeventhPlanningDate: new FormControl(''),
        EighthPlanningDate: new FormControl(''),
        NinthPlanningDate: new FormControl(''),
        TenthPlanningDate: new FormControl(''),
        EleventhPlanningDate: new FormControl(''),
        TwelvthPlanningDate: new FormControl(''),
        HNDSiteID: new FormControl('0'),
        PlanningServiceProviderID: new FormControl('0'),
        PlanningSchoolID: new FormControl('0'),
        NIVSlumName: new FormControl(''),
        NIVAshaName: new FormControl(''),
        PlanningActivityType: new FormControl('0'),
        TrainingType: new FormControl('0'),
        PlanningFor: new FormControl(''),
        FinancialYear:new FormControl('2023-2024'),

        ModalSchoolCode: new FormControl(''),
        ModalSchoolname: new FormControl(''),
        ModalSchoolCategory: new FormControl(''),
        ModalSchoolManagement: new FormControl(''),
        ModalPrincipalName: new FormControl(''),
        ModalPrincipalMobile: new FormControl(''),
        ModalTeachersMale: new FormControl(''),
        ModalTeachersFemale: new FormControl(''),
        ClassDetails: this.formBuilder.array([]),
        
        ModalActivityConductedBy: new FormControl(''),
        ModalActivityName: new FormControl(''),
        ModalActivityVenue: new FormControl(''),
        ModalActivityBackground: new FormControl(''),
        ModalActivityObjectives: new FormControl(''),
        ModalActivityProcess: new FormControl(''),
        ModalActivityDate: new FormControl(''),
        ModalActivityTime: new FormControl(''),
        ModalNoofParticipants: new FormControl(''),
        ModalNameofParticipants: new FormControl(''),
        ModalOutcomeofmeeting: new FormControl(''),
        ModalServiceProviderIDs: new FormControl(''),
        ModalProjectStaffIDs: new FormControl(''),
        ModalSchoolActIDs: new FormControl(''),

        ModalRemark: new FormControl(''),
    }); 
    if(this.datafor=='Activity'){
      this.selectsForm.patchValue({PlanningActivityType:this.valuefor});
    }
    //this.LoadSelectedData();   
  }
  get myList(): FormArray {
    return this.selectsForm.get('ClassDetails') as FormArray;
  }
  get myHHList(): FormArray {
    return this.selectsForm.get('HouseholdDetails') as FormArray;
  }
  AddClassData(classdata:any) {
    // Add an item to the list
    this.myList.push(
      this.formBuilder.group({
        ModalSchoolClassID: new FormControl(classdata==null?'':classdata.ModalSchoolClassID),
        ModalSchoolClass: new FormControl(classdata==null?'':classdata.ModalSchoolClass),
        ModalSchoolSection: new FormControl(classdata==null?'':classdata.ModalSchoolSection),
        ModalClassTeacherName: new FormControl(classdata==null?'':classdata.ModalClassTeacherName),
        ModalClassTeacherMobile: new FormControl(classdata==null?'':classdata.ModalClassTeacherMobile),
        ModalStudentMale: new FormControl(classdata==null?'':classdata.ModalStudentMale),
        ModalStudentFemale: new FormControl(classdata==null?'':classdata.ModalStudentFemale),
        ModalStudentMalariaWarriorName: new FormControl(classdata==null?'':classdata.ModalStudentMalariaWarriorName),
        ModalStudentMalariaWarriorMobile: new FormControl(classdata==null?'':classdata.ModalStudentMalariaWarriorMobile),
      })
    );
  }
  AddHouseholdData(hhdata:any) {
    // Add an item to the list
    this.myHHList.push(
      this.formBuilder.group({
        StructureNo: new FormControl(hhdata==null?'':hhdata.StructureNo),
        HouseHoldNo: new FormControl(hhdata==null?'':hhdata.HouseHoldNo),
        Noofmember: new FormControl(hhdata==null?'':hhdata.Noofmember),
        HouseHoldname: new FormControl(hhdata==null?'':hhdata.HouseHoldname),
        HHMobile: new FormControl(hhdata==null?'':hhdata.HHMobile),
        Rented: new FormControl(hhdata==null?'':hhdata.Rented),
        Renteddate: new FormControl(hhdata==null?'':hhdata.Renteddate),        
      })
    );
  }
  ispastdatecheck(datetocheck:any){
    if(this.selectsForm.get(datetocheck)?.value != "" && this.selectsForm.get(datetocheck)?.value !="0000-00-00"){
      if(new Date(this.selectsForm.get(datetocheck)?.value)<new Date()){
        this.selectsForm.controls[datetocheck].disable();
      }
      else{
        this.selectsForm.controls[datetocheck].enable();
      }      
    }    
  }
  selectedadministrativeAreas:any[]=[];
  selectedClusters: any[] = [];
  selectedServiceProviders:any[]=[];
  selectedProjectStaffs:any[]=[];
  selectedSchoolAct:any[]=[];
  ddlMultiSelectSettings={};
  ddlMultiClusterSelectSettings={};
  LoadSelectedData(){
    
    // Populate child component form controls based on the selected row item
    this.selectsForm.patchValue({
      // Update form control values based on the selected row item properties
      UpdateID:this.selectedRowItem.ID, 
      DataName: this.selectedRowItem?.DataName     
    }); 
    if(this.datafor == 'ActivityType' || this.datafor == 'ProjectStaffType'){
      this.selectsForm.patchValue({ModalAppStatus: this.selectedRowItem?.AppDisplay})
    }
    if(this.datafor == 'HNDSite'){
      this.selectsForm.patchValue({ModalHNDSite: this.selectedRowItem?.HND_Location}),
      this.selectsForm.patchValue({ModalHNDFixDay: this.selectedRowItem?.FixHND_Day})
    }
    if(this.datafor == 'WaterStagnation'){
      this.selectsForm.patchValue({ModalWaterStagnationTypeID: this.selectedRowItem?.WaterSatgnation_Type_id}),
      this.selectsForm.patchValue({ModalWaterStagnationNo: this.selectedRowItem?.WaterSatgnation_No}),
      this.selectsForm.patchValue({ModalLarvaStatus: this.selectedRowItem?.Larva_Status})
      
    }
    if(this.datafor == 'SSVisitPlanning'){
      this.selectsForm.patchValue({ModalDistanceO_L1: this.selectedRowItem?.DistanceO_L1}),
      this.selectsForm.patchValue({ModalDistanceL1_L2: this.selectedRowItem?.DistanceL1_L2}),
      this.selectsForm.patchValue({ModalDistanceL2_O: this.selectedRowItem?.DistanceL2_O}),
      this.selectsForm.patchValue({ModalSSTimeFrom: this.selectedRowItem?.Time_From}),
      this.selectsForm.patchValue({ModalSSTimeTo: this.selectedRowItem?.Time_To}),
      this.selectsForm.patchValue({ModalSSStaffType: this.selectedRowItem?.StaffType}),
      this.selectsForm.patchValue({ModalSSVehicle: this.selectedRowItem?.Vehicle}),
      this.selectsForm.patchValue({ModalSSActivity: this.selectedRowItem?.ActivityName}),
      this.selectsForm.patchValue({ModalSSVisitDate: this.selectedRowItem?.ActivityDate}),
      
      this.selectsForm.patchValue({ModalExecutionStatus: this.selectedRowItem?.ExecutionStatus}),
      this.selectsForm.patchValue({ModalDeviationReason: this.selectedRowItem?.DeviationReason}),
      this.selectsForm.patchValue({ModalRemark: this.selectedRowItem?.Remark})
      
    }
    if(this.datafor == 'AdministrativeArea'){
      this.selectsForm.patchValue({AdministrativeAreaType: this.selectedRowItem?.Administrative_Area_Type}),
      this.selectsForm.patchValue({ModalLatitude: this.selectedRowItem?.Latitude}),
      this.selectsForm.patchValue({ModalLongitude: this.selectedRowItem?.Longitude}),
      this.selectsForm.patchValue({ModalPINCode: this.selectedRowItem?.Pincode}),
      this.selectsForm.patchValue({ModalSlumCode: this.selectedRowItem?.CensusCode}),
      this.selectsForm.patchValue({ModalInceptionDate: this.selectedRowItem?.InceptionDate.replace("0000-00-00","")}),
      this.selectsForm.patchValue({ModalPhaseOutDate: this.selectedRowItem?.PhaseOutDate.replace("0000-00-00","")}),
      //this.selectsForm.patchValue({ModalSlumPhoto: this.selectedRowItem?.Administrative_Area_Photo}),
      this.selectsForm.patchValue({ModalLayoutDate: this.selectedRowItem?.LayoutDate.replace("0000-00-00","")}),
      this.selectsForm.patchValue({ModalLayoutHelper: this.selectedRowItem?.LayoutHelper}),
      this.selectsForm.patchValue({Modalshcname: this.selectedRowItem?.SHCName}),
      this.selectsForm.patchValue({Modalshcdist: this.selectedRowItem?.SHCDistance}),
      this.selectsForm.patchValue({Modalphcname: this.selectedRowItem?.PHCName}),
      this.selectsForm.patchValue({Modalphcdist: this.selectedRowItem?.PHCDistance}),
      this.selectsForm.patchValue({Modaldistrhospname: this.selectedRowItem?.DistrictHospName}),
      this.selectsForm.patchValue({Modaldistrhospdist: this.selectedRowItem?.DistrictHospDistance}),
      this.selectsForm.patchValue({Modalprivhospname: this.selectedRowItem?.PrivateHospName}),
      this.selectsForm.patchValue({Modalprivhospdist: this.selectedRowItem?.PrivateHospDistance});
      this.ModalSlumPhotos= [];
    }
    if(this.datafor == 'HouseHold'){
      this.selectsForm.patchValue({ModalHouseHoldNo: this.selectedRowItem?.Household_No}),
      this.selectsForm.patchValue({ModalStructureNo: this.selectedRowItem?.Structure_No}),
      this.selectsForm.patchValue({ModalNoofmember: this.selectedRowItem?.NoOfMember}),
      this.selectsForm.patchValue({ModalHouseHoldname: this.selectedRowItem?.HouseHeadName}),
      this.selectsForm.patchValue({ModalHHMobile: this.selectedRowItem?.mobilenumber}),
      this.selectsForm.patchValue({ModalRented: this.selectedRowItem?.Rented}),
      this.selectsForm.patchValue({ModalRenteddate: this.selectedRowItem?.Rented_Date.replace("0000-00-00","")})      
    }
    if(this.datafor == 'OutletData'){
      this.selectsForm.patchValue({ModalOutletType: this.selectedRowItem?.Outlet_Type}),
      this.selectsForm.patchValue({ModalAddress: this.selectedRowItem?.Outlet_Address})
    }
    if(this.datafor == 'SchoolData'){
      this.selectsForm.patchValue({ModalSchoolCode: this.selectedRowItem?.School_code_DISE_code}),
      this.selectsForm.patchValue({ModalSchoolname: this.selectedRowItem?.School_Name}),
      this.selectsForm.patchValue({ModalSchoolCategory: this.selectedRowItem?.School_Category}),
      this.selectsForm.patchValue({ModalSchoolManagement: this.selectedRowItem?.School_Management}),
      this.selectsForm.patchValue({ModalPrincipalName: this.selectedRowItem?.PrincipalName}),
      this.selectsForm.patchValue({ModalPrincipalMobile: this.selectedRowItem?.PrincipalMobileNo}),
      this.selectsForm.patchValue({ModalTeachersMale: this.selectedRowItem?.NumberOfTeacherMale}),
      this.selectsForm.patchValue({ModalTeachersFemale: this.selectedRowItem?.NumberOfTeacherFemale});
      this.onSchoolCategoryChange();
      if(this.selectedRowItem?.SchoolClass){
        for(let h=0;h<this.selectedRowItem?.SchoolClass.length;h++){
          this.AddClassData(this.selectedRowItem?.SchoolClass[h]);
        }
      }
    }
    if(this.datafor == 'Activity'){
      this.selectsForm.patchValue({ModalActivityConductedBy: this.selectedRowItem?.ConductedBy}),
      this.selectsForm.patchValue({ModalActivityName: this.selectedRowItem?.Activity_Name}),
      this.selectsForm.patchValue({ModalActivityVenue: this.selectedRowItem?.Venue}),
      this.selectsForm.patchValue({ModalActivityBackground: this.selectedRowItem?.Background}),
      this.selectsForm.patchValue({ModalActivityObjectives: this.selectedRowItem?.Objectives}),
      this.selectsForm.patchValue({ModalActivityProcess: this.selectedRowItem?.Process}),
      this.selectsForm.patchValue({ModalActivityDate: this.selectedRowItem?.Activity_Date}),
      this.selectsForm.patchValue({ModalActivityTime: this.selectedRowItem?.ActivityTime}),
      this.selectsForm.patchValue({ModalNoofParticipants: this.selectedRowItem?.No_of_Participants}),
      this.selectsForm.patchValue({ModalNameofParticipants: this.selectedRowItem?.Name_of_Participants}),
      this.selectsForm.patchValue({ModalOutcomeofmeeting: this.selectedRowItem?.Outcome}),
      this.selectsForm.patchValue({PlanningActivityType:this.selectedRowItem.ActivityType_id});
      this.ModalActivityPhoto= [];
      this.ModalActivityAttendance= [];
      if(this.valuefor=='84'){
        this.selectsForm.patchValue({ProjectInformation:this.selectedRowItem.ProjectInformation});
        this.selectsForm.patchValue({ProjectName:this.selectedRowItem.ProjectName});
        this.selectsForm.patchValue({NameofTeamMembers:this.selectedRowItem.NameofTeamMembers});
        this.selectsForm.patchValue({CaseIdentificationNumber:this.selectedRowItem.CaseIdentificationNumber});
        this.selectsForm.patchValue({participantname:this.selectedRowItem.participantname});
        this.selectsForm.patchValue({father_husband_name:this.selectedRowItem.father_husband_name});
        this.selectsForm.patchValue({household_head:this.selectedRowItem.household_head});
        this.selectsForm.patchValue({household_number:this.selectedRowItem.household_number});
        this.selectsForm.patchValue({address_landmark:this.selectedRowItem.address_landmark});
        this.selectsForm.patchValue({noofmember:this.selectedRowItem.noofmember});
        this.selectsForm.patchValue({noofperson_testdone:this.selectedRowItem.noofperson_testdone});
        this.selectsForm.patchValue({noofperson_testpositive:this.selectedRowItem.noofperson_testpositive});
        this.selectsForm.patchValue({noof_pfpvmixedcase:this.selectedRowItem.noof_pfpvmixedcase});
        this.selectsForm.patchValue({noofperson_treatmentstarted:this.selectedRowItem.noofperson_treatmentstarted});
        this.selectsForm.patchValue({noofhousehold:this.selectedRowItem.noofhousehold});
        this.selectsForm.patchValue({noofhousehold_feversurvey:this.selectedRowItem.noofhousehold_feversurvey});
        this.selectsForm.patchValue({noofhousehold_feveridentified:this.selectedRowItem.noofhousehold_feveridentified});
        this.selectsForm.patchValue({noofperson_malariadenguescreen:this.selectedRowItem.noofperson_malariadenguescreen});
        this.selectsForm.patchValue({noofperson_testedmalden:this.selectedRowItem.noofperson_testedmalden});
        this.selectsForm.patchValue({noofperson_pfpvmixedcase:this.selectedRowItem.noofperson_pfpvmixedcase});
        this.selectsForm.patchValue({noofhousehold_larvasurved:this.selectedRowItem.noofhousehold_larvasurved});
        this.selectsForm.patchValue({noofhousehold_larvafound:this.selectedRowItem.noofhousehold_larvafound});
        this.selectsForm.patchValue({sourcereductionactivities:this.selectedRowItem.sourcereductionactivities});
        this.selectsForm.patchValue({noofwaterstagnation:this.selectedRowItem.noofwaterstagnation});
        this.selectsForm.patchValue({noofwaterstagnation_inspected:this.selectedRowItem.noofwaterstagnation_inspected});
        this.selectsForm.patchValue({noofwaterstagnation_larvafound:this.selectedRowItem.noofwaterstagnation_larvafound});
        this.selectsForm.patchValue({typeof_larvadestruction:this.selectedRowItem.typeof_larvadestruction});
      }
    }    
    if(this.datafor=='ProjectStaff'){
      this.selectsForm.patchValue({ModalProjectStaffTypeID: this.selectedRowItem?.ProjectStaffType_id}),
      this.selectsForm.patchValue({ModalMobile: this.selectedRowItem?.mobilenumber}),
      this.selectsForm.patchValue({ModalPSemail: this.selectedRowItem?.emailid}),
      this.selectsForm.patchValue({ModalPSOfficialemail: this.selectedRowItem?.OfficialEmailID}),
      this.selectsForm.patchValue({ModalEducation: this.selectedRowItem?.Education_Qualification}),
      this.selectsForm.patchValue({ModalExperience: this.selectedRowItem?.Experience_Year_PH}),
      this.selectsForm.patchValue({ModalJoiningDate: this.selectedRowItem?.ProjectJoining_Date.replace("0000-00-00","")}),
      this.selectsForm.patchValue({ModalLeavingDate: this.selectedRowItem?.ProjectLeaving_Date.replace("0000-00-00","")}),
      this.selectsForm.patchValue({ModalPostingLocation: this.selectedRowItem?.PostingLocation});
      this.ModalPhotos= [];
    }
    if(this.datafor=='ServiceProvider'){
      this.selectsForm.patchValue({ModalProviderTypeID: this.selectedRowItem?.ProviderType_id}),
      this.selectsForm.patchValue({ModalAddress: this.selectedRowItem?.Address}),
      this.selectsForm.patchValue({ModalMobile: this.selectedRowItem?.mobilenumber}),
      this.selectsForm.patchValue({ModalJoiningDate: this.selectedRowItem?.DateofEngagement.replace("0000-00-00","")}),
      this.selectsForm.patchValue({ModalProviderID: this.selectedRowItem?.ProviderID})   
      this.selectsForm.patchValue({ModalProviderStatus: this.selectedRowItem?.ProviderStatus})   
      this.selectsForm.patchValue({ModalStatusReason: this.selectedRowItem?.StatusReason})   
    }
    if(this.datafor=='ChaupalPlanning' || this.datafor=='EModulePlanning' || this.datafor=='SchoolEventPlanning' || this.datafor=='HNDPlanning' || this.datafor=='ActivityPlanning' || this.datafor=='MeetingPlanning'){
      this.selectsForm.patchValue({FinancialYear:this.selectedRowItem.FinancialYear});      
      this.selectsForm.patchValue({FirstPlanningDate:this.selectedRowItem.First_Date.replace("0000-00-00","")});
      //this.ispastdatecheck('FirstPlanningDate');
      this.selectsForm.patchValue({SecondPlanningDate:this.selectedRowItem.Second_Date.replace("0000-00-00","")});
      //this.ispastdatecheck('SecondPlanningDate');
      this.selectsForm.patchValue({ThirdPlanningDate:this.selectedRowItem.Third_Date.replace("0000-00-00","")});
      //this.ispastdatecheck('ThirdPlanningDate');
      this.selectsForm.patchValue({FourthPlanningDate:this.selectedRowItem.Fourth_Date.replace("0000-00-00","")});
      //this.ispastdatecheck('FourthPlanningDate');
      if(this.datafor=='HNDPlanning' || this.datafor=='ActivityPlanning' || this.datafor=='MeetingPlanning'){
        this.selectsForm.patchValue({FifthPlanningDate:this.selectedRowItem.Fifth_Date.replace("0000-00-00","")});
        this.selectsForm.patchValue({SixthPlanningDate:this.selectedRowItem.Sixth_Date.replace("0000-00-00","")});
        this.selectsForm.patchValue({SeventhPlanningDate:this.selectedRowItem.Seventh_Date.replace("0000-00-00","")});
        this.selectsForm.patchValue({EighthPlanningDate:this.selectedRowItem.Eighth_Date.replace("0000-00-00","")});
        this.selectsForm.patchValue({NinthPlanningDate:this.selectedRowItem.Ninth_Date.replace("0000-00-00","")});
        this.selectsForm.patchValue({TenthPlanningDate:this.selectedRowItem.Tenth_Date.replace("0000-00-00","")});
        this.selectsForm.patchValue({EleventhPlanningDate:this.selectedRowItem.Eleventh_Date.replace("0000-00-00","")});
        this.selectsForm.patchValue({TwelvthPlanningDate:this.selectedRowItem.Twelvth_Date.replace("0000-00-00","")});
      }
      if(this.datafor=='MeetingPlanning'){
        this.selectsForm.patchValue({ModalMeetingType:this.valuefor});
      }
      if(this.datafor=='SchoolEventPlanning'){
        this.selectsForm.patchValue({PlanningSchoolID:this.selectedRowItem.School_id});
      }
      if(this.datafor=='ActivityPlanning'){
        this.selectsForm.patchValue({PlanningActivityType:this.selectedRowItem.ActivityType_id});
      }
      if(this.datafor=='EModulePlanning'){
        this.selectsForm.patchValue({PlanningServiceProviderID:this.selectedRowItem.ServiceProvider_id});
        this.selectsForm.patchValue({NIVSlumName:this.selectedRowItem.Administrative_Area_Name});
        this.selectsForm.patchValue({NIVAshaName:this.selectedRowItem.ServiceProviderName});
        this.selectsForm.patchValue({TrainingType:this.selectedRowItem.Training_Type});        
      }
      if(this.valuefor=='CommunicationActivity'){
        this.selectsForm.patchValue({PlanningFor:this.selectedRowItem.PlanningFor});                   
      }      
    }
    if(!(this.datafor=='State' || this.datafor=='ActivityType' || this.datafor=='ProjectStaffType')){      
      const currentStateId = this.selectedRowItem?.State_id;           
      this.selectsForm.patchValue({ modalstateid: currentStateId });
      if(this.datafor!='District'){
        this.loadDistricts(currentStateId);         
      }
    }    
  }
  ngOnChanges(changes: SimpleChanges) {
    // Handle changes to the selected row item
    //console.log(changes['selectedRowItem']);
    
    this.ResetGeoDataData('BCCFZone');
    this.ResetFormData();
    if (changes['selectedRowItem'] && !changes['selectedRowItem'].firstChange) {
      this.selectedRowItem=changes['selectedRowItem'].currentValue;  
      if(this.selectedRowItem!=undefined){
        this.LoadSelectedData();
      }
    }    
  }
  CheckLoginType(checkfor:any):boolean{
    var resp :boolean=false;
    if(checkfor==='BCCFZone'){
      resp = this.userlogin.logintype=='BCCFZone';
    }
    if(checkfor==='MunicipalArea'){
      resp = this.userlogin.logintype=='MunicipalArea' || this.userlogin.logintype=='BCCFZone';
    }
    if(checkfor==='District'){
      resp = this.userlogin.logintype=='District' || this.userlogin.logintype=='MunicipalArea' || this.userlogin.logintype=='BCCFZone';
    }
    if(checkfor==='State'){
      resp = this.userlogin.logintype=='State' || this.userlogin.logintype=='District' || this.userlogin.logintype=='MunicipalArea' || this.userlogin.logintype=='BCCFZone';
    }
    return resp;
  }
  async loadStates() {
    this.states = await lastValueFrom(this.rtmisservice.GetMasterData('State', 'main', '1', 'stateid', this.CheckLoginType('State')?this.userlogin.State_id:'0').pipe(takeLast(1)));      
  }
  
  async loadDistricts(stateId: number) {
    this.districts =await lastValueFrom(this.rtmisservice.GetMasterData('District', 'stateid', stateId, 'districtid', this.CheckLoginType('District')?this.userlogin.District_id:'0').pipe(takeLast(1)));
      
    const currentDistrictId = this.selectedRowItem?.District_id;        
      if (currentDistrictId) {
        setTimeout(() => {
          this.selectsForm.patchValue({ modaldistrictid: currentDistrictId });
        }, 0);
        if(!(this.datafor=='MunicipalArea' || this.datafor=='MeetingPlanning' || this.datafor=='EmbedTarget')){
          await this.loadMunicipalAreas(currentDistrictId);
        }
      } else {
        this.ResetGeoDataData("Municipal");
      }    
  }
  
  async loadMunicipalAreas(districtId: number) {
    if(districtId==0){ this.municipalAreas=[];return;}
    this.municipalAreas =await lastValueFrom(this.rtmisservice.GetMasterData('MunicipalArea', 'districtid', districtId, 'municipalareaid', this.CheckLoginType('MuncipalArea')?this.userlogin.MuncipalArea_id:'0').pipe(takeLast(1)));
      const currentMunicipalAreaId = this.selectedRowItem?.MunicipalArea_id;
      if (currentMunicipalAreaId) {
        setTimeout(() => {
          this.selectsForm.patchValue({ modalmunicipalAreaid: currentMunicipalAreaId });
        }, 0);
        if(!(this.datafor=='BCCFZone')){
          await this.loadBCCFZones(currentMunicipalAreaId);
        }
      } else {
        this.ResetGeoDataData("BCCFZone");
      }      
  }  
  
  async loadBCCFZones(municipalAreaId: number) {   
    if(municipalAreaId==0){ this.bccfZones=[];return;}
    this.bccfZones =await lastValueFrom(this.rtmisservice.GetMasterData('BCCFZone', 'municipalareaid', municipalAreaId, 'zoneid', this.CheckLoginType('BCCFZone')?this.userlogin.ZoneID:'0').pipe(takeLast(1)));
      const currentbccfZoneId = this.selectedRowItem?.BCCFZone_id;
      if (currentbccfZoneId) {
        setTimeout(() => {          
          this.selectsForm.patchValue({ modalbccfZoneid: currentbccfZoneId });
        }, 0);
        if(!(this.datafor=='TerritorialArea' || this.datafor=='User')){
          await this.loadTerritorialAreas(currentbccfZoneId);
        }
      } else {
        this.ResetGeoDataData("Territorial");
      }     
  }
  
  async loadTerritorialAreas(bccfZoneId: number) {
    if(bccfZoneId==0){ this.territorialAreas=[];return;}
    this.territorialAreas =await lastValueFrom(this.rtmisservice.GetMasterData('TerritorialArea', 'zoneid', bccfZoneId, 'territorialareaid', '0').pipe(takeLast(1)));
      const currentterritorialAreaId = this.selectedRowItem?.TerritorialArea_id;
      if (currentterritorialAreaId) {
        setTimeout(() => { 
          this.selectsForm.patchValue({ modalterritorialAreaid: currentterritorialAreaId });
        }, 0);
        if(!(this.datafor=='AdministrativeArea' || (this.datafor=='EModulePlanning' && this.valuefor=='NIV'))){
          await this.loadAdministrativeAreas(currentterritorialAreaId);
        }
      } else {
        this.ResetGeoDataData("Administrative");
      }  
  }

  async loadAdministrativeAreas(territorialAreaId: number) {  
    this.administrativeAreas =await lastValueFrom(this.rtmisservice.GetMasterData('AdministrativeArea', 'territorialareaid', territorialAreaId, 'administrativeareaid', '0').pipe(takeLast(1)));
      const currentadministrativeId = this.selectedRowItem?.Administrative_Area_id;
      if (currentadministrativeId) {
        setTimeout(() => { 
          this.selectsForm.patchValue({ modaladministrativeAreaid: currentadministrativeId });    
        }, 0);
        if(this.datafor=='HouseHold' || this.datafor=='ChaupalPlanning' || this.datafor=='SSVisitPlanning' || (this.datafor=='ServiceProvider' && this.valuefor=='9')){
          await this.loadClusters(currentadministrativeId);
        }
        if(this.datafor=='HNDPlanning'){
          await this.loadHNDSites(currentadministrativeId);
        }
        if(this.datafor=='SchoolEventPlanning'){
          await this.loadSchools(currentadministrativeId);
        }
        if(this.datafor=='ServiceProvider' || this.datafor=='Activity' || this.datafor=='EModulePlanning'){   
          if(this.datafor=='ServiceProvider' || this.datafor=='EModulePlanning'){         
            await this.loadServicePovider(currentadministrativeId); 
          }
          if(this.datafor=='Activity' && (this.valuefor == '9' || this.valuefor == '10' || this.valuefor == '11' || this.valuefor == '12')){          
            await this.loadServicePovider(currentadministrativeId);          
          }  
          if(this.datafor=='Activity' && (this.valuefor == '2')){          
            await this.loadSchools(currentadministrativeId);          
          } 
          if(this.datafor=='Activity' && (this.valuefor == '24')){   
            await this.loadClusters(currentadministrativeId);          
          }
          if (this.datafor=='Activity') {
            setTimeout(() => {
                
                this.selectedadministrativeAreas=[];
                for(let y=0;y<this.administrativeAreas.length;y++){
                  if((","+this.selectedRowItem?.Administrative_Area_id).includes(","+this.administrativeAreas[y].ID)){
                    this.selectedadministrativeAreas.push({"ID":this.administrativeAreas[y].ID,"DataName":this.administrativeAreas[y].DataName});                
                  }
                }  
                this.selectsForm.patchValue({ modalmultipleadministrativeAreaid: this.selectedadministrativeAreas });               
            }, 0);
          }       
        }
      }
      else {        
        this.ResetGeoDataData("Cluster");
      }
              
  }
  onSelect (data:any){
    
    let seladministrativeAreas="";
    for (var u = 0; u < this.selectedadministrativeAreas.length; u++) {
      seladministrativeAreas = seladministrativeAreas+(seladministrativeAreas==""?"": ',') + this.selectedadministrativeAreas[u].ID;
    }
    if(this.datafor=='Activity' && (this.valuefor == '9' || this.valuefor == '10' || this.valuefor == '11' || this.valuefor == '12')){
      this.loadServicePovider(seladministrativeAreas);
    }
    if(this.datafor=='Activity' && this.valuefor == '2'){
      this.loadSchools(seladministrativeAreas);
    }
    if(this.datafor=='Activity' && this.valuefor == '24'){
      this.loadClusters(seladministrativeAreas);
    }
  }
  onDeSelect(data:any){
    
    let seladministrativeAreas="";
    for (var u = 0; u < this.selectedadministrativeAreas.length; u++) {
      seladministrativeAreas = seladministrativeAreas+(seladministrativeAreas==""?"": ',') + this.selectedadministrativeAreas[u].ID;
    }
    if(this.datafor=='Activity' && (this.valuefor == '9' || this.valuefor == '10' || this.valuefor == '11' || this.valuefor == '12')){
      this.loadServicePovider(seladministrativeAreas);
    }
    if(this.datafor=='Activity' && this.valuefor == '2'){
      this.loadSchools(seladministrativeAreas);
    }
    if(this.datafor=='Activity' && this.valuefor == '24'){
      this.loadClusters(seladministrativeAreas);
    }
  }
  isscaleup_civic:boolean=false;
  async loadClusters(administrativeAreaId: any) {  
    
    if((this.datafor=='ServiceProvider' && this.valuefor=='9') || this.datafor=='HouseHold' || this.datafor=='SSVisitPlanning' || (this.datafor=='Activity' && this.valuefor=='24') || (this.datafor=='ChaupalPlanning'))
    {
      this.isscaleup_civic=true;
      for (var u = 0; u < this.administrativeAreas.length; u++) {
        if(this.administrativeAreas[u].Administrative_Area_id==administrativeAreaId && (this.administrativeAreas[u].Administrative_Area_Type=='CIVIC' || this.administrativeAreas[u].Administrative_Area_Type=='SCALEUP')){
          this.isscaleup_civic=false;
          break;
        }      
      }
    }
    else{
      this.isscaleup_civic=false;
    }      
    this.clusters =await lastValueFrom(this.rtmisservice.GetMasterData('Cluster', 'administrativeareaid', administrativeAreaId, 'clusterid', '0').pipe(takeLast(1)));      
    
      const currentclusterId = this.selectedRowItem?.Cluster_id;      
      if (currentclusterId) {
        setTimeout(() => {
          this.selectsForm.patchValue({ modalclusterid: currentclusterId });             
        }, 0);
      }
      if (this.selectedRowItem?.Cluster_ids  && ((this.datafor=='Activity' && this.valuefor == '24') || (this.valuefor=='CommunicationActivity'))) {
        setTimeout(() => {
            
            this.selectedClusters=[];
            for(let y=0;y<this.clusters.length;y++){
              if((","+this.selectedRowItem?.Cluster_ids).includes(","+this.clusters[y].ID)){
                this.selectedClusters.push({"ID":this.clusters[y].ID,"ClusterSlumName":this.clusters[y].ClusterSlumName});                
              }
            }  
            this.selectsForm.patchValue({ modalmultipleclusterid: this.selectedClusters });               
        }, 0);
      }
  }
  async loadHNDSites(administrativeAreaId: number) {
    this.rtmisservice.GetMasterData('HNDSite', 'administrativeareaid', administrativeAreaId, '', '').subscribe(hndsites => {
        this.hndsites = hndsites;
    });
  }
  
  loadServicePovider(administrativeAreaId: any) {
    var Providertypeid:any;
    
    if(this.datafor=='ServiceProvider' || this.datafor=='Activity' || this.datafor=='EModulePlanning'){
      if(this.valuefor=="1" && this.datafor=='ServiceProvider')
      {Providertypeid = '10';}
      if(this.valuefor=="IV" && this.datafor=='EModulePlanning')
      {Providertypeid = this.valueforid;}
      else if(this.datafor=='Activity' && (this.valuefor == '9' || this.valuefor == '10' || this.valuefor == '11' || this.valuefor == '12'))
      {Providertypeid = this.valuefor=='9'?'7':this.valuefor=='10'?'8':this.valuefor=='11'?'9':'1';}
      else
      {Providertypeid = this.valuefor;}
    }
    this.rtmisservice.GetMasterData('ServiceProvider', 'administrativeareaid', administrativeAreaId, 'valuefor', Providertypeid).subscribe(serviceproviders => {
      
        this.ModalServiceProviders = serviceproviders;
        if (this.selectedRowItem?.ServiceProviderIDs  && (this.datafor=='Activity' && (this.valuefor == '9' || this.valuefor == '10' || this.valuefor == '11' || this.valuefor == '12'))) {
          setTimeout(() => {
              
              this.selectedServiceProviders=[];
              for(let y=0;y<this.ModalServiceProviders.length;y++){
                if((","+this.selectedRowItem?.ServiceProviderIDs).includes(","+this.ModalServiceProviders[y].ID)){
                  this.selectedServiceProviders.push({"ID":this.ModalServiceProviders[y].ID,"DataName":this.ModalServiceProviders[y].DataName});                
                }
              }  
              this.selectsForm.patchValue({ ModalServiceProviderIDs: this.selectedServiceProviders });               
          }, 0);
        }
    });
  }
  loadProjectStaffType() {
    this.rtmisservice.GetMasterData('ProjectStaffType', 'main', '', '', '').subscribe(ProjStaffTypes => {
        this.masterProjectStaffType = ProjStaffTypes;
    });
  }
  masterProjectStaff:any[]=[];
  
  loadProjectStaff(administrativeAreaId: number) {
    this.rtmisservice.GetMasterData('ProjectStaff', 'administrativeareaid', administrativeAreaId, '', '').subscribe(projectstaffs => {
      
        this.masterProjectStaff = projectstaffs;
        if (this.selectedRowItem?.ProjectStaffIDs  && (this.datafor=='Activity' && (this.valuefor == '13'))) {
          setTimeout(() => {
              
              this.selectedProjectStaffs=[];
              for(let y=0;y<this.masterProjectStaff.length;y++){
                if((","+this.selectedRowItem?.ProjectStaffIDs).includes(","+this.masterProjectStaff[y].ID)){
                  this.selectedProjectStaffs.push({"ID":this.masterProjectStaff[y].ID,"DataName":this.masterProjectStaff[y].DataName});                
                }
              }  
              this.selectsForm.patchValue({ ModalProjectStaffIDs: this.selectedProjectStaffs });               
          }, 0);
        }
    });
  }
  loadWaterStagnationType() {
    this.rtmisservice.GetMasterData('WSType', 'main', '', '', '').subscribe(WaterStagnationTypes => {
        this.masterWaterStagnationType = WaterStagnationTypes;
    });
  }
  loadProviderType() {
    this.rtmisservice.GetMasterData('ProviderType', 'main', '', '', '').subscribe(ProviderTypes => {
        this.masterProviderType = ProviderTypes;
        this.selectsForm.patchValue({ ModalProviderTypeID: this.valuefor }); 
    });
  }
  masterSchoolCategory:any[]=[];
  loadSchoolCategory() {
    this.rtmisservice.GetMasterData('SchoolCategory', 'main', '', '', '').subscribe(SchoolCategorys => {
        this.masterSchoolCategory = SchoolCategorys;
    });
  }
  masterSchoolManagement:any[]=[];
  loadSchoolManagement() {
    this.rtmisservice.GetMasterData('SchoolManagement', 'main', '', '', '').subscribe(SchoolManagements => {
        this.masterSchoolManagement = SchoolManagements;
    });
  }
  
  loadSchools(administrativeAreaId: any) {
    this.rtmisservice.GetMasterData('SchoolData', 'administrativeareaid', administrativeAreaId, '', '').subscribe(schooldata => {
        this.schooldata = schooldata;
        if (this.selectedRowItem?.SchoolIDs  && (this.datafor=='Activity' && (this.valuefor == '2'))) {
          setTimeout(() => {
              
              this.selectedSchoolAct=[];
              for(let y=0;y<this.schooldata.length;y++){
                if((","+this.selectedRowItem?.SchoolIDs).includes(","+this.schooldata[y].ID)){
                  this.selectedSchoolAct.push({"ID":this.schooldata[y].ID,"DataName":this.schooldata[y].DataName});                
                }
              }  
              this.selectsForm.patchValue({ ModalSchoolActIDs: this.selectedSchoolAct });               
          }, 0);
        }
    });
  }
  onSchoolCategoryChange() {
    this.loadSchoolClasses(this.selectsForm.get('ModalSchoolCategory')?.value);    
  }
  masterSchoolClass:any[]=[];
  loadSchoolClasses(SchoolCategory:any) { 
		if(SchoolCategory == null)
		{this.masterSchoolClass = [];}
		else {
      if(SchoolCategory=="PRIMARY")
        this.masterSchoolClass = [{"SchoolClass":"1"},{"SchoolClass":"2"},{"SchoolClass":"3"},{"SchoolClass":"4"},{"SchoolClass":"5"}];
      else if(SchoolCategory=="MIDDLE")
        this.masterSchoolClass = [{"SchoolClass":"1"},{"SchoolClass":"2"},{"SchoolClass":"3"},{"SchoolClass":"4"},{"SchoolClass":"5"},{"SchoolClass":"6"},{"SchoolClass":"7"},{"SchoolClass":"8"}];
      else if(SchoolCategory=="HIGH SCHOOL")
        this.masterSchoolClass = [{"SchoolClass":"1"},{"SchoolClass":"2"},{"SchoolClass":"3"},{"SchoolClass":"4"},{"SchoolClass":"5"},{"SchoolClass":"6"},{"SchoolClass":"7"},{"SchoolClass":"8"},{"SchoolClass":"9"},{"SchoolClass":"10"}];
      else
        this.masterSchoolClass = [{"SchoolClass":"1"},{"SchoolClass":"2"},{"SchoolClass":"3"},{"SchoolClass":"4"},{"SchoolClass":"5"},{"SchoolClass":"6"},{"SchoolClass":"7"},{"SchoolClass":"8"},{"SchoolClass":"9"},{"SchoolClass":"10"},{"SchoolClass":"11"},{"SchoolClass":"12"}];
		}
	}
	
  loadActivityType() {
    this.rtmisservice.GetMasterData('ActivityType', 'main', '', '', '').subscribe(activitytypes => {
        this.activitytypes = activitytypes;
    });
  }
  
  onStateChange() {
    this.loadDistricts(this.selectsForm.get('modalstateid')?.value);
    this.ResetGeoDataData('District');
  }

  onDistrictChange() {
    this.loadMunicipalAreas(this.selectsForm.get('modaldistrictid')?.value);
    this.ResetGeoDataData('Municipal');
  }

  onMunicipalAreaChange() {
    this.loadBCCFZones(this.selectsForm.get('modalmunicipalAreaid')?.value);
    this.ResetGeoDataData('BCCFZone');
  }

  onBCCFZoneChange() {
    this.loadTerritorialAreas(this.selectsForm.get('modalbccfZoneid')?.value);
    this.ResetGeoDataData('Territorial');
  }

  onTerritorialAreaChange() {
    
    this.loadAdministrativeAreas(this.selectsForm.get('modalterritorialAreaid')?.value);
    this.ResetGeoDataData('Administrative');
  }
  onAdministrativeAreaChange() {
    
    this.loadClusters(this.selectsForm.get('modaladministrativeAreaid')?.value);
    if(this.datafor=='HNDPlanning'){
      this.loadHNDSites(this.selectsForm.get('modaladministrativeAreaid')?.value);
    }
    if(this.datafor=='ServiceProvider' || (this.datafor=='Activity' && (this.valuefor == '9' || this.valuefor == '10' || this.valuefor == '11' || this.valuefor == '12')) || this.datafor=='EModulePlanning'){
      this.loadServicePovider(this.selectsForm.get('modaladministrativeAreaid')?.value);
    }
    if(this.datafor=='SchoolEventPlanning' || (this.datafor=='Activity' && this.valuefor == '2')){
      this.loadSchools(this.selectsForm.get('modaladministrativeAreaid')?.value);
    }
    this.ResetGeoDataData('Cluster');
  }
  ValidateFormControl(valuetocheck:any,value:any,anycondition:any):boolean{
    if(anycondition){
      if(valuetocheck==value){
        return true;
      }
    }
    return false;
  }
  customvalidationerr:boolean=false;
  @ViewChildren(IncludeIfDirective) includeIfDirectives!: QueryList<IncludeIfDirective>;
  onSubmit() {    
       
    let seladministrativeAreas:any="";let selClusters:any="";let selServiceProviders:any="";let selProjectStaffs:any="";let selSchoolActs:any="";
    for (var u = 0; u < this.selectedadministrativeAreas.length; u++) {
      seladministrativeAreas = seladministrativeAreas+(seladministrativeAreas==""?"": ',') + this.selectedadministrativeAreas[u].ID;
    } 
    for (var u = 0; u < this.selectedClusters.length; u++) {
      selClusters = selClusters+(selClusters==""?"": ',') + this.selectedClusters[u].ID;
    }   
    for (var u = 0; u < this.selectedServiceProviders.length; u++) {
      selServiceProviders = selServiceProviders+(selServiceProviders==""?"": ',') + this.selectedServiceProviders[u].ID;
    } 
    for (var u = 0; u < this.selectedProjectStaffs.length; u++) {
      selProjectStaffs = selProjectStaffs+(selProjectStaffs==""?"": ',') + this.selectedProjectStaffs[u].ID;
    } 
    for (var u = 0; u < this.selectedSchoolAct.length; u++) {
      selSchoolActs = selSchoolActs+(selSchoolActs==""?"": ',') + this.selectedSchoolAct[u].ID;
    } 
    this.customvalidationerr=false;
    if(this.selectsForm.get('modalstateid')?.value=="0" && !(this.datafor=='State' || this.datafor=='ActivityType' || this.datafor=='ProjectStaffType')){alert("Kindly Select State");return;}
    if(this.selectsForm.get('modaldistrictid')?.value=="0" && !(this.datafor=='District' || this.datafor=='ActivityType' || this.datafor=='ProjectStaffType' || this.datafor=='State')){alert("Kindly Select District");return;}
    if(this.selectsForm.get('modalmunicipalAreaid')?.value=="0" && !(this.datafor=='ProjectStaff' || this.datafor=='MunicipalArea' || this.datafor=='MeetingPlanning' || this.datafor=='EmbedTarget' || this.datafor=='District' || this.datafor=='ActivityType' || this.datafor=='ProjectStaffType' || this.datafor=='State')){alert("Kindly Select Nagar Nigam / Block");return;}
    if(this.selectsForm.get('modalbccfZoneid')?.value=="0" && !(this.datafor=='ProjectStaff' || this.datafor=='MunicipalArea' || this.datafor=='MeetingPlanning' || this.datafor=='EmbedTarget' || this.datafor=='District' || this.datafor=='ActivityType' || this.datafor=='ProjectStaffType' || this.datafor=='State' || this.datafor=='BCCFZone' || this.datafor=='Activity' ||  (this.datafor=='EModulePlanning' && this.valuefor=='NIV'))){alert("Kindly Select BCCF Zone");return;}
    if(this.selectsForm.get('modalterritorialAreaid')?.value=="0" && !(this.datafor=='ProjectStaff' || this.datafor=='MunicipalArea' || this.datafor=='MeetingPlanning' || this.datafor=='EmbedTarget' || this.datafor=='District' || this.datafor=='ActivityType' || this.datafor=='ProjectStaffType' || this.datafor=='State' || this.datafor=='BCCFZone' || this.datafor=='Activity' || (this.datafor=='EModulePlanning' && this.valuefor=='NIV') || this.datafor=='TerritorialArea' || this.datafor=='User')){alert("Kindly Select Ward / Panchayat");return;}
    if(this.selectsForm.get('modaladministrativeAreaid')?.value=="0" && !(this.datafor=='ProjectStaff' || this.datafor=='MunicipalArea' || this.datafor=='MeetingPlanning' || this.datafor=='EmbedTarget' || this.datafor=='District' || this.datafor=='ActivityType' || this.datafor=='ProjectStaffType' || this.datafor=='State' || this.datafor=='BCCFZone' || this.datafor=='Activity' || (this.datafor=='EModulePlanning' && this.valuefor=='NIV') || this.datafor=='TerritorialArea' || this.datafor=='User' || this.datafor=='AdministrativeArea')){alert("Kindly Select Slum / Village");return;}
    
    
    if(this.selectsForm.get('modalclusterid')?.value=="0" && (this.datafor=='HouseHold' || (this.isscaleup_civic && (this.datafor=='SSVisitPlanning' || (this.datafor=='ServiceProvider' && this.valuefor=='9') || (this.datafor=='ChaupalPlanning' && this.valuefor!='CommunicationActivity'))))){alert("Kindly Select Cluster");return;}
    
    
    if(this.selectsForm.get('DataName')?.value=="" && !(this.datafor=='ActivityPlanning' || this.datafor=='SupportSupervisionDC' || this.datafor=='HNDSite' || this.datafor=='HouseHold' || this.datafor=='Project_StaffType' || this.datafor=='WaterSatgnation' || this.datafor=='CommunityVolunteer' || this.datafor=='SchoolData' || this.datafor=='UrbanAshaTraining' || this.datafor=='OjhaTraining' || this.datafor=='HCPTraining' || this.datafor=='CVTraining' || this.datafor=='Activity' || this.datafor=='ChaupalPlanning' || this.datafor=='MeetingPlanning' || this.datafor=='HNDPlanning' || this.datafor=='EModulePlanning' || this.datafor =='SchoolEventPlanning' || this.datafor=='SSVisitPlanning' || this.datafor=='EmbedTarget')){alert("Kindly Enter Name");return;}
    if(this.selectsForm.get('ModalOutletType')?.value=="0" && this.datafor=='OutletData'){alert("Kindly Select Outlet Type");return;}
    if(this.selectsForm.get('ModalProjectStaffTypeID')?.value=="0" && this.datafor=='ProjectStaff'){alert("Kindly Select Project Staff Type");return;}
    if(this.selectsForm.get('ModalProviderTypeID')?.value=="0" && this.datafor=='ServiceProvider'){this.customvalidationerr=true;return;}
    if(this.datafor=='HouseHold' && this.selectsForm.controls['UpdateID'].value=='0' && this.myHHList.length==0){
      alert("Kindly Add Households");return;
    }    
    if (this.selectsForm.valid) {
      // Form is valid, perform submit logic here
      //console.log(this.selectsForm.value);
      var formdata = new FormData();
      this.includeIfDirectives.forEach(directive => {       
        if (directive.shouldInclude()) {          
          const control = directive.getFormControl();
          const ngControl = directive.getngControl();
          const controlName = ngControl.name != null ? ngControl.name.toString() : '';
          formdata.append(controlName,control.value);
        }
      });
      // Object.keys(this.selectsForm.value).forEach(key => {        
      //   formdata.append(key, this.selectsForm.value[key]);
      // });
      if(this.datafor=='Activity' && (this.valuefor=='2' || this.valuefor=='9' || this.valuefor=='10' || this.valuefor=='11' || this.valuefor=='12' || this.valuefor=='24'))
      {
        if(seladministrativeAreas==""){
          alert("Kindly Select Village / Slum");
          return;
        }
        else{
          formdata.append("modalmultipleadministrativeAreaid",seladministrativeAreas);
        }
      }
      else{
        formdata.append("modaladministrativeAreaid",this.selectsForm.get('modaladministrativeAreaid')?.value);
      }
      if(this.datafor=='AdministrativeArea')
      {
        if(this.selectsForm.get('AdministrativeAreaType')?.value=="0"){
          alert("Kindly Select Slum / Village Type");
          return;
        }        
        if(this.selectsForm.get('AdministrativeAreaType')?.value=="PHASE OUT"){
          if(this.selectsForm.get('ModalPhaseOutDate')?.value==""){
            alert("Kindly Enter PhaseOut Date.");
            return;
          }          
        }
        else{
          if(this.selectsForm.get('ModalInceptionDate')?.value==""){
            alert("Kindly Enter Inception Date.");
            return;
          }
        }
      }
      if(this.datafor=='Activity')
      {
        if(selClusters=="" && this.valuefor=='24'){
          alert("Kindly Select Cluster");
          return;
        }
        if(selServiceProviders=="" && (this.valuefor=='9' || this.valuefor=='10' || this.valuefor=='11' || this.valuefor=='12')){
          alert("Kindly Select Service Provider");
          return;
        }
        if(selSchoolActs=="" && this.valuefor=='2'){
          alert("Kindly Select School");
          return;
        }        
      }
      if((this.datafor=='ChaupalPlanning' && this.valuefor =='CommunicationActivity') || (this.datafor=='Activity' && this.valuefor=='24'))
      {
        formdata.append("modalmultipleclusterid",selClusters);
      }
      if(this.datafor=='Activity' && (this.valuefor=='9' || this.valuefor=='10' || this.valuefor=='11' || this.valuefor=='12'))
      {
        formdata.append("ModalServiceProviderIDs",selServiceProviders);
      }
      if(this.datafor=='Activity' && (this.valuefor=='13'))
      {
        formdata.append("ModalProjectStaffIDs",selProjectStaffs);
      }
      if(this.datafor=='Activity' && (this.valuefor=='2'))
      {
        formdata.append("ModalSchoolActIDs",selSchoolActs);
      }
      if(this.datafor=='Activity')
      {
          if(this.selectsForm.get('ModalActivityDate')?.value==""){
            alert("Kindly enter Activity Date");
            return;
          }
          if(this.selectsForm.get('ModalNoofParticipants')?.value==""){
            alert("Kindly enter No.of Participants");
            return;
          }
          if(this.selectsForm.get('ModalActivityName')?.value==""){
            alert("Kindly enter Activity Name");
            return;
          }
      }
      if(this.datafor=='SchoolData'){
        formdata.append("SchoolClass",JSON.stringify(this.myList.value));
      }
      if(this.datafor=='HouseHold'){
        formdata.append("HouseHoldData",JSON.stringify(this.myHHList.value));
      }
        
      formdata.append("UpdateID",this.selectsForm.controls['UpdateID'].value);
      formdata.append("indctr",this.datafor);
      formdata.append("valuefor",this.valuefor);
      formdata.append("valueforid",this.valueforid);
      formdata.append("EnteredBy", this.userlogin.userid);    
      formdata.append("AppVersion", "1.0");   
      if(this.datafor=='AdministrativeArea'){
        for (let i = 0; i < this.ModalSlumPhotos.length; i++) {
          formdata.append(`SlumPhotos${i}`, this.ModalSlumPhotos[i]);
        }
      }
      if(this.datafor=='ProjectStaff'){
        for (let i = 0; i < this.ModalPhotos.length; i++) {
          formdata.append(`Photo${i}`, this.ModalPhotos[i]);
        }
      }
      if(this.datafor=='Activity'){
        for (let i = 0; i < this.ModalActivityPhoto.length; i++) {
          formdata.append(`ActivityPhoto${i}`, this.ModalActivityPhoto[i]);
        }
        for (let i = 0; i < this.ModalActivityAttendance.length; i++) {
          formdata.append(`ActivityAttendance${i}`, this.ModalActivityAttendance[i]);
        }
      }
      this.http.post<any>(`https://rtmis.fhindia.org/api/ModalPostServices.php`, formdata, {
      }).subscribe(res => {  
              
        if(res.success){
          this.messageService.add({ severity: 'success', summary: res.message.replace(this.datafor,this.modaltitle), detail: res.dataid, sticky: true }); 
          this.ResetGeoDataData('Administrative');
          this.ResetFormData();
          this.dataChanged.emit(res.dataid);
          if(this.selectsForm.controls['UpdateID'].value!="" && this.selectsForm.controls['UpdateID'].value!="0" && this.datafor=='SSVisitPlanning'){
            this.SendMail(this.selectsForm.controls['UpdateID'].value);
          }       
          this.selectsForm.get('UpdateID')?.reset('0');   
        }
        else{
          this.messageService.add({ severity: 'warn', summary: res.message, detail: res.dataid, sticky: true }); 
        }
      }), (err:any) => {      
        console.log("Error Occured " + err);
      }
    } else {
        alert("Check Mandatory Fields");
      // Form is invalid, display error messages or handle accordingly
      //this.validateAllFormFields(this.selectsForm);
    }
    
  }
  ref: DynamicDialogRef | undefined;
  SendMail(updid:any){
    this.ref = this.dialogService.open(MailSenderComponent, { 
        data: {
           modalfor: this.datafor,
           valuefor:this.valuefor,
           valueforid:updid,
           isMaster:true
         },
         header: "SSVisitPlanning",
         width: '89%',
         height:'99vh',
         contentStyle: { overflow: 'auto' },
         baseZIndex: 10000,
         maximizable: true,
         draggable:true,
         resizable:false
     });
  }
  ResetGeoDataData(resetfor:any){
    
    if(this.selectsForm==undefined)return;
    if(resetfor=='State'){      
      this.selectsForm.get('modalstateid')?.reset('0');
    }
    if(resetfor=='District'){
      this.districts=[];
      this.selectsForm.get('modaldistrictid')?.reset('0');
    }
    //this.userlogin.logintype=='Admin' || this.userlogin.logintype=='State' || this.userlogin.logintype=='District' || 
    if(resetfor=='State' || resetfor=='District' || resetfor=='Municipal'){
      this.municipalAreas=[];
      this.selectsForm.get('modalmunicipalAreaid')?.reset('0');            
    }
    if(resetfor=='State' || resetfor=='District' || resetfor=='Municipal' || resetfor=='BCCFZone'){
      this.bccfZones=[];
      this.selectsForm.get('modalbccfZoneid')?.reset('0');
    }
    if(resetfor=='State' || resetfor=='District' || resetfor=='Municipal' || resetfor=='BCCFZone' || resetfor=='Territorial'){
      this.territorialAreas=[];
      this.selectsForm.get('modalterritorialAreaid')?.reset('0');
    }
    if(resetfor=='State' || resetfor=='District' || resetfor=='Municipal' || resetfor=='BCCFZone' || resetfor=='Territorial'  || resetfor=='Administrative'){
      this.administrativeAreas=[];
      this.selectedadministrativeAreas=[];
      this.selectsForm.get('modaladministrativeAreaid')?.reset('0');
      this.selectsForm.get('modalmultipleadministrativeAreaid')?.setValue('');
    }
    if(resetfor=='State' || resetfor=='District' || resetfor=='Municipal' || resetfor=='BCCFZone' || resetfor=='Territorial'  || resetfor=='Administrative'  || resetfor=='Cluster'){
      this.clusters=[];
      this.selectedClusters=[];
      this.selectsForm.get('modalclusterid')?.reset('0');
      this.selectsForm.get('modalmultipleclusterid')?.setValue('');
    }
    this.selectsForm.get('HNDSiteID')?.reset('0');
    this.selectsForm.get('PlanningServiceProviderID')?.reset('0');
    this.selectsForm.get('PlanningSchoolID')?.reset('0');
  }
  ResetFormData(){
    if(this.selectsForm==undefined)return;
    
    this.selectsForm.get('DataName')?.reset('');
    this.selectsForm.get('HNDSiteID')?.reset('0');
    this.selectsForm.get('PlanningServiceProviderID')?.reset('0');
    this.selectsForm.get('PlanningSchoolID')?.reset('0');
    this.selectsForm.get('FirstPlanningDate')?.reset('');
    this.selectsForm.get('SecondPlanningDate')?.reset('');
    this.selectsForm.get('ThirdPlanningDate')?.reset('');
    this.selectsForm.get('FourthPlanningDate')?.reset('');
    this.selectsForm.get('FifthPlanningDate')?.reset('');
    this.selectsForm.get('SixthPlanningDate')?.reset('');
    this.selectsForm.get('SeventhPlanningDate')?.reset('');
    this.selectsForm.get('EighthPlanningDate')?.reset('');
    this.selectsForm.get('NinthPlanningDate')?.reset('');
    this.selectsForm.get('TenthPlanningDate')?.reset('');
    this.selectsForm.get('EleventhPlanningDate')?.reset('');
    this.selectsForm.get('TwelvthPlanningDate')?.reset('');
    this.selectsForm.get('NIVSlumName')?.reset('');
    this.selectsForm.get('NIVAshaName')?.reset('');
    this.selectsForm.get('PlanningActivityType')?.reset('0');
    this.selectsForm.get('TrainingType')?.reset('0');
    this.selectsForm.get('PlanningFor')?.reset('');
    this.selectsForm.get('ModalLatitude')?.reset('');
    this.selectsForm.get('AdministrativeAreaType')?.reset('');

      this.selectsForm.get('ModalLongitude')?.reset('');
      this.selectsForm.get('ModalPINCode')?.reset('');
      this.selectsForm.get('ModalSlumCode')?.reset('');
      this.selectsForm.get('ModalInceptionDate')?.reset('');
      this.selectsForm.get('ModalPhaseOutDate')?.reset('');
      //this.selectsForm.get('ModalSlumPhoto')?.reset('');
      this.selectsForm.get('ModalLayoutDate')?.reset('');
      this.selectsForm.get('ModalLayoutHelper')?.reset('');
      this.selectsForm.get('Modalshcname')?.reset('');
      this.selectsForm.get('Modalshcdist')?.reset('');
      this.selectsForm.get('Modalphcname')?.reset('');
      this.selectsForm.get('Modalphcdist')?.reset('');
      this.selectsForm.get('Modaldistrhospname')?.reset('');
      this.selectsForm.get('Modaldistrhospdist')?.reset('');
      this.selectsForm.get('Modalprivhospname')?.reset('');
      this.selectsForm.get('Modalprivhospdist')?.reset('');
      this.selectsForm.get('ModalWaterStagnationNo')?.reset('');
      
      this.selectsForm.get('ModalWaterStagnationTypeID')?.reset('0');
      this.selectsForm.get('ModalHouseHoldNo')?.reset('');
      this.selectsForm.get('ModalLarvaStatus')?.reset('');
      this.selectsForm.get('ModalStructureNo')?.reset('');
      this.selectsForm.get('ModalNoofmember')?.reset('');
      this.selectsForm.get('ModalHouseHoldname')?.reset('');
      this.selectsForm.get('ModalHHMobile')?.reset('');
      this.selectsForm.get('ModalRented')?.reset('');
      this.selectsForm.get('ModalRenteddate')?.reset('');
      this.selectsForm.get('ModalMobile')?.reset('');
      this.selectsForm.get('ModalPSemail')?.reset('');
      this.selectsForm.get('ModalPSOfficialemail')?.reset('');
      this.selectsForm.get('ModalAddress')?.reset('');
      this.selectsForm.get('ModalPostingLocation')?.reset('');
      this.selectsForm.get('ModalEducation')?.reset('');
      this.selectsForm.get('ModalExperience')?.reset('');
      this.selectsForm.get('ModalJoiningDate')?.reset('');
      this.selectsForm.get('ModalLeavingDate')?.reset('');
      this.selectsForm.get('ModalDistanceO_L1')?.reset('');
      this.selectsForm.get('ModalDistanceL1_L2')?.reset('');
      this.selectsForm.get('ModalDistanceL2_O')?.reset('');
      this.selectsForm.get('ModalSSTimeFrom')?.reset('');
      this.selectsForm.get('ModalSSTimeTo')?.reset('');
      this.selectsForm.get('ModalSSStaffType')?.reset('');
      this.selectsForm.get('ModalSSVehicle')?.reset('');
      this.selectsForm.get('ModalSSActivity')?.reset('');
      this.selectsForm.get('ModalSSVisitDate')?.reset('');
      this.selectsForm.get('ModalExecutionStatus')?.reset('');
      this.selectsForm.get('ModalDeviationReason')?.reset('');
      this.selectsForm.get('ModalRemark')?.reset('');
      this.ModalPhotos=[];
      this.ModalSlumPhotos=[];

      this.selectsForm.get('ModalSchoolCode')?.reset('');
      this.selectsForm.get('ModalSchoolname')?.reset('');
      this.selectsForm.get('ModalSchoolCategory')?.reset('');
      this.selectsForm.get('ModalSchoolManagement')?.reset('');
      this.selectsForm.get('ModalPrincipalName')?.reset('');
      this.selectsForm.get('ModalPrincipalMobile')?.reset('');
      this.selectsForm.get('ModalTeachersMale')?.reset('');
      this.selectsForm.get('ModalTeachersFemale')?.reset('');

      this.selectsForm.get('ModalActivityConductedBy')?.reset('');
      this.selectsForm.get('ModalActivityName')?.reset('');
      this.selectsForm.get('ModalActivityVenue')?.reset('');
      this.selectsForm.get('ModalActivityBackground')?.reset('');
      this.selectsForm.get('ModalActivityObjectives')?.reset('');
      this.selectsForm.get('ModalActivityProcess')?.reset('');
      this.selectsForm.get('ModalActivityDate')?.reset('');
      this.selectsForm.get('ModalActivityTime')?.reset('');
      this.selectsForm.get('ModalNoofParticipants')?.reset('');
      this.selectsForm.get('ModalNameofParticipants')?.reset('');
      this.selectsForm.get('ModalOutcomeofmeeting')?.reset('');

      this.selectsForm.get('ProjectInformation')?.reset('');
      this.selectsForm.get('ProjectName')?.reset('');
      this.selectsForm.get('NameofTeamMembers')?.reset('');
      this.selectsForm.get('CaseIdentificationNumber')?.reset('');
      this.selectsForm.get('participantname')?.reset('');
      this.selectsForm.get('father_husband_name')?.reset('');
      this.selectsForm.get('household_head')?.reset('');
      this.selectsForm.get('household_number')?.reset('');
      this.selectsForm.get('address_landmark')?.reset('');
      this.selectsForm.get('noofmember')?.reset('');
      this.selectsForm.get('noofperson_testdone')?.reset('');
      this.selectsForm.get('noofperson_testpositive')?.reset('');
      this.selectsForm.get('noof_pfpvmixedcase')?.reset('');
      this.selectsForm.get('noofperson_treatmentstarted')?.reset('');
      this.selectsForm.get('noofhousehold')?.reset('');
      this.selectsForm.get('noofhousehold_feversurvey')?.reset('');
      this.selectsForm.get('noofhousehold_feveridentified')?.reset('');
      this.selectsForm.get('noofperson_malariadenguescreen')?.reset('');
      this.selectsForm.get('noofperson_testedmalden')?.reset('');
      this.selectsForm.get('noofperson_pfpvmixedcase')?.reset('');
      this.selectsForm.get('noofhousehold_larvasurved')?.reset('');
      this.selectsForm.get('noofhousehold_larvafound')?.reset('');
      this.selectsForm.get('sourcereductionactivities')?.reset('');
      this.selectsForm.get('noofwaterstagnation')?.reset('');
      this.selectsForm.get('noofwaterstagnation_inspected')?.reset('');
      this.selectsForm.get('noofwaterstagnation_larvafound')?.reset('');
      this.selectsForm.get('typeof_larvadestruction')?.reset('');
      this.ModalActivityPhoto=[];
      this.ModalActivityAttendance=[];
      this.selectedClusters=[];
      this.selectedServiceProviders=[];
      this.selectedProjectStaffs=[];
      this.selectedSchoolAct=[];
      this.masterSchoolClass=[];
      this.myList?.clear();
      this.myHHList?.clear();
  }
  
  validationErrors!:any;
  invalidFields() {
    this.validationErrors = {};    
    Object.keys(this.selectsForm.controls).forEach(controlName => {
      const control = this.selectsForm.get(controlName);
      
      if (control?.invalid) {
        // Check if custom validation errors exist for the control
        const customErrors = control.errors && control.errors['custom'];
        if (customErrors) {
          this.validationErrors[controlName] = customErrors;
        }
      }
    });
    
    return this.validationErrors;
  }
  getCustomErrorMessage(error: any): string {
    if (error.custom) {
      return 'Custom validation error';
    }
  
    return 'Validation error';
  }
  validateDate(control: AbstractControl): ValidationErrors | null {    
    const currentDate = new Date();
    const selectedDate = new Date(control.value);
   if(control.value==""){return null;}
    // Add your custom validation logic here...
    // if (isNaN(selectedDate.getTime()) || selectedDate < currentDate) {
    //   return { invalidDate: true };
    // }

    // Check if the date falls within the current financial year
    // const financialYearStart = new Date(currentDate.getFullYear(), 3, 1); // Assuming financial year starts from April 1st
    // const financialYearEnd = new Date(currentDate.getFullYear() + 1, 2, 31); // Assuming financial year ends on March 31st

    // if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
    //   return { financialYear: true };
    // }

    return null;
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  isShift: boolean[] = [];

  onKeyDown(event: KeyboardEvent, formctrlname: any) {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 16) {
      this.isShift[formctrlname] = true;
    }
  }
  onKeyUpInner(event: KeyboardEvent, formctrlname: any,ind:any) {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 16) {
      this.isShift[formctrlname] = false;
    }    
    var dateValue = this.myHHList.controls[ind].get(formctrlname)?.value;
    if (
      (keyCode >= 48 && keyCode <= 57) || // Numeric keys
      keyCode === 8 || // Backspace
      keyCode <= 37 || // Arrow keys
      keyCode <= 39 || // Arrow keys
      (keyCode >= 96 && keyCode <= 105) // Numpad keys
    ) {
      if ((dateValue.length === 4 || dateValue.length === 7) && keyCode !== 8) {
        dateValue += '-';
        this.myHHList.controls[ind].get(formctrlname)?.setValue(dateValue);
      }
    } else {
      event.preventDefault();
    }
  }
  onKeyUp(event: KeyboardEvent, formctrlname: any) {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 16) {
      this.isShift[formctrlname] = false;
    }
    
var formctrl = this.selectsForm.get(formctrlname);
if(formctrlname=='Renteddate'){formctrl=this.myHHList.get(formctrlname);}
    var dateValue = formctrl?.value;
    if (
      (keyCode >= 48 && keyCode <= 57) || // Numeric keys
      keyCode === 8 || // Backspace
      keyCode <= 37 || // Arrow keys
      keyCode <= 39 || // Arrow keys
      (keyCode >= 96 && keyCode <= 105) // Numpad keys
    ) {
      if ((dateValue.length === 4 || dateValue.length === 7) && keyCode !== 8) {
        dateValue += '-';
        if(formctrlname=='Renteddate'){this.myHHList.get(formctrlname)?.setValue(dateValue);}
        else{this.selectsForm.get(formctrlname)?.setValue(dateValue);}
      }
    } else {
      event.preventDefault();
    }
  }
  Q1Start:any;Q1End:any;Q2Start:any;Q2End:any;Q3Start:any;Q3End:any;Q4Start:any;Q4End:any;CurDate:any;
  LoadQuarterDated(){    
    var s_finyear:any = new Date().getFullYear();
    var e_finyear:any = new Date().getFullYear();
    var curmonth:any = new Date().getMonth()+1;
    if (curmonth <= 3) {
      s_finyear = s_finyear - 1;
      e_finyear = e_finyear;
    } else {
        s_finyear = s_finyear;
        e_finyear = e_finyear + 1;
    }
    var fs_day = new Date(s_finyear, 3, 1);
    var fe_day = new Date(e_finyear, 2, 31);
    var cs_day = new Date(new Date().getFullYear(), 0, 1);  
    this.Q1Start=  moment(new Date(s_finyear,3,1));
    this.Q1End=  moment(new Date(s_finyear,5,30));
    this.Q2Start=  moment(new Date(s_finyear,6,1));
    this.Q2End=  moment(new Date(s_finyear,8,30));
    this.Q3Start=  moment(new Date(s_finyear,9,1));
    this.Q3End=  moment(new Date(s_finyear,11,31));
    this.Q4Start=  moment(new Date(e_finyear,0,1));
    this.Q4End=  moment(new Date(e_finyear,2,31));
    this.CurDate = moment();
    // 'Today': [moment(), moment()],
    // 'Current Financial Year': [moment(fs_day), moment(fe_day)],
    // 'Current Calendar Year': [moment(cs_day), moment()],
    // 'Quarter-1': [moment(new Date(s_finyear,3,1)), moment(new Date(s_finyear,5,30))],
    // 'Quarter-2': [moment(new Date(s_finyear,6,1)), moment(new Date(s_finyear,8,30))],
    // 'Quarter-3': [moment(new Date(s_finyear,9,1)), moment(new Date(s_finyear,11,31))],
    // 'Quarter-4': [moment(new Date(e_finyear,0,1)), moment(new Date(e_finyear,2,31))],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    // 'Three Month': [moment().subtract(3, 'month'), moment()]    
    return null;
  }
  
}
