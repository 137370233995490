import { Injectable } from '@angular/core';

const USER_KEY = 'auth-user';
const PER_KEY = 'auth-perm';
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  clean(): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.clear();
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return this.decodeToken(user).data;
    }

    return null;
  }
  public savePermission(perm: any): void {
    window.sessionStorage.removeItem(PER_KEY);
    window.sessionStorage.setItem(PER_KEY, JSON.stringify(perm));
  }

  public getPermission(): any {
    const perm = window.sessionStorage.getItem(PER_KEY); 
    if (perm) {
      return perm;
    }

    return null;   
    
  }
  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return true;
    }

    return false;
  }
  decodeToken(token: string): any {
	  const base64Url = token.split('.')[1];
	  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	  const jsonPayload = decodeURIComponent(
		atob(base64)
		  .split('')
		  .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
		  .join('')
	  );

	  return JSON.parse(jsonPayload);
	}
}
