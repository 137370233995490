import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  startOfMonth,
  startOfWeek,
  endOfWeek,
} from 'date-fns';
import { Observable, Subject, Subscription, map } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { MessageService } from 'primeng/api';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { StorageService } from '../login/storage.service';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/shared/services/filter.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import moment from 'moment';

interface EventPlanning {
  id: number;
  title: string;
  start: string;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-activity-calendar',
  templateUrl: './activity-calendar.component.html',
  styleUrls: ['./activity-calendar.component.css'],
  providers: [DialogService,MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityCalendarComponent implements OnInit { 
  
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  refresh: Subject<void> = new Subject();

  ref: DynamicDialogRef | undefined;  
  events$: Observable<CalendarEvent<{ eventplan: EventPlanning; }>[]> | undefined;  
  userlogin:any;
  filterState: any = {};
  private filterStateSubscription!: Subscription; 
  selectedDateRange :any;
  constructor(private rtmisservice:FHIRtmisService, private storageService: StorageService
    , private router: Router,private filterService: FilterService,private dialogService: DialogService, private messageService: MessageService) {    
    this.userlogin=this.storageService.getUser();
    if(this.userlogin){    
        this.selectedDateRange= { startDate: moment().startOf('month'), endDate: moment().endOf('month') };        
        filterService.resetFilter();
   this.filterState = filterService.filterState;    
   this.filterService.setFilterValue("selectedDateRange", this.selectedDateRange,false);
    }
    else{
      this.router.navigate(['/login']);
    }
  }
 
  ngOnInit(): void {
    this.filterStateSubscription = this.filterService.getFilterStateChanges().subscribe(filterData => {
        // Update filter state with the changed value
        this.filterState = filterData.filterState; 
        // Call the method in other component to handle the filter changes
        if(this.filterService.isInitialized()){
          //this.handleFilterChanges(filterData.changedFilterName, filterData.changedFilterValue, filterData.previousFilterValue);
          if(this.filterService.getFilterValue("SelBCCFZoneID") != "0"){
            this.BindAllData();
          }
        }
      });   
      
  }
  BindAllData(){
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay
    }[this.view];
    this.events$ = this.rtmisservice.GetCalendarDataSummary(this.filterState,this.selectedDateRange).pipe(
      map(({ results }: { results: EventPlanning[] }) => {
        return results.map((film: EventPlanning) => {
          return {
            title: film.title,
            start: new Date(
              film.start //+ getTimezoneOffsetString(this.viewDate)
            ),
            color: colors.yellow,
            allDay: true
          };
        });
      })
    );
    this.refresh.next();
    console.log(this.events$);
  }

  ngOnDestroy(): void {
    if (this.filterStateSubscription) {
      this.filterStateSubscription.unsubscribe();
    }
    this.filterStateSubscription.unsubscribe();
    if (this.ref) {
          this.ref.close();
      }
  }
  activeDayIsOpen: boolean = true;

  setView(view: CalendarView) {
    this.view = view;
  }
}