<style>
  .footer {
    width: 100%;    
  }
    .footer .inner {
      color: white;
      font-family: 'Anaheim';
      font-size: 12px;
      background-color: rgba(0,0,0,.6);
      padding: 0px 15px 0px 15px;
      text-align: center;
    }
      .footer .inner a {
        color: orange;
        font-weight: bold;
      }

</style>
<footer class="footer">
  <div class="inner">
    <!-- Designed and Developed by <a target="_blank" href="http://smilingbrains.com/">Smiling Brains Software Solutions</a> -->
    <p style="text-align: center; margin-bottom: 0rem;">Last reviewed and updated on :<span id="rdate">{{curdate}}. </span></p>
    <!--<img class="statcounter" src="https://c.statcounter.com/10362311/0/8e310e8a/0/" alt="counter">-->
  </div>
</footer>
