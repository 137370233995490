<style>
  #site-header {
    position: fixed;
    _position: absolute;
    top: 0px;
    left: 0;
    height: 45px;
    z-index: 99999;
    width: 100%;
    padding: 0;
    background: #DC005A;
    border-bottom: 2px solid #fff;
    box-shadow: 0 -3px 8px #000000;
  }

    #site-header .site-title {
      display: inline-block;
      padding: 0px 5px 10px 5px;
      background: #fff;
      position: absolute;
      top: 0;
      left: 30px;
      z-index: 9999;
      width: 310px;
      height: 75px;
      -webkit-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.15);
      -ms-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.15);
      box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.15);
    }

      #site-header .site-title a {
        display: block;
        background: url(assets/gallery/phfilogo.jpg) no-repeat 0 0;
        width: 300px;
        height: 75px;
        background-size: 300px 75px;
        color: #e8592b;
        text-indent: -999em;
      }

  
  

  .nationalLogo {
    display: block;
    padding-top: 1px;
  }
  .headlogo{
    padding-right:1px;
  }
  
</style>
<div class="wrapper" id="myDiv1">
  <header class="sticky" id="site-header">
    <div class="nationalLogo">
      <!--<img src="../../../assets/gallery/prerna.jpg" style="height:70px;padding-left:1px;" class="img-fluid headlogo">
      <img src="../../../assets/gallery/unicef.png" class="img-fluid headlogo">
      <img src="../../../assets/gallery/phfi.png" class="img-fluid headlogo">-->
      <adminnavbar-cmp></adminnavbar-cmp>
    </div>
  </header>
  <!-- END HEADER -->
  <div class="container-fluid" style="margin-top:80px;padding:1px !important;">
    <!-- BEGIN CONTAINER -->
    <div class="page-content" style="background: #f7f7f7;padding: 1px;">
      <!-- BEGIN BREADCRUMBS -->
      <div class="heading">
        <h1>{{getTitle()}}<small></small></h1>
      </div>
      <!-- END BREADCRUMBS -->
      <!-- BEGIN PAGE CONTENT INNER -->
      <router-outlet></router-outlet>
    </div>
  </div>
  <footer-cmp></footer-cmp>
</div>
