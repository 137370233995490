
<div class="wrapper" id="myDiv1">
  <header class="sticky" id="site-header">
    <header id="headerbanner">
      <homenavbar-cmp></homenavbar-cmp>
    </header>
  </header>
  <!-- END HEADER -->
  <div class="container-fluid">
    <!-- BEGIN CONTAINER -->
    <div class="page-content" style="background: #f7f7f7;padding: 15px;">
      <masterfilter-cmp></masterfilter-cmp>
      <!-- BEGIN PAGE CONTENT INNER -->
      <router-outlet></router-outlet>
    </div>
  </div>
  <footer-cmp></footer-cmp>
</div>
