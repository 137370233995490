import { Component } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { FHIRtmisService } from 'src/app/shared/services/fhirtmis.service';
import { FilterService } from 'src/app/shared/services/filter.service';

@Component({
  selector: 'app-data-home',
  templateUrl: './data-home.component.html',
  styleUrls: ['./data-home.component.css']
})
export class DataHomeComponent {
  filterState: any = {};
  isstaticdataloaded:boolean=false;
  isdynamicdataloaded:boolean=false;
  StaticSummaryData:any={};
  DynamicSummaryData:any ={}; 
  selectedDateRange :any;
  constructor(private rtmisservice:FHIRtmisService
    , private router: Router,private filterService: FilterService) {    
   this.LoadDateRange();
   this.selectedDateRange= { startDate: moment(this.fs_day), endDate: moment(this.fe_day) };
   filterService.resetFilter();
   this.filterState = filterService.filterState;    
   this.filterService.setFilterValue("selectedDateRange", this.selectedDateRange,false);
   this.BindAllData();
  }
  
  ngOnInit() {
     
  }
  
  BindAllData(){
    this.DynamicSummaryData=[];
      this.isdynamicdataloaded=false;
      this.isstaticdataloaded=false;
      this.StaticSummaryData=[];
      this.rtmisservice.GetDashboardDataSummary('StaticSummary',this.filterState).subscribe((data: [{}]) => {
        if(data.length>0){
          this.StaticSummaryData = data[0];
          this.isstaticdataloaded=true;
        }           
      });
      this.DynamicSummaryData = {"Chaupal":20883,"SourceReduction":788,"CommunicationVan":214,"HND":2253,"AshaTraining":629,"CommunityDrive":1322,"SchoolEvents":185};
      
      this.isdynamicdataloaded=true;
      //console.log(this.DynamicSummaryData);
  }
  fs_day !:any;fe_day !:any;ranges:any;
  LoadDateRange():void{
    var s_finyear:any = new Date().getFullYear();
    var e_finyear:any = new Date().getFullYear();
    var curmonth:any = new Date().getMonth()+1;
    if (curmonth <= 3) {
      s_finyear = s_finyear - 1;
      e_finyear = e_finyear;
    } else {
        s_finyear = s_finyear;
        e_finyear = e_finyear + 1;
    }
    this.fs_day = new Date(s_finyear, 3, 1);
    this.fe_day = new Date(e_finyear, 2, 31);
    var cs_day = new Date(new Date().getFullYear(), 0, 1);
    this.ranges = {
        'Today': [moment(), moment()],
        'Current Financial Year': [moment(this.fs_day), moment(this.fe_day)],
        'Current Calendar Year': [moment(cs_day), moment()],
        'Quarter-1': [moment(new Date(s_finyear,3,1)), moment(new Date(s_finyear,5,30))],
        'Quarter-2': [moment(new Date(s_finyear,6,1)), moment(new Date(s_finyear,8,30))],
        'Quarter-3': [moment(new Date(s_finyear,9,1)), moment(new Date(s_finyear,11,31))],
        'Quarter-4': [moment(new Date(e_finyear,0,1)), moment(new Date(e_finyear,2,31))],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Three Month': [moment().subtract(3, 'month'), moment()]
    };
  }
}
